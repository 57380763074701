import {IonContent, IonPage} from "@ionic/react";
import React from "react";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    description: string,
    title: string,
}

const BrandDescriptionModal: React.FC<ContainerProps> = ({onDismiss, description, title}) => {

    async function confirm() {
        onDismiss();
    }

    return (
        <IonPage>
            <IDXModalHeader leftButtonAction={() => onDismiss(null, 'cancel')}
                            titleText={`${title} Info`}
                            rightButtonText={'Close'} rightButtonAction={() => onDismiss(null, 'cancel')}/>
            <IonContent className="ion-padding">
                <div>
                    {description.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br/>
                        </React.Fragment>
                    ))}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default BrandDescriptionModal;
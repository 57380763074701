import React, {useEffect} from 'react';
import {IonButton, IonButtons, IonHeader, IonTitle, IonToolbar} from "@ionic/react";

interface ChipProps {
    leftButtonText?: string;
    leftButtonAction?: () => void
    titleText: string;
    rightButtonAction?: () => void
    rightButtonText: string;
}

const IDXModalHeader: React.FC<ChipProps> = ({
                                                 leftButtonText,
                                                 leftButtonAction,
                                                 titleText,
                                                 rightButtonAction,
                                                 rightButtonText
                                             }) => {

    useEffect(() => {
    }, [leftButtonText, leftButtonAction, titleText, rightButtonAction, rightButtonText]);

    return (
        <IonHeader>
            <IonToolbar className="flex flex-row">
                <IonButtons slot="start" className={!leftButtonText ? "mr-14" : ""}>
                    <IonButton strong={true} color="theme-alternative" onClick={leftButtonAction}>
                        {leftButtonText}
                    </IonButton>
                </IonButtons>
                <IonTitle className="items-center text-center">{titleText}</IonTitle>
                <IonButtons slot="end">
                    <IonButton strong={true} color="theme-alternative" onClick={rightButtonAction}>
                        {rightButtonText}
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
};

export default IDXModalHeader;
import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

// @ts-ignore
import { Auth0Lock } from 'auth0-lock';

const Claim: React.FC = () => {
    const [name, setName] = React.useState('');

    const history = useHistory();

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    // @ts-ignore
    var accessToken = null;
    var profile = null;
    // @ts-ignore
    var lock = null;

    useEffect(() => {
        console.log("Loading Login Claim Page!");
        if (query.has("username") && query.has("infd_id") && query.has("name")) {
            console.log("Claim URL is valid, loading Lock Auth0 page");

            console.log("Username: " + query.get("username"));
            console.log("Infd ID: " + query.get("infd_id"));
            console.log("Name: " + query.get("name"));

            const name = query.get("name");
            const infd_id = query.get("infd_id");
            const username = query.get("username");
            setName(name);

            lock = new Auth0Lock(process.env.REACT_APP_AUTH_CLIENT_ID, process.env.REACT_APP_AUTH_EXTERNAL_DOMAIN_LOCK, {
                auth: {
                    redirectUrl: process.env.REACT_APP_REDIRECT_URI + '/profile',
                    responseType: 'token',
                },
                additionalSignUpFields: [{
                    type: "hidden",
                    name: "id_name",
                    value: name
                },
                {
                    type: "hidden",
                    name: "infd_id",
                    value: infd_id
                },
                {
                    type: "hidden",
                    name: "id_username",
                    value: username
                }],
                allowLogin: false,
                allowSignUp: true,
                allowedConnections: ['Username-Password-Authentication', 'google-oauth2', 'apple'],
                theme: {
                    logo: "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG.png",
                    primaryColor: "#5963A7",
                },
                container: "auth0-lock-container",
                showTerms: false,
                forceAutoHeight: true
            });
            lock.show();

            // @ts-ignore
            lock.on('authenticated', function (authResult) {
                // @ts-ignore
                lock.getUserInfo(authResult.accessToken, function (error, profileResult) {
                    if (error) {
                        // Handle error
                        return;
                    }

                    accessToken = authResult.accessToken;
                    console.log('Access Token: ' + accessToken);
                    profile = profileResult;
                    console.log('Profile: ' + JSON.stringify(profile));
                    history.push('/profile/')
                });
            });

        } else {
            console.log("Invalid Claim Link");
            history.push('/home')
        }
    }, [history, location.search, lock]);

    return (
        <IonPage>
            <IonContent>
                <div className="flex flex-col gap-2 m-4 space-y-4 text-center">
                    <div>Claim your profile {name.split(" ")[0]} for Ecliptic!</div>
                    <div>Thanks for signing up, your input and participation in Ecliptic will truly impact Ecliptic in
                        an
                        extremely positive way, help shape skiing and snowboarding the way you want, and hopefully the
                        inspire next generation, regardless of where they live or who they are
                    </div>
                    <div>You will be able to use this login for Ecliptic across all platforms (iOS, Android, and Web)
                    </div>
                    <div id='auth0-lock-container' style={{ flexGrow: "unset" }}></div>
                    <div>Hopefully with your involvement, we can start to see more skiers, snowboarders, coaches,
                        halfpipes, terrain parks, films, broadcasts, and competitions around the world. - Brendan,
                        Cindy, and Tagg
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Claim;
import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonIcon, IonInput } from '@ionic/react';
import { isEmail } from 'validator';
import { useAppState } from "../../../AppListenerProvider";
import { useAuth } from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import '../Welcome.css'
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import { Keyboard } from "@capacitor/keyboard";
import { arrowBack, mailOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { OnboardingStatus } from '../../../models/onboardingStatus';
import { useSignUp } from '../SignUpProvider/SignUpProvider';

interface WelcomeLoginForgotPasswordProps { }

const WelcomeForgotPassword: React.FC<WelcomeLoginForgotPasswordProps> = ({ }) => {
    const history = useHistory();
    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [emailSent, setEmailSent] = useState(false);
    const { setStep } = useSignUp();

    const { isActive, isNative, platform } = useAppState();
    const { user, isLoading, isAuthenticated, firebaseApp, auth } = useAuth();
    const authService = AuthService({ user, isLoading, isAuthenticated, firebaseApp, auth }, {
        isActive,
        isNative,
        platform
    });

    const userInput = useRef<HTMLIonInputElement>(null);

    const forgotEmailHandler = async (email: string) => {
        try {
            const res = await authService.sendPasswordResetEmail(email);
            if (res) {
                setEmailSent(true);
            }
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            setError("Invalid email or password. Please try again or text us at 1-855-MAX-BISK (1-855-629-2475), reach out on IG @ecliptic.day, or email mia@idx.style if you think this was an error and we will get this sorted ASAP!");
        }
    }

    const onBack = async () => {
        setEmailOrUsername('');
        setError('');
        setEmailSent(false);
        console.log("Going back to login page");
        history.push('/flow/login');
    }

    const handleSendPassword = async () => {
        // remove focus from input fields
        userInput.current?.blur();

        if (!emailOrUsername) {
            setError('Email is required');
            return;
        }

        if (isEmail(emailOrUsername)) {
            console.log('Logging in with email:', emailOrUsername);
            const res = await forgotEmailHandler(emailOrUsername);
        } else {
            setError('A valid email is required');
            return;
        }
    };

    const setFocusForInput = async () => {
        setTimeout(async () => {
            await userInput.current?.setFocus();
            if (isNative) {
                await Keyboard.show();
            }
        }, 300);
    };

    useEffect(() => {
        console.log("Loading Login In Flow Page");
        setError(null);
        setEmailOrUsername('');

        // Focus the userInput and show keyboard
        setFocusForInput();
    }, [isNative]);

    const onKeyPress = async (e: any) => {
        if (e.key === 'Enter') {
            await handleSendPassword();
        }
    }

    const handleSetEmailOrUsername = (emailOrUsername: string) => {
        if (error) setError(null);
        setEmailOrUsername(emailOrUsername);
    }

    if (emailSent) {
        return (
            <OnboardingPageWrapper
                title={'Check Your Email'}
                showBackButton={true}
                showSkipButton={false}
                onBack={onBack}>
                <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                    <div className="w-full max-w-md text-center">
                        <p className="text-white-500 font-bold mb-6">
                            We've sent a password reset link to your email address.
                            Please check your inbox and follow the instructions to reset your password.
                        </p>
                        <p className="text-sm font-bold text-white-500 mb-8">
                            If you don't see the email, check your spam folder or text us at 1-855-MAX-BISK (1-855-629-2475)
                            for assistance.
                        </p>
                        <div className="mt-2">
                            <IonButton
                                expand="block"
                                className="neon-button w-full normal-case font-bold"
                                onClick={onBack}>
                                <span className="text-lg normal-case font-bold">Back To Login</span>
                            </IonButton>
                        </div>
                    </div>
                </div>
            </OnboardingPageWrapper >
        );
    }

    return (
        <OnboardingPageWrapper
            title={'Enter Email To Recover Password'}
            showBackButton={true}
            showSkipButton={false}
            onBack={onBack}>
            <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                <div className="mt-4 space-y-2 font-bold">
                    <IonInput
                        ref={userInput}
                        type="text"
                        color="light"
                        placeholder="Email"
                        value={emailOrUsername}
                        onIonInput={e => handleSetEmailOrUsername(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                    />
                    {error && <div className="text-red-500">{error}</div>}
                    <div className="text-sm font-bold text-white-500"
                        onClick={() => setError('Text us at 1-855-MAX-BISK (1-855-629-2475), reach out on IG @ecliptic.day, or email mia@idx.style and we will get this sorted ASAP!')}>
                        Didn't receive an email or forgot email/username?
                    </div>
                    <div className="mt-2">
                        <IonButton
                            expand="block"
                            className="neon-button w-full normal-case font-bold"
                            disabled={emailOrUsername === ""}
                            onClick={handleSendPassword}>
                            <span className="text-lg normal-case font-bold">Send Email</span>
                        </IonButton>
                    </div>
                </div>
            </div>
        </OnboardingPageWrapper >
    );
};

export default WelcomeForgotPassword;
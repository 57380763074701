import React from 'react';
import { IonSkeletonText, IonIcon, IonButton } from '@ionic/react';
import { heart, chatbubble, share } from 'ionicons/icons';

const TrickVideoLoading = () => {
    return (
        <div className="text-white p-4 max-w-md mx-auto">

            {/* Video placeholder */}
            <div className="aspect-video bg-gray-700 mb-4 rounded-lg relative animate-pulse">
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-12 h-12 bg-gray-600 rounded-full"></div>
                </div>
            </div>

            {/* Header */}
            <div className="flex items-center mb-4">
                <div className="w-10 h-10 bg-gray-700 rounded-full mr-3"></div>
                <div className="flex-1">
                    <IonSkeletonText animated className="h-4 w-1/2 mb-2" />
                    <IonSkeletonText animated className="h-3 w-1/3" />
                </div>
            </div>

            {/* Caption */}
            <IonSkeletonText animated className="h-4 w-3/4 mb-2" />
            <IonSkeletonText animated className="h-4 w-1/2 mb-4" />

            {/* Comments */}
            <div className="border-t border-gray-700 pt-4">
                <div className="flex items-center mb-3">
                    <div className="w-8 h-8 bg-gray-700 rounded-full mr-2"></div>
                    <IonSkeletonText animated className="h-4 w-3/4" />
                </div>
                <div className="flex items-center">
                    <div className="w-8 h-8 bg-gray-700 rounded-full mr-2"></div>
                    <IonSkeletonText animated className="h-4 w-1/2" />
                </div>
            </div>
        </div>
    );
};

export default TrickVideoLoading;
import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection } from "@capacitor-community/sqlite";
import { useRef, useState, useEffect } from "react";

const DB_NAME = "ecliptic";

const useSQLiteDB = () => {
    const db = useRef<SQLiteDBConnection>();
    const sqlite = useRef<SQLiteConnection>();
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        const initializeDB = async () => {
            try {
                if (sqlite.current) return;

                sqlite.current = new SQLiteConnection(CapacitorSQLite);
                const ret = await sqlite.current.checkConnectionsConsistency();
                const isConn = (await sqlite.current.isConnection(DB_NAME, false)).result;

                if (ret.result && isConn) {
                    db.current = await sqlite.current.retrieveConnection(DB_NAME, false);
                } else {
                    db.current = await sqlite.current.createConnection(
                        DB_NAME,
                        false,
                        "no-encryption",
                        1,
                        false
                    );
                }

                // Open the database immediately after creation/retrieval
                await db.current.open();

                // Initialize tables
                await initializeTables();

                console.log("DB initialized");
                setInitialized(true);
            } catch (error) {
                console.error("Failed to initialize database:", error);
                setInitialized(false);
            }
        };

        initializeDB();

        // Cleanup function
        return () => {
            const cleanup = async () => {
                try {
                    if (db.current && (await db.current.isDBOpen())?.result) {
                        await db.current.close();
                    }
                } catch (error) {
                    console.error("Error during cleanup:", error);
                }
            };
            cleanup();
        };
    }, []);

    const performSQLAction = async (
        action: (db: SQLiteDBConnection | undefined) => Promise<void>,
        cleanup?: () => Promise<void>
    ) => {
        if (!db.current) {
            console.error("Database not initialized");
        }

        try {
            const isOpen = (await db.current.isDBOpen())?.result;
            if (!isOpen) {
                await db.current.open();
            }
            await action(db.current);
        } catch (error) {
            console.error("SQL action error:", error);
        } finally {
            try {
                cleanup && (await cleanup());
            } catch (error) {
                console.error("Cleanup error:", error);
            }
        }
    };

    const initializeTables = async () => {
        performSQLAction(async (db: SQLiteDBConnection | undefined) => {
            const query = `
            CREATE TABLE IF NOT EXISTS element_sizes (
                id TEXT PRIMARY KEY,
                height REAL NOT NULL
            );
        `;
            const respCT = await db?.execute(query);
            console.log(`res: ${JSON.stringify(respCT)}`);
        });
    };

    return { performSQLAction, initialized };
};

export default useSQLiteDB;
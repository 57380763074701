import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonPage } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { LocationInput } from "../../../models/locationInput";
import { SelectOptions } from "../../../models/profileSelectOptions";
import { User } from "../../../models/user";
import LocationService from "../../../services/location.service";
import { handleSetUsername } from "../../../services/utils";
import DeleteAccount from "../../ComponentsLogin/DeleteAccount/DeleteAccount";
import Dropdown from "../../ComponentsUI/Dropdown/Dropdown";
import EditProfileSelect from "../EditProfileSelect/EditProfileSelect";
import IonInputWithLabel from "../../ComponentsUI/IonInputWithLabel/IonInputWithLabel";
import IonInputWithLabelEditUsername from "../../ComponentsUI/IonInputWithLabel/IonInputWithLabelEditUsername";
import IonLongInputWithLabel from "../../ComponentsUI/IonLongInputWithLabel/IonLongInputWithLabel";
import { TerrainOptions } from "../../../models/terrainOptions";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    userProfileEdit: User,
    onEditProfileSubmit: (name: string, username: string, homeMountain: string, sports: string[],
        trickInterests: string[], instagram: string, tiktok: string, youtube: string,
        website: string, website_label: string, discord: string, twitter: string,
        push_notifications: string, setup: string) => void,
}

const sportOptions: SelectOptions[] = [
    {
        id: 1,
        name: 'Skiing',
    },
    {
        id: 2,
        name: 'Snowboarding',
    }
];

/*
    PARK = "Park",
    HALFPIPE = "Halfpipe",
    URBAN = "Urban",
    FREERIDE = "Freeride",
    BACKCOUNTRY = "Backcountry",
    GROOMERS = "Groomers",
    MOGULS = "Moguls",
    POWDER = "Powder",
    RACETRACK = "Course",
    TRAMP = "Tramp",
    AIRBAG = "Airbag",
    DRYSLOPE = "Dry Slope",
    OTHER = "Other",
*/

const terrains: SelectOptions[] = [
    {
        id: 1,
        name: TerrainOptions.PARK,
    },
    {
        id: 2,
        name: TerrainOptions.FREERIDE,
    },
    {
        id: 3,
        name: TerrainOptions.BACKCOUNTRY,
    },
    {
        id: 4,
        name: TerrainOptions.URBAN,
    },
    {
        id: 5,
        name: TerrainOptions.AIRBAG,
    },
    {
        id: 6,
        name: TerrainOptions.TRAMP,
    },
    {
        id: 7,
        name: TerrainOptions.HALFPIPE,
    },
    {
        id: 8,
        name: TerrainOptions.POWDER,
    },
    {
        id: 9,
        name: TerrainOptions.GROOMERS,
    },
    {
        id: 10,
        name: TerrainOptions.MOGULS,
    },
    {
        id: 11,
        name: TerrainOptions.RACETRACK,
    },
    {
        id: 12,
        name: TerrainOptions.DRYSLOPE,
    },
    {
        id: 13,
        name: TerrainOptions.FRIDGE,
    }
];

const pushNotificationOptions: SelectOptions[] = [
    {
        id: 1,
        name: 'All',
    },
    {
        id: 2,
        name: 'None',
    },
];

const EditProfileModal: React.FC<ContainerProps> = ({ onDismiss, userProfileEdit, onEditProfileSubmit }) => {
    const [editedProfile, setEditedProfile] = React.useState(userProfileEdit);

    const [name, setName] = useState(userProfileEdit?.name);
    const [homeMountain, setHomeMountain] = useState(userProfileEdit?.profile?.home_mountain);
    const [sports, setSports] = useState(userProfileEdit?.profile?.sports);
    const [trickInterests, setTrickInterests] = useState(userProfileEdit?.profile?.trick_interests);
    const [oldUsername, setOldUsername] = useState(userProfileEdit?.username);
    const [username, setUsername] = useState(userProfileEdit?.username);
    const [invalidUsername, setInvalidUsername] = useState(false);
    const [instagram, setInstagram] = useState(userProfileEdit?.profile?.instagram);
    const [tiktok, setTikTok] = useState(userProfileEdit?.profile?.tiktok);
    const [youtube, setYoutube] = useState(userProfileEdit?.profile?.youtube);
    const [website, setWebsite] = useState(userProfileEdit?.profile?.website);
    const [website_label, setWebsiteLabel] = useState(userProfileEdit?.profile?.website_name);
    const [discord, setDiscord] = useState(userProfileEdit?.profile?.discord);
    const [twitter, setTwitter] = useState(userProfileEdit?.profile?.twitter);
    const [push_notifications, setPushNotifications] = useState(userProfileEdit?.profile?.push_notifications);
    const [setup, setSetup] = useState(userProfileEdit?.profile?.setup);


    useEffect(() => {
        console.log("Waiting for props to be mounted");
        setName(userProfileEdit?.name);
        setHomeMountain(userProfileEdit?.profile?.home_mountain);
        setSports(userProfileEdit?.profile?.sports);
        setTrickInterests(userProfileEdit?.profile?.trick_interests);
        setInstagram(userProfileEdit?.profile?.instagram);
        setTikTok(userProfileEdit?.profile?.tiktok);
        setYoutube(userProfileEdit?.profile?.youtube);
        setWebsite(userProfileEdit?.profile?.website);
        setWebsiteLabel(userProfileEdit?.profile?.website_name);
        setDiscord(userProfileEdit?.profile?.discord);
        setTwitter(userProfileEdit?.profile?.twitter);
        setPushNotifications(userProfileEdit?.profile?.push_notifications);
        setSetup(userProfileEdit?.profile?.setup);
        console.log(username);

    }, [userProfileEdit]);

    useEffect(() => {
        setUsername(userProfileEdit?.username);
    }, [userProfileEdit.username]);

    const [locationDropdownOptions, setLocationDropdownOptions] = useState<LocationInput[]>([]);
    const [locationDropdown, setLocationDropdown] = useState(false);
    const [googlePlacesSessionToken, setGooglePlacesSessionToken] = useState(null);

    // Location Dropdown
    const closeDropdown = useRef(null);

    const closeOpenMenus = (e: any) => {
        if (locationDropdown && !closeDropdown.current.contains(e.target)) {
            setLocationDropdown(false);
        }

        if (locationDropdown && e.key === 'Enter') {
            setLocationDropdown(false);
        }
    }

    const setHomeMountainLocation = async (locationVal: string) => {
        if (locationVal === homeMountain) {
            setLocationDropdown(false);
            return;
        }

        let currGooglePlacesToken = googlePlacesSessionToken;

        if (locationVal === '' || locationVal === null) {
            currGooglePlacesToken = uuidv4();
            setGooglePlacesSessionToken(currGooglePlacesToken);
        }

        setHomeMountain(locationVal);
        await queryTrickLocation(locationVal, currGooglePlacesToken);
    }

    const queryTrickLocation = async (location: string, currGooglePlacesToken: string) => {
        const res = await LocationService.getLocationFromPlacesAPI(location, currGooglePlacesToken);
        const locationDropdownOptions: LocationInput[] = res.map((location: any) => {
            const locationInput: LocationInput = {
                description: location.description,
                main_text: location.structured_formatting.main_text,
                place_id: location.place_id,
            }
            return locationInput;
        });
        setLocationDropdownOptions(locationDropdownOptions);
        if (locationDropdownOptions.length > 0) {
            setLocationDropdown(true);
        }
    }

    const addLocationItem = (locationInput: LocationInput) => {
        setHomeMountain(locationInput.main_text);
        setLocationDropdown(false);
    }

    const confirm = async () => {
        if (username == '' || username == "" || username == null) {
            setInvalidUsername(true);
            return;
        }


        const isInvalidUsername = await handleSetUsername(username.trim(), oldUsername);
        setUsername(username.trim());
        setInvalidUsername(isInvalidUsername);

        if (isInvalidUsername) {
            console.log("Invalid username");
            return;
        }

        onEditProfileSubmit(name, username, homeMountain, sports, trickInterests, instagram,
            tiktok, youtube, website, website_label, discord, twitter, push_notifications, setup);
        onDismiss();
    }

    useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenus);
        document.addEventListener('keypress', closeOpenMenus);

        return () => {
            document.removeEventListener("mousedown", closeOpenMenus);
            document.removeEventListener('keypress', closeOpenMenus);
        }
    }, [locationDropdown]);

    return (
        <IonPage>
            <IDXModalHeader leftButtonText={'Cancel'} leftButtonAction={() => onDismiss(null, 'cancel')}
                titleText={'Edit Profile'}
                rightButtonText={'Submit'} rightButtonAction={() => confirm()} />
            <IonContent className="ion-padding">
                <IonInputWithLabel value={name} label={"Name"} placeholder={"Thunder Jack"}
                    parentCallback={setName}></IonInputWithLabel>
                <IonInputWithLabelEditUsername value={username} label={"Username"} placeholder={"username"}
                    parentCallback={setUsername}
                    invalid={invalidUsername}></IonInputWithLabelEditUsername>
                <div ref={closeDropdown}>
                    <IonItem>
                        <IonLabel position="stacked">{"Home Base"}</IonLabel>
                        <IonInput type="text" placeholder={"Mammoth Mountain, CA"} value={homeMountain}
                            onIonInput={(e) => setHomeMountainLocation(e.detail.value as string)} />
                    </IonItem>
                    {locationDropdown ?
                        <Dropdown list={locationDropdownOptions} addItem={addLocationItem} accessProperty={true}
                            accessPropertyString={'description'}></Dropdown> : null}
                </div>
                <EditProfileSelect parentCallback={setSports} options={sportOptions}
                    values={userProfileEdit?.profile?.sports} value="" label="Sports"
                    multiple={true}></EditProfileSelect>
                <EditProfileSelect parentCallback={setTrickInterests} options={terrains}
                    values={userProfileEdit?.profile?.trick_interests} value=""
                    label="Favorite TerrainOptions"
                    multiple={true}></EditProfileSelect>
                <IonLongInputWithLabel label={"Bio/Setup/Sponsors/Accomplishments/Stats"} value={setup}
                    placeholder={"Skis or Board/Boots/Bindings, Local Comp or Jam Results, or Anything Else"}
                    parentCallback={setSetup}></IonLongInputWithLabel>
                <IonInputWithLabel value={discord} label={"Discord"} placeholder={"username#1234"}
                    parentCallback={setDiscord}></IonInputWithLabel>
                <IonInputWithLabel value={instagram} label={"Instagram"} placeholder={"username"}
                    parentCallback={setInstagram}></IonInputWithLabel>
                <IonInputWithLabel value={tiktok} label={"TikTok"} placeholder={"@username"}
                    parentCallback={setTikTok}></IonInputWithLabel>
                <IonInputWithLabel value={twitter} label={"Twitter"} placeholder={"username"}
                    parentCallback={setTwitter}></IonInputWithLabel>
                <IonInputWithLabel value={youtube} label={"YouTube"} placeholder={"https://youtube.com/c/channelid"}
                    parentCallback={setYoutube}></IonInputWithLabel>
                <IonInputWithLabel value={website} label={"Website"} placeholder={"https://www.yourwebsiteorlink.com"}
                    parentCallback={setWebsite}></IonInputWithLabel>
                <IonInputWithLabel value={website_label} label={"Website/Link Label"}
                    placeholder={"Label for your website or link (optional)"}
                    parentCallback={setWebsiteLabel}></IonInputWithLabel>
                <EditProfileSelect parentCallback={setPushNotifications} options={pushNotificationOptions} values={[]}
                    value={userProfileEdit?.profile?.push_notifications} label="Push Notifications"
                    multiple={false}></EditProfileSelect>
                <IonButton className="flex items-center" size="default" color="favorite"
                    onClick={() => confirm()}>Submit</IonButton>
                <DeleteAccount auth_id={userProfileEdit.auth_id} onDelete={onDismiss}></DeleteAccount>
            </IonContent>
        </IonPage>
    );
};

export default EditProfileModal;
import { useState } from 'react';
import { Instagram } from '@awesome-cordova-plugins/instagram';

declare var IGStory: any;

interface ShareToStoryParams {
    backgroundVideoURL?: string;
    stickerImageURL?: string;
}

export const useInstagramShare = () => {
    const [instagramInstalled, setInstagramInstalled] = useState(false);
    const [loading, setLoading] = useState(false);

    const checkInstagram = async ({ backgroundVideoURL, stickerImageURL }: ShareToStoryParams) => {
        setLoading(true);
        const isInstalled = await Instagram.isInstalled();
        if (isInstalled) {
            setInstagramInstalled(true);
            // do story share stuff
            (IGStory).shareVideoToStory(
                {
                    backgroundVideo: backgroundVideoURL || '',
                    stickerImage: stickerImageURL || "",
                    attributionURL: "",
                    backgroundTopColor: "",
                    backgroundBottomColor: "",
                },
                (success: any) => {
                    console.log(success);
                },
                (err: any) => {
                    console.error(err);
                }
            );
        }
        setLoading(false);
    }

    return {
        instagramInstalled,
        loading,
        checkInstagram
    };
};
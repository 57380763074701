import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { UserType } from "../models/usertype";
import { Post } from "../models/post";
import { UserTrick } from "../models/user-trick";
import { analyzeUserTrickResForUnlisted, arrangeTricksByPreferences } from "../services/utils";
import BrandsService from "../services/brand.service";
import { useHistory } from "react-router";
import { useContext } from "react";
import { AppContext } from "../AppStateProvider";
import { Brand } from "../models/brand";

// Custom hook to manage brand profile data
// WORK ON THIS
export const useBrandProfile = (username: string, isAuthenticated: boolean, userId: string) => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const { dispatch } = useContext(AppContext);

    const updateProfileMutation = useMutation({
        mutationFn: async (profileData: {
            name: string;
            username: string;
            homeMountain: string;
            sports: string[];
            trickInterests: string[];
            instagram: string;
            tiktok: string;
            youtube: string;
            website: string;
            website_label: string;
            discord: string;
            twitter: string;
            brand_description: string;
            contact: string;
            setup: string;
        }) => {
            return BrandsService.upsertBrandProfileInformation(
                userId,
                profileData.name,
                profileData.username,
                profileData.homeMountain,
                profileData.sports,
                profileData.trickInterests,
                profileData.instagram,
                profileData.tiktok,
                profileData.youtube,
                profileData.website,
                profileData.website_label,
                profileData.discord,
                profileData.twitter,
                profileData.brand_description,
                profileData.contact,
                profileData.setup
            );
        },
        onSuccess: (returnedUser: Brand) => {
            // Update global state
            dispatch({ type: 'setUser', payload: returnedUser });

            // Invalidate queries
            queryClient.invalidateQueries({ queryKey: ['brand', returnedUser.username] });
            queryClient.invalidateQueries({ queryKey: ['brandPosts', returnedUser.id] });
            queryClient.invalidateQueries({ queryKey: ['brandTricks', returnedUser.id] });

            // Navigate to updated profile
            history.push(`/crews/${returnedUser.username}`);
        }
    });

    // Add a refresh mutation
    const refreshMutation = useMutation({
        mutationFn: async () => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ['brand', username] }),
            ]);
        },
        onError: (error) => {
            console.error('Error refreshing brand data:', error);
        }
    });

    return {
        // Data
        // Mutations
        updateProfileMutation,

        // Refetch function
        refreshMutation,
    };
};
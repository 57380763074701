import { Capacitor } from '@capacitor/core';
import { useHistory } from "react-router";
import { OnboardingStatus } from "../models/onboardingStatus";
import { useSignUp } from "../components/ComponentsOnboarding/SignUpProvider/SignUpProvider";
import PreferencesService from '../services/preferences.service';
import UserService from '../services/user.service';
import { UserType } from '../models/usertype';
import { useAuth } from '../AuthProvider';
import { useContext } from 'react';
import { AppContext } from '../AppStateProvider';
import { usePushNotifications } from './usePushNotifications';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { useAppState } from '../AppListenerProvider';
import { User } from '../models/user';

interface AuthServiceHook {
    login: () => Promise<void>;
    setAuthVariables: () => Promise<UserType>;
}

const useAuthService = (): AuthServiceHook => {
    const history = useHistory();
    const { setStep } = useSignUp();
    const { user } = useAuth();
    const { dispatch } = useContext(AppContext);
    const { checkPushNotificationPermission } = usePushNotifications();
    const { isNative } = useAppState();

    const logInToRevenueCat = async (retrievedUserId: string) => {
        console.log("Logging Into Revenue cat with User ID: ", retrievedUserId);
        await Purchases.logIn({ appUserID: retrievedUserId });
    }

    // AUTH LOGIC
    const setAuthVariables = async () => {
        await PreferencesService.setUserUid(user?.uid);
        await UserService.checkCreateUser(user.uid, user.email, user.displayName);
        await UserService.setUser(true, dispatch);
        const retrievedUserType = await UserService.getUserType(user?.uid)
        console.log("Entering isAuthenticated for Payments");
        if (isNative && retrievedUserType === UserType.USER) {
            console.log("Entering Authenticated for Payments Natively to set up Revenue Cat");
            const retrievedUserFromAuthId: User = await UserService.getUserByAuthId(user?.uid);
            await logInToRevenueCat(retrievedUserFromAuthId.id);
        } else {
            console.log("Not configuring RevenueCat because this is not native or not authenticated");
        }
        await checkPushNotificationPermission();

        return retrievedUserType;
    }

    // Auth is directly tied to sign up / onboarding which is why we have the sign up in this hook
    const login = async (): Promise<void> => {
        await setStep(OnboardingStatus.INIT);
        history.push('/');
    };

    return {
        login,
        setAuthVariables
    };
}

export default useAuthService;
import {IonButton, useIonToast} from "@ionic/react";
import {clipboardOutline, shareOutline} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import {Share} from "@capacitor/share";
import {Clipboard} from "@capacitor/clipboard";
import {BrandEvent} from "../../../models/brandEvent";
import IDXButtonIcon from "../../ComponentsUI/IDXButtonIcon/IDXButtonIcon";

interface ContainerProps {
    isCapacitor: boolean;
    event: BrandEvent;
}

const TrickShare: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [eventUrl, setEventUrl] = useState<string>('');
    const [titleUrl, setTitleUrl] = useState<string>('');
    const [present] = useIonToast();

    useEffect(() => {
        if (props.event?.id) {
            setEventUrl(`${process.env.REACT_APP_IDX_URL}/event/${props.event.id}`);
        }
    }, [props.event?.id]);

    useEffect(() => {
        if (props.event?.name) {
            setTitleUrl("Ecliptic // " + props.event.name + " // View Event");
        }
    }, [props.event?.name]);

    const presentToast = async (message: string, duration: number, icon: string) => {
        await present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    const writeTrickUrlToClipboard = async () => {
        if (props.isCapacitor) {
            const shareOptions = {
                title: titleUrl,
                url: eventUrl,
                dialogTitle: titleUrl
            }
            await Share.share(shareOptions)
        } else {
            await Clipboard.write({
                string: eventUrl
            });
            await presentToast('Copied To Clipboard!', 1500, clipboardOutline);
        }
    };

    return (
        <IonButton size={"small"} fill="clear"
                   onClick={writeTrickUrlToClipboard}>
            <IDXButtonIcon size="text-xl" icon={shareOutline} color="theme-alternative"/>
        </IonButton>
    );
};

export default TrickShare;
import { useState, useLayoutEffect, useCallback, useRef } from "react";
import { useAppState } from "../AppListenerProvider";
import { useAuth } from "../AuthProvider";
import { useSignUp } from "../components/ComponentsOnboarding/SignUpProvider/SignUpProvider";
import { useDeepLink } from "../DeepLinkProvider";
import { OnboardingStatus } from "../models/onboardingStatus";
import useAuthService from "./useAuthService";

export const useDetermineOnboarding = () => {
    const { user, isLoading, isAuthenticated, finalizeIsLoading } = useAuth();
    const { step, isLoadingOnboarding, setStep } = useSignUp();
    const { isNative } = useAppState();
    const [isOnboarding, setIsOnboarding] = useState<boolean>(false); // Changed initial state to false
    const [isDeterminingOnboarding, setIsDeterminingOnboarding] = useState<boolean>(true);
    const { setAuthVariables } = useAuthService();

    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    // Add mounted tracking
    const isMounted = useRef(true);

    // Cleanup function
    useLayoutEffect(() => {
        return () => {
            isMounted.current = false;
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        };
    }, []);

    // Safety timeout to prevent infinite loading
    const startSafetyTimeout = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }

        timeoutRef.current = setTimeout(async () => {
            if (!isMounted.current) return;

            console.warn('Safety timeout triggered - forcing onboarding determination');
            try {
                setIsDeterminingOnboarding(false);
                const shouldShowOnboarding = !isAuthenticated;
                setIsOnboarding(shouldShowOnboarding);

                if (isAuthenticated) {
                    await setAuthVariables();
                }
            } catch (error) {
                console.error('Error in safety timeout:', error);
                setIsOnboarding(!isAuthenticated);
            } finally {
                finalizeIsLoading();
            }
        }, 4000);
    }, [isAuthenticated, setAuthVariables, finalizeIsLoading]);

    useLayoutEffect(() => {
        try {
            // Start safety timeout on every state change
            startSafetyTimeout();

            // Don't make any decisions until initial loading is complete
            if (isLoading || isLoadingOnboarding) {
                setIsDeterminingOnboarding(true);
                return;
            }

            console.log('Determining onboarding state:', {
                isLoading,
                isLoadingOnboarding,
                isAuthenticated,
                currentStep: step
            });

            const shouldShowOnboarding = !isAuthenticated ||
                [
                    OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER,
                    OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH,
                    OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME,
                    OnboardingStatus.INIT
                ].includes(step as OnboardingStatus);

            if (isMounted.current) {
                setIsOnboarding(shouldShowOnboarding);
                setIsDeterminingOnboarding(false);

                if (!shouldShowOnboarding && step !== OnboardingStatus.COMPLETED) {
                    setStep(OnboardingStatus.COMPLETED);
                }

                // Clear safety timeout since we've completed successfully
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                    timeoutRef.current = null;
                }

                finalizeIsLoading();
            }
        } catch (error) {
            console.error('Error in determining onboarding state:', error);
            if (isMounted.current) {
                setIsDeterminingOnboarding(false);
                setIsOnboarding(!isAuthenticated);
                finalizeIsLoading();
            }
        }
    }, [isAuthenticated, isLoading, user, isLoadingOnboarding, step, setStep, startSafetyTimeout, finalizeIsLoading]);

    // Add a recovery method that can be called externally if needed
    const resetDetermination = useCallback(() => {
        if (isMounted.current) {
            console.log('Manually resetting onboarding determination');
            setIsDeterminingOnboarding(false);
            setIsOnboarding(!isAuthenticated);
        }
    }, [isAuthenticated]);

    const getOnboardingRedirect = useCallback(() => {
        console.log("Handling redirect to proper sign up page with status: " + step);
        if (step) {
            if (step === OnboardingStatus.COMPLETED && isAuthenticated) {
                console.log("Redirecting to / - onboarding completed and authenticated");
                return '/';
            }

            if (step === OnboardingStatus.INIT) {
                console.log("Redirecting to /flow - onboarding init");
                return '/flow';
            } else if (step.includes(OnboardingStatus.SIGNUP)) {
                console.log("Redirecting to /flow/signup - onboarding signup");
                return '/flow/signup';
            } else if (step.includes(OnboardingStatus.LOGIN)) {
                console.log("Redirecting to /flow/login - onboarding login");
                return '/flow/login';
            } else {
                console.log("Redirecting to /flow - onboarding completed and not authenticated");
                return '/flow';
            }
        } else {
            console.log("Redirecting to /flow - there is no onboarding status");
            return '/flow';
        }
    }, [step, isAuthenticated]);

    /* - Onboarding Deep Link Hook
    useEffect(() => {
    const handleDeepLink = async () => {
        if (isLoading) return;
        if (deepLinkData.slug) {
            console.log("Handling deep link in onboarding:", deepLinkData.slug);
            if (deepLinkData.slug.startsWith('/share') && deepLinkData.referrer) {
                // Store referrer for later use
                await PreferencesService.setReferrer(deepLinkData.referrer);
                console.log(`Referral from: ${deepLinkData.referrer}`);
                history.push('/flow/signup');
            } else if (protectedRoutes.some(route => deepLinkData.slug.startsWith(route))) {
                // If it's a protected route, store it for later and continue with onboarding
                await PreferencesService.setIntendedDestination(deepLinkData.slug);
                console.log('Protected route deep link stored for after onboarding:', deepLinkData.slug);
            } else {
                // Handle other onboarding-specific deep links
                switch (deepLinkData.slug) {
                    case '/signup':
                        history.push('/flow/signup');
                        break;
                    case '/login':
                        history.push('/flow/login');
                        break;
                    default:
                        console.log('Unhandled deep link in onboarding:', deepLinkData.slug);
                }
            }

            // Clear the deep link data after handling, but keep the referrer
            await clearDeepLinkData();
        }
    };

    handleDeepLink();
    }, [deepLinkData, isLoading, clearDeepLinkData, history]);
    */

    /* - Deep Link Data Hook
    useEffect(() => {
    const handleDeepLink = async () => {
        if (isLoading) return; // Don't handle deep links while loading
        console.log("HANDLING DEEP LINKS IN MAIN APP");

        if (deepLinkData.slug) {
            // Check if the slug starts with any of the protected routes
            const matchedRoute = protectedRoutes.find(route => deepLinkData.slug.startsWith(route));

            if (matchedRoute) {
                // If it's a protected route, navigate to it
                history.push(deepLinkData.slug);
            } else if (deepLinkData.slug.startsWith('/refer')) {
                // Handle referral links
                history.push('/profile');
            } else {
                // Handle any other deep links
                console.log('Unhandled deep link in main app:', deepLinkData.slug);
            }

            // Clear the deep link data after handling
            await clearDeepLinkData();
        }
    };

    handleDeepLink();
    }, [deepLinkData, isLoading, clearDeepLinkData, history]);
    */

    return {
        isOnboarding,
        isDeterminingOnboarding: isDeterminingOnboarding,
        getOnboardingRedirect,
        resetDetermination
    };
};

import React, { useState } from 'react';
import { useMediaState, type MediaPlayerInstance } from '@vidstack/react';
import { PlayIcon } from '@vidstack/react/icons';
import { useVideoContext } from '../VideoContextManager/VideoContextManager';

interface FullscreenPlayControlProps {
    hasInteracted: boolean;
}

const FullscreenPlayControl: React.FC<FullscreenPlayControlProps> = ({ hasInteracted }) => {
    const { playerRef } = useVideoContext();
    const paused = useMediaState('paused', playerRef);
    const isControlsVisible = useMediaState('controls', playerRef);
    const canPlay = useMediaState('canPlay', playerRef);

    const handleVideoClick = (e: React.MouseEvent) => {
        // Get the click target and check if it's part of the controls
        const target = e.target as HTMLElement;
        const isControlsClick = target.closest('[class*="vds-controls"]');
        const isSettingsClick = target.closest('[class*="vds-menu"]');

        // Only handle play/pause if we didn't click the controls or settings
        if (!isControlsClick && !isSettingsClick && playerRef.current && canPlay) {
            if (paused) {
                playerRef.current.play();
            } else {
                playerRef.current.pause();
            }
        }
    };

    if (!hasInteracted) {
        return (
            <div
                className="absolute inset-0 w-full h-full cursor-pointer"
                onClick={handleVideoClick}
                aria-label="Play video"
            />
        );
    }

    return (
        <div
            className="absolute inset-0 w-full h-full cursor-pointer"
            onClick={handleVideoClick}
            aria-label={paused ? "Play video" : "Pause video"}
        >
            {/* Show play icon only when video is paused and controls aren't visible */}
            {paused && !isControlsVisible && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="p-4 rounded-full transition-opacity">
                        <PlayIcon className="w-24 h-12 text-white" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FullscreenPlayControl;
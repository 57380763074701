import { createGesture, Gesture, GestureDetail } from "@ionic/react";
import customBackAnimation from "../NavigationAnimation/customBackAnimation";

export const clamp = (min: number, n: number, max: number) => {
    return Math.max(min, Math.min(n, max));
};

export const isRTL = (hostEl?: Pick<HTMLElement, 'dir'>) => {
    if (hostEl) {
        if (hostEl.dir !== '') {
            return hostEl.dir.toLowerCase() === 'rtl';
        }
    }
    return document?.dir.toLowerCase() === 'rtl';
};

export const createSwipeBackGesture = (
    el: HTMLElement,
    canStartHandler: () => boolean,
    onStartHandler: () => void,
    onMoveHandler: (step: number) => void,
    onEndHandler: (shouldComplete: boolean, step: number, dur: number) => void
): Gesture => {
    const win = el.ownerDocument!.defaultView!;
    let rtl = isRTL(el);
    let animation: any;

    const isAtEdge = (detail: GestureDetail) => {
        const threshold = win.innerWidth * 0.75; // 75% of screen width
        const { startX } = detail;
        if (rtl) {
            return startX >= win.innerWidth - threshold;
        }
        return startX <= threshold;
    };

    const getDeltaX = (detail: GestureDetail) => {
        return rtl ? -detail.deltaX : detail.deltaX;
    };

    const getVelocityX = (detail: GestureDetail) => {
        return rtl ? -detail.velocityX : detail.velocityX;
    };

    const canStart = (detail: GestureDetail) => {
        rtl = isRTL(el);
        return isAtEdge(detail) && canStartHandler();
    };

    const onStart = () => {
        animation = customBackAnimation(el, {
            direction: 'back',
            enteringEl: el.children[0] as HTMLElement,
            leavingEl: el.children[1] as HTMLElement
        });
        if (animation) {
            animation.progressStart(true, 0);
            onStartHandler();
        }
    };

    const onMove = (detail: GestureDetail) => {
        const delta = getDeltaX(detail);
        const stepValue = delta / win.innerWidth;
        if (animation) {
            animation.progressStep(stepValue);
            onMoveHandler(stepValue);
        }
    };

    const onEnd = (detail: GestureDetail) => {
        const delta = getDeltaX(detail);
        const width = win.innerWidth;
        const stepValue = delta / width;
        const velocity = getVelocityX(detail);
        const z = width / 2.0;

        const shouldComplete = velocity >= 0 && (velocity > 0.1 || delta > z * 0.7);

        const missing = shouldComplete ? 1 - stepValue : stepValue;
        const missingDistance = missing * width;
        let realDur = 0;
        if (missingDistance > 5) {
            const dur = missingDistance / Math.abs(velocity);
            realDur = Math.min(dur, 540);
        }

        if (animation) {
            animation.progressEnd(shouldComplete, stepValue, realDur);
            onEndHandler(shouldComplete, stepValue <= 0 ? 0.01 : clamp(0, stepValue, 0.9999), realDur);
        }
    };

    return createGesture({
        el,
        gestureName: 'goback-swipe',
        gesturePriority: 101,
        threshold: 5,
        canStart,
        onStart,
        onMove,
        onEnd,
    });
};
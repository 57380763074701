import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { UserTrickSearchMetadata } from '../models/userTrickSearchMetadata';
import { filterTricksForBlockedIds } from '../services/utils';
import SearchService from '../services/search.service';
import { de } from 'date-fns/locale';

const numberOfTVTricksToRetrieve = 6;

interface TVTricksPage {
    tricks: UserTrickSearchMetadata[];
    nextPage: number;
    isLastPage: boolean;
}

const useTVTricks = (
    blockedUsers: string[],
    blockingUsers: string[],
    isAuthed: boolean,
    externalTricks?: UserTrickSearchMetadata[]
) => {
    const queryClient = useQueryClient();

    const deduplicateTricks = (tricks: UserTrickSearchMetadata[]): UserTrickSearchMetadata[] => {
        const seen = new Set<string>();
        return tricks.filter(trick => {
            if (seen.has(trick.trick_id)) {
                return false; // Skip duplicates
            }
            seen.add(trick.trick_id);
            return true; // Keep unique tricks
        });
    };

    const processExploreTricksForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[], currentPage: number): Promise<UserTrickSearchMetadata[]> => {
        let tricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        while (tricks.length > 0) {
            processedTricks = filterTricksForBlockedIds(tricks, blockedUsers, blockingUsers);

            if (processedTricks.length > 0) {
                break;
            }

            tricks = await SearchService.getExploreTricks(
                numberOfTVTricksToRetrieve,
                currentPage + 1
            );

            if (tricks.length === 0) {
                break;
            }
        }

        return processedTricks;
    };

    const fetchTVTricks = async ({ pageParam = 0 }: { pageParam?: number }): Promise<TVTricksPage> => {
        let tvTricks = await SearchService.getExploreTricks(numberOfTVTricksToRetrieve, pageParam);
        tvTricks = await processExploreTricksForBlockedUsers(tvTricks, pageParam);

        deduplicateTricks(tvTricks);

        return {
            tricks: tvTricks,
            nextPage: pageParam + 1,
            isLastPage: tvTricks.length === 0
        };
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        error,
        refetch
    } = useInfiniteQuery({
        queryKey: ['tvTricks', blockedUsers.length, blockingUsers.length],
        queryFn: fetchTVTricks,
        initialPageParam: 0,
        getNextPageParam: (lastPage: TVTricksPage) => lastPage.isLastPage ? undefined : lastPage.nextPage,
        enabled: isAuthed && !externalTricks,
        staleTime: 0,
        refetchOnWindowFocus: false,
    });

    const tricks = deduplicateTricks(externalTricks || data?.pages.flatMap(page => page.tricks) || []);

    const getMoreNewTricks = () => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    };

    const invalidateQueries = async () => {
        // First reset the query cache
        await queryClient.resetQueries({ queryKey: ['tvTricks'] });
        return refetch();
    }

    return {
        tricks,
        isLoadingTVTricks: isLoading,
        error,
        infiniteScrollDisabled: !hasNextPage,
        getMoreNewTricks,
        refreshTricks: invalidateQueries
    };
};

export default useTVTricks;
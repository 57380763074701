import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { UserTrickSearchMetadata } from '../models/userTrickSearchMetadata';
import { filterTricksForBlockedIds } from '../services/utils';
import UserService from '../services/user.service';
import { HotType } from '../models/hotType';
import PreferencesService from '../services/preferences.service';

const numberOfHotTricksToRetrieve = 6;
const topScore = "1.7976931348623157e+308";

interface HotTricksPage {
    tricks: UserTrickSearchMetadata[];
    nextCursor: string | undefined;
    isLastPage: boolean;
}

const useHotTricks = (
    blockedUsers: string[],
    blockingUsers: string[],
    isAuthed: boolean,
    externalTricks?: UserTrickSearchMetadata[]
) => {
    const queryClient = useQueryClient();

    const deduplicateTricks = (tricks: UserTrickSearchMetadata[]): UserTrickSearchMetadata[] => {
        const seen = new Set<string>();
        return tricks.filter(trick => {
            if (seen.has(trick.trick_id)) {
                return false; // Skip duplicates
            }
            seen.add(trick.trick_id);
            return true; // Keep unique tricks
        });
    };

    const processHotTricksForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[], selectedHotValue: string): Promise<UserTrickSearchMetadata[]> => {
        let tricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        while (tricks.length > 0) {
            processedTricks = filterTricksForBlockedIds(tricks, blockedUsers, blockingUsers);

            if (processedTricks.length > 0) {
                break;
            }

            tricks = await UserService.getTrendingTricksMore(
                numberOfHotTricksToRetrieve,
                tricks[tricks.length - 1].trick_id,
                selectedHotValue
            );

            if (tricks.length === 0) {
                break;
            }
        }

        return processedTricks;
    };

    const { data: selectedHotValue } = useQuery({
        queryKey: ['hotSettings'],
        queryFn: async () => {
            const hotSettings = await PreferencesService.getHotSettings();
            if (hotSettings) {
                return hotSettings;
            } else {
                await PreferencesService.setHotSettings(HotType.Year);
                return HotType.Year;
            }
        },
        enabled: isAuthed,
    });

    const fetchHotTricks = async ({ pageParam = topScore }: { pageParam?: string }): Promise<HotTricksPage> => {
        let hotTricks: UserTrickSearchMetadata[];
        if (pageParam === topScore) {
            hotTricks = await UserService.getTrendingTricks(numberOfHotTricksToRetrieve, pageParam, selectedHotValue || HotType.Year);
        } else {
            hotTricks = await UserService.getTrendingTricksMore(numberOfHotTricksToRetrieve, pageParam, selectedHotValue || HotType.Year);
        }

        hotTricks = await processHotTricksForBlockedUsers(hotTricks, selectedHotValue || HotType.Year);

        hotTricks = deduplicateTricks(hotTricks);

        return {
            tricks: hotTricks,
            nextCursor: hotTricks.length > 0 ? hotTricks[hotTricks.length - 1].trick_id : undefined,
            isLastPage: hotTricks.length === 0
        };
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        error,
        refetch
    } = useInfiniteQuery({
        queryKey: ['hotTricks', selectedHotValue],
        queryFn: fetchHotTricks,
        initialPageParam: topScore,
        getNextPageParam: (lastPage: HotTricksPage) => lastPage.nextCursor,
        enabled: isAuthed && !externalTricks && !!selectedHotValue,
        staleTime: 0, // 5 minutes
        refetchOnWindowFocus: false,
    });

    const tricks = deduplicateTricks(externalTricks || data?.pages.flatMap(page => page.tricks) || []);

    const getMoreHotTricks = () => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    };

    const handleHotChange = async (newHotValue: string) => {
        const valueToSet = newHotValue === '' ? HotType.Year : newHotValue;
        await PreferencesService.setHotSettings(valueToSet);
        queryClient.setQueryData(['hotSettings'], valueToSet);
        await queryClient.invalidateQueries({ queryKey: ['hotTricks', selectedHotValue] });
    };

    const invalidateQueries = async () => {
        // First reset the query cache
        await queryClient.resetQueries({ queryKey: ['hotTricks', selectedHotValue] });
        return refetch();
    }

    return {
        tricks,
        isLoadingHotTricks: isLoading,
        error,
        infiniteScrollDisabled: !hasNextPage,
        getMoreHotTricks,
        refreshTricks: invalidateQueries,
        handleHotChange,
        selectedHotValue: selectedHotValue || HotType.Year
    };
};

export default useHotTricks;
import {
    IonButton,
    IonContent,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    useIonToast
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Bisk from "../Bisk/Bisk";
import BiskInput from "../BiskInput/BiskInput";
import paypalLogo from "../../../assets/icons/logos/PayPal.svg";
import venmoLogo from "../../../assets/icons/logos/Venmo_Logo.svg";
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import { Capacitor } from "@capacitor/core";
import { alertCircleOutline } from "ionicons/icons";
import { WithdrawalPlatformType } from "../../../models/withdrawalPlatformType";
import BiskService from "../../../services/bisk.service";
import WithdrawalModal from "../WithdrawalModal/WithdrawalModal";
import { BiskTransactionType } from "../../../models/biskTransactionType";
import BiskActivityCard from "../BiskActivityCard/BiskActivityCard";
import { getDateNowUTCISO, getSlangTerm } from "../../../services/utils";
import { BiskTransactionView } from "../../../models/biskTransactionView";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";
import { useAuth } from "../../../AuthProvider";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    bisk: number,
    profileBisk: number,
    onBuy?: () => void,
    onSend?: (bisk: number, note: string) => void,
    onWithdraw?: (biskWithdraw: number, platform: string, platformId: string) => void,
    trickName?: string,
    recipientUsername?: string,
    isYourProfile?: boolean,
    email?: string
    recipientId?: string
    onClickUsernameHandler?: (username: string) => void;
    onClickObjectHandler?: (targetId: string, objectId: string, transactionType: string) => void;
}

const BiskProfileModal: React.FC<ContainerProps> = ({
    onDismiss,
    onBuy,
    bisk,
    profileBisk,
    onSend,
    trickName,
    recipientUsername,
    isYourProfile,
    email,
    onWithdraw,
    recipientId,
    onClickObjectHandler,
    onClickUsernameHandler
}) => {
    const [segmentValue, setSegmentValue] = React.useState("account");
    const isNative = Capacitor.isNativePlatform();
    const { isAuthenticated } = useAuth();
    const platform = Capacitor.getPlatform();
    const minBisk = 2500;
    const [presentToast] = useIonToast();
    const [paypalWithdrawalAlertOpen, setPaypalWithdrawalAlertOpen] = useState(false);
    const [venmoWithdrawalAlertOpen, setVenmoWithdrawalAlertOpen] = useState(false);
    const [currBisk, setCurrBisk] = useState(bisk);
    const [currProfileBisk, setProfileBisk] = useState(profileBisk);

    const [biskTransactions, setBiskTransactions] = React.useState<BiskTransactionView[]>([]);
    const [biskTransactionsInfiniteScrollDisabled, setBiskTransactionsInfiniteScrollDisabled] = React.useState<boolean>(false);
    const [biskTransactionsLoading, setBiskTransactionsLoading] = React.useState<boolean>(false);
    const [biskFilter, setBiskFilter] = React.useState<string>("");
    const numberOfBiskTransactions = Capacitor.isNativePlatform() ? 10 : 15;
    const slangTerm = getSlangTerm();
    const [showBiskOniOS, setShowBiskOniOS] = useState(false);

    useEffect(() => {
        setCurrBisk(bisk);
        setProfileBisk(profileBisk);
    }, [bisk, profileBisk]);

    useEffect(() => {
        setPaypalWithdrawalAlertOpen(false);
        setVenmoWithdrawalAlertOpen(false);
    }, []);

    useEffect(() => {
        if (recipientId) {
            getInitBiskTransactions();
        }
    }, [recipientId]);

    const handleNavigateToShop = () => {
        onDismiss(null, 'cancel');
        onBuy();
    }

    const handleSendBisk = (bisk: number, note: string) => {
        onDismiss(null, 'cancel');
        onSend(bisk, note);
    }

    const handleOnClickUsername = (username: string) => {
        onDismiss(null, 'cancel');
        onClickUsernameHandler(username);
    }

    const handleOnClickObject = (targetId: string, objectId: string, transactionType: string) => {
        onDismiss(null, 'cancel');
        onClickObjectHandler(targetId, objectId, transactionType);
    }

    const handleSegmentChange = (segment: CustomEvent) => {
        console.log("Segment Changed: " + segment.detail.value);
        setSegmentValue(segment.detail.value);
    }

    const onWithdrawBisk = async (platform: string) => {
        if (currBisk < minBisk) {
            // present toast saying you need at least 2500 bisk to withdraw
            await presentToast({
                message: "You need at least 2500 Bisk to Redeem",
                duration: 2000,
                color: "danger",
                icon: alertCircleOutline
            })
        } else {
            if (platform === WithdrawalPlatformType.PAYPAL) {
                setPaypalWithdrawalAlertOpen(true);
                setVenmoWithdrawalAlertOpen(false);
            } else if (platform === WithdrawalPlatformType.VENMO) {
                setVenmoWithdrawalAlertOpen(true);
                setPaypalWithdrawalAlertOpen(false);
            } else {
                await presentToast({
                    message: "Something went wrong, please try again",
                    duration: 2000,
                    color: "danger",
                    icon: alertCircleOutline
                })
            }
        }
    }

    const onClickRedeemBiskOniOS = async () => {
        if (currBisk >= minBisk) {
            setShowBiskOniOS(true);
        } else {
            await presentToast({
                message: "You need at least 2500 Bisk to Redeem",
                duration: 2000,
                color: "danger",
                icon: alertCircleOutline
            });
        }
    }

    const getInitBiskTransactions = async () => {
        setBiskTransactionsInfiniteScrollDisabled(false);
        setBiskTransactionsLoading(true);
        const actualDate = getDateNowUTCISO();
        let initBiskTransactions: BiskTransactionView[] = [];
        if (biskFilter !== "") {
            initBiskTransactions = await BiskService.getUserBiskActivityByType(recipientId, numberOfBiskTransactions, actualDate, biskFilter);
        } else {
            initBiskTransactions = await BiskService.getUserBiskActivity(recipientId, numberOfBiskTransactions, actualDate);
        }
        setBiskTransactions(initBiskTransactions);
        setBiskTransactionsLoading(false);
        console.log("Bisk Transactions: ", initBiskTransactions);
    }

    const getMoreBiskTransactions = async () => {
        console.log("Getting more following tricks with date: " + biskTransactions[biskTransactions.length - 1].date);
        let newBiskTransactions: BiskTransactionView[] = [];
        if (biskFilter !== "") {
            newBiskTransactions = await BiskService.getUserBiskActivityByType(recipientId, numberOfBiskTransactions, biskTransactions[biskTransactions.length - 1].date, biskFilter);
        } else {
            newBiskTransactions = await BiskService.getUserBiskActivity(recipientId, numberOfBiskTransactions, biskTransactions[biskTransactions.length - 1].date);
        }

        if (newBiskTransactions.length === 0) {
            setBiskTransactionsInfiniteScrollDisabled(true);
        }

        setBiskTransactions([
            ...biskTransactions,
            ...newBiskTransactions
        ]);

        console.log('Loaded more following tricks');
    }

    const handleBiskFilterChange = async (biskFilter: CustomEvent) => {
        console.log("Bisk Filter Changed: " + biskFilter.detail.value);
        setBiskFilter(biskFilter.detail.value);
        setBiskTransactionsInfiniteScrollDisabled(false);
        setBiskTransactionsLoading(true);
        const actualDate = getDateNowUTCISO();
        let initBiskTransactions: BiskTransactionView[] = [];
        if (biskFilter.detail.value !== "") {
            initBiskTransactions = await BiskService.getUserBiskActivityByType(recipientId, numberOfBiskTransactions, actualDate, biskFilter.detail.value);
        } else {
            initBiskTransactions = await BiskService.getUserBiskActivity(recipientId, numberOfBiskTransactions, actualDate);
        }
        setBiskTransactions(initBiskTransactions);
        console.log("Bisk Transactions: ", initBiskTransactions);
        setBiskTransactionsLoading(false);
    }


    const handleWithdrawal = (inputNumericValue: number, inputPlatform: string, inputPlatformId: string) => {
        console.log("Handling paypal withdrawal with value: ", inputNumericValue, " and username: ", inputPlatformId, " and platform: ", inputPlatform);
        setPaypalWithdrawalAlertOpen(false);
        setVenmoWithdrawalAlertOpen(false);
        onWithdraw(inputNumericValue, inputPlatform, inputPlatformId);
        onDismiss(null, 'cancel');
    }

    // TODO: create a separate component for an action sheet modal and pass the state up that way instead of using refs
    // https://ionic.io/blog/5-examples-of-the-new-ionic-6-bottom-sheet-modal

    return (
        <IonPage>
            <IDXModalHeader leftButtonAction={() => onDismiss(null, 'cancel')}
                titleText={`${recipientUsername}'s Bisk`}
                rightButtonText={'Close'} rightButtonAction={() => onDismiss(null, 'cancel')} />
            <div>
                <IonSegment>
                    <IonSegment color="dark" mode="md" value={segmentValue}
                        onIonChange={(ev) => handleSegmentChange(ev)}>
                        <IonSegmentButton value="account">
                            <IonLabel>Account</IonLabel>
                        </IonSegmentButton>
                        {!isYourProfile &&
                            <IonSegmentButton value="send">
                                <IonLabel>Send</IonLabel>
                            </IonSegmentButton>
                        }
                        <IonSegmentButton value="activity">
                            <IonLabel>Activity</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonSegment>
            </div>
            <IonContent className="ion-padding">
                {
                    segmentValue === "send" &&
                    <div>
                        <Bisk onBuy={handleNavigateToShop} />
                        <BiskInput bisk={currBisk} onBuy={handleNavigateToShop} onSend={handleSendBisk}
                            recipientUsername={recipientUsername} />
                    </div>
                }

                {
                    segmentValue === "account" &&
                    <div>
                        <Bisk onBuy={handleNavigateToShop} />
                        <div className="flex flex-col my-4 space-y-4">
                            <div>
                                <div className="flex flex-row justify-center items-center space-x-2">
                                    <IonIcon src={biskLogo} size="large" />
                                    <div className="text-4xl">
                                        {currProfileBisk}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row justify-center">
                                <IonButton shape="round" color="favorite-secondary" onClick={handleNavigateToShop}>
                                    <div className="underline underline-offset-4 text-lg">BUY BISK</div>
                                </IonButton>
                            </div>
                            {
                                isYourProfile && platform === "ios" &&
                                <div className="flex flex-row justify-center">
                                    <IonButton shape="round" color="favorite" onClick={onClickRedeemBiskOniOS}>
                                        <div className="underline underline-offset-4 text-lg">REDEEM BISK</div>
                                    </IonButton>
                                </div>
                            }
                            {
                                ((isYourProfile && (platform === "web" || platform === 'android')) || (isYourProfile && platform === 'ios' && showBiskOniOS)) &&
                                <div>
                                    <div className="flex flex-row justify-center">
                                        <div className="text-lg">
                                            Withdraw Bisk
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center space-x-2 mb-4">
                                        <IonButton shape="round" size="large" color="warning"
                                            onClick={() => onWithdrawBisk(WithdrawalPlatformType.PAYPAL)}>
                                            <IonIcon icon={paypalLogo} slot="icon-only" size="h-32 w-32"></IonIcon>
                                        </IonButton>
                                        <IonButton shape="round" size="large" color="theme-secondary"
                                            onClick={() => onWithdrawBisk(WithdrawalPlatformType.VENMO)}>
                                            <IonIcon icon={venmoLogo} slot="icon-only" size="h-32 w-32"></IonIcon>
                                        </IonButton>
                                    </div>
                                    {
                                        paypalWithdrawalAlertOpen &&
                                        <WithdrawalModal onWithdraw={handleWithdrawal} currBisk={currBisk}
                                            minBisk={minBisk} platform={WithdrawalPlatformType.PAYPAL}
                                            email={email} />
                                    }
                                    {
                                        venmoWithdrawalAlertOpen &&
                                        <WithdrawalModal onWithdraw={handleWithdrawal} currBisk={currBisk}
                                            minBisk={minBisk} platform={WithdrawalPlatformType.VENMO}
                                            email={email} />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }

                {
                    segmentValue === "activity" &&
                    <>
                        <div className="items-center my-4 mx-8 justify-center">
                            <div>
                                <IonSelect
                                    label={recipientUsername + "'s Biskness"}
                                    interface="popover"
                                    placeholder="Select transaction type"
                                    style={{ ionBackgroundColor: "none" }}
                                    onIonChange={(e) => handleBiskFilterChange(e)}
                                >
                                    <IonSelectOption value="">All</IonSelectOption>
                                    <IonSelectOption value={BiskTransactionType.SEND}>Sent</IonSelectOption>
                                    <IonSelectOption value={BiskTransactionType.AWARD}>Awarded</IonSelectOption>
                                    <IonSelectOption value={BiskTransactionType.BUY}>Bought</IonSelectOption>
                                    <IonSelectOption value={BiskTransactionType.WITHDRAW}>Withdrew</IonSelectOption>
                                    <IonSelectOption value={BiskTransactionType.REDEEM}>Redeemed</IonSelectOption>
                                </IonSelect>
                            </div>
                            {
                                biskTransactions.map((biskTransaction, index) => {
                                    return (
                                        <BiskActivityCard key={index} transaction={biskTransaction} isModal={true}
                                            onClickObjectHandler={handleOnClickObject}
                                            onClickUsernameHandler={handleOnClickUsername}
                                            blurred={false} isAuthed={isAuthenticated} />
                                    )
                                })
                            }
                            <IonInfiniteScroll
                                onIonInfinite={(ev) => {
                                    getMoreBiskTransactions();
                                    setTimeout(() => ev.target.complete(), 500);
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>

                        </div>
                    </>
                }

            </IonContent>
        </IonPage>
    );
};

export default BiskProfileModal;
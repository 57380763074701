import {Capacitor} from "@capacitor/core";
import {IonButton, useIonAlert} from "@ionic/react";
import React, {useContext} from "react";
import {AppContext} from "../../../AppStateProvider";
import {useAuth} from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import {useAppState} from "../../../AppListenerProvider";
import SettingsService from "../../../services/settings.service";
import PreferencesService from "../../../services/preferences.service";
import {OnboardingStatus} from "../../../models/onboardingStatus";
import {useSignUp} from "../../ComponentsOnboarding/SignUpProvider/SignUpProvider";

interface ContainerProps {
    auth_id: string,
    onDelete: (data?: string | null | undefined | number, role?: string) => void
}

const DeleteAccount: React.FC<ContainerProps> = (props: ContainerProps) => {
    const {isActive, isNative, platform} = useAppState();
    const {setStep} = useSignUp();
    const {user, isLoading, isAuthenticated, firebaseApp, auth} = useAuth();
    const authService = AuthService({user, isLoading, isAuthenticated, firebaseApp, auth}, {
        isActive,
        isNative,
        platform
    });
    const {dispatch} = useContext(AppContext);
    const [presentAlert] = useIonAlert();


    const clearStorage = async () => {
        await PreferencesService.clearPreferences();
        await setStep(OnboardingStatus.INIT);
    }

    const clearState = async () => {
        dispatch({type: "clearUser", payload: null});
        dispatch({type: "clearBisk", payload: null});
        dispatch({type: "clearIsSubscribed", payload: null});
        dispatch({type: "clearSubscriptionLevel", payload: null});
        dispatch({type: "clearFeedSettings", payload: null});
        dispatch({type: "clearMutedSettings", payload: null});
        dispatch({type: "clearUserType", payload: null});
        dispatch({type: "clearFollowers", payload: null});
        dispatch({type: "clearFollowing", payload: null});
        dispatch({type: "clearPushNotificationRedirectUrl", payload: null});
        dispatch({type: "clearBlockedUsers", payload: null});
        dispatch({type: "clearBlockingUsers", payload: null});
    }

    const deleteAccount = async (auth_id: string) => {
        await SettingsService.sendFeedback("Delete Account", "User wants to delete their account for id: " + auth_id);
        await clearStorage();
        await clearState();
        try {
            const res = await authService.signOut();
        } catch (error) {
            console.error("Error signing out: ", error);
        }
        props.onDelete();
    }

    return (<IonButton color="danger" size="default" className="flex items-center" onClick={async () => {
        if (Capacitor.getPlatform() === 'web') {
            await presentAlert({
                header: 'dO yOu WAnT tO DeLETe YOuR AcCOunT?',
                message: 'If you have any subscriptions, these will be cancelled at the end of your billing period. This is not recoverable.',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'OK',
                        role: 'confirm',
                        handler: () => {
                            deleteAccount(props.auth_id);
                        },
                    },
                ],
            })
        } else {
            await presentAlert({
                header: 'dO yOu WAnT tO DeLETe YOuR AcCOunT?',
                message: 'If you have any subscriptions, these will be cancelled at the end of your billing period. This is not recoverable',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'OK',
                        role: 'confirm',
                        handler: () => {
                            deleteAccount(props.auth_id);
                        },
                    },
                ],
            })
        }
    }}>Delete Account</IonButton>);
};

export default DeleteAccount;
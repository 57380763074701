import {
    IonItem,
    IonLabel,
    IonList,
    IonSkeletonText,
} from '@ionic/react';
import './LocationSearch.css';

const LocationListSkeleton = () => {
    return (
        <IonList className="custom-list z-50 w-full max-w-7xl mx-auto">
            {[...Array(15)].map((_, index) => (
                <IonItem
                    key={index}
                    className="bg-transparent
                             px-2 sm:px-4 md:px-6 lg:px-8 
                             py-2 sm:py-3 md:py-4 custom-item"
                    lines="none"
                >
                    {/* Icon/Logo skeleton */}
                    <div className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 flex-shrink-0">
                        <IonSkeletonText
                            animated={true}
                            className="h-full w-full rounded-md opacity-25"
                        />
                    </div>

                    {/* Main content skeleton */}
                    <IonLabel className="flex-1 ml-2 sm:ml-4 md:ml-6">
                        <IonSkeletonText
                            animated={true}
                            className="h-4 sm:h-5 md:h-6 w-3/4 mb-1 sm:mb-2 md:mb-3 rounded opacity-25"
                        />
                        <IonSkeletonText
                            animated={true}
                            className="h-3 sm:h-4 md:h-4 w-1/2 rounded opacity-25"
                        />
                    </IonLabel>

                    {/* Riders count skeleton */}
                    <IonLabel slot="end" className="ml-2 sm:ml-4 md:ml-6 text-right flex flex-col items-end">
                        <IonSkeletonText
                            animated={true}
                            className="h-4 sm:h-5 md:h-6 w-8 sm:w-10 md:w-12 mb-1 sm:mb-2 md:mb-3 rounded opacity-25"
                        />
                        <IonSkeletonText
                            animated={true}
                            className="h-3 sm:h-4 md:h-4 w-12 sm:w-14 md:w-16 rounded opacity-25"
                        />
                    </IonLabel>
                </IonItem>
            ))}
        </IonList>
    );
};

export default LocationListSkeleton;
import {IonButton, IonButtons, IonHeader, IonIcon, IonSearchbar, IonTitle, IonToolbar} from "@ionic/react";
import {chevronBackOutline} from "ionicons/icons";
import React, {useEffect} from "react";

interface ContainerProps {
    handleBack: () => void,
    onTrickSearch: (search: string) => void,
    onProfileSearch: (search: string) => void,
    displayClips: boolean,
    displayRiders: boolean,
    onDisplayClips: () => void,
    onDisplayRiders: () => void,
    searchValue: string,
}

const FilterToolbar: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [showTitle, setShowTitle] = React.useState(true);

    const [clipsSelected, setClipsSelected] = React.useState(props.displayClips);
    const [defaultSearch, setDefaultSearch] = React.useState(props.searchValue);
    const [ridersSelected, setRidersSelected] = React.useState(props.displayRiders);

    useEffect(() => {
        setShowTitle(true);
        setClipsSelected(true);
        setRidersSelected(false);
    }, []);

    useEffect(() => {
        setClipsSelected(props.displayClips);
        setRidersSelected(props.displayRiders);
    }, [props.displayClips, props.displayRiders]);

    useEffect(() => {
        console.log("setting default search")
        console.log(props.searchValue);
        setDefaultSearch(props.searchValue);
    }, [props.searchValue]);

    const onTrickProfileSearch = async (search: string) => {
        setShowTitle(false);
        if (clipsSelected) {
            setDefaultSearch(search);
            await props.onTrickSearch(search);
        } else if (ridersSelected) {
            setDefaultSearch(search);
            await props.onProfileSearch(search);
        } else {
            setDefaultSearch(search);
            await props.onTrickSearch(search);
        }

        return;
    }

    return (
        <IonHeader>
            {showTitle &&
                <IonToolbar style={{paddingTop: `calc(0.5rem + var(--ion-safe-area-top, 0))`}} mode="ios">
                    <IonButtons slot="start" onClick={props.handleBack}>
                        <IonButton color="theme-alternative">
                            <IonIcon slot="start" icon={chevronBackOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>The Ecliptic Lab</IonTitle>
                </IonToolbar>}
            <IonToolbar style={!showTitle ? {
                paddingTop: `calc(0.5rem + var(--ion-safe-area-top, 0))`,
                marginTop: "0rem",
                marginBottom: "-0.75rem",
                paddingBottom: "0rem"
            } : null} mode="ios">
                {!showTitle &&
                    <IonButtons slot="start" onClick={props.handleBack}>
                        <IonButton color="theme-alternative">
                            <IonIcon slot="start" icon={chevronBackOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                }
                {
                    <IonSearchbar
                        placeholder={"Search for tricks, riders, spots, and more"}
                        animated={false}
                        value={defaultSearch}
                        showClearButton="focus"
                        showCancelButton="focus"
                        onIonChange={(e) => {
                            setDefaultSearch(e.detail.value)
                        }}
                        onIonInput={(e) => {
                            onTrickProfileSearch(e.detail.value)
                        }}
                        onFocus={() => {
                            setShowTitle(false)
                        }}
                        onIonCancel={() => {
                            setShowTitle(true)
                        }}
                        debounce={0}
                    />
                }
            </IonToolbar>
            {
                !showTitle &&
                <IonToolbar style={{
                    paddingLeft: `3.4rem`,
                    paddingRight: `3rem`,
                    marginTop: "0rem",
                    marginBottom: "0rem",
                    paddingTop: "0rem",
                    paddingBottom: "0rem"
                }} mode="ios">
                    <IonButtons className="flex flex-row gap-x-4">
                        <IonButton color={!clipsSelected ? "theme-secondary" : "favorite"}
                                   fill={!clipsSelected ? "clear" : "solid"} shape="round"
                                   onClick={props.onDisplayClips}>
                            Clips
                        </IonButton>
                        <IonButton color={!ridersSelected ? "theme-secondary" : "favorite"}
                                   fill={!ridersSelected ? "clear" : "solid"} shape="round"
                                   onClick={props.onDisplayRiders}>
                            Riders
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            }
        </IonHeader>
    )
};

export default FilterToolbar;
import axios from "axios";

const AnnouncementsService = {
    fetchActiveAnnouncements: async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/announcements/active`);
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    },
};

export default AnnouncementsService;
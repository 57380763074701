import React, { useEffect } from "react";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import { OnboardingStatus } from "../../../models/onboardingStatus";
import WelcomeHeader from "../WelcomeHeader/WelcomeHeader";
import "../Welcome.css";
import { motion } from 'framer-motion';
import { useHistory } from "react-router";
import SparkGrid from "../SparkGrid/SparkGrid";
import WelcomeTOS from "../WelcomeTOS/WelcomeTOS";
import { useSignUp } from "../SignUpProvider/SignUpProvider";

interface WelcomeProps {

}

const Welcome: React.FC<WelcomeProps> = ({ }) => {
    const history = useHistory();
    const { setStep } = useSignUp();


    useEffect(() => {
        console.log("Loading Welcome Page");
        setStep(OnboardingStatus.INIT);
    }, []);

    const handleDirectToSignUp = async () => {
        history.push('/flow/signup');
        await setStep(OnboardingStatus.IN_PROGRESS_SIGNUP_INIT);
    }

    const handleDirectToLogin = async () => {
        history.push('/flow/login');
        await setStep(OnboardingStatus.IN_PROGRESS_LOGIN_INIT);
    }

    useEffect(() => {
        window.prerenderReady = true; // should probably make this a hook with some boolean input to set it to true
    }, []);

    return (
        <IonPage>
            <IonContent className="bg-grid-pattern">
                <div className="relative h-full w-full">
                    <SparkGrid />
                    <div className="relative z-10 h-full w-full flex flex-col">
                        <WelcomeHeader text={'Welcome To Ecliptic'} animate={true} />
                        <motion.div
                            className="flex-grow flex flex-col justify-start items-center p-4 space-y-6"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.8, duration: 0.8 }}
                        >
                            <div className="text-xl px-8 font-bold text-center text-white retro-text">
                                Push your progression
                            </div>
                            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                <IonButton className="neon-button-alt w-64 h-12" onClick={handleDirectToSignUp}>
                                    <span className="text-lg normal-case font-bold">Get Started</span>
                                </IonButton>
                            </motion.div>
                            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                <IonButton className="neon-button w-64 h-12" onClick={handleDirectToLogin}>
                                    <span className="text-lg normal-case font-bold">Log in</span>
                                </IonButton>
                            </motion.div>
                            <WelcomeTOS />
                        </motion.div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Welcome;
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import LoadingProfileComponent from "../components/ComponentsProfile/LoadingProfileComponent/LoadingProfileComponent";

interface ContainerProps {
}

const LoadingPage: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();

    useEffect(() => {
        console.log("Loading Login In App Page");
    }, [history]);

    return (
        <IonPage>
            <ToolbarSearchBar></ToolbarSearchBar>
            <IonContent>
                <LoadingProfileComponent />
            </IonContent>
        </IonPage>
    );
};

export default LoadingPage;
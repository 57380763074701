import { SportType } from "./sportType";
import magicIcon from '../assets/icons/onboarding/game-icons--magick-trick.svg';
import streetIcon from '../assets/icons/onboarding/game-icons--street-light.svg';
import rampIcon from '../assets/icons/onboarding/guidance--ramp-up-arrow.svg';
import trampolineIcon from '../assets/icons/onboarding/hugeicons--trampoline.svg';
import airbagIcon from '../assets/icons/onboarding/mdi--airbag.svg';
import halfpipeIcon from '../assets/icons/onboarding/mdi--bowl-outline.svg';
import mountainIcon from '../assets/icons/onboarding/mynaui--mountain.svg';
import rockIcon from '../assets/icons/onboarding/fluent-emoji-high-contrast--rock.svg';
import desertIcon from '../assets/icons/onboarding/hugeicons--desert.svg';
import flagIcon from '../assets/icons/onboarding/game-icons--checkered-flag.svg'
import snowIcon from '../assets/icons/onboarding/mingcute--snow-line.svg'
import plowIcon from '../assets/icons/onboarding/fa6-solid--snowplow.svg'
import wavesIcon from '../assets/icons/onboarding/material-symbols--waves.svg'
import fridgeIcon from '../assets/icons/onboarding/solar--fridge-bold-duotone.svg'

export enum TerrainOptions {
    PARK = "Park",
    HALFPIPE = "Halfpipe",
    URBAN = "Urban",
    FREERIDE = "Freeride",
    BACKCOUNTRY = "Backcountry",
    GROOMERS = "Groomers",
    MOGULS = "Moguls",
    POWDER = "Powder",
    RACETRACK = "Course",
    TRAMP = "Tramp",
    AIRBAG = "Airbag",
    DRYSLOPE = "Dry Slope",
    FRIDGE = "Fridge",
    OTHER = "Other",
}

export const terrainToIcon = {
    [TerrainOptions.PARK]: rampIcon,
    [TerrainOptions.HALFPIPE]: halfpipeIcon,
    [TerrainOptions.URBAN]: streetIcon,
    [TerrainOptions.FREERIDE]: mountainIcon,
    [TerrainOptions.BACKCOUNTRY]: rockIcon,
    [TerrainOptions.TRAMP]: trampolineIcon,
    [TerrainOptions.AIRBAG]: airbagIcon,
    [TerrainOptions.DRYSLOPE]: desertIcon,
    [TerrainOptions.OTHER]: magicIcon,
    [TerrainOptions.RACETRACK]: flagIcon,
    [TerrainOptions.POWDER]: snowIcon,
    [TerrainOptions.GROOMERS]: plowIcon,
    [TerrainOptions.MOGULS]: wavesIcon,
    [TerrainOptions.FRIDGE]: fridgeIcon,
}

export const terrainIconDictionary = {
    [SportType.SNOWBOARDING]: [
        {
            name: TerrainOptions.PARK,
            icon: terrainToIcon[TerrainOptions.PARK],
        },
        {
            name: TerrainOptions.HALFPIPE,
            icon: terrainToIcon[TerrainOptions.HALFPIPE],
        },
        {
            name: TerrainOptions.URBAN,
            icon: terrainToIcon[TerrainOptions.URBAN],
        },
        {
            name: TerrainOptions.FREERIDE,
            icon: terrainToIcon[TerrainOptions.FREERIDE],
        },
        {
            name: TerrainOptions.BACKCOUNTRY,
            icon: terrainToIcon[TerrainOptions.BACKCOUNTRY],
        },
        {
            name: TerrainOptions.GROOMERS,
            icon: terrainToIcon[TerrainOptions.GROOMERS],
        },
        {
            name: TerrainOptions.MOGULS,
            icon: terrainToIcon[TerrainOptions.MOGULS],
        },
        {
            name: TerrainOptions.POWDER,
            icon: terrainToIcon[TerrainOptions.POWDER],
        },
        {
            name: TerrainOptions.RACETRACK,
            icon: terrainToIcon[TerrainOptions.RACETRACK],
        },
        {
            name: TerrainOptions.TRAMP,
            icon: terrainToIcon[TerrainOptions.TRAMP],
        },
        {
            name: TerrainOptions.AIRBAG,
            icon: terrainToIcon[TerrainOptions.AIRBAG],
        },
        {
            name: TerrainOptions.DRYSLOPE,
            icon: terrainToIcon[TerrainOptions.DRYSLOPE],
        },
        {
            name: TerrainOptions.OTHER,
            icon: terrainToIcon[TerrainOptions.OTHER],
        },
    ],
    [SportType.SKIING]: [
        {
            name: TerrainOptions.PARK,
            icon: terrainToIcon[TerrainOptions.PARK],
        },
        {
            name: TerrainOptions.HALFPIPE,
            icon: terrainToIcon[TerrainOptions.HALFPIPE],
        },
        {
            name: TerrainOptions.URBAN,
            icon: terrainToIcon[TerrainOptions.URBAN],
        },
        {
            name: TerrainOptions.FREERIDE,
            icon: terrainToIcon[TerrainOptions.FREERIDE],
        },
        {
            name: TerrainOptions.BACKCOUNTRY,
            icon: terrainToIcon[TerrainOptions.BACKCOUNTRY],
        },
        {
            name: TerrainOptions.GROOMERS,
            icon: terrainToIcon[TerrainOptions.GROOMERS],
        },
        {
            name: TerrainOptions.MOGULS,
            icon: terrainToIcon[TerrainOptions.MOGULS],
        },
        {
            name: TerrainOptions.POWDER,
            icon: terrainToIcon[TerrainOptions.POWDER],
        },
        {
            name: TerrainOptions.RACETRACK,
            icon: terrainToIcon[TerrainOptions.RACETRACK],
        },
        {
            name: TerrainOptions.TRAMP,
            icon: terrainToIcon[TerrainOptions.TRAMP],
        },
        {
            name: TerrainOptions.AIRBAG,
            icon: terrainToIcon[TerrainOptions.AIRBAG],
        },
        {
            name: TerrainOptions.DRYSLOPE,
            icon: terrainToIcon[TerrainOptions.DRYSLOPE],
        },
        {
            name: TerrainOptions.OTHER,
            icon: terrainToIcon[TerrainOptions.OTHER],
        }
    ],
}
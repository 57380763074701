import React, { useMemo, useCallback, Suspense, lazy } from 'react';
import { IonButton, IonSkeletonText } from '@ionic/react';
import { format, subYears } from 'date-fns';
import { useSignUp } from "../SignUpProvider/SignUpProvider";
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";

// Lazy load the IonDatetime component
const IonDatetime = lazy(() => import('@ionic/react').then(module => ({
    default: module.IonDatetime
})));

interface BirthdayStepProps {
    onNext: () => void;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack?: () => void;
}

const BirthdayStep: React.FC<BirthdayStepProps> = ({
    onNext,
    showSkipButton,
    showBackButton,
    title,
    onBack
}) => {
    const { signUpData, updateSignUpData } = useSignUp();

    const dates = useMemo(() => {
        const earliestDate = new Date(0);
        earliestDate.setUTCFullYear(1);

        return {
            maxDate: format(subYears(new Date(), 4), 'yyyy-MM-dd'),
            startDate: format(subYears(new Date(), 18), 'yyyy-MM-dd'),
            minDate: format(earliestDate, 'yyyy-MM-dd')
        };
    }, []);

    const handleDateChange = useCallback(async (event: CustomEvent) => {
        const value = event.detail.value;
        if (typeof value === 'string') {
            await updateSignUpData({ birthday: value });
        } else if (Array.isArray(value) && value.length > 0) {
            await updateSignUpData({ birthday: value[0] });
        }
    }, [updateSignUpData]);

    // Simple loading state component
    const LoadingState = () => (
        <div className="w-full h-48 bg-primary border border-electric-blue rounded-lg p-4">
            {/* Wheels */}
            <div className="flex justify-center items-center gap-4 h-32">
                {/* Month wheel */}
                <div className="flex flex-col space-y-2 w-20">
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                </div>

                {/* Day wheel */}
                <div className="flex flex-col space-y-2 w-16">
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                </div>

                {/* Year wheel */}
                <div className="flex flex-col space-y-2 w-20">
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                    <IonSkeletonText
                        animated={true}
                        className="h-8 w-full rounded-md"
                    />
                </div>
            </div>
        </div>
    );

    return (
        <OnboardingPageWrapper
            title={title}
            showBackButton={showBackButton}
            showSkipButton={showSkipButton}
            onBack={onBack}
        >
            <div className="w-full h-full max-w-md space-y-6 flex flex-col items-center">
                <Suspense fallback={<LoadingState />}>
                    <IonDatetime
                        presentation="date"
                        min={dates.minDate}
                        max={dates.maxDate}
                        value={signUpData.birthday || dates.startDate}
                        preferWheel={true}
                        className="ion-datetime-custom bg-primary border border-electric-blue b-2 rounded-lg w-full"
                        color="dark"
                        onIonChange={handleDateChange}
                    />
                </Suspense>
                <IonButton
                    expand="block"
                    className="neon-button-alt w-full normal-case font-bold"
                    onClick={onNext}
                    disabled={!signUpData.birthday}
                >
                    Continue
                </IonButton>
            </div>
        </OnboardingPageWrapper>
    );
};

export default BirthdayStep;
import { UserTrickSearchMetadata } from '../../../models/userTrickSearchMetadata';
import HomeTrickCardContainer from '../HomeTrickCardContainer/HomeTrickCardContainer';
import useNewTricks from '../../../hooks/useNewTricks';
import { useContext, useEffect, useCallback, forwardRef, useImperativeHandle, useRef } from 'react';
import { useAppState } from '../../../AppListenerProvider';
import { AppContext } from '../../../AppStateProvider';
import { useAuth } from '../../../AuthProvider';
import { UserType } from '../../../models/usertype';
import LoginButton from '../../ComponentsLogin/LoginButton/LoginButton';
import GenericVirtualizedVideoList, { VirtualizedListRef } from '../GenericVirtualizedVideoList/GenericVirtualizedVideoList';
import HomeLoading from '../HomeLoading/HomeLoading';
import { ElementSizeService } from '../../../services/database/elementsize.service';
import { IonRefresher, IonRefresherContent } from '@ionic/react';

interface Props {
    externalTricks?: UserTrickSearchMetadata[];
    onDataFetched?: (tricks: UserTrickSearchMetadata[]) => void;
    onError?: (error: Error) => void;
    elementSizeService: ElementSizeService | null;
}

export interface ExploreNewVirtualizedListRef {
    scrollToTop: () => void;
    refreshTricks: () => void;
}

const ExploreNewVirtualizedList = forwardRef<ExploreNewVirtualizedListRef, Props>(({
    externalTricks,
    onDataFetched,
    onError,
    elementSizeService
}, ref) => {
    const { state } = useContext(AppContext);
    const { isAuthenticated, isLoading } = useAuth();
    const { isNative } = useAppState();

    const {
        tricks,
        isLoadingNewTricks,
        error,
        infiniteScrollDisabled,
        getMoreNewTricks,
        refreshTricks,
    } = useNewTricks(state.blockedUsers, state.blockingUsers, isAuthenticated, externalTricks);

    const genericListRef = useRef<VirtualizedListRef>(null);

    useImperativeHandle(ref, () => ({
        scrollToTop: () => {
            console.log("SCROLLING TO TOP NEW TRICKS");
            genericListRef.current?.scrollToTop();
        },
        refreshTricks: () => {
            refreshTricks();
        }
    }));

    useEffect(() => {
        if (onDataFetched) {
            onDataFetched(tricks);
        }
    }, [tricks, onDataFetched]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error, onError]);

    const renderItem = useCallback((trick: UserTrickSearchMetadata, isScrolling: boolean, scrollSpeed: number) => (
        <HomeTrickCardContainer
            trick={trick}
            isAuthed={isAuthenticated}
            isCapacitor={isNative}
            userId={state.user?.auth_id}
            publicId={state.user?.id}
            userType={UserType.USER}
            isScrolling={isScrolling}
            scrollSpeed={scrollSpeed}
        />
    ), [isAuthenticated, isNative, state.user]);

    const renderEmptyList = useCallback(() => {
        if (isLoadingNewTricks || isLoading) {
            return <HomeLoading />;
        } else if (!isAuthenticated) {
            return (
                <div className="my-8 mx-4">
                    <LoginButton title="See New Clips on Ecliptic" upload={false} profile={true} />
                </div>
            );
        } else {
            return (
                <div className="h-screen flex flex-row justify-center">
                    <div className="text-xl m-4 text-center">
                        Looks like we don't have what you're looking for based on your filters set at the toolbar,
                        change them or change the game to see more! Refresh if you've uploaded too or changed your filters
                    </div>
                </div>
            );
        }
    }, [isLoadingNewTricks, isLoading, isAuthenticated]);

    return (
        <GenericVirtualizedVideoList
            ref={genericListRef}
            elementSizeService={elementSizeService}
            items={tricks}
            isLoading={isLoadingNewTricks}
            isAuthenticated={isAuthenticated}
            infiniteScrollDisabled={infiniteScrollDisabled}
            loadMoreItems={getMoreNewTricks}
            refreshItems={refreshTricks}
            renderItem={renderItem}
            renderEmptyList={renderEmptyList}
            isNative={isNative}
        />
    );
});

export default ExploreNewVirtualizedList;
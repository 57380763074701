import { Capacitor } from "@capacitor/core";
import { IonHeader, IonSearchbar, IonToolbar } from "@ionic/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";

interface ContainerProps {
    placeholder: string;
    mobilePlaceholder?: string;
    webPlaceholder?: string;
    onSearch: any;
    onSubmit: any;
    searchPlaceholder?: string;
}

const StandardSearchBar: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [placeholder, setPlaceholder] = useState('');

    useEffect(() => {

    }, []);

    // This isn't the marmaque, this is the formikque
    const formik = useFormik({
        initialValues: {
            searchText: props.searchPlaceholder
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("Submitted: " + values.searchText);
            if (values.searchText === "undefined" || values.searchText === undefined) {
                props.onSubmit("");
            } else {
                props.onSubmit(values.searchText);
            }
            formik.values.searchText = '';
        },
    });

    useEffect(() => {
        setPlaceholder(Capacitor.getPlatform() !== 'web' ? (props.mobilePlaceholder ? props.mobilePlaceholder : props.placeholder) : (props.webPlaceholder ? props.webPlaceholder : props.placeholder))
    }, [props]);

    // TODO: animate placeholder for search bar when clicked on but nothing typed in

    return (
        <>
            <IonHeader className="bg-grey">
                <IonToolbar>
                    <div className="flex flex-row flex-nowrap justify-start items-center">
                        <div>
                            <img alt="ID Logo" src="/assets/photos/logo_white.png"></img>
                        </div>
                        <div className="w-5/6">
                            <form onSubmit={formik.handleSubmit}>
                                <IonSearchbar
                                    value={formik.values.searchText}
                                    placeholder={Capacitor.getPlatform() !== 'web' ? (props.mobilePlaceholder ? props.mobilePlaceholder : props.placeholder) : (props.webPlaceholder ? props.webPlaceholder : props.placeholder)}
                                    onIonInput={formik.handleChange}
                                    animated={false}
                                    showClearButton="focus"
                                    showCancelButton="focus"
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                    id="searchText"
                                    debounce={0}
                                />
                            </form>
                        </div>
                    </div>
                </IonToolbar>
            </IonHeader>
        </>
    );
};

export default StandardSearchBar;

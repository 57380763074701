import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import OnboardingPageWrapper from '../OnboardingPageWrapper/OnboardingPageWrapper';

const AuthHandler = () => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const mode = queryParams.get('mode');
        const oobCode = queryParams.get('oobCode');

        switch (mode) {
            case 'resetPassword':
                // Redirect to your password reset page with the oobCode
                history.push(`/auth/reset-password?oobCode=${oobCode}`);
                break;
            default:
                history.push('/flow/login');
        }
    }, [location, history]);

    // Show loading while redirecting
    return (
        <OnboardingPageWrapper title={''} showBackButton={false} showSkipButton={false}>
            <div>

            </div>
        </OnboardingPageWrapper>
    );
};

export default AuthHandler;
import {IonContent, IonPage} from "@ionic/react";
import React, {useEffect, useState} from "react";
import Bisk from "../Bisk/Bisk";
import BiskInput from "../BiskInput/BiskInput";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    bisk: number,
    onBuy?: () => void,
    onSend?: (bisk: number, note: string) => void,
    trickName?: string,
    recipientUsername?: string,
}

const BiskSendModal: React.FC<ContainerProps> = ({onDismiss, onBuy, bisk, onSend, trickName, recipientUsername}) => {
    const [modalBisk, setModalBisk] = useState<number>(bisk);

    useEffect(() => {
        setModalBisk(bisk);
    }, [bisk]);

    const handleNavigateToShop = () => {
        onDismiss(null, 'cancel');
        onBuy();
    }

    const handleSendBisk = (bisk: number, note: string) => {
        onDismiss(null, 'cancel');
        onSend(bisk, note);
    }

    return (
        <IonPage>
            <IDXModalHeader leftButtonAction={() => onDismiss(null, 'cancel')}
                            titleText={`Send Bisk to ${recipientUsername}`} rightButtonText={'Close'}
                            rightButtonAction={() => onDismiss(null, 'cancel')}/>
            <IonContent className="ion-padding">
                <Bisk onBuy={handleNavigateToShop}/>
                <BiskInput bisk={modalBisk} onBuy={handleNavigateToShop} onSend={handleSendBisk} trickName={trickName}
                           recipientUsername={recipientUsername}/>
            </IonContent>
        </IonPage>
    );
};

export default BiskSendModal;
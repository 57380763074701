import React, { useState, useEffect, useCallback, useRef, useLayoutEffect, useMemo } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableItemProps<T> {
    id: string;
    item: T;
    renderItem: (item: T) => React.ReactNode;
    onClick: (item: T) => void;
    width: number;
}

const getColumnCountDefault = () => {
    const width = window.innerWidth;
    if (width >= 1280) return 6;
    if (width >= 768) return 4;
    if (width >= 640) return 3;
    return 2;
};

interface ProfileVirtualSortableGridProps<T> {
    items: T[];
    setItems: (newItems: T[]) => void;
    renderItem: (item: T) => React.ReactNode;
    getItemId: (item: T) => string;
    filter: (item: T) => boolean;
    disabled: boolean;
    isNative: boolean;
    onClick: (item: T) => void;
    activeTab: string;
    getColumnCount?: () => number;
}

function SortableItem<T>({ id, item, renderItem, onClick, width }: SortableItemProps<T>) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: `${width}px`,
        padding: '0.25rem',
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} onClick={() => onClick(item)}>
            {renderItem(item)}
        </div>
    );
}

function ProfileVirtualSortableGrid<T>({
    items,
    setItems,
    renderItem,
    getItemId,
    filter,
    disabled,
    isNative,
    onClick,
    activeTab,
    getColumnCount = getColumnCountDefault,
}: ProfileVirtualSortableGridProps<T>) {
    const [filteredItems, setFilteredItems] = useState(items.filter(filter));
    const parentRef = useRef<HTMLDivElement>(null);
    const parentOffsetRef = useRef(0);
    const averageHeight = 250;
    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey(prev => prev + 1);
    }, [activeTab, items.length]);

    useLayoutEffect(() => {
        const updateLayout = () => {
            if (parentRef.current) {
                parentOffsetRef.current = parentRef.current.offsetTop;
                // Force reflow
                parentRef.current.style.display = 'none';
                parentRef.current.style.display = '';
            }
        };
        updateLayout();
        const timeout = setTimeout(updateLayout, 100);
        return () => clearTimeout(timeout);
    }, [activeTab, items]);

    useLayoutEffect(() => {
        const newFilteredItems = items.filter(filter);
        setFilteredItems(newFilteredItems);
    }, [items, filter]);

    const [columnCount, setColumnCount] = useState(getColumnCount());
    const rowCount = useMemo(() => Math.ceil(filteredItems.length / columnCount), [filteredItems.length, columnCount]);

    useLayoutEffect(() => {
        const updateColumnCount = () => {
            setColumnCount(getColumnCount());
        };

        window.addEventListener('resize', updateColumnCount);
        return () => window.removeEventListener('resize', updateColumnCount);
    }, [getColumnCount]);

    const rowVirtualizer = useVirtualizer({
        count: Math.ceil(filteredItems.length / columnCount),
        estimateSize: () => averageHeight,
        overscan: 4,
        scrollMargin: parentOffsetRef.current,
        getScrollElement: () => parentRef.current,
    });

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: columnCount,
        getScrollElement: () => parentRef.current,
        estimateSize: useCallback(() => (rowCount * averageHeight), [items, columnCount]),
        overscan: 100,
    });

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: isNative ? 500 : 100,
                tolerance: 5,
                distance: 8
            },
        }),
        useSensor(KeyboardSensor)
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = filteredItems.findIndex((item) => getItemId(item) === active.id);
            const newIndex = filteredItems.findIndex((item) => getItemId(item) === over?.id);

            const newItems = [...filteredItems];
            const [reorderedItem] = newItems.splice(oldIndex, 1);
            newItems.splice(newIndex, 0, reorderedItem);

            setFilteredItems(newItems);
            setItems(newItems);
        }
    };

    const columnItems = columnVirtualizer.getVirtualItems();

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={filteredItems.map(getItemId)} strategy={rectSortingStrategy} disabled={disabled}>
                <div
                    key={`${key}-${activeTab}-${filteredItems.length}-${columnCount}`}
                    ref={parentRef}
                    style={{ minHeight: '1px' }}
                    className="h-full overflow-auto">
                    <div
                        style={{
                            height: `${rowVirtualizer.getTotalSize()}px`,
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        {rowVirtualizer.getVirtualItems().map((virtualRow: any) => (
                            <div
                                key={virtualRow.key}
                                data-index={virtualRow.index}
                                ref={rowVirtualizer.measureElement}
                                className="absolute top-0 left-0 w-full"
                                style={{
                                    transform: `translateY(${virtualRow.start - rowVirtualizer.options.scrollMargin}px)`,
                                    display: 'flex',
                                }}
                            >
                                {columnItems.map((virtualColumn) => {
                                    const itemIndex = virtualRow.index * columnCount + virtualColumn.index;
                                    const item = filteredItems[itemIndex];
                                    return item ? (
                                        <SortableItem
                                            key={getItemId(item)}
                                            id={getItemId(item)}
                                            item={item}
                                            renderItem={renderItem}
                                            onClick={onClick}
                                            width={virtualColumn.size}
                                        />
                                    ) : <div
                                        key={Math.ceil(Math.random() * 100000)}
                                        style={{
                                            width: `${virtualColumn.size}px`,
                                            padding: '0.25rem',
                                        }}></div>;
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            </SortableContext>
        </DndContext>
    );
};

export default ProfileVirtualSortableGrid;
import React, { useEffect, useState } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { hydrateRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from "./AuthProvider";
import { patchCordovaJs } from "./services/utils";
import { JeepSqlite } from 'jeep-sqlite/dist/components/jeep-sqlite';
import { Capacitor } from "@capacitor/core";
import {
    CapacitorSQLite,
    SQLiteConnection,
} from "@capacitor-community/sqlite";

patchCordovaJs();

defineCustomElements(window);

// replace console.* for disable log on production
if (process.env.REACT_APP_PRODUCTION === "true" && process.env.REACT_APP_HOST_TYPE === "web") {
    console.log = () => {
    };
    console.error = () => {
    };
    console.debug = () => {
    };
}

declare global {
    interface Window {
        prerenderReady: boolean;
    }
}

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    recaptchaSiteKey: process.env.REACT_APP_FIREBASE_RECAPTCHA_SITE_KEY
};

const Main = () => {
    const [hydrated, setHydrated] = useState(false);

    useEffect(() => {
        setHydrated(true);
    }, []);

    if (!hydrated) {
        return null;
    }

    return (
        <HelmetProvider>
            <AuthProvider firebaseConfig={firebaseConfig}>
                <Auth0Provider
                    domain={process.env.REACT_APP_AUTH_EXTERNAL_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
                    authorizationParams={{
                        redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
                        audience: process.env.REACT_APP_AUTH_API,
                        scope: "read:current_user",
                    }}
                    useRefreshTokens={true}
                    cacheLocation="localstorage"
                >
                    <App />
                </Auth0Provider>
            </AuthProvider>
        </HelmetProvider>
    );
};

const rootRender = () => {
    const container = document.getElementById("root");
    if (container) {
        hydrateRoot(container, <Main />);
    }
};

// USED FOR SQLITE INITIALIZATION

window.addEventListener("DOMContentLoaded", async () => {
    try {
        const platform = Capacitor.getPlatform();

        // WEB SPECIFIC FUNCTIONALITY
        if (platform === "web") {
            const sqlite = new SQLiteConnection(CapacitorSQLite);
            // Create the 'jeep-sqlite' Stencil component
            customElements.define("jeep-sqlite", JeepSqlite);
            const jeepSqliteEl = document.createElement("jeep-sqlite");
            document.body.appendChild(jeepSqliteEl);
            await customElements.whenDefined("jeep-sqlite");
            console.log(`after customElements.whenDefined`);

            // Initialize the Web store
            await sqlite.initWebStore();
            console.log(`after initWebStore`);
        }

        rootRender();
    } catch (e) {
        console.log(e);
    }
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import {
    IonSkeletonText,
    IonList,
    IonItem,
    IonAvatar,
    IonLabel
} from '@ionic/react';

const UserListSkeleton = ({ count = 16 }) => {
    return (
        <div className="w-full">
            {Array(count).fill(0).map((_, index) => (
                <IonItem key={index} className="py-2">
                    <IonAvatar slot="start" className="w-10 h-10">
                        <IonSkeletonText animated={true} />
                    </IonAvatar>

                    <IonLabel>
                        <div className="flex flex-col gap-2">
                            <IonSkeletonText
                                animated={true}
                                className="h-4 w-24"
                            />
                            <IonSkeletonText
                                animated={true}
                                className="h-3 w-32 opacity-50"
                            />
                        </div>
                    </IonLabel>
                </IonItem>
            ))}
        </div>
    );
};

export default UserListSkeleton;
import React, { useContext, useEffect } from "react";
import basicBitch from "../../../assets/icons/system/idXBasicBadge.svg";
import premiumHo from "../../../assets/icons/system/idXPremiumBadge.svg";
import { IonIcon } from "@ionic/react";
import { SubscriptionType } from "../../../models/subscriptionType";
import { AppContext } from "../../../AppStateProvider";
import BiskService from "../../../services/bisk.service";
import { useQuery } from "@tanstack/react-query";

interface ContainerProps {
    username: string,
    className: string,
    userId?: string,
    onUsernameClickHandler?: (username: string) => void,
    loggedInId?: string
    bypassState?: boolean
}

const UsernameDisplay: React.FC<ContainerProps> = ({
    username,
    className,
    userId,
    onUsernameClickHandler,
    loggedInId,
    bypassState = false
}) => {
    const { state } = useContext(AppContext);
    const [usernameDisplay, setUsernameDisplay] = React.useState<string>('');

    // Query for subscription data
    const { data: subscriptionData } = useQuery({
        queryKey: ['subscription', userId || username],
        queryFn: async () => {
            if (loggedInId && userId && loggedInId === userId && !bypassState) {
                return {
                    isSubscribed: state.isSubscribed,
                    subscriptionLevel: state.subscriptionLevel
                };
            }

            if (userId) {
                const isSubscribed = await BiskService.getIsSubscribedById(userId);
                const subscriptionLevel = isSubscribed
                    ? await BiskService.getSubscriptionLevelById(userId)
                    : SubscriptionType.None;
                return { isSubscribed, subscriptionLevel };
            }

            const isSubscribed = await BiskService.getIsSubscribedByUsername(username);
            const subscriptionLevel = isSubscribed
                ? await BiskService.getSubscriptionLevelByUsername(username)
                : SubscriptionType.None;
            return { isSubscribed, subscriptionLevel };
        },
        enabled: !!username,
        staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
    });

    useEffect(() => {
        if (username) {
            setUsernameDisplay(username);
        }
    }, [username]);

    const onUsernameClick = () => {
        if (!onUsernameClickHandler) return;
        onUsernameClickHandler(username)
    }

    return (
        <div className={className + " flex flex-row items-center"} onClick={onUsernameClick}>
            {usernameDisplay}
            {subscriptionData?.isSubscribed && (
                <IonIcon
                    src={subscriptionData.subscriptionLevel === SubscriptionType.Basic ? basicBitch : premiumHo}
                    className={"w-3.5 h-3.5 ml-2"}
                />
            )}
        </div>
    );
};

export default UsernameDisplay;
import React from "react";
import { Helmet } from "react-helmet-async";

interface ContainerProps {
    title: string;
    description: string;
    image: string;
    url: string;
}

const CustomHelmet: React.FC<ContainerProps> = ({
    title = "Ecliptic // View",
    description = "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed",
    image = "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png",
    url = ""
}) => {
    return (
        <Helmet>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta
                property="og:description"
                content={description}
            />
            <meta
                property="og:image"
                content={image}
            />
            <meta property="og:url" content={url} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@idxstyle" />
            <meta
                property="twitter:image"
                content={image}
            />
        </Helmet>
    );
};

export default CustomHelmet;
import { useEffect, useRef } from 'react';
import { useIonRouter } from '@ionic/react';
import customBackAnimation from '../components/ComponentsGestures/NavigationAnimation/customBackAnimation';
import { createSwipeBackGesture } from '../components/ComponentsGestures/SwipeBackGesture/customSwipeBackGesture';

export const useCustomSwipeBack = (elementRef: React.RefObject<HTMLIonRouterOutletElement>) => {
    const router = useIonRouter();
    const gestureRef = useRef<any>(null);

    useEffect(() => {
        const el = elementRef.current;
        if (el) {
            const gesture = createSwipeBackGesture(
                el,
                () => router.canGoBack(),
                () => {
                    // Animation start is handled in createSwipeBackGesture
                },
                (step: number) => {
                    // Animation progress is handled in createSwipeBackGesture
                },
                (shouldComplete: boolean, step: number, duration: number) => {
                    if (shouldComplete) {
                        const animation = customBackAnimation(el, {
                            direction: 'back',
                            enteringEl: el.children[0] as HTMLElement,
                            leavingEl: el.children[1] as HTMLElement
                        });

                        // If the animation is defined, use it for the back navigation
                        if (animation) {
                            router.goBack(() => animation);
                        } else {
                            router.goBack();
                        }
                    }
                }
            );

            gesture.enable(true);
            gestureRef.current = gesture;
        }

        return () => {
            if (gestureRef.current) {
                gestureRef.current.destroy();
            }
        };
    }, [router]);
};
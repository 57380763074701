import React, { useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import WelcomeHeader from "../WelcomeHeader/WelcomeHeader";
import "../Welcome.css";
import { motion } from 'framer-motion';
import SparkGrid from "../SparkGrid/SparkGrid";

interface WelcomeEclipticLoadingProps {

}

const WelcomeEclipticLoading: React.FC<WelcomeEclipticLoadingProps> = ({ }) => {
    return (
        <IonPage>
            <IonContent className="bg-grid-pattern">
                <div className="relative h-full w-full">
                    <SparkGrid />
                </div>
            </IonContent>
        </IonPage>
    );
};

export default WelcomeEclipticLoading;
import React from 'react';
import { IonSkeletonText } from '@ionic/react';

const EventPageLoading = () => {
    return (
        <div className="text-white p-4 max-w-md mx-auto">
            {/* Header Image */}
            <div className="aspect-video bg-gray-800 mb-4 rounded-lg relative animate-pulse">
                <IonSkeletonText animated className="absolute bottom-4 left-4 h-6 w-3/4" />
            </div>

            {/* Event Title */}
            <IonSkeletonText animated className="h-6 w-3/4 mb-2" />

            {/* Event Creator */}
            <IonSkeletonText animated className="h-4 w-1/4 mb-2" />

            {/* Event Date */}
            <IonSkeletonText animated className="h-4 w-1/2 mb-4" />

            {/* Status and Location */}
            <div className="flex space-x-2 mb-4">
                <IonSkeletonText animated className="h-8 w-20 rounded-full" />
                <IonSkeletonText animated className="h-8 w-24 rounded-full" />
            </div>

            {/* Tags */}
            <div className="flex flex-wrap gap-2 mb-4">
                <IonSkeletonText animated className="h-8 w-32 rounded-full" />
                <IonSkeletonText animated className="h-8 w-16 rounded-full" />
                <IonSkeletonText animated className="h-8 w-24 rounded-full" />
                <IonSkeletonText animated className="h-8 w-28 rounded-full" />
                <IonSkeletonText animated className="h-8 w-32 rounded-full" />
            </div>

            {/* Action Buttons */}
            <div className="flex justify-between mb-4">
                <IonSkeletonText animated className="h-10 w-24" />
                <IonSkeletonText animated className="h-10 w-24" />
            </div>

            {/* Event Bag */}
            <div className="mb-4">
                <IonSkeletonText animated className="h-6 w-32 mb-2" />
                <IonSkeletonText animated className="h-2 w-full" />
            </div>

            {/* Search Bar */}
            <IonSkeletonText animated className="h-10 w-full mb-4 rounded-full" />

            {/* Media Grid */}
            <div className="grid grid-cols-2 gap-2">
                <div className="aspect-video bg-gray-800 rounded-lg relative animate-pulse">
                    <IonSkeletonText animated className="absolute bottom-2 left-2 h-4 w-3/4" />
                    <IonSkeletonText animated className="absolute bottom-8 left-2 h-3 w-1/2" />
                </div>
                <div className="aspect-video bg-gray-800 rounded-lg relative animate-pulse">
                    <IonSkeletonText animated className="absolute bottom-2 left-2 h-4 w-3/4" />
                    <IonSkeletonText animated className="absolute bottom-8 left-2 h-3 w-1/2" />
                </div>
            </div>
        </div>
    );
};

export default EventPageLoading;
import React from 'react';
import { IonSkeletonText } from '@ionic/react';

const HomeLoading = () => {
    return (
        <div className="text-white p-4 max-w-md mx-auto">
            {/* Header */}
            <div className="flex items-center mb-2">
                <IonSkeletonText animated className="w-8 h-8 rounded-full mr-2" />
                <IonSkeletonText animated className="h-4 w-1/3" />
            </div>

            {/* Video placeholder */}
            <div className="aspect-video bg-gray-800 mb-2 rounded-lg relative animate-pulse">
                <IonSkeletonText animated className="absolute bottom-2 left-2 w-16 h-5" />
            </div>

            {/* Post title */}
            <IonSkeletonText animated className="h-5 w-1/4 mb-2" />

            {/* Tags */}
            <div className="flex space-x-2">
                <IonSkeletonText animated className="h-6 w-24" />
                <IonSkeletonText animated className="h-6 w-12" />
                <IonSkeletonText animated className="h-6 w-12" />
            </div>

            {/* Header */}
            <div className="flex items-center mb-2">
                <IonSkeletonText animated className="w-8 h-8 rounded-full mr-2" />
                <IonSkeletonText animated className="h-4 w-1/3" />
            </div>

            {/* Video placeholder */}
            <div className="aspect-[9/16] bg-gray-800 mb-2 rounded-lg relative animate-pulse">
                <IonSkeletonText animated className="absolute bottom-2 left-2 w-16 h-5" />
            </div>

            {/* Post title */}
            <IonSkeletonText animated className="h-5 w-1/4 mb-2" />

            {/* Tags */}
            <div className="flex space-x-2">
                <IonSkeletonText animated className="h-6 w-24" />
                <IonSkeletonText animated className="h-6 w-12" />
                <IonSkeletonText animated className="h-6 w-12" />
            </div>
        </div>
    );
};

export default HomeLoading;
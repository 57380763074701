import React, { createContext, useContext, useState, useCallback } from 'react';
import type { MediaPlayerInstance } from '@vidstack/react';

// Define the context type
interface VideoContextType {
    hasInteracted: boolean;
    setHasInteracted: (value: boolean) => void;
    playerRef: React.RefObject<MediaPlayerInstance> | null;
}

// Create the context
const VideoContext = createContext<VideoContextType>({
    hasInteracted: false,
    setHasInteracted: () => { },
    playerRef: null,
});

// Create a provider component
interface VideoProviderProps {
    children: React.ReactNode;
    playerRef: React.RefObject<MediaPlayerInstance>;
}

export const VideoProvider: React.FC<VideoProviderProps> = ({ children, playerRef }) => {
    const [hasInteracted, setHasInteracted] = useState(false);

    const handleInteraction = useCallback((value: boolean) => {
        setHasInteracted(value);
    }, []);

    return (
        <VideoContext.Provider
            value={{
                hasInteracted,
                setHasInteracted: handleInteraction,
                playerRef
            }}
        >
            {children}
        </VideoContext.Provider>
    );
};

// Custom hook to use the video context
export const useVideoContext = () => {
    const context = useContext(VideoContext);
    if (!context) {
        throw new Error('useVideoContext must be used within a VideoProvider');
    }
    return context;
};
import React, { useRef, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react';
import { IonButton, IonIcon, IonSpinner, IonRefresher, IonRefresherContent } from '@ionic/react';
import { UserTrickSearchMetadata } from '../../../models/userTrickSearchMetadata';
import LoginButton from '../../ComponentsLogin/LoginButton/LoginButton';
import HomeLoading from '../HomeLoading/HomeLoading';
import HomeTrickCardContainer from '../HomeTrickCardContainer/HomeTrickCardContainer';
import { AppContext } from '../../../AppStateProvider';
import { arrowUpOutline } from 'ionicons/icons';
import { useAuth } from '../../../AuthProvider';
import { useAppState } from '../../../AppListenerProvider';
import { UserType } from '../../../models/usertype';
import useFollowTricks from '../../../hooks/useFollowTricks';
import GenericVirtualizedVideoList, { VirtualizedListRef } from '../GenericVirtualizedVideoList/GenericVirtualizedVideoList';
import { ElementSizeService } from '../../../services/database/elementsize.service';

interface Props {
    externalTricks?: UserTrickSearchMetadata[];
    onDataFetched?: (tricks: UserTrickSearchMetadata[]) => void;
    onError?: (error: Error) => void;
    elementSizeService: ElementSizeService | null;
}

export interface ExploreFollowVirtualizedListRef {
    scrollToTop: () => void;
    refreshTricks: () => void;
}

const ExploreFollowVirtualizedList = forwardRef<ExploreFollowVirtualizedListRef, Props>(({
    externalTricks,
    onDataFetched,
    onError,
    elementSizeService
}, ref) => {
    const { state } = React.useContext(AppContext);
    const { isAuthenticated, isLoading } = useAuth();
    const { isNative } = useAppState();

    const {
        tricks,
        isLoadingFollowTricks,
        error,
        infiniteScrollDisabled,
        getMoreFollowingTricks,
        refreshTricks,
    } = useFollowTricks(
        state?.user?.id,
        state.following,
        state.blockedUsers,
        state.blockingUsers,
        isAuthenticated,
        externalTricks
    );

    const genericListRef = useRef<VirtualizedListRef>(null);

    useImperativeHandle(ref, () => ({
        scrollToTop: () => {
            genericListRef.current?.scrollToTop();
        },
        refreshTricks: () => {
            refreshTricks();
        }
    }));

    useEffect(() => {
        if (onDataFetched) {
            onDataFetched(tricks);
        }
    }, [tricks, onDataFetched]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error, onError]);

    const renderItem = useCallback((trick: UserTrickSearchMetadata, isScrolling: boolean, scrollSpeed: number) => (
        <HomeTrickCardContainer
            trick={trick}
            isAuthed={isAuthenticated}
            isCapacitor={isNative}
            userId={state.user?.auth_id}
            publicId={state.user?.id}
            userType={UserType.USER}
            isScrolling={isScrolling}
            scrollSpeed={scrollSpeed}
        />
    ), [isAuthenticated, isNative, state.user]);

    const renderEmptyList = useCallback(() => {
        if (isLoadingFollowTricks || isLoading) {
            return <HomeLoading />;
        } else if (!isAuthenticated) {
            return (
                <div className="my-8 mx-4">
                    <LoginButton title="See Clips of Riders You Follow" upload={false} profile={true} />
                </div>
            );
        } else if (state.following <= 0) {
            return (
                <div className="flex flex-row justify-center">
                    <div className="text-xl m-4 text-center">
                        Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed!
                    </div>
                </div>
            );
        } else if (state.following > 0 && tricks.length <= 0) {
            return (
                <div className="flex flex-row justify-center">
                    <div className="text-xl m-4 text-center">
                        Start Following More People in the Park on Ecliptic!
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex flex-row justify-center">
                    <div className="text-xl m-4 text-center">
                        Looks like we don't have what you're looking for based on your filters set at the toolbar,
                        change them or change the game to see more!
                    </div>
                </div>
            );
        }
    }, [isLoadingFollowTricks, isLoading, isAuthenticated, state.following, tricks.length]);

    const renderFooter = useCallback(() => {
        if (
            isLoading ||
            !isAuthenticated ||
            state.following <= 0 ||
            (state.following > 0 && tricks.length <= 0)
        ) {
            return null;
        } else if (tricks.length > 0 && !infiniteScrollDisabled && isLoadingFollowTricks) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonSpinner color="theme-secondary"></IonSpinner>
                </div>
            );
        } else {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonButton
                        fill="clear"
                        color="theme-secondary"
                        size="small"
                        className="pb-4"
                        onClick={() => genericListRef.current?.scrollToTop()}
                    >
                        <span className="text-zinc-300 text-lg">
                            Back to top
                        </span>
                        <IonIcon slot="end" icon={arrowUpOutline} />
                    </IonButton>
                </div>
            );
        }
    }, [isLoading, isAuthenticated, state.following, tricks.length, infiniteScrollDisabled, isLoadingFollowTricks]);

    return (
        <GenericVirtualizedVideoList
            elementSizeService={elementSizeService}
            ref={genericListRef}
            items={tricks}
            isLoading={isLoadingFollowTricks}
            isAuthenticated={isAuthenticated}
            infiniteScrollDisabled={infiniteScrollDisabled}
            loadMoreItems={getMoreFollowingTricks}
            refreshItems={refreshTricks}
            renderItem={renderItem}
            renderEmptyList={renderEmptyList}
            renderFooter={renderFooter}
            isNative={isNative}
        />
    );
});

export default ExploreFollowVirtualizedList;
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonCheckbox,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonProgressBar,
    IonSpinner,
    IonTextarea,
    IonToolbar,
    isPlatform,
    useIonRouter,
    useIonToast
} from "@ionic/react";
import { alertCircleOutline, chevronDownOutline, informationCircleOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useHistory, useLocation } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import Dropdown from "../components/ComponentsUI/Dropdown/Dropdown";
import MultiSelectHashtag from "../components/ComponentsUI/MultiSelectHashtag/MultiSelectHashtag";
import TagSelectOnUpload from "../components/ComponentsUI/TagSelectOnUpload/TagSelectOnUpload";
import "../global.css";
import { ClipType } from "../models/clipType";
import { LocationInput } from "../models/locationInput";
import { SelectOptions } from "../models/profileSelectOptions";
import { SportType } from "../models/sportType";
import { User } from "../models/user";
import { UserTrick } from "../models/user-trick";
import LocationService from "../services/location.service";
import UserService from "../services/user.service";
import userService from "../services/user.service";
import { base64FromBlob, FOLLOWERS_ONLY, userUploadsDirectory } from "../services/utils";
import { AppContext } from "../AppStateProvider";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import { useAuth } from "../AuthProvider";
import LoadingPage from "./LoadingPage";
import LoginButtonPage from "./LoginButtonPage";
import PreferencesService from "../services/preferences.service";
import { LoadSuggestedTricksStatus } from "../models/loadSuggestedTricksStatus";
import { UserType } from "../models/usertype";
import IDXButtonIcon from "../components/ComponentsUI/IDXButtonIcon/IDXButtonIcon";
import { useAppState } from "../AppListenerProvider";
import UploadSuccess from "../components/ComponentsUpload/UploadSuccess/UploadSuccess";
import { useQueryClient } from "@tanstack/react-query";
import { vi } from "date-fns/locale";

const sportOptions: SelectOptions[] = [
    {
        id: 1,
        name: SportType.SKIING,
    },
    {
        id: 2,
        name: SportType.SNOWBOARDING,
    }
];

const features: SelectOptions[] = [
    {
        id: 1,
        name: 'Jump',
    },
    {
        id: 2,
        name: 'Rail/Jib',
    },
    {
        id: 3,
        name: 'Halfpipe',
    },
    {
        id: 3,
        name: 'Freeride',
    },
    {
        id: 4,
        name: 'Off Mountain',
    },
];

const options = [
    { value: ClipType.CLIP, label: 'Clip' },
    { value: ClipType.LINE, label: 'Line' },
    { value: ClipType.EDIT, label: 'Edit' },
];

const otherFeatures: SelectOptions[] = [];

const trickNamePlaceholderText = [
    'switch lip 270, dub kinkinator slide, crowd pleaser, frontside 540...',
    'frontside 360, the streets arent safe, back swap transfer, butternut squash...'
]

const processingYourVideoText = [
    'Making your clip viewable for Ecliptic',
    'Thanks for uploading :)',
    'Just checking to see if you filmed better',
    'Analyzing for 3XXXL Sized Clothes',
    'Booling on the back end so you can stunt on the front end',
    'Ensuring that wu tang is for the children',
    'Cheddar bisq is around the corner',
    'Making bomb holes on the back end',
    'Yo is it a swap or a switch up, let us know',
    'Were gonna be pretty hyped after you upload this fyi',
    'Scanning your Ecliptic login',
    'Our servers are internally raging about the debate of poles vs no poles',
    'Checking to see if your a terrorist from the public school era',
    'Who is the GOAT, let us know',
    'Dua Lipa Slaps',
    'Creating the Ecliptic Hoop Mixtape',
    'Is Ecliptic core?!?',
    'Is Ecliptic the most least core company in the game!?!',
    '"May the force be with you" - Obiwan Sendobi',
    "Marvel Avengers - Fact or Fraud?",
    "Jesus saved the steeze, it was just ruined by [Insert Brand You Hate]",
    "congrats on your cork elementry degree",
    "I miss the old Kayne",
    "Tearz",
    "Protect Ya Neck",
    "Wu-Tang Clan Ain't Nuthin Ta F' Wit",
    "C.R.E.A.M",
    "Bring Da Ruckus",
    "Can It Be All So Simple?!?!",
    "Da Mystery of Chessboxin'",
    "Are You The Method Man",
    "The Art of Steeze",
    "I mean seriously, is freeskiing and snowboarding an art or sport?",
    "Nah fam, freeskiing and snowboarding is a CRAFT",
    "Who you rockin with",
    "Dua Lipa",
    "Our Servers are Levitating",
    "And there is a drive deep into left by Castellanos",
    "Please, finish uploading I can't take it too see another one of your stupid phrases",
    "NS is the best ski company.........NOT",
    "Forever shoutout the Mom and Dad Cam #Legendary",
    "F* Vail",
    "I mean seriously, the Ikon and Epic Pass are great deals but ruins local communities",
    "Ecliptic Knows What's Real",
    "THE SLC TAKEOVER IS HERE",
    "The Original Iron Man Slapped",
    "What's your favorite ski movie - let us know",
    "Yew!",
    "Christopher Railbus chartered the rails and side hits of America - learned this from 7th grade history"
]

const Upload: React.FC = () => {
    const { isNative, platform } = useAppState();
    const { state, dispatch } = React.useContext(AppContext);
    const history = useHistory();
    const locationReact = useLocation();

    const [selectedFile, setSelectedFile] = useState();
    const [desiredBlobName, setDesiredBlobName] = useState<string>(undefined);

    const [trickName, setTrickName] = useState("");
    const [trickCaption, setTrickCaption] = useState("");
    const [hashtags, setHashtags] = useState([]);

    const [location, setLocation] = useState("");
    const [locationInput, setLocationInput] = useState<LocationInput>(null);
    const [locationDropdownOptions, setLocationDropdownOptions] = useState<LocationInput[]>([]);
    const [locationDropdown, setLocationDropdown] = useState(false);

    const [sport, setSport] = useState("");
    const [feature, setFeature] = useState("");
    const [preview, setPreview] = useState("");

    const [linkLat, setLinkLat] = useState(undefined);
    const [linkLng, setLinkLng] = useState(undefined);
    const [linkCreationTime, setLinkCreationTime] = useState(undefined);
    const [linkAlt, setLinkAlt] = useState(undefined);

    const [selectedTags, setSelectedTags] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [filmDate, setFilmDate] = useState(new Date(new Date().toDateString()).toISOString());
    const [videoUploading, setVideoUploading] = useState(false);
    const [videoProcessing, setVideoProcessing] = useState(false);

    const [randomText, setRandomText] = useState("");

    const [present] = useIonToast();

    const [trickUploaderTrickNamePlaceholderText, setTrickUploaderTrickNamePlaceholderText] = useState(trickNamePlaceholderText[0]);

    // states for determining what to show to users
    const [fileLoaded, setFileLoaded] = useState<boolean>(false);
    const [fileIsLink, setFileIsLink] = useState<boolean>(false);
    const [fileLink, setFileLink] = useState<string>(undefined);
    const [showTrickForm, setShowTrickForm] = useState(false);
    const [showTagSelect, setShowTagSelect] = useState(false);

    // Edit
    const [editLink, setEditLink] = useState<string>("");
    const [isValidLink, setIsValidLink] = useState<boolean>(false);

    const { user, isLoading, isAuthenticated } = useAuth();
    const [userProfile, setProfile] = useState(null);
    const [profileError, setProfileError] = useState(false);
    const [isAuthed, setIsAuthed] = useState(undefined);

    const previewVideoRef = useRef(null);

    const [displayClipInfoEntry, setDisplayClipInfoEntry] = useState(false);
    const [displayTrickInfoEntry, setDisplayTrickInfoEntry] = useState(false);

    const CHUNK_SIZE = 5 * 1024 * 1024
    const MAX_FILE_SIZE = 256000 // 250 MB
    const MAX_FILE_SIZE_ANDROID_PLAY = 20480 // 20MB
    const MAX_FILE_SIZE_IPHONE_PLAY = 32768 // 32MB
    const MAX_FILE_SIZE_WEB_PLAY = 32768 // 32MB

    const [unlisted, setUnlisted] = useState(false);

    const [googlePlacesSessionToken, setGooglePlacesSessionToken] = useState(null);

    // Control Flow
    const [segmentValue, setSegmentValue] = useState<string>(ClipType.CLIP);

    useEffect(() => {
        console.log("On the upload page and rendering");
        setProfileError(false);
        setProfile(null);

        // Location
        setLocationDropdown(false);
        setLocationDropdownOptions([]);
        setLocationInput(null);
        setLocation("");
        setGooglePlacesSessionToken(uuidv4());

        const random = Math.floor(Math.random() * trickNamePlaceholderText.length);
        setTrickUploaderTrickNamePlaceholderText(trickNamePlaceholderText[random]);

        const getUserProfile = async () => {
            console.log("getting user profile")
            const response: User = await UserService.getUserByAuthId(user?.uid);
            if (response === null) {
                setProfileError(true);
            }

            setProfile(response);

            if (response !== null && response !== undefined) {
                if (response.profile.sports.length === 1) {
                    setSport(response.profile.sports[0]);
                }
            }
        }

        if (!isLoading) {
            window.prerenderReady = true;
            if (isAuthenticated) {
                getUserProfile();
            }
        }
    }, [isLoading, user?.uid, isAuthenticated]);

    useEffect(() => {
        console.log("On the upload page and rendering");

        setDesiredBlobName(null);
    }, []);

    useEffect(() => {
        if (videoProcessing || videoUploading) {
            const intervalId = setInterval(() => {
                const randomIndex = Math.floor(Math.random() * processingYourVideoText.length);
                setRandomText(processingYourVideoText[randomIndex]);
            }, 4000);
            return () => clearInterval(intervalId);
        }
    }, [videoProcessing, videoUploading]);

    const storeVideoiOS = async (blob: any, pathName: string) => {
        const base64Data = await base64FromBlob(blob);
        const savedFile = await Filesystem.writeFile({
            path: pathName,
            data: base64Data,
            directory: Directory.Cache,
            recursive: true
        });
        return savedFile.uri;
    }

    const storeVideo = async (blob: any, pathName: string) => {
        if (Capacitor.getPlatform() === "ios") {
            return await storeVideoiOS(blob, pathName);
        } else if (Capacitor.getPlatform() === "android") {
            console.log("Storing in the file path");
            // can assume all videos will be uploaded under 25MB which should cause android to not crash
            return await storeVideoiOS(blob, pathName);
        } else {
            return await storeVideoiOS(blob, pathName);
        }
    }

    const fetchVideoAndPlay = (video: any, url: string) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                video.srcObject = blob;
                video.play();
                video.muted = false;
                return;
            })
            .then(_ => {
                // Video playback started ;)
                console.log("video has started");
            })
            .catch(e => {
                // Video playback failed ;(
                console.log("video playback has failed");
            })
    }

    const processVideoWeb = async (objectUrl: string) => {
        try {
            // Validate the object URL
            if (!objectUrl.startsWith('blob:')) {
                console.error('Invalid object URL format:', objectUrl);
                return;
            }

            // Create a new blob from the object URL to ensure proper format
            try {
                const response = await fetch(objectUrl);
                const blob = await response.blob();

                // Log blob details for debugging
                console.log('Video blob details:', {
                    type: blob.type,
                    size: blob.size
                });

                // Create a new blob URL
                const newBlobUrl = URL.createObjectURL(blob);
                setPreview(newBlobUrl);

                // Clean up the old URL
                URL.revokeObjectURL(objectUrl);

                if (previewVideoRef.current) {
                    // Reset video element
                    previewVideoRef.current.currentTime = 0;

                    // For Safari, we need to set the source directly
                    previewVideoRef.current.src = newBlobUrl;

                    // Wait for metadata to load before playing
                    previewVideoRef.current.onloadedmetadata = async () => {
                        try {
                            previewVideoRef.current!.muted = !isNative;
                            await previewVideoRef.current!.play();
                        } catch (playError) {
                            console.error('Error playing video:', playError);
                        }
                    };

                    // Force load
                    await previewVideoRef.current.load();
                }
            } catch (error) {
                console.error('Error processing blob:', error);
            }
        } catch (error) {
            console.error('Error in processVideoWeb:', error);
        }
    };

    const processVideoMobile = async (blob: any, pathName: string) => {
        const mobileVideoUri = await storeVideo(blob, pathName);
        console.log("URL Storage: " + mobileVideoUri);
        const objectUrlCapacitor = Capacitor.convertFileSrc(mobileVideoUri)
        console.log("URL Preview: " + objectUrlCapacitor);
        setPreview(objectUrlCapacitor);
        if (Capacitor.getPlatform() === 'android') {
            // @ts-ignore
            if (previewVideoRef.current) {
                previewVideoRef.current.load();
                fetchVideoAndPlay(previewVideoRef, objectUrlCapacitor);
            }
        } else {
            if (previewVideoRef.current) {
                previewVideoRef.current.play();
                previewVideoRef.current.muted = !isNative;
            }
        }
    }

    const getFileExtensionFromBlob = (blob: any) => {
        let filename = blob.name || '';
        let dotIndex = filename.lastIndexOf('.');
        let extension = dotIndex >= 0 ? filename.substring(dotIndex + 1).toLowerCase() : '';
        return extension || 'mp4';
    }

    const compressVideoForViewing = async (blob: any) => {
        setVideoProcessing(true);

        const fileExtension = getFileExtensionFromBlob(blob);
        const filename = uuidv4().toString()
        const fullFileName = filename + "." + fileExtension;

        console.log("Chunking to the server");
        // must send to the server in chunks to compress

        const CHUNK_SIZE = 12 * 1024 * 1024; // 24 MB
        const fileSize = blob.size;
        const chunks = Math.ceil(fileSize / CHUNK_SIZE);
        const infd_id = userProfile.id;

        for (let i = 0; i < chunks; i++) {
            const start = i * CHUNK_SIZE;
            const end = Math.min(fileSize, start + CHUNK_SIZE);
            const chunk = blob.slice(start, end);

            const formData = new FormData();
            formData.append('file', chunk, `${filename}.part${i}`);
            formData.append('filename', filename);

            const chunkRes = await UserService.uploadTrickInChunks(formData, infd_id, filename);
            console.log("Res uploaded success: " + chunkRes)
        }

        console.log("Compressing and uploading to the server");
        const urlObject = await UserService.uploadUserTrickAssemble(infd_id, filename, fileExtension);
        console.log("Downloading via the server");

        if (urlObject) {
            if (urlObject['url'] !== '' && urlObject['url'] != null) {
                const url = urlObject['url'];
                setFileIsLink(true);
                setFileLink(url);
                setPreview(url);

                if ('lat' in urlObject && urlObject['lat'] !== null) {
                    setLinkLat(urlObject['lat'].toString());
                }

                if ('long' in urlObject && urlObject['long'] !== null) {
                    setLinkLng(urlObject['long'].toString());
                }

                if ('altitude' in urlObject && urlObject['altitude'] !== null) {
                    setLinkAlt(urlObject['altitude'].toString());
                }

                if ('creation_time' in urlObject && urlObject['creation_time'] !== null) {
                    setLinkCreationTime(urlObject['creation_time'].toString());
                }

                setFileLoaded(true);
                setVideoProcessing(false);
                if (previewVideoRef.current) {
                    await previewVideoRef.current.load();
                    previewVideoRef.current.play();
                    previewVideoRef.current.muted = !isNative;
                }
                return;
            } else {
                setVideoProcessing(false);
                presentToast("Something went wrong when we were trying to compress your clip, try something under 20MB for Android and 32MB for iPhone and Web");
                return;
            }
        } else {
            setVideoProcessing(false);
            presentToast("Something went wrong when we were trying to compress your clip, try something under 20MB for Android and 32MB for iPhone and Web");
            return;
        }
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        console.log("Object url is this: " + objectUrl);

        if (isNative && platform === "android") {
            const fileName = new Date().getTime() + '.mp4'
            const pathName = userUploadsDirectory + fileName;

            processVideoMobile(selectedFile, pathName);
        } else {
            processVideoWeb(objectUrl);
        }
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    // Location Dropdown
    const closeDropdown = useRef(null);

    const closeOpenMenus = (e: any) => {
        if (locationDropdown && !closeDropdown.current.contains(e.target)) {
            setLocationDropdown(false);
        }

        if (locationDropdown && e.key === 'Enter') {
            setLocationDropdown(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenus);
        document.addEventListener('keypress', closeOpenMenus);

        return () => {
            document.removeEventListener("mousedown", closeOpenMenus);
            document.removeEventListener('keypress', closeOpenMenus);
        }
    }, [locationDropdown]);

    const addHashtag = (item: any) => {
        setHashtags(hashtags.concat(item));
        setSelectedTags(selectedTags.concat(item));
    }

    const removeHashtag = (item: any) => {
        const filtered = hashtags.filter((e: any) => e !== item);
        setHashtags(filtered);

        const filteredTags = hashtags.filter((e: any) => e !== item);
        setSelectedTags(filteredTags);
    }

    // adds new item to multiselect
    const addTag = (item: any) => {
        setSelectedTags(selectedTags.concat(item));
        setShowDropdown(false);
    };

    // removes item from multiselect
    const removeTag = (item: any) => {
        const filtered = selectedTags.filter((e: any) => e !== item);
        setSelectedTags(filtered);
    }

    const onSelectFile = async (event: any) => {
        if (!event.target.files || event.target.files.length === 0 || event.target.files.length > 1) {
            setSelectedFile(undefined);
            return;
        }

        const fileSizeKiloBytes = event.target.files[0].size / 1024

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            presentToast("The max file size must be less than 250MB so we can afford that sweet ramen");
            return;
        }

        if (!event.target.files[0].type.startsWith('video/')) {
            presentToast("Clips only!");
            return;
        }

        if ((fileSizeKiloBytes > MAX_FILE_SIZE_WEB_PLAY && Capacitor.getPlatform() === 'web') ||
            (fileSizeKiloBytes > MAX_FILE_SIZE_IPHONE_PLAY && Capacitor.getPlatform() === 'ios') ||
            (fileSizeKiloBytes > MAX_FILE_SIZE_ANDROID_PLAY && Capacitor.getPlatform() === 'android')) {
            await compressVideoForViewing(event.target.files[0]);
        } else {
            setSelectedFile(event.target.files[0]);
            setFileLoaded(true);
        }
    }

    const analyzeEditLink = (inputStr: string) => {
        const youtubePattern = /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)[\w-]+/;

        const isMatch = youtubePattern.test(inputStr);
        setIsValidLink(isMatch);

        if (isMatch) {
            setEditLink(inputStr);
        }
    }

    const goToVideoPreviewForEdit = () => {
        setFileLoaded(true);
    }

    // call the backend to upload the video based on the stored states
    const uploadVideo = async () => {

        console.log("you've reached upload vid");
        setShowTrickForm(false);
        setVideoUploading(true);

        const data = new FormData();
        desiredBlobName ? data.append('file', selectedFile, desiredBlobName) : data.append('file', selectedFile);
        // @ts-ignore
        data.append('filename', selectedFile.value);

        const infd_id = userProfile.id;

        selectedTags.concat(hashtags);

        // go to the video playing url after upload
        const res: UserTrick = await userService.uploadUserTrick(infd_id, trickName, selectedTags,
            filmDate, location, sport, feature, trickCaption, data, locationInput, unlisted, segmentValue);

        if (res == null) {
            console.log("upload failed!");
            presentToast("Upload failed :/ - reach out to devs @idx.style")
            setVideoUploading(false);
        } else {
            console.log("upload successful!");
            await clearStatesOnUploadSuccess();
            history.push('/clip-upload/' + res.user_id + '/' + res.trick_id);
        }
    }

    // call the backend to upload the video based on the stored states
    const uploadVideoAsLink = async () => {
        console.log("you've reached upload vid");
        setShowTrickForm(false);
        setVideoUploading(true);

        const infd_id = userProfile.id;

        // go to the video playing url after upload
        const res: UserTrick = await userService.uploadUserTrickAsLink(infd_id, trickName, selectedTags,
            filmDate, location, sport, feature, trickCaption, fileLink, locationInput, unlisted, linkLat, linkLng, linkAlt, linkCreationTime,
            segmentValue);

        if (res == null) {
            console.log("upload failed!");
            presentToast("Upload failed :/ - reach out to devs @idx.style");
            setVideoUploading(false);
        } else {
            console.log("upload successful!");
            await clearStatesOnUploadSuccess();
            history.push('/clip-upload/' + res.user_id + '/' + res.trick_id);
        }
    }

    // call the backend to upload the video based on the stored states
    const uploadEditAsLink = async () => {
        console.log("you've reached upload vid");
        setShowTrickForm(false);
        setVideoUploading(true);

        const infd_id = userProfile.id;

        // go to the video playing url after upload
        const res: UserTrick = await userService.uploadUserTrickAsExternalLink(infd_id, trickName, selectedTags,
            filmDate, location, sport, feature, trickCaption, editLink, locationInput, unlisted, segmentValue);

        if (res == null) {
            console.log("upload failed!");
            presentToast("Upload failed :/ - reach out to devs @idx.style");
            setVideoUploading(false);
        } else {
            console.log("upload successful!");
            await clearStatesOnUploadSuccess();
            history.push('/clip-upload/' + res.user_id + '/' + res.trick_id);
        }
    }


    const clearStatesOnUploadSuccess = async () => {
        setFileLoaded(false);
        setFileIsLink(false);
        setFileLink(undefined);
        setVideoUploading(false);
        setShowTrickForm(false);
        setShowTagSelect(false);
        setSelectedFile(null);
        setDesiredBlobName(null);
        setVideoProcessing(false);
        setUnlisted(false);

        // Link Clear
        setLinkLat(undefined);
        setLinkAlt(undefined);
        setLinkLng(undefined);
        setLinkCreationTime(undefined);

        // Location
        setLocationDropdown(false);
        setLocationDropdownOptions([]);
        setLocationInput(null);
        setLocation("");
        setGooglePlacesSessionToken(uuidv4());

        setTrickCaption("");
        setTrickName("");
        setSport("");
        setFeature("");
        setPreview("");
        setHashtags([]);
        setSelectedTags([]);
        setRandomText("");
        setFilmDate(new Date(new Date().toDateString()).toISOString());
        const query = new URLSearchParams(locationReact.search);
        if (query.has("tag")) {
            query.delete('tag')
            history.replace({
                search: query.toString(),
            })
        }

        await PreferencesService.setLoadSuggestedTricks(LoadSuggestedTricksStatus.ONE);

        // Segment Value
        setDisplayClipInfoEntry(false);
        setDisplayTrickInfoEntry(false);

        // Edits
        setEditLink("");
        setIsValidLink(false);
    }

    const setTrickLocation = async (location: string) => {
        let currGooglePlacesToken = googlePlacesSessionToken;

        if (location === '' || location === null) {
            currGooglePlacesToken = uuidv4();
            setGooglePlacesSessionToken(currGooglePlacesToken);
        }

        setLocation(location);
        await queryTrickLocation(location, currGooglePlacesToken);
    }

    const queryTrickLocation = async (location: string, currGooglePlacesToken: string) => {
        const res = await LocationService.getLocationFromPlacesAPI(location, currGooglePlacesToken);
        const locationDropdownOptions: LocationInput[] = res.map((location: any) => {
            const locationInput: LocationInput = {
                description: location.description,
                main_text: location.structured_formatting.main_text,
                place_id: location.place_id,
            }
            return locationInput;
        });
        console.log(locationDropdownOptions);
        setLocationDropdownOptions(locationDropdownOptions);
        if (locationDropdownOptions.length > 0) {
            setLocationDropdown(true);
        }
    }

    const addLocationItem = (locationInput: LocationInput) => {
        setLocationInput(locationInput);
        setLocation(locationInput.main_text);
        setLocationDropdown(false);
    }

    const onHandleShowTrickForm = () => {
        setShowTrickForm(true);
        // get url parameters if any for events
        const query = new URLSearchParams(locationReact.search);
        if (query.has("tag")) {
            const hashtag = "#" + query.get("tag");
            addHashtag(hashtag);
        }
    }

    const onSetUnlisted = (checked: boolean) => {
        console.log("unlisted: " + checked)
        setUnlisted(checked);
    }

    // GENERIC
    const handleSegmentChange = (segement: string) => {
        console.log("Segment Changed: " + segement);
        setSegmentValue(segement);
    }

    const toggleClipInfo = () => {
        setDisplayClipInfoEntry(!displayClipInfoEntry);
    }

    const toggleTrickInfo = () => {
        setDisplayTrickInfoEntry(!displayTrickInfoEntry);
    }

    const handleUploadVideoFromFileOrLink = async () => {
        if (segmentValue === ClipType.LINE) {
            if (!fileIsLink) {
                await uploadVideo();
            } else {
                await uploadVideoAsLink();
            }
        } else if (segmentValue === ClipType.EDIT) {
            if (!fileIsLink && editLink === '') {
                await uploadVideo();
            } else if (fileIsLink && editLink === '') {
                await uploadVideoAsLink();
            } else if (editLink !== '') {
                await uploadEditAsLink();
            } else {
                await uploadVideo();
            }
        } else {
            await uploadVideo();
        }
    }

    const presentToast = (message: string) => {
        present({
            message: message,
            duration: 5000,
            position: 'top',
            icon: alertCircleOutline,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };


    // const handleGoToClip = async () => {
    //     await invalidateQueries(); -> for some reason this didn't work!!! 
    //     const userId = uploadedUserTrick.user_id;
    //     const trickId = uploadedUserTrick.trick_id;
    //     // Set up a one-time listener for after navigation
    //     const unlisten = history.listen(() => {
    //         clearStatesOnUploadSuccess();
    //         unlisten(); // Remove the listener after it fires once
    //     });

    //     history.push(`/ clip - upload / ${ userId } / ${ trickId }`);
    // }

    if (isLoading) {
        return (<LoadingPage />);
    }

    if (!isAuthenticated) {
        return (<LoginButtonPage
            title="Build Your Bag Tricks To Get Noticed by the Squad, Sponsors, and More"
            upload={true} profile={false} />);
    }

    if (videoUploading) {
        return (
            <IonPage>
                <IonHeader translucent={true}>
                </IonHeader>
                <IonContent>
                    {
                        segmentValue === ClipType.CLIP ?
                            <div className="text-2xl text-center items-center justify-center mt-20">
                                Upload a clip/trick
                            </div>
                            : segmentValue === ClipType.LINE ?
                                <div className="text-2xl text-center items-center justify-center mt-20">
                                    Upload a line
                                </div>
                                : segmentValue === ClipType.EDIT ?
                                    <div className="text-2xl text-center items-center justify-center mt-20">
                                        Upload a edit
                                    </div> :
                                    <div className="text-2xl text-center items-center justify-center mt-20">
                                        Upload a clip/trick
                                    </div>
                    }
                    <div className="flex flex-col justify-center items-center m-4">
                        <div className="items-center bg-transparent text-center space-x-4 mb-4">This could take up to 30
                            seconds depending on the depending on the size, length, and aspect orientation of your
                            video. It's still very experimental to navigate away from this page and check back on the upload tab to see if it's done, so maybe just keep the app open while touching grass or snow.
                        </div>
                        <div className="flex flex-row items-center bg-transparent text-center space-x-4 mb-4">
                            <div>{randomText}</div>
                        </div>
                        <IonProgressBar type="indeterminate" color="favorite"></IonProgressBar>
                    </div>
                </IonContent>
            </IonPage>);
    } else if (videoProcessing) {
        return (
            <IonPage>
                <IonHeader translucent={true}>
                </IonHeader>
                <IonContent>
                    {
                        segmentValue === ClipType.CLIP ?
                            <div className="text-2xl text-center items-center justify-center mt-20">
                                Upload a clip/trick
                            </div>
                            : segmentValue === ClipType.LINE ?
                                <div className="text-2xl text-center items-center justify-center mt-20">
                                    Upload a line
                                </div>
                                : segmentValue === ClipType.EDIT ?
                                    <div className="text-2xl text-center items-center justify-center mt-20">
                                        Upload an edit
                                    </div> :
                                    <div className="text-2xl text-center items-center justify-center mt-20">
                                        Upload a clip/trick
                                    </div>
                    }
                    <div className="flex flex-col justify-center items-center m-4">
                        <div className="items-center bg-transparent text-center space-x-4 mb-4">Compressing your clip
                            for filming better. Sit tight, this could take up to 30 seconds but depends how large and
                            long your clip is. It's still very experimental to navigate away from this page and check back on the upload tab to see if it's done, so maybe just keep the app open while touching grass or snow.
                        </div>
                        <div className="flex flex-row items-center bg-transparent text-center space-x-4 mb-4">
                            <div>{randomText}</div>
                            <IonSpinner name="dots"></IonSpinner>
                        </div>
                        <IonProgressBar type="indeterminate" color="tertiary"></IonProgressBar>
                    </div>
                </IonContent>
            </IonPage>
        );
    } else if (showTagSelect && segmentValue === ClipType.CLIP) {
        return (
            <IonPage>
                <IonHeader translucent={true}>
                </IonHeader>
                <IonContent>
                    <div className="text-2xl text-center items-center justify-center mt-20">
                        Upload a clip/trick for {state.user?.username ? state.user?.username : userProfile.username}
                    </div>
                    <div className="flex justify-center items-center w-full mt-8">
                        <form className="w-10/12 lg:w-8/12">
                            <h2 className="text-2xl font-bold tracking-wide">Select Your Tags</h2>
                            <div className="mb-6">
                                <TagSelectOnUpload sport={[sport]} feature={[feature]} parentAddTag={addTag}
                                    parentRemoveTag={removeTag} />
                            </div>
                            <div className="flex justify-center items-center gap-x-4 mb-10">
                                <IonButton className="neon-button"
                                    onClick={() => setShowTagSelect(false)}>Back</IonButton>
                                <IonButton className="neon-button-alt" onClick={() => {
                                    if (!fileIsLink) {
                                        uploadVideo();
                                    } else {
                                        uploadVideoAsLink();
                                    }
                                }}>Upload</IonButton>
                            </div>
                        </form>
                    </div>
                </IonContent>
            </IonPage>
        );
    } else if (showTagSelect && (segmentValue === ClipType.LINE || segmentValue === ClipType.EDIT)) {
        return (
            <IonPage>
                <IonHeader translucent={true}>
                </IonHeader>
                <IonContent>
                    {
                        segmentValue === ClipType.LINE ?
                            <div className="text-2xl text-center items-center justify-center mt-20">
                                Upload a line
                            </div>
                            : segmentValue === ClipType.EDIT ?
                                <div className="text-2xl text-center items-center justify-center mt-20">
                                    Upload an edit
                                </div> :
                                <div className="text-2xl text-center items-center justify-center mt-20">
                                    Upload a clip/trick
                                </div>
                    }
                    <div className="flex justify-center items-center w-full mt-8">
                        <form className="w-10/12 lg:w-8/12">
                            <h2 className="text-2xl font-bold tracking-wide">Select Your Tags</h2>
                            <div className="mb-6">
                                <TagSelectOnUpload sport={[sport]} feature={[feature]} parentAddTag={addTag}
                                    parentRemoveTag={removeTag} tagsCombinedToOne={true}
                                    currentTags={selectedTags} />
                            </div>
                            <div className="flex justify-center items-center gap-x-4 mb-10">
                                <IonButton className="neon-button"
                                    onClick={() => setShowTagSelect(false)}>Back</IonButton>
                                <IonButton className="neon-button-alt"
                                    onClick={handleUploadVideoFromFileOrLink}>Upload</IonButton>
                            </div>
                        </form>
                    </div>
                </IonContent>
            </IonPage>
        );
    } else if (fileLoaded && !showTrickForm) {
        return (
            <IonPage>
                <IonHeader translucent={true}>
                </IonHeader>
                <IonContent>
                    {
                        segmentValue === ClipType.CLIP ?
                            <div className="text-2xl text-center items-center justify-center mt-20">
                                Upload a clip/trick
                            </div>
                            : segmentValue === ClipType.LINE ?
                                <div className="text-2xl text-center items-center justify-center mt-20">
                                    Upload a line
                                </div>
                                : segmentValue === ClipType.EDIT ?
                                    <div className="text-2xl text-center items-center justify-center mt-20">
                                        Upload an edit
                                    </div> :
                                    <div className="text-2xl text-center items-center justify-center mt-20">
                                        Upload a clip/trick
                                    </div>
                    }
                    <div className="flex justify-center items-center mt-10 mx-10">
                        {
                            ClipType.EDIT && editLink !== "" && editLink !== undefined ?
                                <div
                                    className="flex flex-col justify-center items-center xs:h-full xs:w-full sm:w-10/12 sm:h-10/12 md:w-9/12 md:h-9/12 l:w-8/12 l:h-8/12 xl:w-7/12 xl:h-7/12">
                                    <ReactPlayer url={editLink} controls={true} width={"100%"}></ReactPlayer>
                                </div> :
                                <video ref={previewVideoRef}
                                    playsInline
                                    webkit-playsinline="true"
                                    autoPlay={false}
                                    className="flex flex-col justify-center items-center xs:h-full xs:w-full sm:w-10/12 sm:h-10/12 md:w-9/12 md:h-9/12 l:w-8/12 l:h-8/12 xl:w-7/12 xl:h-7/12"
                                    controls preload="auto">
                                    <source src={!fileIsLink ? preview : fileLink} type="video/mp4" />
                                </video>
                        }
                    </div>
                    <div className="flex justify-center items-center mt-4 gap-x-4">
                        <IonButton className="mr-4 neon-button" onClick={() => {
                            setSelectedFile(undefined);
                            setPreview("");
                            setFileLoaded(false);
                            setDesiredBlobName(null);
                            setFileIsLink(false);
                            setFileLink(undefined);
                            setEditLink("");
                            setIsValidLink(false);
                        }}>
                            Back</IonButton>
                        <IonButton onClick={() => onHandleShowTrickForm()} className="neon-button-alt">Next</IonButton>
                    </div>
                </IonContent>
            </IonPage>
        );
    } else if (showTrickForm && segmentValue === ClipType.CLIP) {
        const currentDate = new Date(new Date().toDateString()).toISOString();
        return (
            <IonPage>
                <IonHeader translucent={true}>
                </IonHeader>
                <IonContent>
                    <div className="text-2xl text-center items-center justify-center mt-20">
                        Upload a clip/trick
                    </div>
                    <div className="flex justify-center items-center w-full mt-8">
                        <form className="w-10/12 lg:w-8/12">
                            <h2 className="text-xl font-bold tracking-wide mb-2">Details</h2>
                            <div className="mb-6">
                                <div className="flex flex-row items-center">
                                    <label className="block mb-2 text-lg font-medium">
                                        Trick Name
                                    </label>
                                </div>

                                <input
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ion-text-area-upload-clip"
                                    placeholder={'Call your style'}
                                    required
                                    value={trickName}
                                    onChange={(e) => {
                                        setTrickName(e.target.value);
                                        // setOptions(e.target.value.split(" "));
                                    }}
                                />
                            </div>
                            <div className="mb-6">
                                <div className="flex flex-row items-center">
                                    <label className="block mb-2 text-lg font-medium">
                                        Caption
                                    </label>
                                </div>
                                <IonTextarea autoGrow={true} value={trickCaption}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 ion-text-area-upload-clip"
                                    placeholder="Optional but go crazy" rows={1}
                                    onIonInput={(e) => setTrickCaption(e.detail.value as string)}></IonTextarea>
                            </div>
                            <div className="mb-5">
                                <div className="flex flex-row items-center">
                                    <label className="block mb-2 text-lg font-medium">
                                        Hashtags
                                    </label>
                                </div>
                                <MultiSelectHashtag
                                    addTag={addHashtag}
                                    removeTag={removeHashtag}
                                    selectedOptions={hashtags}
                                    placeholder={'For events, brands, swaq, etc.. (click tag for trick tags)'}
                                />
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <label className="block text-xl font-medium">Clip Info</label>
                                    <IDXButtonIcon size={"text-2xl"} icon={chevronDownOutline}
                                        onClick={toggleClipInfo} />
                                </div>
                                {
                                    displayClipInfoEntry ?
                                        <>
                                            <div className="mb-6 ml-2" ref={closeDropdown}>
                                                <label className="block mb-2 text-lg font-medium">
                                                    Location
                                                    <IonIcon icon={informationCircleOutline} color="theme-alternative"
                                                        className="ml-2"
                                                        onClick={() => presentToast("If you want your clip location private, don't enter one, we do not sell your data :)")}>
                                                    </IonIcon>
                                                </label>
                                                <input
                                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ion-text-area-upload-clip"
                                                    placeholder="mountain or city"
                                                    value={location}
                                                    onChange={(e) => setTrickLocation(e.target.value)}
                                                />
                                                {locationDropdown ?
                                                    <Dropdown list={locationDropdownOptions} addItem={addLocationItem}
                                                        accessProperty={true}
                                                        accessPropertyString={'description'}></Dropdown> : null}
                                            </div>
                                            <div className="ml-2 mb-3">
                                                <label className="block mb-2 text-lg font-medium">Date Filmed</label>
                                                <IonDatetimeButton className="justify-start" datetime="datetime" />
                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime id="datetime" presentation="date" color="dark"
                                                        value={filmDate} max={currentDate}
                                                        onIonChange={(event) => setFilmDate(event.target.value.toString())} />
                                                </IonModal>
                                                <p id="helper-text-explanation"
                                                    className="mt-3 text-sm text-gray-500">If you aren't sure when
                                                    you filmed this trick, just select today's date.</p>
                                            </div>
                                            <div className="mb-6 ml-2">
                                                <IonCheckbox labelPlacement="start"
                                                    onIonChange={(ev) => onSetUnlisted(ev.detail.checked)}
                                                    className="text-lg">
                                                    {FOLLOWERS_ONLY}
                                                </IonCheckbox>
                                            </div>
                                        </> : null
                                }
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <label className="block text-xl font-medium">Trick Info</label>
                                    <IDXButtonIcon size={"text-2xl"} icon={chevronDownOutline}
                                        onClick={toggleTrickInfo} />
                                </div>
                                {
                                    displayTrickInfoEntry ?
                                        <>
                                            <div className="mb-6 ml-2">
                                                <label className="block mb-2 text-lg font-medium">Sport</label>
                                                <div className="relative w-full lg:max-w-sm">
                                                    <select
                                                        value={sport}
                                                        onChange={(event) => {
                                                            setSport(event.target.value);
                                                        }}
                                                        className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                                                    >
                                                        <option selected hidden>Select a sport</option>
                                                        {sportOptions.map((sport) => (
                                                            <option id={sport.id.toString()}>{sport.name}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="ml-2 mb-3">
                                                <label className="block mb-2 text-lg font-medium">Feature</label>
                                                <div className="relative w-full lg:max-w-sm">
                                                    <select
                                                        value={feature}
                                                        onChange={(event) => {
                                                            setFeature(event.target.value);
                                                        }}
                                                        className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                                                    >
                                                        <option selected hidden>Select a feature</option>
                                                        {sport === "Other" ? otherFeatures.map((feature) => (<option
                                                            id={feature.id.toString()}>{feature.name}</option>)) : features.map((feature) => (
                                                                <option
                                                                    id={feature.id.toString()}>{feature.name}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                        </> : null
                                }
                            </div>
                            <div>
                                <div className="flex flex-row justify-between mb-10">
                                    <IonButton className="neon-button" onClick={() => {
                                        setShowTrickForm(false)
                                    }
                                    }>Back</IonButton>
                                    <IonButton className="neon-button-highlight" onClick={() => {
                                        if (trickName && sport && feature) {
                                            setShowTagSelect(true);
                                            setDisplayTrickInfoEntry(false);
                                            setDisplayClipInfoEntry(false);
                                        } else {
                                            presentToast("Please fill trick info fields to tag your clip!");
                                        }
                                    }}>Tag</IonButton>
                                    <IonButton className="neon-button-alt" onClick={() => {
                                        if (trickName) {
                                            if (!fileIsLink) {
                                                uploadVideo();
                                            } else {
                                                uploadVideoAsLink();
                                            }
                                        } else {
                                            presentToast("Please fill out the trick name and other fields if desired!");
                                        }
                                    }}>Upload</IonButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </IonContent>
            </IonPage>
        );
    } else if (showTrickForm && segmentValue === ClipType.LINE) {
        const currentDate = new Date(new Date().toDateString()).toISOString();
        return (
            <IonPage>
                <IonHeader translucent={true}>
                </IonHeader>
                <IonContent>
                    <div className="text-2xl text-center items-center justify-center mt-20">
                        Upload a line
                    </div>
                    <div className="flex justify-center items-center w-full mt-8">
                        <form className="w-10/12 lg:w-8/12">
                            <h2 className="text-xl font-bold tracking-wide mb-2">Details</h2>
                            <div className="mb-6">
                                <div className="flex flex-row items-center">
                                    <label className="block mb-2 text-lg font-medium">
                                        Line Name
                                    </label>
                                </div>

                                <input
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ion-text-area-upload-clip"
                                    placeholder={'Call your style'}
                                    required
                                    value={trickName}
                                    onChange={(e) => {
                                        setTrickName(e.target.value);
                                        // setOptions(e.target.value.split(" "));
                                    }}
                                />
                            </div>
                            <div className="mb-6">
                                <div className="flex flex-row items-center">
                                    <label className="block mb-2 text-lg font-medium">
                                        Caption
                                    </label>
                                </div>
                                <IonTextarea autoGrow={true} value={trickCaption}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 ion-text-area-upload-clip"
                                    placeholder="Optional but go crazy on this one" rows={1}
                                    onIonInput={(e) => setTrickCaption(e.detail.value as string)}></IonTextarea>
                            </div>
                            <div className="mb-5">
                                <div className="flex flex-row items-center">
                                    <label className="block mb-2 text-lg font-medium">
                                        Hashtags
                                    </label>
                                </div>
                                <MultiSelectHashtag
                                    addTag={addHashtag}
                                    removeTag={removeHashtag}
                                    selectedOptions={hashtags}
                                    placeholder={'For events, brands, swaq, etc.. (click tag for trick tags)'}
                                />
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <label className="block text-xl font-medium">Clip Info</label>
                                    <IDXButtonIcon size={"text-2xl"} icon={chevronDownOutline}
                                        onClick={toggleClipInfo} />
                                </div>
                                {
                                    displayClipInfoEntry ?
                                        <>
                                            <div className="mb-6 ml-2" ref={closeDropdown}>
                                                <label className="block mb-2 text-lg font-medium">
                                                    Location
                                                    <IonIcon icon={informationCircleOutline} color="theme-alternative"
                                                        className="ml-2"
                                                        onClick={() => presentToast("If you want your clip location private, don't enter one, we do not sell your data :)")}>
                                                    </IonIcon>
                                                </label>
                                                <input
                                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ion-text-area-upload-clip"
                                                    placeholder="mountain or city"
                                                    value={location}
                                                    onChange={(e) => setTrickLocation(e.target.value)}
                                                />
                                                {locationDropdown ?
                                                    <Dropdown list={locationDropdownOptions} addItem={addLocationItem}
                                                        accessProperty={true}
                                                        accessPropertyString={'description'}></Dropdown> : null}
                                            </div>
                                            <div className="ml-2 mb-3">
                                                <label className="block mb-2 text-lg font-medium">Date Filmed</label>
                                                <IonDatetimeButton className="justify-start" datetime="datetime" />
                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime id="datetime" presentation="date" color="dark"
                                                        value={filmDate} max={currentDate}
                                                        onIonChange={(event) => setFilmDate(event.target.value.toString())} />
                                                </IonModal>
                                                <p id="helper-text-explanation"
                                                    className="mt-3 text-sm text-gray-500">If you aren't sure when
                                                    you filmed this line, just select today's date.</p>
                                            </div>
                                            <div className="mb-6 ml-2">
                                                <IonCheckbox labelPlacement="start"
                                                    onIonChange={(ev) => onSetUnlisted(ev.detail.checked)}
                                                    className="text-lg">
                                                    {FOLLOWERS_ONLY}
                                                </IonCheckbox>
                                            </div>
                                        </> : null
                                }
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <label className="block text-xl font-medium">Line Info</label>
                                    <IDXButtonIcon size={"text-2xl"} icon={chevronDownOutline}
                                        onClick={toggleTrickInfo} />
                                </div>
                                {
                                    displayTrickInfoEntry ?
                                        <>
                                            <div className="mb-6 ml-2">
                                                <label className="block mb-2 text-lg font-medium">Sport</label>
                                                <div className="relative w-full lg:max-w-sm">
                                                    <select
                                                        value={sport}
                                                        onChange={(event) => {
                                                            setSport(event.target.value);
                                                        }}
                                                        className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                                                    >
                                                        <option selected hidden>Select a sport</option>
                                                        {sportOptions.map((sport) => (
                                                            <option id={sport.id.toString()}>{sport.name}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                        </> : null
                                }
                            </div>
                            <div>
                                <div className="flex flex-row justify-between mb-10">
                                    <IonButton className="neon-button" onClick={() => {
                                        setShowTrickForm(false);
                                        setDisplayTrickInfoEntry(false);
                                        setDisplayClipInfoEntry(false);
                                    }}>Back</IonButton>
                                    <IonButton className="neon-button-highlight" onClick={() => {
                                        if (trickName && sport) {
                                            setShowTagSelect(true);
                                        } else {
                                            presentToast("Please fill line info fields to tag your line!");
                                        }
                                    }}>Tag</IonButton>
                                    <IonButton className="neon-button-alt" onClick={() => {
                                        if (trickName) {
                                            if (!fileIsLink) {
                                                uploadVideo();
                                            } else {
                                                uploadVideoAsLink();
                                            }
                                        } else {
                                            presentToast("Please fill out the trick name and other fields if desired!");
                                        }
                                    }}>Upload</IonButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </IonContent>
            </IonPage>
        );
    } else if (showTrickForm && segmentValue === ClipType.EDIT) {
        const currentDate = new Date(new Date().toDateString()).toISOString();
        return (
            <IonPage>
                <IonHeader translucent={true}>
                </IonHeader>
                <IonContent>
                    <div className="text-2xl text-center items-center justify-center mt-20">
                        Upload an edit
                    </div>
                    <div className="flex justify-center items-center w-full mt-8">
                        <form className="w-10/12 lg:w-8/12">
                            <h2 className="text-xl font-bold tracking-wide mb-2">Details</h2>
                            <div className="mb-6">
                                <div className="flex flex-row items-center">
                                    <label className="block mb-2 text-lg font-medium">
                                        Edit Name
                                    </label>
                                </div>

                                <input
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ion-text-area-upload-clip"
                                    placeholder={'Call your style'}
                                    required
                                    value={trickName}
                                    onChange={(e) => {
                                        setTrickName(e.target.value);
                                        // setOptions(e.target.value.split(" "));
                                    }}
                                />
                            </div>
                            <div className="mb-6">
                                <div className="flex flex-row items-center">
                                    <label className="block mb-2 text-lg font-medium">
                                        Caption
                                    </label>
                                </div>
                                <IonTextarea autoGrow={true} value={trickCaption}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 ion-text-area-upload-clip"
                                    placeholder="Optional but go crazy on this one" rows={1}
                                    onIonInput={(e) => setTrickCaption(e.detail.value as string)}></IonTextarea>
                            </div>
                            <div className="mb-5">
                                <div className="flex flex-row items-center">
                                    <label className="block mb-2 text-lg font-medium">
                                        Hashtags
                                    </label>
                                </div>
                                <MultiSelectHashtag
                                    addTag={addHashtag}
                                    removeTag={removeHashtag}
                                    selectedOptions={hashtags}
                                    placeholder={'For events, brands, swaq, etc.. (click tag for trick tags)'}
                                />
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="flex flex-row justify-between items-center mb-2">
                                    <label className="block text-xl font-medium">Edit Info</label>
                                    <IDXButtonIcon size={"text-2xl"} icon={chevronDownOutline}
                                        onClick={toggleClipInfo} />
                                </div>
                                {
                                    displayClipInfoEntry ?
                                        <>
                                            <div className="mb-6 ml-2">
                                                <label className="block mb-2 text-lg font-medium">Sport</label>
                                                <div className="relative w-full lg:max-w-sm">
                                                    <select
                                                        value={sport}
                                                        onChange={(event) => {
                                                            setSport(event.target.value);
                                                        }}
                                                        className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                                                    >
                                                        <option selected hidden>Select a sport</option>
                                                        {sportOptions.map((sport) => (
                                                            <option id={sport.id.toString()}>{sport.name}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-6 ml-2" ref={closeDropdown}>
                                                <label className="block mb-2 text-lg font-medium">
                                                    Location
                                                    <IonIcon icon={informationCircleOutline} color="theme-alternative"
                                                        className="ml-2"
                                                        onClick={() => presentToast("If you want your clip location private, don't enter one, we do not sell your data :)")}>
                                                    </IonIcon>
                                                </label>
                                                <input
                                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ion-text-area-upload-clip"
                                                    placeholder="Optional since you could have filmed all over"
                                                    value={location}
                                                    onChange={(e) => setTrickLocation(e.target.value)}
                                                />
                                                {locationDropdown ?
                                                    <Dropdown list={locationDropdownOptions} addItem={addLocationItem}
                                                        accessProperty={true}
                                                        accessPropertyString={'description'}></Dropdown> : null}
                                            </div>
                                            <div className="ml-2 mb-3">
                                                <label className="block mb-2 text-lg font-medium">Date</label>
                                                <IonDatetimeButton className="justify-start" datetime="datetime" />
                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime id="datetime" presentation="date" color="dark"
                                                        value={filmDate} max={currentDate}
                                                        onIonChange={(event) => setFilmDate(event.target.value.toString())} />
                                                </IonModal>
                                                <p id="helper-text-explanation"
                                                    className="mt-3 text-sm text-gray-500">If this edit took place
                                                    overtime, just select today's date.</p>
                                            </div>
                                            <div className="mb-6 ml-2">
                                                <IonCheckbox labelPlacement="start"
                                                    onIonChange={(ev) => onSetUnlisted(ev.detail.checked)}
                                                    className="text-lg">
                                                    {FOLLOWERS_ONLY}
                                                </IonCheckbox>
                                            </div>
                                        </> : null
                                }
                            </div>
                            <div>
                                <div className="flex flex-row justify-between mb-10">
                                    <IonButton className="neon-button" onClick={() => {
                                        setShowTrickForm(false);
                                        setDisplayTrickInfoEntry(false);
                                        setDisplayClipInfoEntry(false);
                                    }}>Back</IonButton>
                                    <IonButton className="neon-button-highlight" onClick={() => {
                                        if (trickName && sport) {
                                            setShowTagSelect(true);
                                        } else {
                                            presentToast("Please fill the edit name and sport fields to tag your edit!");
                                        }
                                    }}>Tag</IonButton>
                                    <IonButton className="neon-button-alt"
                                        onClick={handleUploadVideoFromFileOrLink}>Upload</IonButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </IonContent>
            </IonPage>
        );
    } else {
        return (
            <IonPage>
                <CustomHelmet title={"idx // Upload}"}
                    description={"Upload your clips to Ecliptic to build your bag of tricks and get noticed by the crew, sponsors, or the world"}
                    image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                    url={`https://ecliptic.day/upload`} />
                <IonHeader translucent={true}>
                    <IonToolbar>
                        <div>
                            <div className="flex min-h-12">
                                {options.map((option, index) => (
                                    <div
                                        key={index}
                                        className={`flex-1 py-3 px-4 text-center text-sm uppercase ${segmentValue === option.value ? 'text-white border-b-2 border-white font-bold' : 'text-[#9c9c9c]'}`}
                                        onClick={() => handleSegmentChange(option.value)}
                                    >
                                        {option.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {
                        segmentValue === ClipType.CLIP ?
                            <div className="flex flex-col text-center items-center justify-center">
                                <div className="text-2xl text-center items-center justify-center mt-10">
                                    Upload a clip/trick
                                </div>
                                <div className="flex justify-center items-center w-full mt-10">
                                    <label
                                        className="flex flex-col justify-center items-center w-10/12 h-64 rounded-lg border-2 border-dashed cursor-pointer">
                                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                            <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                            </svg>
                                            <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span>{isPlatform("mobile") || isPlatform("cordova") ? "" : " or drag and drop"}
                                            </p>
                                            {isPlatform("mobile") || isPlatform("cordova") ? "" :
                                                <p className="text-xs text-gray-500">.MP4, .MOV, .M4V</p>}
                                        </div>
                                        <input id="dropzone-file" type="file" accept='video/mp4,video/x-m4v,video/*'
                                            className="hidden" onChange={(event) => {
                                                console.log(typeof (event))
                                                onSelectFile(event)
                                            }} />
                                    </label>
                                </div>
                            </div>
                            :
                            segmentValue === ClipType.LINE ?
                                <div className="flex flex-col text-center items-center justify-center">
                                    <div className="text-2xl text-center items-center justify-center mt-10">
                                        Upload a line
                                    </div>
                                    <div className="flex justify-center items-center w-full mt-10">
                                        <label
                                            className="flex flex-col justify-center items-center w-10/12 h-64 rounded-lg border-2 border-dashed cursor-pointer">
                                            <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                                <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400"
                                                    fill="none"
                                                    stroke="currentColor" viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                </svg>
                                                <p className="mb-2 text-sm text-gray-500"><span
                                                    className="font-semibold">Click to upload</span>{isPlatform("mobile") || isPlatform("cordova") ? "" : " or drag and drop"}
                                                </p>
                                                {isPlatform("mobile") || isPlatform("cordova") ? "" :
                                                    <p className="text-xs text-gray-500">.MP4, .MOV, .M4V</p>}
                                            </div>
                                            <input id="dropzone-file" type="file" accept='video/mp4,video/x-m4v,video/*'
                                                className="hidden" onChange={(event) => {
                                                    console.log(typeof (event))
                                                    onSelectFile(event)
                                                }} />
                                        </label>
                                    </div>
                                </div>
                                : segmentValue === ClipType.EDIT ?
                                    <div className="flex flex-col text-center items-center justify-center">
                                        <div className="text-2xl text-center items-center justify-center mt-10">
                                            Upload an edit
                                        </div>
                                        <div className="mt-10 w-full">
                                            <IonAccordionGroup className="mx-5">
                                                <IonAccordion value="first">
                                                    <IonItem slot="header" color="favorite">
                                                        <IonLabel>Upload A File</IonLabel>
                                                    </IonItem>
                                                    <div className="ion-padding" slot="content">
                                                        <div className="flex justify-center items-center w-full">
                                                            <label
                                                                className="flex flex-col justify-center items-center w-10/12 h-64 rounded-lg border-2 border-dashed cursor-pointer">
                                                                <div
                                                                    className="flex flex-col justify-center items-center pt-5 pb-6">
                                                                    <svg aria-hidden="true"
                                                                        className="mb-3 w-10 h-10 text-gray-400"
                                                                        fill="none"
                                                                        stroke="currentColor" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                                            strokeWidth="2"
                                                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                                                    </svg>
                                                                    <p className="mb-2 text-sm text-gray-500"><span
                                                                        className="font-semibold">Click to upload</span>{isPlatform("mobile") || isPlatform("cordova") ? "" : " or drag and drop"}
                                                                    </p>
                                                                    {isPlatform("mobile") || isPlatform("cordova") ? "" :
                                                                        <p className="text-xs text-gray-500">.MP4, .MOV,
                                                                            .M4V</p>}
                                                                </div>
                                                                <input id="dropzone-file" type="file"
                                                                    accept='video/mp4,video/x-m4v,video/*'
                                                                    className="hidden"
                                                                    onChange={(event) => {
                                                                        console.log(typeof (event))
                                                                        onSelectFile(event)
                                                                    }} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </IonAccordion>
                                                <IonAccordion value="second">
                                                    <IonItem slot="header" color="favorite-secondary">
                                                        <IonLabel>Upload a Link</IonLabel>
                                                    </IonItem>
                                                    <div className="ion-padding" slot="content">
                                                        <IonItem>
                                                            <IonInput label="YouTube Link" labelPlacement="stacked"
                                                                placeholder="ex: https://www.youtube.com/watch?v=LNu_YZss2pw"
                                                                clearInput={true}
                                                                onIonInput={e => analyzeEditLink(e.detail.value)}></IonInput>
                                                        </IonItem>
                                                        <div className="flex justify-center items-center mt-4">
                                                            <IonButton className="ml-4 neon-button-alt"
                                                                onClick={() => goToVideoPreviewForEdit()}
                                                                disabled={!isValidLink}>Next</IonButton>
                                                        </div>
                                                    </div>
                                                </IonAccordion>
                                            </IonAccordionGroup>
                                        </div>
                                    </div>
                                    : null
                    }
                </IonContent>
            </IonPage >
        );
    }
};

export default Upload;

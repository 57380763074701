import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSearchbar,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import './LocationSearch.css';
import { LocationOnboarding } from "../../../models/locationOnboarding";
import { useSignUp } from "../SignUpProvider/SignUpProvider";
import SparkGrid from "../SparkGrid/SparkGrid";
import {
    chevronBackOutline,
    compassOutline,
    locationOutline,
    navigateCircleOutline,
    navigateOutline,
    pinOutline
} from "ionicons/icons";
import { Geolocation } from "@capacitor/geolocation";
import surfIcon from '../../../assets/icons/sports/twemoji--man-surfing.svg';
import skateIcon from '../../../assets/icons/sports/noto--skateboard.svg';
import bikeIcon from '../../../assets/icons/sports/cil--bike.svg';
import mountainIcon from '../../../assets/icons/sports/noto--mountain.svg';
import { useAppState } from "../../../AppListenerProvider";
import UserService from "../../../services/user.service";
import IonSpinnerMainContent from "../../ComponentsUI/IonSpinnerMainContent/IonSpinnerMainContent";
import { IonInfiniteScrollCustomEvent } from "@ionic/core/dist/types/components";
import IDXButtonIcon from "../../ComponentsUI/IDXButtonIcon/IDXButtonIcon";
import LocationListSkeleton from './LocationLoading';
import { debounce } from 'lodash';

interface LocationSearchProps {
    onNext: () => void;
    onBack: () => void;
    defaultLocations: LocationOnboarding[];
    defaultIsLoading: boolean;
    onSkip: () => void;
    sessionToken: string;
}

const LocationSearch: React.FC<LocationSearchProps> = ({
    onNext,
    onBack,
    defaultLocations,
    defaultIsLoading,
    onSkip,
    sessionToken
}) => {
    const { signUpData, updateSignUpData } = useSignUp();
    const { isNative, isActive } = useAppState();
    const [searchTerm, setSearchTerm] = useState('');
    const [locations, setLocations] = useState<LocationOnboarding[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<LocationOnboarding | null>(null);
    const [page, setPage] = useState(1);
    const [searchLocationsIsLoading, setSearchLocationsIsLoading] = useState(true);
    const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);
    const [locationPermissionDenied, setLocationPermissionDenied] = useState(false);
    const [locationBasedResults, setLocationBasedResults] = useState<LocationOnboarding[]>([]);

    const currentSearchTerm = useRef('');
    const searchbarRef = React.createRef<HTMLIonSearchbarElement>();

    const locationIcons = [compassOutline, locationOutline, navigateOutline, navigateCircleOutline, pinOutline];
    const skateIcons = [skateIcon];
    const bikeIcons = [bikeIcon];
    const snowIcons = [mountainIcon];
    const surfIcons = [surfIcon];

    const fetchGoogleLocations = async (searchTermInput: string) => {
        try {
            if (searchTermInput !== currentSearchTerm.current) return;
            console.log("fetchGoogleLocations -> searchTermInput", searchTermInput)

            const searchLocations = await UserService.searchForLocationViaGoogle(searchTermInput, sessionToken);

            if (searchTermInput === currentSearchTerm.current) {
                setLocations(searchLocations);
                setIsInfiniteDisabled(true); // Disable infinite scroll for Google results
                setSearchLocationsIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching Google locations:', error);
            if (searchTermInput === currentSearchTerm.current) {
                setSearchLocationsIsLoading(false);
            }
        }
    };

    // Function to fetch locations with pagination
    const fetchLocations = async (searchTermInput: string, pageNumber: number, isNewSearch: boolean = false) => {
        try {
            if (searchTermInput !== currentSearchTerm.current) return;

            const searchLocations = await UserService.getUserLocationsOnboardingBase(searchTermInput, pageNumber);

            if (searchTermInput === currentSearchTerm.current) {
                // If no results found in DB and this is a new search, try Google
                if (isNewSearch && searchLocations.length === 0) {
                    await fetchGoogleLocations(searchTermInput);
                    return;
                }

                if (isNewSearch) {
                    setLocations(searchLocations);
                } else {
                    setLocations(prev => [...prev, ...searchLocations]);
                }
                setIsInfiniteDisabled(searchLocations.length === 0);
                setSearchLocationsIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching locations:', error);
            if (searchTermInput === currentSearchTerm.current) {
                // If DB search fails on a new search, try Google
                if (isNewSearch) {
                    await fetchGoogleLocations(searchTermInput);
                    return;
                }
                setSearchLocationsIsLoading(false);
            }
        }
    };

    const loadLocationsBasedOnCoordinates = async (latitude: number, longitude: number) => {
        try {
            const nearbyLocations = await UserService.getUserLocationsOnboardingLocation(1, latitude, longitude);
            setLocationBasedResults(nearbyLocations);
            if (currentSearchTerm.current === '') {
                setLocations(nearbyLocations);
            }
            setSearchLocationsIsLoading(false);
            setPage(2);
            setIsInfiniteDisabled(nearbyLocations.length === 0);
        } catch (error) {
            console.error('Error getting nearby locations:', error);
            setSearchLocationsIsLoading(false);
            if (currentSearchTerm.current === '') {
                setLocations(defaultLocations);
            }
        }
    };

    // Debounced search function
    const debouncedSearch = useCallback(
        debounce((term: string) => {
            setSearchLocationsIsLoading(true);
            setPage(1);
            fetchLocations(term, 1, true);
        }, 300),
        []
    );

    // Handle search input
    const handleLocationSearch = (e: CustomEvent) => {
        const value = e.detail.value;
        setSearchTerm(value);
        currentSearchTerm.current = value;

        if (value) {
            debouncedSearch(value);
        } else {
            setSearchLocationsIsLoading(true);
            setLocations(locationBasedResults);
            setPage(2);
            setIsInfiniteDisabled(locationBasedResults.length === 0);
            setSearchLocationsIsLoading(false);
        }
    };

    // Handle infinite scroll
    const getMoreLocations = async (ev: IonInfiniteScrollCustomEvent<void>) => {
        try {
            // Don't paginate if we're showing Google results
            if (locations.some(loc => !loc.location_id)) {
                ev.target.complete();
                return;
            }

            if (currentSearchTerm.current) {
                await fetchLocations(currentSearchTerm.current, page + 1, false);
            } else if (signUpData.latitude && signUpData.longitude && !locationPermissionDenied) {
                const moreLocations = await UserService.getUserLocationsOnboardingLocation(
                    page,
                    signUpData.latitude,
                    signUpData.longitude
                );
                setLocations(prev => [...prev, ...moreLocations]);
                setLocationBasedResults(prev => [...prev, ...moreLocations]);
                setIsInfiniteDisabled(moreLocations.length === 0);
            }
            setPage(page + 1);
        } catch (error) {
            console.error('Error getting more locations:', error);
        }
        await ev.target.complete();
    };

    // Get current location on mount
    useEffect(() => {
        getCurrentLocation();
        return () => {
            debouncedSearch.cancel();
        };
    }, []);


    useEffect(() => {
        if (signUpData.location) {
            setSelectedLocation(signUpData.location);
        }
    }, [signUpData.location]);

    const handleLocationSelectAndContinue = async (base: LocationOnboarding) => {
        setSelectedLocation(base);
        const locationDetails: LocationOnboarding = {
            location_id: base.location_id,
            location_name: base.location_name,
            secondary_location: base.secondary_location,
            google_place_ids: base.google_place_ids,
            location_types: base.location_types,
            logo: base.logo,
            address: base.address,
            lng: base.lng,
            lat: base.lat,
            audited: base.audited,
            people: base.people,
        };
        await updateSignUpData({ location: locationDetails });
        onNext();
    };

    const loadDefaultLocations = async () => {
        try {
            if (currentSearchTerm.current === '') {
                setLocations(defaultLocations);
                setLocationBasedResults(defaultLocations);
                setPage(2);
                setIsInfiniteDisabled(defaultLocations.length === 0);
            }
        } catch (error) {
            console.error('Error loading default locations:', error);
        } finally {
            setSearchLocationsIsLoading(false);
        }
    };
    const getCurrentLocation = async () => {
        try {
            if (isNative) {
                const result = await Geolocation.getCurrentPosition();
                await updateSignUpData({
                    latitude: result.coords.latitude,
                    longitude: result.coords.longitude
                });
                await loadLocationsBasedOnCoordinates(result.coords.latitude, result.coords.longitude);
            } else {
                try {
                    await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(
                            async (position) => {
                                await updateSignUpData({
                                    latitude: position.coords.latitude,
                                    longitude: position.coords.longitude
                                });
                                await loadLocationsBasedOnCoordinates(position.coords.latitude, position.coords.longitude);
                                resolve(position);
                            },
                            (error) => {
                                setLocationPermissionDenied(true);
                                loadDefaultLocations();
                                reject(error);
                            }
                        );
                    });
                } catch (e) {
                    console.error('Error getting location:', e);
                    setLocationPermissionDenied(true);
                    loadDefaultLocations();
                }
            }
        } catch (error) {
            console.error('Error requesting location permission:', error);
            setLocationPermissionDenied(true);
            loadDefaultLocations();
        }
    };

    useEffect(() => {
        getCurrentLocation();
    }, []);

    const generateIcon = (base: LocationOnboarding) => {
        // Create a simple hash from the location_id or place_id
        const getHash = (str: string) => {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                const char = str.charCodeAt(i);
                hash = ((hash << 5) - hash) + char;
                hash = hash & hash; // Convert to 32-bit integer
            }
            return Math.abs(hash);
        };

        // Use location_id for consistent hashing
        const hash = getHash(base.location_id || base.google_place_ids[0] || '');

        const locationName = base.location_name.toLowerCase();

        if (locationName.includes('skate')) {
            return skateIcons[0]; // Since we only have one icon, just return it
        } else if (locationName.includes('bike')) {
            return bikeIcons[0];
        } else if (locationName.includes('snow') ||
            locationName.includes('ski') ||
            locationName.includes('mountain') ||
            locationName.includes('resort') ||
            locationName.includes('snowboard')) {
            return snowIcons[0];
        } else if (locationName.includes('surf')) {
            return surfIcons[0];
        } else {
            // Use the hash to consistently select a location icon
            return locationIcons[hash % locationIcons.length];
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={onBack}>
                            <IDXButtonIcon size="text-2xl" icon={chevronBackOutline} className="text-primary-alt" />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Pick your base</IonTitle>
                    <IonButtons slot="end" onClick={onSkip}>
                        <IonButton><span className="text-primary-alt normal-case">Skip</span></IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                        placeholder="Search..."
                        value={searchTerm}
                        className="custom-searchbar"
                        debounce={0}
                        onIonInput={(e) => handleLocationSearch(e)}
                        ref={searchbarRef}
                    />
                </IonToolbar>
            </IonHeader>
            <IonContent className="bg-grid-pattern">
                <div className="relative bg-grid-pattern">
                    <SparkGrid height='120%' />
                    {(locations.length === 0 && !searchLocationsIsLoading) && (
                        <>
                            {searchTerm && (
                                <IonList className='custom-list z-50'>
                                    <IonItem
                                        button={true}
                                        className="custom-item"
                                        onClick={async () => {
                                            searchbarRef.current?.blur();
                                            const newBase: LocationOnboarding = {
                                                location_id: `new-${Date.now()}`, // Temporary ID
                                                location_name: searchTerm,
                                                secondary_location: '',
                                                google_place_ids: [],
                                                location_types: [],
                                                logo: '',
                                                address: '',
                                                lng: signUpData.longitude || 0,
                                                lat: signUpData.latitude || 0,
                                                audited: false,
                                                people: 0,
                                            };
                                            await handleLocationSelectAndContinue(newBase);
                                        }}
                                    >
                                        <IonIcon
                                            icon={locationOutline}
                                            size="large"
                                            slot="start"
                                            className="text-electric-blue"
                                        />
                                        <IonLabel>
                                            <div className="text-primary-alt text-xl">{searchTerm}</div>
                                            <div className="text-primary-secondary text-sm"></div>
                                        </IonLabel>
                                        <IonLabel slot="end" className="ion-text-right text-center">
                                            <div className="text-electric-blue text-xl font-bold text-center">0</div>
                                            <div className="text-primary-secondary text-sm text-center">Riders</div>
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                            )}
                            <div className="flex flex-col items-center justify-center p-4 mb-4">
                                <IonIcon icon={locationOutline} size="large" className="text-electric-blue" />
                                <div className="text-primary-secondary text-center text-lg">
                                    No riders are at this location. Want to add this as a new base to Ecliptic? Props to exploring new frontiers! Our community is going to ❤️ it.
                                </div>
                            </div>
                        </>
                    )}
                    {
                        searchLocationsIsLoading ? (
                            <div className="flex flex-col items-center justify-center h-full">
                                <LocationListSkeleton />
                            </div>
                        ) : (
                            <>
                                <IonList className="custom-list z-50">
                                    {locations.map((base, index) => (
                                        <IonItem
                                            key={index}
                                            button={true}
                                            onClick={async () => {
                                                searchbarRef.current?.blur();
                                                await handleLocationSelectAndContinue(base);
                                            }}
                                            className={
                                                selectedLocation === null
                                                    ? "custom-item"
                                                    : base.google_place_ids.includes(selectedLocation.google_place_ids[0])
                                                        ? "custom-item-selected"
                                                        : "custom-item"
                                            }
                                        >
                                            {base.logo ? (
                                                <IonImg
                                                    src={base.logo}
                                                    slot="start"
                                                    className="h-8 w-8 rounded-md bg-white"
                                                />
                                            ) : (
                                                <IonIcon
                                                    icon={generateIcon(base)}
                                                    size="large"
                                                    slot="start"
                                                    className="text-electric-blue"
                                                />
                                            )}
                                            <IonLabel>
                                                <div className="text-primary-alt text-xl">{base.location_name}</div>
                                                <div className="text-primary-secondary text-sm">{base.secondary_location}</div>
                                            </IonLabel>
                                            <IonLabel slot="end" className="ion-text-right text-center">
                                                <div className="text-electric-blue text-xl font-bold text-center">{base.people}</div>
                                                <div className="text-primary-secondary text-sm text-center">Riders</div>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </IonList>
                                <IonInfiniteScroll
                                    onIonInfinite={getMoreLocations}
                                    threshold="500px"
                                    disabled={isInfiniteDisabled || searchTerm !== '' || locations.some(loc => !loc.location_id)}
                                >
                                    <IonInfiniteScrollContent
                                        loadingSpinner="bubbles"
                                        loadingText="Loading more bases..."
                                    />
                                </IonInfiniteScroll>
                            </>
                        )
                    }
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LocationSearch;
import React, { useEffect } from "react";
import '../Welcome.css'
import { OnboardingStatus } from "../../../models/onboardingStatus";
import { useHistory } from "react-router";
import WelcomeLoginUsername from "../WelcomeLoginUsername/WelcomeLoginUsername";
import { OnboardingStepConfig } from "../../../models/onboardingStepConfig";
import WelcomeLoginAuth from "../WelcomeLoginAuth/WelcomeLoginAuth";
import { useSignUp } from "../SignUpProvider/SignUpProvider";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import LoadingSplash from "../../../pages/LoadingSplash";

interface ContainerProps {
}

const WelcomeLogin: React.FC<ContainerProps> = ({ }) => {
    const history = useHistory();
    const { step, setStep } = useSignUp();

    const stepConfigState: Record<string, OnboardingStepConfig> = {
        [OnboardingStatus.IN_PROGRESS_LOGIN_INIT]: {
            titleText: '',
            showBackButton: false,
            showSkipButton: false,
        },
        [OnboardingStatus.IN_PROGRESS_LOGIN]: {
            titleText: 'Log In to Ecliptic',
            showBackButton: true,
            showSkipButton: false,
            onBack: async () => {
                await navigateBackToInitial();
            },
            onNext: async () => {
                history.push('/');
                await handleOnboardingStatus(OnboardingStatus.COMPLETED);
            }
        },
        [OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME]: {
            titleText: 'Enter Email/Username and Password',
            showSkipButton: false,
            showBackButton: true,
            onBack: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN);
            },
            onNext: async () => {
                history.push('/');
                await handleOnboardingStatus(OnboardingStatus.COMPLETED);
            },
            onForgotPassword: async () => {
                history.push('/auth/forgot-password');
            }
        },
        [OnboardingStatus.IN_PROGRESS_LOGIN_PHONE]: {
            titleText: 'Enter Phone Number',
            showSkipButton: false,
            showBackButton: true,
            onBack: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN);
            },
            onNext: async () => {
                history.push('/');
                await handleOnboardingStatus(OnboardingStatus.COMPLETED);
            }
        }
    };

    const navigateBackToInitial = async () => {
        await handleOnboardingStatus(OnboardingStatus.INIT);
        history.push('/flow');
    }

    const handleOnboardingStatus = async (status: string) => {
        await setStep(status);
    }

    useEffect(() => {
        const initializeStep = async () => {
            if (step) {
                if (step.includes(OnboardingStatus.LOGIN)) {
                    console.log("VALID STATUS: " + step);
                    await handleOnboardingStatus(step);
                } else {
                    console.log("INVALID STATUS: GOING TO FLOW");
                    await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN);
                }
            } else {
                await handleOnboardingStatus(OnboardingStatus.INIT);
                history.push("/flow");
            }
        };

        console.log("Loading Login Flow App Page");
        initializeStep();
    }, []);  // Empty dependency array means this runs once on mount

    useEffect(() => {
        const redirectBasedOnStep = async () => {
            console.log("Step: " + step);
            if (step === OnboardingStatus.IN_PROGRESS_LOGIN_INIT) {
                console.log("REDIRECTING TO LOGIN FLOW");
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN);
            }
        };

        redirectBasedOnStep();
    }, [step]);

    const renderStep = () => {
        switch (step) {
            case OnboardingStatus.IN_PROGRESS_LOGIN:
                return (
                    <WelcomeLoginAuth onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN].onBack}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN].titleText}
                        handleAuthedStep={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN].onNext}
                        handlePhoneAuthStep={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN_PHONE)}
                        handleUserPasswordAuthedStep={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME)} />
                );
            case OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME:
                return (
                    <WelcomeLoginUsername
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME].titleText}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME].showSkipButton}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME].showBackButton}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME].onBack}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME].onNext}
                        onForgotPassword={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME].onForgotPassword}
                    />
                )
            case OnboardingStatus.IN_PROGRESS_LOGIN_PHONE:
                return (
                    <PhoneNumberInput
                        signUp={false}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_PHONE].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_PHONE].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_PHONE].titleText}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_PHONE].onBack}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN_PHONE].onNext} />
                )
            case OnboardingStatus.COMPLETED:
                return (
                    <LoadingSplash />
                );
            default:
                return null;
        }
    }

    return renderStep();
};

export default WelcomeLogin;
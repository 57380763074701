import React, { useContext, useEffect, useState } from 'react';
import { useMediaState } from '@vidstack/react';
import { MuteIcon, VolumeHighIcon } from '@vidstack/react/icons';
import { useVideoContext } from '../VideoContextManager/VideoContextManager';
import { AppContext } from '../../../AppStateProvider';

interface CustomMuteButtonProps {
    hasInteracted: boolean;
    mutedVolume: boolean;
    handleMuteButtonClick: (newMutedState: boolean) => void;
}

// THIS ONLY CONTROLS THE DISPLAY OF THE BUTTON NOT THE VOLUME
const CustomMuteButton: React.FC<CustomMuteButtonProps> = ({ hasInteracted, mutedVolume, handleMuteButtonClick }) => {
    const handleMuteClick = async () => {
        const newMutedState = !mutedVolume;
        console.log("Handling setting the player to muted: ", newMutedState);
        handleMuteButtonClick(newMutedState);
    };

    if (!hasInteracted) {
        return null;
    }

    return (
        <button
            onClick={handleMuteClick}
            className={`group ring-sky-400 relative inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset 
                data-[focus]:ring-4`}
            aria-label={mutedVolume ? 'Unmute' : 'Mute'}
            data-state={mutedVolume ? 'muted' : 'unmuted'}
        >
            <MuteIcon
                className="w-8 h-8 hidden group-data-[state='muted']:block"
            />
            <VolumeHighIcon
                className="w-8 h-8 hidden group-data-[state='unmuted']:block"
            />
        </button>
    );
};

export default CustomMuteButton;
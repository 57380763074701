import { SQLiteDBConnection } from "@capacitor-community/sqlite";
import { ElementSize } from "../../models/elementSize";

export class ElementSizeService {
    private readonly tableName = 'element_sizes';
    private initialized = false;

    constructor(private performSQLAction: (
        action: (db: SQLiteDBConnection | undefined) => Promise<void>,
        cleanup?: () => Promise<void>
    ) => Promise<void>) { }

    private async ensureTableExists(): Promise<void> {
        if (this.initialized) return;

        try {
            await this.performSQLAction(async (db) => {
                if (!db) console.error("Database not initialized");

                // Create table if it doesn't exist
                const createTableQuery = `
                    CREATE TABLE IF NOT EXISTS ${this.tableName} (
                        id TEXT PRIMARY KEY,
                        height REAL NOT NULL
                    );
                `;
                await db.execute(createTableQuery);
            });

            console.log("Bulk save complete");

            this.initialized = true;
        } catch (error) {
            console.error('Error ensuring table exists:', error);
        }
    }

    async saveElementSize(elementSize: ElementSize): Promise<void> {
        // Ensure table exists before querying
        await this.ensureTableExists();

        try {
            await this.performSQLAction(async (db) => {
                await db?.query(
                    `INSERT OR REPLACE INTO ${this.tableName} (id, height) VALUES (?, ?);`,
                    [elementSize.id, elementSize.height]
                );
            });
        } catch (error) {
            console.error('Error saving element size:', error);
        }
    }

    async getElementSize(id: string): Promise<ElementSize | null> {
        let result: ElementSize | null = null;
        // Ensure table exists before querying
        await this.ensureTableExists();

        try {
            await this.performSQLAction(async (db) => {
                const queryResult = await db?.query(
                    `SELECT * FROM ${this.tableName} WHERE id = ?;`,
                    [id]
                );
                if (queryResult?.values && queryResult.values.length > 0) {
                    result = queryResult.values[0] as ElementSize;
                }
            });

            return result;
        } catch (error) {
            console.error('Error getting element size:', error);
        }
    }

    async bulkSaveElementSizes(sizes: ElementSize[]): Promise<void> {
        if (sizes.length === 0) return;
        // Ensure table exists before querying
        await this.ensureTableExists();

        try {
            await this.performSQLAction(async (db) => {
                // Process all inserts in batches
                const BATCH_SIZE = 100; // Adjust based on your needs

                for (let i = 0; i < sizes.length; i += BATCH_SIZE) {
                    const batch = sizes.slice(i, i + BATCH_SIZE);
                    const placeholders = batch.map(() => "(?, ?)").join(", ");
                    const values = batch.flatMap(size => [size.id, size.height]);

                    const query = `
                        INSERT OR REPLACE INTO ${this.tableName} (id, height) 
                        VALUES ${placeholders};
                    `;

                    await db?.query(query, values);
                }

                console.log("Bulk save complete");
            });
        } catch (error) {
            console.error('Error in bulk save:', error);
        }
    }

    async getAllElementSizesAsMap(): Promise<{ [key: string]: number }> {
        let result: { [key: string]: number } = {};
        // Ensure table exists before querying
        await this.ensureTableExists();

        try {
            await this.performSQLAction(async (db) => {
                const queryResult = await db?.query(`SELECT * FROM ${this.tableName};`);
                if (queryResult?.values) {
                    result = (queryResult.values as ElementSize[]).reduce((acc, { id, height }) => ({
                        ...acc,
                        [id]: height
                    }), {});
                }
            });

            return result;
        } catch (error) {
            console.error('Error getting all element sizes:', error);
        }
    }

    async bulkGetElementSizes(ids: string[]): Promise<ElementSize[]> {
        if (ids.length === 0) return [];

        let results: ElementSize[] = [];

        // Ensure table exists before querying
        await this.ensureTableExists();

        try {
            await this.performSQLAction(async (db) => {
                const placeholders = ids.map(() => '?').join(',');
                const query = `SELECT * FROM ${this.tableName} WHERE id IN (${placeholders});`;
                const queryResult = await db?.query(query, [...ids]);
                if (queryResult?.values) {
                    results = queryResult.values as ElementSize[];
                }
            });

            return results;
        } catch (error) {
            console.error('Error in bulk get:', error);
        }
    }
}
import { OverlayEventDetail } from "@ionic/core/components";
import { createGesture, Gesture, IonActionSheet, IonButton, IonIcon, useIonModal, useIonToast } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ProfilePictureModal from "../ProfilePictureModal/ProfilePictureModal";
import { PictureUploadType } from "../../../models/pictureUploadType";
import { User } from "../../../models/user";
import { UserType } from "../../../models/usertype";
import BrandsService from "../../../services/brand.service";
import UserService from "../../../services/user.service";
import { AppContext } from "../../../AppStateProvider";
import { alertCircleOutline, closeOutline } from "ionicons/icons";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import FullScreenPicture from "../../ComponentsUI/FullScreenPicture/FullScreenPicture";
import { useAppState } from "../../../AppListenerProvider";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";

interface ContainerProps {
    editProfile: boolean;
    src: string;
    pictureUploadType?: string;
    isNative: boolean;
}

const defaultButtonsActionSheet = [
    {
        text: 'View',
        data: {
            action: 'view',
        },
    },
    {
        text: 'Upload',
        data: {
            action: 'edit',
        },
    },
    {
        text: 'Cancel',
        role: 'cancel',
        data: {
            action: 'cancel',
        },
    },
]

const ProfilePicture: React.FC<ContainerProps> = (props: ContainerProps) => {
    const { state, dispatch } = useContext(AppContext);
    const [userType, setUserType] = useState<UserType>(UserType.USER);
    const [profilePicSrc, setProfilePicSrc] = useState(props.src);
    const [profilePictureFullScreen, setProfilePictureFullScreen] = useState(false);
    const { isNative } = useAppState();

    const [viewEditProfilePictureOpen, setViewEditProfilePictureOpen] = useState(false);

    const [preview, setPreview] = useState<any>(undefined);

    const [buttonsActionSheet, setButtonsActionSheet] = useState(defaultButtonsActionSheet);

    const [present] = useIonToast();

    const confirmProfilePictureSubmit = async (selectedFile: any) => {
        console.log('uploading');
        const data = new FormData();
        data.append('file', selectedFile);
        console.log("selected file", selectedFile);
        // @ts-ignore
        data.append('filename', selectedFile.value);
        console.log("filename", selectedFile.value);
        if (userType == UserType.USER) {
            const updatedUser: User = await UserService.uploadUserProfilePicture(state.user.id, data);
            await dispatch({ type: 'setUser', payload: updatedUser });
            console.log("updated user pro pic", updatedUser.profile.profile_pic);
            setProfilePicSrc(updatedUser.profile.profile_pic);
            setPreview(undefined);
        }

        if (userType == UserType.BRAND && props.pictureUploadType == PictureUploadType.PROFILE) {
            const updatedBrand = await BrandsService.uploadBrandProfilePicture(state.user.id, data);
            await dispatch({ type: 'setUser', payload: updatedBrand });
            setProfilePicSrc(updatedBrand.profile.profile_pic);
            setPreview(undefined);
        }

        if (userType == UserType.BRAND && props.pictureUploadType == PictureUploadType.COVER) {
            const updatedBrand = await BrandsService.uploadBrandCoverPicture(state.user.id, data);
            await dispatch({ type: 'setUser', payload: updatedBrand });
            setProfilePicSrc(updatedBrand.profile.cover_pic);
            setPreview(undefined);
        }

    }

    function openProfilePictureModal() {
        presentProfilePicModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const [presentProfilePicModal, dismissProfilePicModal] = useIonModal(ProfilePictureModal, {
        onDismiss: (data: string, role: string) => dismissProfilePicModal(data, role),
        onProfilePictureSubmit: confirmProfilePictureSubmit,
        src: props.src,
        pictureUploadType: props.pictureUploadType,
        preview: preview,
    });

    const getUserType = async () => {
        const retrievedUserType = await UserService.getUserType(state.user.auth_id);
        setUserType(retrievedUserType);
    }

    useEffect(() => {
        console.log("Waiting for props to be mounted");
        setProfilePicSrc(props.src);
        getUserType();
    }, [props.src, userType])

    useEffect(() => {
        if (props.editProfile && !profilePicSrc && props.isNative) {
            setButtonsActionSheet([
                {
                    text: 'Upload',
                    data: {
                        action: 'edit',
                    },
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    data: {
                        action: 'cancel',
                    },
                },
            ]);
        } else {
            setButtonsActionSheet(defaultButtonsActionSheet);
        }
    }, [props.editProfile, profilePicSrc]);


    const openSettings = async () => {
        if (isNative) {
            try {
                if (Capacitor.getPlatform() === 'ios') {
                    await NativeSettings.openIOS({
                        option: IOSSettings.App,
                    });
                } else if (Capacitor.getPlatform() === 'android') {
                    await NativeSettings.openAndroid({
                        option: AndroidSettings.ApplicationDetails,
                    });
                }
            } catch (error) {
                console.error('Error opening settings:', error);
            }
        }
    };

    const presentPermissionsErrorToast = async () => {
        await present({
            message: 'Open your settings to allow access to your photos so you can upload a profile picture',
            duration: 5000, // Duration in milliseconds
            position: 'top',
            color: "danger",
            icon: alertCircleOutline,
            buttons: [
                {
                    text: 'Ok',
                    handler: async () => {
                        await openSettings();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    /*
    Logic:
    User is viewing their profile
    1. If the user is viewing their profile, and they don't have a profile picture, then display icon to upload pro pic
    2. If they have a profile picture, then they can click that and change their pro pic using the modal

    User is viewing another profile
    1. If the user cannot edit a profile picture, display the default profile picture
    2. If the user that they are viewing does not have a profile picture, display the default profile picture


     */

    /*
    For some reason rendering this was causing an error
    <IonButton id="open-modal-upload" fill="solid" color="light" size="large" onClick={() => setIsOpen(true)}>
     */

    const handleActionSheet = async (data: any) => {
        if (data) {
            const action = data.action;
            if (action === 'view') {
                // view profile picture
                setViewEditProfilePictureOpen(false);
                setProfilePictureFullScreen(true);
            } else if (action === 'edit') {
                setViewEditProfilePictureOpen(false);
                // edit profile picture
                console.log('editing profile picture');
                await selectFile();
            } else {
                setViewEditProfilePictureOpen(false);
                console.log('cancelling');
            }
        } else {
            setViewEditProfilePictureOpen(false);
            console.log('cancelling');
        }
    }

    const selectFile = async () => {
        try {
            if (isNative) {
                const permissions = await Camera.checkPermissions();
                if (permissions.photos === 'granted' || permissions.photos === 'limited' || permissions.camera === 'granted' || permissions.camera === 'limited') {
                    console.log('Permissions granted');
                } else {
                    console.log('Permissions denied');
                    const reqPermRes = await Camera.requestPermissions();
                    if (reqPermRes.photos === 'granted' || reqPermRes.photos === 'limited' || reqPermRes.camera === 'granted' || reqPermRes.camera === 'limited') {
                        console.log('Permissions granted');
                    } else {
                        console.log('Permissions denied');
                        // present IonToast to open settings
                        await presentPermissionsErrorToast();
                        return;
                    }
                }
            }

            const image = await Camera.getPhoto({
                quality: 80,
                allowEditing: true,
                resultType: CameraResultType.Uri,
                webUseInput: !isNative,
                source: !isNative ? CameraSource.Photos : CameraSource.Prompt,
            });

            if (!image.webPath && !image.path) {
                console.error('No image path available');
                return;
            }

            let file: File;

            if (isNative) {
                // For native platforms, fetch the file and create a File object
                const response = await fetch(image.webPath!);
                setPreview(image.webPath);
                const blob = await response.blob();
                file = new File([blob], `profile_picture_${Date.now()}.${image.format}`, { type: `image/${image.format}` });
                await confirmProfilePictureSubmit(file);
            } else {
                // For web, set preview and open modal
                if (image.webPath) {
                    setPreview(image.webPath);
                    openProfilePictureModal();
                } else {
                    console.error('No web path available');
                }
            }
        } catch (error) {
            console.log("Error picking image", error);
        }
    }

    const handleProfilePictureClick = () => {
        if (props.editProfile) {
            setViewEditProfilePictureOpen(true);
        } else {
            setProfilePictureFullScreen(true);
        }
    };

    return (
        <>
            <img
                alt="profile"
                className="shrink w-24 h-24 rounded-full cursor-pointer"
                src={profilePicSrc || '/assets/photos/defaultProPic.png'}
                onClick={handleProfilePictureClick}
            />
            {profilePictureFullScreen && (
                <FullScreenPicture
                    src={profilePicSrc || '/assets/photos/defaultProPic.png'}
                    onClose={() => setProfilePictureFullScreen(false)}
                />
            )}
            <IonActionSheet
                isOpen={viewEditProfilePictureOpen}
                buttons={buttonsActionSheet}
                onDidDismiss={(e) => handleActionSheet(e.detail.data)}
            />
        </>
    );
};

export default ProfilePicture;

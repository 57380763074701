import { IonButton, IonInput } from "@ionic/react";
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import AuthService from "../../../services/auth.service";
import { useAppState } from "../../../AppListenerProvider";
import { useAuth } from "../../../AuthProvider";

const WelcomeResetPassword = () => {
    const history = useHistory();
    const location = useLocation();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const userInput = useRef<HTMLIonInputElement>(null);

    const [passwordResetSuccessfully, setPasswordResetSuccessfully] = useState(false);

    const { isActive, isNative, platform } = useAppState();
    const { user, isLoading, isAuthenticated, firebaseApp, auth } = useAuth();
    const authService = AuthService({ user, isLoading, isAuthenticated, firebaseApp, auth }, {
        isActive,
        isNative,
        platform
    });

    const onBack = async () => {
        setPasswordResetSuccessfully(false);
        setNewPassword('');
        setConfirmPassword('');
        setError('');
        console.log("Going back to login page");
        history.push('/flow/login');
    }

    // Get the parameters from URL
    const queryParams = new URLSearchParams(location.search);
    const oobCode = queryParams.get('oobCode');
    const mode = queryParams.get('mode');

    const validatePassword = (password: string): any => {
        // if (password.length < 8) {
        //     return 'Password must be at least 8 characters long';
        // }
        // Add more password validation as needed
        return null;
    };

    const handleResetPassword = async () => {
        setError('');
        setLoading(true);

        // Validate passwords
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            setLoading(false);
            return;
        }

        const passwordError = validatePassword(newPassword);
        if (passwordError) {
            setError(passwordError);
            setLoading(false);
            return;
        }

        if (!oobCode) {
            setError('Invalid password reset link');
            setLoading(false);
            return;
        }

        try {
            // Confirm password reset
            await authService.confirmPasswordReset(oobCode, newPassword);

            // Navigate to success page or login
            setPasswordResetSuccessfully(true);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    if (passwordResetSuccessfully) {
        return (
            <OnboardingPageWrapper
                title={'Password Reset Successfully'}
                showBackButton={false}
                showSkipButton={false}>
                <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                    <div className="mt-4 space-y-2 font-bold text-center">
                        <p>Your password has been successfully reset.</p>
                        <p>You can now log in with your new password.</p>

                        <div className="mt-4">
                            <IonButton
                                expand="block"
                                className="neon-button w-full normal-case font-bold"
                                onClick={onBack}>
                                <span className="text-lg normal-case font-bold">
                                    Continue to Login
                                </span>
                            </IonButton>
                        </div>
                    </div>
                </div>
            </OnboardingPageWrapper>
        );
    }

    return (
        <OnboardingPageWrapper
            title={'Reset Your Password'}
            showBackButton={true}
            showSkipButton={false}
            onBack={onBack}>
            <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                <div className="mt-4 space-y-2 font-bold">
                    <IonInput
                        ref={userInput}
                        type="password"
                        color="light"
                        placeholder="New Password"
                        value={newPassword}
                        onIonInput={e => setNewPassword(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                    />
                    <IonInput
                        type="password"
                        color="light"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onIonInput={e => setConfirmPassword(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                    />
                    {error && <div className="text-red-500">{error}</div>}

                    <div className="text-sm font-bold text-white-500"
                        onClick={() => setError('Text us at 1-855-MAX-BISK (1-855-629-2475), reach out on IG @ecliptic.day, or email mia@idx.style and we will get this sorted ASAP!')}>
                        Having trouble? Contact support
                    </div>

                    <div className="mt-2">
                        <IonButton
                            expand="block"
                            className="neon-button w-full normal-case font-bold"
                            disabled={loading || !newPassword || !confirmPassword}
                            onClick={handleResetPassword}>
                            <span className="text-lg normal-case font-bold">
                                {loading ? 'Resetting...' : 'Reset Password'}
                            </span>
                        </IonButton>
                    </div>
                </div>
            </div>
        </OnboardingPageWrapper>
    );
};

export default WelcomeResetPassword;
import React, {useEffect} from 'react';
import {IonIcon} from "@ionic/react";

interface ChipProps {
    size: string;
    icon?: string; // Optional icon
    src?: string;
    color?: string;
    onClick?: () => void;
    className?: string;
}

const IDXButtonIcon: React.FC<ChipProps> = ({size = 'text-2xl', icon, src, color, onClick, className}) => {

    useEffect(() => {

    }, [size, icon, color, onClick]);

    return (
        <>
            {icon && <IonIcon slot="icon-only" className={size + " " + className} icon={icon} color={color}
                              onClick={onClick}/>}
            {src && <IonIcon slot="icon-only" className={size + " " + className} src={src} onClick={onClick}/>}
        </>
    )
};

export default IDXButtonIcon;
import { IonButton, useIonToast } from "@ionic/react";
import React, { useEffect } from "react";
import { AppContext } from "../../../AppStateProvider";
import { useHistory } from "react-router";
import PaymentsService from "../../../services/payments.service";
import { Purchases } from "@revenuecat/purchases-capacitor";
import { Browser } from "@capacitor/browser";
import PreferencesService from "../../../services/preferences.service";

interface ContainerProps {
    name: string;
    display: boolean;
    id: string;
    isNative: boolean;
}

const SubscribeButton: React.FC<ContainerProps> = (props: ContainerProps) => {
    const { state } = React.useContext(AppContext);
    const history = useHistory();
    const [isSubscribed, setIsSubscribed] = React.useState<boolean>(false);
    const [presentToast] = useIonToast();

    const getRevenueCatSubscription = async (id: string) => {
        const customerInfo = await Purchases.getCustomerInfo()
        await Browser.open(
            {
                url: customerInfo?.customerInfo?.managementURL
            }
        )
    }

    const getStripePortalSession = async (id: string) => {
        console.log("getting a portal session for id: " + id);
        const auth_id_value = await PreferencesService.getUserUid();
        if (!auth_id_value || auth_id_value === "") {
            console.error("Error getting auth_id from capacitor preferences");
            await presentToast({
                message: "Error getting stripe portal sessions. Contact devs on IG",
                duration: 2000,
                color: "danger"
            });
            return;
        }

        const auth_id = auth_id_value;
        console.log("auth_id: " + auth_id);
        const stripeUrl = await PaymentsService.getCustomerPortalSession(auth_id);
        if (stripeUrl) {
            window.location.href = stripeUrl;
        } else {
            console.error("Error getting stripe portal session");
            await presentToast({
                message: "Error getting stripe portal session. Contact devs on IG",
                duration: 2000,
                color: "danger"
            });
        }
    }

    useEffect(() => {
    }, [props.isNative, props.id]);

    useEffect(() => {
        if (state.isSubscribed) {
            setIsSubscribed(true);
        }
    }, [state.isSubscribed]);

    const subscribe = () => {
        history.push(`/shop`);
    }

    const manageSubscriptions = async () => {
        if (props.isNative) {
            await getRevenueCatSubscription(props.id);
        } else {
            await getStripePortalSession(props.id);
        }
    }

    return (props.display ?
        isSubscribed ?
            <div className="w-6/12">
                <IonButton
                    color="favorite"
                    shape="round"
                    expand="full"
                    onClick={manageSubscriptions}>
                    Manage Subscriptions
                </IonButton>
            </div> :
            <div className="w-6/12">
                <IonButton
                    color="favorite"
                    shape="round"
                    expand="full"
                    onClick={subscribe}>
                    Subscribe
                </IonButton>
            </div> : <div></div>
    );
};

export default SubscribeButton;

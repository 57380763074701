import {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import "../global.css";
import { UserType } from "../models/usertype";
import UserService from "../services/user.service";
import { AppContext } from "../AppStateProvider";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import { useAuth } from "../AuthProvider";
import ToolbarSearchBarExplore from "../components/ComponentsUI/ToolbarSearchBarExplore/ToolbarSearchBarExplore";
import ExploreNewVirtualizedList, { ExploreNewVirtualizedListRef } from "../components/ComponentsUI/ExploreNewVirtualizedList/ExploreNewVirtualizedList";
import ExploreFollowVirtualizedList, { ExploreFollowVirtualizedListRef } from "../components/ComponentsUI/ExploreFollowVirtualizedList/ExploreFollowVirtualizedList";
import ExploreHotVirtualizedList, { ExploreHotVirtualizedListRef } from "../components/ComponentsUI/ExploreHotVirtualizedList/ExploreHotVirtualizedList";
import ExploreTVVirtualizedList from "../components/ComponentsUI/ExploreTVVirtualizedList/ExploreTVVirtualizedList";
import useSQLiteDB from "../hooks/useSQLiteDB";
import { ElementSizeService } from "../services/database/elementsize.service";
import { usePushNotifications } from "../hooks/usePushNotifications";
import { useAppState } from "../AppListenerProvider";

const ExplorePage: React.FC = () => {
    const { state } = useContext(AppContext);
    const { isAuthenticated, isLoading, user } = useAuth();
    const { platform } = useAppState();


    // GENERIC
    const [segmentValue, setSegmentValue] = React.useState(undefined);

    // TV 
    const tvListRef = useRef<ExploreHotVirtualizedListRef>(null);

    // FOLLOWING
    const followListRef = useRef<ExploreFollowVirtualizedListRef>(null);

    // NEW
    const newListRef = useRef<ExploreNewVirtualizedListRef>(null);

    // HOTT
    const hotListRef = useRef<ExploreHotVirtualizedListRef>(null)

    // GENERIC
    const [userType, setUserType] = React.useState(UserType.UNKNOWN);

    const { performSQLAction, initialized } = useSQLiteDB();
    const [elementSizeService, setElementSizeService] = useState<ElementSizeService | null>(null);

    const { permissionStatus, requestPushNotificationPermission } = usePushNotifications();

    useEffect(() => {
        const handleEnableNotifications = () => {
            // Only request if we're in prompt state
            if (permissionStatus === 'prompt' || permissionStatus === 'prompt-with-rationale') {
                requestPushNotificationPermission();
            }
        };

        handleEnableNotifications();
    }, [permissionStatus]);


    useEffect(() => {
        const initService = async () => {
            if (!initialized) {
                setElementSizeService(null);
                return;
            }

            try {
                const service = new ElementSizeService(performSQLAction);
                setElementSizeService(service);
            } catch (error) {
                console.error("Failed to initialize ElementSizeService:", error);
                setElementSizeService(null);
            }

            window.prerenderReady = true;
        };

        initService();
    }, [initialized]);

    useEffect(() => {
        console.log("Loading HomePage - New - GETTING NEW TRICKS USE EFFECT");
        const getAuthInformation = async () => {
            if (isAuthenticated) {
                const user_type_retrieval = await UserService.getUserType(user.uid);
                setUserType(user_type_retrieval);
            }
        }

        if (!isLoading) {
            getAuthInformation();
        }
    }, [isAuthenticated, isLoading, user, state.blockedUsers.length, state.blockingUsers.length]);


    const handleSegmentChangeNew = async (segment: string) => {
        console.log("HANDLE SEGMENT CHANGE NEW: ", segment);
        setSegmentValue(segment);
        try {
            // Set segment value first, then wait for next render cycle
            setTimeout(() => {
                if (segment === "explore") {
                    tvListRef.current?.scrollToTop();
                }
                else if (segment === "following") {
                    followListRef.current?.scrollToTop();
                }
                else if (segment === "new") {
                    newListRef.current?.scrollToTop();
                }
                else if (segment === "hot") {
                    hotListRef.current?.scrollToTop();
                }
            }, 0);
        } catch (e) {
            console.log("Error in handleSegmentChangeNew: ", e);
        }
    }

    return (
        <IonPage>
            <CustomHelmet title={"Ecliptic // A Ski and Snowboard Spot"}
                description={"Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed. Search for any trick by name, rider, location, build your bag of tricks, and get noticed by the crew, sponsors or the world."}
                image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/home`} />
            <ToolbarSearchBarExplore handleSegmentChangeNew={handleSegmentChangeNew}
                isAuthenticated={isAuthenticated}></ToolbarSearchBarExplore>
            <IonContent scrollY={false}>
                <IonRefresher slot="fixed" onIonRefresh={async (event) => {
                    try {
                        if (segmentValue === 'explore') {
                            await tvListRef.current?.refreshTricks();
                        } else if (segmentValue === 'following') {
                            await followListRef.current?.refreshTricks();
                        } else if (segmentValue === 'new') {
                            await newListRef.current?.refreshTricks();
                        } else if (segmentValue === 'hot') {
                            await hotListRef.current?.refreshTricks();
                        }
                    } finally {
                        event.detail.complete();
                    }
                }}>
                    <IonRefresherContent />
                </IonRefresher>
                <div className="ion-content-scroll-host">
                    {
                        segmentValue === 'explore' ?
                            (
                                <ExploreTVVirtualizedList ref={tvListRef} elementSizeService={elementSizeService} />
                            )
                            : segmentValue === 'following' ?
                                (
                                    <ExploreFollowVirtualizedList ref={followListRef} elementSizeService={elementSizeService} />
                                ) :
                                segmentValue === 'new' ?
                                    (
                                        <ExploreNewVirtualizedList ref={newListRef} elementSizeService={elementSizeService} />
                                    ) :
                                    segmentValue === 'hot' ?
                                        (
                                            <ExploreHotVirtualizedList ref={hotListRef} elementSizeService={elementSizeService} />
                                        ) :
                                        null
                    }
                </div>
            </IonContent>
        </IonPage>
    );
};

export default ExplorePage;

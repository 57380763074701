import React, {useEffect, useState} from 'react';
import {IonSearchbar} from '@ionic/react';
import {Capacitor} from '@capacitor/core';

interface ContainerProps {
    placeholder: string;
    webPlaceholder?: string;
    mobilePlaceholder?: string;
    onSubmit: (query: string) => void;
    showOnMap: boolean;
}

const MapSearchBar: React.FC<ContainerProps> = (props) => {
    const [searchText, setSearchText] = useState('');
    const [placeholder, setPlaceholder] = useState('');

    useEffect(() => {
        setPlaceholder(
            Capacitor.getPlatform() !== 'web'
                ? props.mobilePlaceholder || props.placeholder
                : props.webPlaceholder || props.placeholder
        );
    }, [props.mobilePlaceholder, props.webPlaceholder, props.placeholder]);

    const handleInput = (event: CustomEvent) => {
        setSearchText(event.detail.value);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        props.onSubmit(searchText);
        setSearchText('');
    };

    if (!props.showOnMap) {
        return null;
    }

    return (
        <>
            {
                props.showOnMap &&
                <div className="w-5/6">
                    <form onSubmit={handleSubmit} action="#">
                        <IonSearchbar
                            value={searchText}
                            placeholder={placeholder}
                            onIonInput={handleInput}
                            animated={false}
                            showClearButton="always"
                            showCancelButton="never"
                            inputmode="search"
                            color="favorite-secondary"
                        />
                    </form>
                </div>
            }
        </>
    );
};

export default MapSearchBar;
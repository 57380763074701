import React from 'react';
import { IonSkeletonText } from '@ionic/react';

const SearchTricksLoading = () => {
    const SkeletonItem = () => (
        <div className="mb-4">
            <div className="aspect-video bg-gray-800 rounded-lg mb-2">
                <IonSkeletonText animated className="w-full h-full" />
            </div>
            <IonSkeletonText animated className="h-4 w-3/4 mb-1" />
            <IonSkeletonText animated className="h-3 w-1/2" />
        </div>
    );

    return (
        <div className="text-white p-4 mx-auto">
            <div className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-4">
                {[...Array(20)].map((_, index) => (
                    <SkeletonItem key={index} />
                ))}
            </div>
        </div>
    );
};

export default SearchTricksLoading;
import React, { useState } from "react";
import { Instagram } from '@awesome-cordova-plugins/instagram';
import { IonButton, IonIcon, IonItem, IonLabel, IonSpinner } from "@ionic/react";
import { logoInstagram } from "ionicons/icons";
import { useInstagramShare } from "../../../hooks/useInstagramShare";
import IDXButtonIcon from "../../ComponentsUI/IDXButtonIcon/IDXButtonIcon";

declare var IGStory: any; // this is the plugin object

interface ContainerProps {
    backgroundVideoURL: string;
}

const InstagramShare: React.FC<ContainerProps> = (props) => {
    const { instagramInstalled, loading, checkInstagram } = useInstagramShare();

    const handleInstagramShare = () => {
        checkInstagram({
            backgroundVideoURL: props.backgroundVideoURL,
            stickerImageURL: 'https://corked.blob.core.windows.net/logos/eclipticigstickershare.png'
        });
    };
    return (
        // maybe need to change the z-index of this div if it doesn't go over the vids
        // make this an ion-item
        <>
            <IonButton fill="clear" size="small" color="dark" onClick={() => handleInstagramShare()}>
                <IDXButtonIcon size="text-2xl" icon={logoInstagram} />
            </IonButton>
        </>
    );
};

export default InstagramShare;

import React, {useEffect, useRef, useState} from "react";
import {IonButton, IonIcon, IonTextarea} from "@ionic/react";
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import {chevronDownOutline, chevronUpOutline, closeCircle} from "ionicons/icons";
import IDXButtonIcon from "../../ComponentsUI/IDXButtonIcon/IDXButtonIcon";


interface ContainerProps {
    bisk: number,
    onBuy?: () => void,
    onSend?: (bisk: number, note: string) => void,
    trickName?: string,
    recipientUsername?: string,
}

const BiskInput: React.FC<ContainerProps> = ({bisk, onBuy, onSend, trickName, recipientUsername}) => {
    const inputRef = useRef(null);
    const [value, setValue] = useState('');
    const [noteValue, setNoteValue] = useState('');
    const [inputWidth, setInputWidth] = useState('1ch');
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [displayAddNote, setDisplayAddNote] = useState(false);
    const [inputBisk, setInputBisk] = useState(bisk);

    useEffect(() => {
        setInputBisk(bisk);
    }, [bisk]);

    const handleChange = (digit: string) => {
        if (digit === '0') {
            return;
        }

        console.log("Handling change for digit: ", digit);
        // Ensure that only numeric values are allowed
        const numericValue = digit.replace(/[^\d]/g, '');
        if (parseInt(numericValue) > bisk) {
            setIsError(true);
            setErrorText("You can't send this much Bisk :( Recharge at the Ecliptic Smoke Shack")
        } else {
            setIsError(false);
        }
        setValue(numericValue);
    };

    const handleSendBisk = (bisk: string, note: string) => {
        const biskValue = parseInt(bisk);
        if (bisk == '') {
            setIsError(true);
            setErrorText("You can't send 0 Bisk :(")
        } else if (biskValue <= 0) {
            setIsError(true);
            setErrorText("You can't send 0 Bisk :(")
        } else {
            setIsError(false);
            onSend(biskValue, note);
        }
    }

    const handleDeleteBiskEntry = () => {
        console.log("Handling delete bisk entry");
        setValue('');
        setInputWidth('1ch');
        setIsError(false);

        if (inputRef.current) {
            inputRef.current.focus(); // Automatically focus on the input when the component mount
        }
    }

    useEffect(() => {
        console.log("Value changed to: ", value);
        if (value !== '') {
            // Update the width of the input based on its value's length
            setInputWidth(`${Math.max(value.length, 1)}ch`);
        } else {
            setInputWidth('1ch');
        }

    }, [value]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Automatically focus on the input when the component mounts
        }
    }, [bisk]);

    useEffect(() => {
        setValue('')
        setInputWidth('1ch');
        setIsError(false);
    }, []);

    const toggleAddNote = () => {
        setDisplayAddNote(!displayAddNote);
    }

    return (
        <div className="flex flex-col my-4 space-y-2">
            <div className="flex flex-row justify-around">
                <IonButton shape="round" fill="clear" color="favorite-secondary" onClick={onBuy}>
                    <div className="underline underline-offset-4 text-lg">BUY BISK</div>
                </IonButton>
                <IonButton shape="round" fill="clear" color="favorite-secondary"
                           onClick={() => handleChange(bisk.toString())}>
                    <div className="underline underline-offset-4 text-lg">USE MAX</div>
                </IonButton>
            </div>
            <div className="flex flex-row items-center justify-center">
                <IonIcon icon={biskLogo} size="large" className="h-6 w-6 pr-2"></IonIcon>
                <div>
                    <input
                        ref={inputRef}
                        type="text" // Change this to text to prevent decimals and 'e'
                        inputMode="numeric" // This ensures mobile users get a numeric keypad
                        pattern="\d*" // This pattern restricts input to digits only for mobile keyboards
                        placeholder="0"
                        value={value}
                        style={{width: inputWidth}}
                        className="bg-transparent h-12 text-5xl border-none focus:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </div>
                <IonIcon icon={closeCircle} className="h-6 w-6 pb-6 pl-1" onClick={handleDeleteBiskEntry}></IonIcon>
            </div>

            <div className="flex flex-row items-center justify-center">
                <IonButton disabled={isError || value === ''} shape="round" color="favorite-secondary"
                           onClick={() => handleSendBisk(value, noteValue)}>
                    <div className="text-lg">Send</div>
                </IonButton>
            </div>
            <div>
                {isError && <p className="text-red-500 text-center">{errorText}</p>}
            </div>
            <div className="text-center text-lg" color="theme-alternative">
                Balance: {bisk} Bisk
            </div>
            <div className="flex flex-col items-center justify-center">
                <div className="flex flex-row justify-between items-center">
                    <label className="block text-lg font-medium">Add Note (Optional)</label>
                    <IonButton fill="clear" size="small" color="dark" onClick={toggleAddNote}>
                        <IDXButtonIcon size="text-2xl" icon={displayAddNote ? chevronUpOutline : chevronDownOutline}/>
                    </IonButton>
                </div>
                {
                    displayAddNote &&
                    <IonTextarea labelPlacement="stacked" onIonInput={(e) => setNoteValue(e.detail.value as string)}
                                 value={noteValue}
                                 placeholder={trickName ? "Steezus Christ, that " + trickName + " was a banger. You a real one." : "Here you go g, don't go too crazy with the Bisk"}></IonTextarea>
                }
            </div>
        </div>
    );
};

export default BiskInput;

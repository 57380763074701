import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { FirebaseAuthentication, User as NativeUser } from '@capacitor-firebase/authentication';
import { FirebaseApp, getApp, getApps, initializeApp } from "@firebase/app";
import { Capacitor } from "@capacitor/core";
import {
    Auth,
    browserLocalPersistence,
    getAuth,
    indexedDBLocalPersistence,
    initializeAuth,
    setPersistence,
    User as WebUser,
} from 'firebase/auth';
import PreferencesService from "./services/preferences.service";
import { AuthContextProps } from "./models/authContextProps";

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
    firebaseConfig: {
        apiKey: string;
        authDomain: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId: string;
        measurementId: string;
        recaptchaSiteKey: string;
    };
}

const isNative = Capacitor.isNativePlatform();

const getFirebaseAuth = async (firebaseConfigParams: AuthProviderProps['firebaseConfig']): Promise<Auth> => {
    let app: FirebaseApp;

    // Check if the Firebase app is already initialized
    if (!getApps().length) {
        app = initializeApp(firebaseConfigParams);
    } else {
        app = getApp();
    }


    if (isNative) {
        return initializeAuth(app, {
            persistence: indexedDBLocalPersistence,
        });
    } else {
        const auth = getAuth(app);
        await setPersistence(auth, browserLocalPersistence);
        return auth;
    }
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, firebaseConfig }) => {
    const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);
    const [auth, setAuth] = useState<Auth | null>(null);
    const [user, setUser] = useState<NativeUser | WebUser | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const initializeFirebaseAuth = async () => {
            console.log("Firebase config: ", firebaseConfig);
            const authInstance = await getFirebaseAuth(firebaseConfig);
            setAuth(authInstance);
            setFirebaseApp(authInstance.app);

            const removeListeners = await addListeners(authInstance);

            return () => {
                removeListeners();
            };
        };

        if (firebaseConfig.apiKey) {
            initializeFirebaseAuth();
        }
    }, [firebaseConfig]);

    const addListeners = async (authInstance: Auth) => {
        let isNativeAuthComplete = !isNative;
        let isWebAuthComplete = false;
        let lastNativeUser: any = null;  // Track the last known native user state

        const updateLoadingState = () => {
            setIsLoading(!(isNativeAuthComplete && isWebAuthComplete));
        };

        const updateUserState = async (user: any, source: 'web' | 'native') => {
            console.log(`${source} auth state changed`, user);

            // If we're on native and this is a web null state, but we have a native user,
            // don't update the user state
            if (isNative && source === 'web' && !user && lastNativeUser) {
                console.log('Ignoring web null state due to active native session');
                return;
            }

            if (user) {
                setUser(user);
                setIsAuthenticated(true);
                await PreferencesService.setUserUid(user.uid);
            } else {
                // Only clear user if both web and native (if applicable) report as signed out
                if (!isNative || (source === 'native' && !user)) {
                    setUser(undefined);
                    setIsAuthenticated(false);
                    await PreferencesService.removeUserUid();
                }
            }
        };

        const removeWebListener = authInstance.onAuthStateChanged(async (user) => {
            await updateUserState(user, 'web');
            isWebAuthComplete = true;
            updateLoadingState();
        });

        let removeNativeListener: (() => void) | null = null;

        if (isNative) {
            const authStateChangeNative = await FirebaseAuthentication.addListener(
                "authStateChange",
                async ({ user }) => {
                    lastNativeUser = user;  // Update the last known native user state
                    await updateUserState(user, 'native');
                    isNativeAuthComplete = true;
                    updateLoadingState();
                }
            );

            if (authStateChangeNative && typeof authStateChangeNative.remove === 'function') {
                removeNativeListener = () => authStateChangeNative.remove();
            }
        }

        return () => {
            removeWebListener();
            if (removeNativeListener) {
                removeNativeListener();
            }
        };
    };

    // update isLoading
    const finalizeIsLoading = () => {
        setIsLoading(false);
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                isLoading,
                isAuthenticated,
                firebaseApp,
                auth,
                finalizeIsLoading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
import { useState, useEffect, useCallback } from 'react';
import PushNotificationsService from '../services/pushNotifications.service';
import PreferencesService from '../services/preferences.service';
import UserService from '../services/user.service';
import { useAuth } from '../AuthProvider';
import { useAppState } from '../AppListenerProvider';
import { useContext } from 'react';
import { AppContext } from '../AppStateProvider';

export const usePushNotifications = () => {
    const [permissionStatus, setPermissionStatus] = useState<'prompt' | 'granted' | 'denied' | 'prompt-with-rationale'>('prompt');
    const { user, isAuthenticated } = useAuth();
    const { isNative, isActive } = useAppState();
    const { dispatch } = useContext(AppContext);

    const initNotificationsHelper = async (token: string) => {
        await PushNotificationsService.addTokenReceivedListener(fcmTokenReceived);
        await PushNotificationsService.addNotificationReceivedListener(notificationReceived);
        await PushNotificationsService.addNotificationActionPerformedListener(notificationActionPerformed);
        if (isAuthenticated) {
            console.log("FCM TOKEN RECEIVED AND USER IS AUTHENTICATED");
            const res = await UserService.updateUserPushNotifications(user?.uid, token);
            if (res) {
                await UserService.setUser(true, dispatch);
                console.log("SET THE USER WITH THE NEW FCN TOKEN");
            }
        }
    }

    const fcmTokenReceived = async (event: any) => {
        console.log("FCM TOKEN RECEIVED");
        await PreferencesService.setPushNotificationToken(event.token);
        if (isAuthenticated) {
            console.log("FCM TOKEN RECEIVED AND USER IS AUTHENTICATED");
            const res = await UserService.updateUserPushNotifications(user?.uid, event.token);
            if (res) {
                await UserService.setUser(true, dispatch);
                console.log("SET THE USER WITH THE NEW FCM TOKEN");
            }
        }
    };

    const notificationReceived = (event: any) => {
        console.log("NOTIFICATION RECEIVED", event);
    };

    const notificationActionPerformed = async (event: any) => {
        console.log("NOTIFICATION ACTION PERFORMED", event);
        if (event.actionId === "tap") {
            await PushNotificationsService.removeAllDeliveredNotifications();
            if (event.notification?.data?.redirect_url) {
                if (event.notification?.data?.redirect_url !== "") {
                    dispatch({ type: "setPushNotificationRedirectUrl", payload: event.notification?.data?.redirect_url })
                }
            }
        }
    };

    const upsertNotificationsInDb = async () => {
        const token = await PushNotificationsService.getToken();
        const tokenInStorage = await PreferencesService.getPushNotificationToken();
        return tokenInStorage !== token;
    }

    const initNotifications = async () => {
        const token = await PushNotificationsService.getToken();
        await PreferencesService.setPushNotificationToken(token);
        await PushNotificationsService.removeAllListeners();
        await initNotificationsHelper(token);
    }

    const requestPushNotificationPermission = async () => {
        if (isNative) {
            console.log("REQUESTING PUSH NOTIFICATION PERMISSION");
            const requestedPerms = await PushNotificationsService.requestPermissions();
            setPermissionStatus(requestedPerms);
            if (requestedPerms === "granted") {
                await initNotifications();
            }
        }
    };

    const checkAndUpdatePushNotifications = useCallback(async () => {
        if (isNative && isActive) {
            console.log("Checking push notifications");
            const permissions = await PushNotificationsService.checkPermissions();
            setPermissionStatus(permissions);

            if (permissions === "granted") {
                const shouldUpdateToken = await upsertNotificationsInDb();
                console.log("Should update push notification token:", shouldUpdateToken);

                if (shouldUpdateToken) {
                    console.log("Updating push notification token");
                    await initNotifications();
                }
            }
        }
    }, [isNative, isActive, isAuthenticated, user]);

    useEffect(() => {
        checkAndUpdatePushNotifications();
    }, [checkAndUpdatePushNotifications]);

    return {
        permissionStatus,
        requestPushNotificationPermission,
        checkPushNotificationPermission: checkAndUpdatePushNotifications,
    };
};

import { IonButton } from "@ionic/react";
import { mapOutline } from "ionicons/icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { LocationTrick } from "../../../models/locationTrick";
import LocationService from "../../../services/location.service";
import IDXButtonIcon from "../IDXButtonIcon/IDXButtonIcon";

interface ContainerProps {
    trick_id: string;
}

const TrickMapLocation: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [trickLocation, setTrickLocation] = React.useState<LocationTrick>(undefined);

    const history = useHistory();

    useEffect(() => {
        if (props.trick_id != "" && props.trick_id != undefined) {
            getTrickLocation(props.trick_id);
        }
    }, [props.trick_id]);

    const getTrickLocation = async (trick_id: string) => {
        setTrickLocation(undefined);
        const response: LocationTrick = await LocationService.getTrickLocation(trick_id);
        if (response) {
            if (Object.keys(response).length !== 0) {
                if ((response.google_lat || response.lat) && (response.google_long || response.long) && response.name && response.name.trim() !== "") {
                    setTrickLocation(response);
                }
            }
        }
    }

    const onTrickMapClick = (trickLocation: LocationTrick) => {
        let lat = trickLocation.lat;
        let long = trickLocation.long;

        if (lat == undefined || long === undefined) {
            lat = trickLocation.google_lat;
            long = trickLocation.google_long;
        }

        history.push('/map' + '?lat=' + lat + '&long=' + long);
    }

    return (
        <>
            {
                trickLocation ?
                    <IonButton fill="clear" size="small" color="dark" onClick={() => onTrickMapClick(trickLocation)}>
                        <IDXButtonIcon size="text-2xl" icon={mapOutline} />
                    </IonButton> : null
            }
        </>
    );
};

export default TrickMapLocation;

import { Capacitor } from "@capacitor/core";
import { Brand } from "../models/brand";
import { User } from "../models/user";
import { UserTrick } from "../models/user-trick";
import brandService from "./brand.service";
import userService from "./user.service";
import { UserTrickSearchMetadata } from "../models/userTrickSearchMetadata";
import { CommentView } from "../models/commentView";
import { Geolocation } from "@capacitor/geolocation";

export function isNativeUtils(): boolean {
    return Capacitor.isNativePlatform();
}

export function formEventTricks(eventTricks: string[][]) {
    const eventTrickTagsStrArray = [];
    for (let i = 0; i < eventTricks.length; i++) {
        if (eventTricks[i].length > 0) {
            const eventTagsJoined = eventTricks[i].join(',');
            eventTrickTagsStrArray.push(eventTagsJoined);
        }
    }
    return eventTrickTagsStrArray
}

export async function base64FromPath(url: string): Promise<string> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            if (typeof reader.result === "string") {
                resolve(reader.result);
            } else {
                reject("method did not return a string");
            }
        };
        reader.readAsDataURL(blob);
    });
}

export async function base64FromBlob(blob: any): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            if (typeof reader.result === "string") {
                resolve(reader.result);
            } else {
                reject("method did not return a string");
            }
        };
        reader.readAsDataURL(blob);
    });
}

const slangTerms = [
    "Dude",
    "Buddy",
    "Homie",
    "Amigo",
    "Partner",
    "Pal",
    "Mac",
    "Chief",
    "Playa",
    "G",
    "Fam",
    "Folks",
    "Peeps",
    "Squad",
    "Yungblood",
    "Hitta"
]

const validCharactersRegex = /^[A-Za-z0-9\-_.@$]+$/;

const MIN_NAME_LENGTH = 2;
const MAX_NAME_LENGTH = 50;

export function getSlangTerm(): string {
    const randomIndex = Math.floor(Math.random() * slangTerms.length);
    // Return the slang term at the random index
    return slangTerms[randomIndex].toLowerCase();
}

export function getDateNowUTCISO(): string {
    const date_now = new Date();

    return new Date(Date.UTC(date_now.getUTCFullYear(), date_now.getUTCMonth(),
        date_now.getUTCDate(), date_now.getUTCHours(),
        date_now.getUTCMinutes(), date_now.getUTCSeconds())).toISOString();
}

export function handleTikTokLink(handle: string): string {
    handle = handle.trim().replace(/[^a-zA-Z0-9._]/g, "");
    handle = handle.toLowerCase();
    if (handle.startsWith("https://tiktok.com/")) {
        handle = handle.slice(18);
    } else if (handle.startsWith("http://tiktok.com/")) {
        handle = handle.slice(16);
    } else if (handle.startsWith("www.tiktok.com/")) {
        handle = handle.slice(14);
    } else if (handle.startsWith("@")) {
        handle = handle.slice(1);
    }
    const match = handle.match(/(?:@|https:\/\/tiktok.com\/)([A-Za-z0-9._]+)/);
    if (match) {
        handle = match[1];
    }
    return `https://tiktok.com/@${handle}`;
}

export function handleTwitterLink(handle: string): string {
    handle = handle.trim().replace(/[^a-zA-Z0-9._]/g, "");
    handle = handle.toLowerCase();
    if (handle.startsWith("https://twitter.com/")) {
        handle = handle.slice(20);
    } else if (handle.startsWith("http://twitter.com/")) {
        handle = handle.slice(18);
    } else if (handle.startsWith("www.twitter.com/")) {
        handle = handle.slice(16);
    } else if (handle.startsWith("@")) {
        handle = handle.slice(1);
    }
    const match = handle.match(/(?:@|https:\/\/twitter.com\/)([A-Za-z0-9._]+)/);
    if (match) {
        handle = match[1];
    }
    return `https://twitter.com/${handle}`;
}

export function handleInstagramLink(handle: string): string {
    handle = handle.trim().replace(/[^a-zA-Z0-9._]/g, "");
    handle = handle.toLowerCase();
    if (handle.startsWith("https://instagram.com/")) {
        handle = handle.slice(22);
    } else if (handle.startsWith("http://instagram.com/")) {
        handle = handle.slice(20);
    } else if (handle.startsWith("www.instagram.com/")) {
        handle = handle.slice(18);
    } else if (handle.startsWith("@")) {
        handle = handle.slice(1);
    }
    const match = handle.match(/(?:@|https:\/\/instagram.com\/)([A-Za-z0-9._]+)/);
    if (match) {
        handle = match[1];
    }
    return `https://instagram.com/${handle}`;
}

export function handleYoutubeLink(handle: string): string {
    handle = handle.trim().replace(/[^a-zA-Z0-9._]/g, "");
    handle = handle.toLowerCase();
    if (handle.startsWith("https://youtube.com/")) {
        handle = handle.slice(19);
    } else if (handle.startsWith("http://youtube.com/")) {
        handle = handle.slice(17);
    } else if (handle.startsWith("www.youtube.com/")) {
        handle = handle.slice(15);
    } else if (handle.startsWith("@")) {
        handle = handle.slice(1);
    } else if (handle.startsWith("youtube.com/")) {
        handle = handle.slice(12);
    }

    const match = handle.match(/(?:@|https:\/\/youtube.com\/)([A-Za-z0-9._]+)/);
    if (match) {
        handle = match[1];
    }
    return `https://youtube.com/@${handle}`;
}

export function handleRouteDirectionLink(path: string, tab: string): string {
    console.log("Path: " + path + " Tab: " + tab)
    if (isNativeUtils() && tab !== undefined && tab !== null && tab !== "") {
        return `/${tab}/${path}`;
    } else {
        return `/${path}`;
    }
}

export function handleTagClickUtil(tag: string): string {
    if (tag.includes('/')) {
        tag = tag.replace('/', '+');
    }

    if (tag.includes('#')) {
        tag = tag.replace('#', '');
    }
    return tag
}

export function splitString(stringToSplit: string, separator: any) {
    return stringToSplit.split(separator);
}

// We are doing preferences like this instead of sorting the array because of different types of clips + also the fact that crews can sort their bag too
export function arrangeTricksByPreferences(userTricksFromDb: UserTrick[], trickPreferences: Map<string, number>): UserTrick[] {
    const sortedTricks: UserTrick[] = [];
    const unsortedTricks: UserTrick[] = [];

    // Iterate over the tricks array
    for (const trick of userTricksFromDb) {
        const preference = trickPreferences.get(trick.trick_id);
        if (preference !== undefined) {
            // Trick found in preferences, add it to the sortedTricks array at the specified position
            sortedTricks[preference] = trick;
        } else {
            // Trick not found in preferences, add it to the unsortedTricks array
            unsortedTricks.push(trick);
        }
    }

    // Combine the sorted and unsorted tricks arrays, placing the unsorted tricks at the front
    return [...unsortedTricks, ...sortedTricks.filter((trick) => trick !== undefined)];
}

export function analyzeUserTrickResForUnlisted(userTricksRes: UserTrick[], editProfileRes: boolean): UserTrick[] {
    const tricksToDisplay: UserTrick[] = [];
    for (const userTrick of userTricksRes) {
        if (userTrick.tags.includes(UNLISTED_CONST) && !editProfileRes) {
            continue;
        }
        tricksToDisplay.push(userTrick);
    }

    return tricksToDisplay;
}

export function arraysAreEqual(arr1: string[], arr2: string[]): boolean {
    return (
        arr1.length === arr2.length &&
        arr1.every((value, index) => value === arr2[index])
    );
}

export async function handleSetUsername(newUsername: string, oldUsername: string): Promise<boolean> {
    console.log(newUsername);
    console.log(oldUsername);

    if (!validCharactersRegex.test(newUsername)) {
        return true;
    }

    const user: User = await userService.getUserByUsername(newUsername);
    const brand: Brand = await brandService.getBrandByUsername(newUsername);
    // if username is not unique and new username is not the user's current username, set invalid state and return
    if ((user.id || brand.id) && newUsername.toLowerCase() !== oldUsername.toLowerCase()) {
        console.log("Can't be it");
        return true;
    }

    return false;
}

export function validateBasics(value: string): { isValid: boolean; error: string | null } {
    const newValue = value.trim();
    if (newValue.length === 0) {
        return { isValid: false, error: "please enter a username :)" };
    } else if (newValue.length > 30) {
        return { isValid: false, error: "please enter a shorter username :)" };
    } else if (!validCharactersRegex.test(newValue)) {
        return {
            isValid: false,
            error: "Uh oh! Your Ecliptic username can only contain letters, numbers, and the following special characters: - _ . @ $. spaces are not allowed."
        };
    } else {
        return { isValid: true, error: null };
    }
}

export async function handleIfUsernameIsTaken(username: string): Promise<any> {
    const user: User = await userService.getUserByUsername(username);
    const brand: Brand = await brandService.getBrandByUsername(username);
    // if username is not unique and new username is not the user's current username, set invalid state and return
    if ((user.id || brand.id)) {
        console.log("Can't be it");
        return { isValid: false, error: "username taken :(, pick a different one" };
    }

    return { isValid: true, error: null };
}

export function handleCheckForEmptyUsername(username: string): boolean {
    return username === '' || username === "" || username == null;
}

export function handleCheckForLongUsername(username: string): boolean {
    return username.trim().length > 30;
}

/**
 * Filters out objects whose id is included in the array of strings.
 * @param objects Array of objects with an id attribute.
 * @param ids Array of string ids to be excluded.
 * @returns Filtered array of objects.
 */
export function filterTricksForBlockedIds(objects: UserTrickSearchMetadata[], ids: string[], blocking_ids: string[]): UserTrickSearchMetadata[] {
    // Create a Set from ids for efficient lookup
    const idSet = new Set(ids);
    const blockingIdSet = new Set(blocking_ids);

    // Filter the array to exclude objects whose id is in the ids array
    return objects.filter(object => !idSet.has(object.user_id) && !blockingIdSet.has(object.user_id));
}

export function filterTrickCommentsForBlockedIds(objects: CommentView[], ids: string[], blocking_ids: string[]): CommentView[] {
    // Create a Set from ids for efficient lookup
    const idSet = new Set(ids);
    const blockingIdSet = new Set(blocking_ids);

    // Filter the array to exclude objects whose id is in the ids array
    return objects.filter(object => !idSet.has(object.action_user_id) && !blockingIdSet.has(object.action_user_id));
}

export function isUUID(input: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(input);
}

export function validateName(name: string): { isValid: boolean; error: string | null } {
    const trimmedName = name.trim();
    if (trimmedName.length < MIN_NAME_LENGTH) {
        return { isValid: false, error: `Name must be at least ${MIN_NAME_LENGTH} characters long` };
    }
    if (trimmedName.length > MAX_NAME_LENGTH) {
        return { isValid: false, error: `Name cannot exceed ${MAX_NAME_LENGTH} characters` };
    }
    if (!/^[a-zA-Z\s-']+$/.test(trimmedName)) {
        return { isValid: false, error: "Please enter a valid name" };
    }
    return { isValid: true, error: null };
}

export async function requestLocationPermission(): Promise<boolean> {
    try {
        const permissionStatus = await Geolocation.checkPermissions();

        if (permissionStatus.location === 'granted') {
            // Permission already granted
            return true;
        }

        if (permissionStatus.location === 'denied') {
            const deviceInfo = await Capacitor.getPlatform();
            if (deviceInfo === 'ios') {
                return false;
            }
        }

        // For Android or if not yet asked on iOS
        const requestResult = await Geolocation.requestPermissions();
        return requestResult.location === 'granted';
    } catch (error) {
        console.error('Error requesting location permission:', error);
        return false;
    }
}

export function decimalToAspectRatio(decimal: number): string {
    // List of common aspect ratios (both landscape and portrait)
    const commonRatios: [number, string][] = [
        [16 / 9, "16/9"], [9 / 16, "9/16"],   // 16:9 and 9:16
        [4 / 3, "4/3"], [3 / 4, "3/4"],     // 4:3 and 3:4
        [21 / 9, "21/9"], [9 / 21, "9/21"],   // 21:9 and 9:21 (Ultrawide)
        [1, "1/1"],                        // Square
        [3 / 2, "3/2"], [2 / 3, "2/3"],     // 3:2 and 2:3
        [5 / 4, "5/4"], [4 / 5, "4/5"],     // 5:4 and 4:5
        [16 / 10, "16/10"], [10 / 16, "10/16"], // 16:10 and 10:16
        [17 / 9, "17/9"], [9 / 17, "9/17"],   // 17:9 and 9:17 (iPhone)
        [18 / 9, "18/9"], [9 / 18, "9/18"],   // 18:9 and 9:18 (Modern smartphones)
        [19.5 / 9, "39/18"], [9 / 19.5, "18/39"],// 19.5:9 and 9:19.5 (iPhone X and newer)
        [2.35 / 1, "47/20"], [1 / 2.35, "20/47"],// 2.35:1 and 1:2.35 (Anamorphic widescreen)
        [2.39 / 1, "239/100"], [1 / 2.39, "100/239"], // 2.39:1 and 1:2.39 (Widescreen cinema)
        [1.85 / 1, "37/20"], [1 / 1.85, "20/37"]  // 1.85:1 and 1:1.85 (Widescreen cinema)
    ];

    // Check for common ratios
    for (const [ratio, fraction] of commonRatios) {
        if (Math.abs(decimal - ratio) < 0.01) {
            return fraction;
        }
    }

    // If not a common ratio, convert to fraction
    const precision = 1000;
    let gcd = (a: number, b: number): number => b ? gcd(b, a % b) : a;
    let numerator = Math.round(decimal * precision);
    let denominator = precision;
    let divisor = gcd(numerator, denominator);

    numerator = Math.round(numerator / divisor);
    denominator = Math.round(denominator / divisor);

    return `${numerator}/${denominator}`;
}

export function patchCordovaJs(): void {
    const cordovaDocumentAddEventListener = document.addEventListener;
    const cordovaDocumentRemoveEventListener = document.removeEventListener;
    const cordovaWindowAddEventListener = window.addEventListener;
    const cordovaWindowRemoveEventListener = window.removeEventListener;

    document.addEventListener = function (evt: any, handler: any, capture: any) {
        if (!evt) return;

        cordovaDocumentAddEventListener.call(document, evt, handler, capture);
    };

    window.addEventListener = function (evt: any, handler: any, capture: any) {
        if (!evt) return;
        cordovaWindowAddEventListener.call(window, evt, handler, capture);
    };

    document.removeEventListener = function (evt: any, handler: any, capture: any) {
        if (!evt) return;
        cordovaDocumentRemoveEventListener.call(document, evt, handler, capture);
    };

    window.removeEventListener = function (evt: any, handler: any, capture: any) {
        if (!evt) return;
        cordovaWindowRemoveEventListener.call(window, evt, handler, capture);
    };
}

export function formatAmount(amount: number): string {
    if (amount < 1000) {
        return amount.toString();
    }
    const units = ['k', 'M', 'B', 'T'];
    const unit = Math.floor((amount.toFixed(0).length - 1) / 3);
    let num = (amount / Math.pow(1000, unit)).toFixed(1);

    // Remove trailing .0 if present
    num = num.replace(/\.0$/, '');

    // Ensure we have at most 3 characters (excluding the unit)
    if (num.length > 3) {
        num = num.slice(0, 3);
        if (num.endsWith('.')) {
            num = num.slice(0, 2);
        }
    }

    return num + units[unit - 1];
};

// Helper function to process terrain data
function processTerrainData(sports: string[] | undefined, terrain: any | null | undefined): string[] {
    if (!terrain || !sports || !Array.isArray(sports)) {
        return [];
    }

    const processedTerrain = sports.reduce<string[]>((acc, sport) => {
        if (terrain[sport] && Array.isArray(terrain[sport])) {
            acc.push(...terrain[sport]);
        }
        return acc;
    }, []);

    return [...new Set(processedTerrain)]; // Remove duplicates
}

// Helper function to process location data
function processLocationData(location: any | null | undefined): any {
    if (!location) {
        return { location_id: null, location_name: null };
    }

    return {
        location_id: location.location_id || null,
        location_name: location.location_name || null
    };
}

// Main processing function
export function processUserData(signUpData: any): any {
    const {
        name = null,
        username = null,
        birthday = null,
        sports = [],
        level = null,
        terrain = null,
        location = null
    } = signUpData;

    const { location_id, location_name } = processLocationData(location);
    const uniqueTerrain = processTerrainData(sports, terrain);

    return {
        name,
        username,
        birthday,
        sports,
        level,
        uniqueTerrain,
        location_id,
        location_name
    };
}

export const ALL_CLIPS = "All";
export const trickVideosDirectory = 'trick-videos/';
export const crewProfilePicDirectory = 'crew-profile-pic/';
export const personProfilePicDirectory = 'user-profile-pic/';
export const userUploadsDirectory = 'user-uploads/';
export const trickThumbsDirectory = 'user-trick-thumbs/';
export const UNLISTED_CONST = 'Unlisted';
export const FOLLOWERS_ONLY = 'Followers Only';
export const AUTH0 = 'auth0'
export const fileDirectories = [trickVideosDirectory, crewProfilePicDirectory, personProfilePicDirectory, userUploadsDirectory, trickThumbsDirectory]

export const protectedRoutes = ["/event", "/crews", "/profile", "/trick", "/clip", "/map", "/search", "/shop"];

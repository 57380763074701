import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonIcon, IonInput } from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { isEmail } from 'validator';
import '../Welcome.css'
import { useAppState } from "../../../AppListenerProvider";
import { useAuth } from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import OnboardingPageWrapper from '../OnboardingPageWrapper/OnboardingPageWrapper';
import { processUserData } from '../../../services/utils';
import UserService from '../../../services/user.service';
import { useSignUp } from '../SignUpProvider/SignUpProvider';
import { useHistory } from 'react-router';
import { OnboardingStatus } from '../../../models/onboardingStatus';

interface WelcomeSignUpEmailProps {
    handleSignUpGoBack: () => void;
    handleSignUpLogin: () => void;
    showBackButton: boolean;
    showSkipButton: boolean;
    title: string;
}

const WelcomeSignUpEmail: React.FC<WelcomeSignUpEmailProps> = ({ handleSignUpGoBack, handleSignUpLogin, showBackButton, showSkipButton, title }) => {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [displayNukeButton, setDisplayNukeButton] = useState(false);
    const [authing, setAuthing] = useState(false);

    const { signUpData, setStep } = useSignUp();
    const { isActive, isNative, platform } = useAppState();
    const { user, isLoading, isAuthenticated, firebaseApp, auth } = useAuth();
    const authService = AuthService({ user, isLoading, isAuthenticated, firebaseApp, auth }, {
        isActive,
        isNative,
        platform
    });

    const userInput = useRef<HTMLIonInputElement>(null);
    const passwordInput = useRef<HTMLIonInputElement>(null);
    const confirmPasswordInput = useRef<HTMLIonInputElement>(null);

    const handleGoBackToHome = () => {
        history.push('/');
        setStep(OnboardingStatus.INIT);
    }

    const signUpWithEmailPasswordHandler = async (email: string, password: string) => {
        try {
            setAuthing(true);
            const res = await authService.linkWithEmailPassword(email, password);
            if (res?.isAuthenticated) {
                // post to user service with email and user_id
                const user_id = await UserService.createUserOnboardingEmail(res?.user.uid, res?.user.email, signUpData.name, signUpData.username);
                // Update User Information
                /*
                birthday - YYYY-MM-DD
                name - string
                username - string
                sports ? sports - string[] : []
                location ? location.location_id - string
                location ? location.name - string
                level ? level: number
                terrain ? terrain: string[]
                */
                /*
                TODO: Update Referral Code on SIGNUP
                */
                const processed = processUserData(signUpData);

                await UserService.upsertUserProfileInformationOnboarding(
                    user_id,
                    processed.name,
                    processed.username,
                    processed.birthday,
                    processed.sports,
                    processed.level,
                    processed.uniqueTerrain,
                    processed.location_id,
                    processed.location_name
                );
                setAuthing(false);
                handleSignUpLogin();
            }
        } catch (error) {
            console.error("Error signing in with Email: ", error);
            setDisplayNukeButton(true);
            setAuthing(false);
            setError("We were unable to sign you up. Please try again or text us at 1-855-MAX-BISK (1-855-629-2475), reach out on IG @ecliptic.day, or email mia@idx.style if you think this was an error and we will get this sorted ASAP!");
        }
    }

    const handleLogin = async () => {
        // remove focus from input fields
        userInput.current?.blur();
        passwordInput.current?.blur();
        confirmPasswordInput.current?.blur();

        if (!email) {
            setError('Email is required');
            return;
        }
        if (!password) {
            setError('Password is required');
            return;
        }

        if (!confirmPassword) {
            setError('Confirm Password is required');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (isEmail(email)) {
            console.log('Signing up with email:', email);
            await signUpWithEmailPasswordHandler(email, password);
        } else {
            setError('Email is invalid');
        }
    };

    useEffect(() => {
        console.log("Loading Login In Flow Page");
        setError(null);
        setEmail('');
        setPassword('');
        setShowPassword(false);
        setConfirmPassword('');
        setShowConfirmPassword(false);
    }, []);

    const handleSetEmailOrUsername = (email: string) => {
        if (error) setError(null);
        setEmail(email);
    }

    const handleSetPassword = (password: string) => {
        if (error) setError(null);
        setPassword(password);
    }

    const handleSetConfirmPassword = (password: string) => {
        if (error) setError(null);
        setConfirmPassword(password);
    }

    return (
        <OnboardingPageWrapper
            title={title}
            showBackButton={showBackButton}
            showSkipButton={showSkipButton}
            onBack={handleSignUpGoBack}
        >
            <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                <div className="w-full relative">
                    <IonInput
                        ref={userInput}
                        type="text"
                        color="light"
                        placeholder="Email"
                        value={email}
                        onIonInput={e => handleSetEmailOrUsername(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                    />
                </div>
                <div className="w-full relative">
                    <IonInput
                        ref={passwordInput}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        color="light"
                        value={password}
                        onIonInput={e => handleSetPassword(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                    />
                    <IonIcon
                        icon={showPassword ? eyeOutline : eyeOffOutline}
                        className="absolute right-3 top-3.5 cursor-pointer z-50 text-primary-secondary"
                        onClick={() => setShowPassword(!showPassword)}
                    />
                </div>
                <div className="w-full relative">
                    <IonInput
                        ref={confirmPasswordInput}
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        color="light"
                        value={confirmPassword}
                        onIonInput={e => handleSetConfirmPassword(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                    />
                    <IonIcon
                        icon={showConfirmPassword ? eyeOutline : eyeOffOutline}
                        className="absolute right-3 top-3.5 cursor-pointer z-50 text-primary-secondary"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    />
                </div>
                {error && <div className="text-highlight-danger font-bold text-center">{error}</div>}
                {
                    displayNukeButton &&
                    <IonButton
                        expand="block"
                        onClick={handleGoBackToHome}
                        className='neon-button w-full nomral-case font-bold'>
                        <span className="text-lg font-bold text-white">Go Back Home + Click Login w/ Google or Apple</span>
                    </IonButton>
                }
                <IonButton
                    expand="block"
                    className="neon-button-alt w-full normal-case font-bold"
                    disabled={email === "" || password === "" || confirmPassword === "" || authing}
                    onClick={handleLogin}
                >
                    <span className="text-lg normal-case font-bold">Continue to Ecliptic</span>
                </IonButton>
            </div>
        </OnboardingPageWrapper >
    );
};

export default WelcomeSignUpEmail;
import React from 'react';
import { Poster, useMediaState, type MediaPlayerInstance } from '@vidstack/react';
import { useVideoContext } from '../VideoContextManager/VideoContextManager';
import { Browser } from '@capacitor/browser';
import { IonImg } from '@ionic/react';

interface YoutubePlayerControlProps {
    src: string;
    thumbnail: string;
    isExternal: boolean;
    isNative: boolean;
}

const YoutubePlayerControl: React.FC<YoutubePlayerControlProps> = ({ src, isExternal, thumbnail, isNative }) => {
    const { playerRef } = useVideoContext();
    const bufferedEnd = useMediaState('bufferedEnd', playerRef);

    const openInBrowser = async () => {
        await Browser.open({ url: src, presentationStyle: 'popover' });
    };

    return (
        !bufferedEnd && isExternal && (
            <div
                className="absolute inset-0 w-full h-full cursor-pointer flex items-center justify-center"
                onClick={openInBrowser}
                aria-label="Youtube video"
            >
                {/* Icon container with higher z-index, using flex centering */}
                <div className="absolute z-30 flex items-center justify-center">
                    <IonImg
                        src="/assets/icon/logos--youtube-icon.svg"
                        alt="Play"
                        className="w-[100px] h-[100px]"
                    />
                </div>

                {/* Poster with lower z-index */}
                <Poster
                    className="absolute inset-0 block h-full w-full bg-black rounded-md opacity-0 transition-opacity data-[visible]:opacity-100 [&>img]:h-full [&>img]:w-full [&>img]:object-cover z-10"
                    src={thumbnail}
                />
            </div>
        )
    );
};

export default YoutubePlayerControl;
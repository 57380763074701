import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonPopover,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { addCircleOutline, informationCircleOutline, trashOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Brand } from "../../../models/brand";
import { BrandEvent } from "../../../models/brandEvent";
import { SelectOptions } from "../../../models/profileSelectOptions";
import BrandsService from "../../../services/brand.service";
import EditProfileSelect from "../../ComponentsProfile/EditProfileSelect/EditProfileSelect";
import PhotoUpload from "../../ComponentsUI/PhotoUpload/PhotoUpload";
import TagSelectOnUpload from "../../ComponentsUI/TagSelectOnUpload/TagSelectOnUpload";
import IDXButtonIcon from "../../ComponentsUI/IDXButtonIcon/IDXButtonIcon";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";

interface ContainerProps {
    brand: Brand,
    onDismiss?: (data?: string | null | undefined | number, role?: string) => void,
    brandEvent?: BrandEvent,
    revertToTheMean?: (creatingEvent: boolean, creatingPost: boolean) => void,
}

const featureOptions: SelectOptions[] = [
    {
        id: 1,
        name: 'Jump',
    },
    {
        id: 2,
        name: 'Rail/Jib',
    },
    {
        id: 3,
        name: 'Halfpipe',
    },
    {
        id: 4,
        name: 'Freeride',
    },
    {
        id: 5,
        name: 'Off Mountain',
    }
];

const sportOptions: SelectOptions[] = [
    {
        id: 1,
        name: 'Skiing',
    },
    {
        id: 2,
        name: 'Snowboarding',
    }
];

const EventUpsert: React.FC<ContainerProps> = ({ brand, brandEvent, onDismiss, revertToTheMean }) => {
    // Event Details
    const [eventName, setEventName] = useState<string>(undefined);
    const [eventLocation, setEventLocation] = useState<string>(undefined);
    const [eventStartDate, setEventStartDate] = useState<string>(new Date(new Date().toDateString()).toISOString());
    const [eventEndDate, setEventEndDate] = useState<string>(new Date(new Date().toDateString()).toISOString());
    const [eventImage, setEventImage] = useState<any>(undefined);
    const [eventTag, setEventTag] = useState<string>(undefined);

    const [eventSports, setEventSports] = useState(undefined);
    const [formEventSports, setFormEventSports] = useState([]);

    const [eventFeatures, setEventFeatures] = useState(undefined);
    const [formEventFeatures, setFormEventFeatures] = useState([]);

    const [eventDescription, setEventDescription] = useState<string>(undefined);

    const [showEventTagForm, setShowEventTagForm] = useState<boolean>(false);
    const [eventTrickTags, setEventTrickTags] = useState<any[]>([]);
    const [eventTricks, setEventTricks] = useState<string[][]>([]); // this is an array of arrays
    const [eventNumberOfTricks, setEventNumberOfTricks] = useState<number>(0); // this is an array of arrays

    const [eventCoverPhoto, setEventCoverPhoto] = useState<any>(undefined);
    const [eventId, setEventId] = useState<string>(undefined);

    const [uploadEventError, setUploadEventError] = useState(false);
    const [fieldsEventError, setFieldsEventError] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setEventName(undefined);
        setEventLocation(undefined);
        setEventStartDate(new Date(new Date().toDateString()).toISOString());
        setEventEndDate(new Date(new Date().toDateString()).toISOString());
        setEventImage(undefined);
        setEventCoverPhoto(undefined);
        setEventTag(undefined);
        setEventSports(undefined);
        setEventFeatures(undefined);
        setEventDescription(undefined);
        setShowEventTagForm(false);
        setEventTrickTags([]);
        setEventTricks([]);
        setEventNumberOfTricks(0);
        setUploadEventError(false);
        setFieldsEventError(false);

        if (brandEvent) {
            setEventName(brandEvent.name);
            setEventLocation(brandEvent.location);
            setEventStartDate(brandEvent.start_date);
            setEventEndDate(brandEvent.end_date);
            setEventCoverPhoto(brandEvent.cover_pic);
            setEventTag(brandEvent.event_tag);
            setEventSports(brandEvent.sports);
            setEventFeatures(brandEvent.features);
            setFormEventSports(brandEvent.sports);
            setFormEventFeatures(brandEvent.features);
            setEventDescription(brandEvent.description);
            setEventTrickTags(brandEvent.event_trick_tags);
            setEventTricks(brandEvent.event_tricks);
            setEventCoverPhoto(brandEvent.cover_pic);
            setEventId(brandEvent.id);
        }

    }, [brandEvent]);


    const onEventImageUpload = (selectedFile: any) => {
        console.log("Setting the selected file as the event image");
        setEventImage(selectedFile);
    }

    const onSetEventTag = (selectedTagInput: string) => {
        const hashTagPosition = selectedTagInput.indexOf('#');
        if (hashTagPosition === 0) {
            setEventTag(selectedTagInput);
        } else if (hashTagPosition === -1) {
            setEventTag(`#${selectedTagInput}`);
        } else {
            setEventTag(`#${selectedTagInput.replace('#', '')}`);
        }
    }

    // adds new item to multiselect
    const addTagEventTag = (item: any) => {
        setEventTrickTags(eventTrickTags.concat(item));
    };

    // removes item from multiselect
    const removeTagEventTag = (item: any) => {
        const filtered = eventTrickTags.filter((e: any) => e !== item);
        setEventTrickTags(filtered);
    }

    const addTagEventTrick = (item: any, index: number) => {
        eventTricks[index] = eventTricks[index].concat(item)
        setEventTricks(eventTricks);
    };

    const removeTagEventTrick = (item: any, index: number) => {
        eventTricks[index] = eventTricks[index].filter((e: any) => e !== item);
        setEventTricks(eventTricks);
    }

    const createBrandEvent = async () => {
        let data = undefined;
        if (eventImage) {
            data = new FormData();
            data.append('file', eventImage);
            // @ts-ignore
            data.append('filename', eventImage.value);
        }

        const res = await BrandsService.upsertEventForBrand(eventId, brand.id, eventName, eventLocation, eventStartDate, eventEndDate,
            data, eventCoverPhoto, eventTag, eventSports, eventFeatures, eventDescription, eventTrickTags, eventTricks);
        if (res == null) {
            console.log("upload failed!");
            setUploadEventError(true);
        } else {
            console.log("upload successful!");
            if (brandEvent) {
                onDismiss(brand.id, 'success');
            } else {
                history.replace('/crews/' + brand.username);
            }
        }
    }

    const addTrickToEventTags = () => {
        setEventNumberOfTricks(eventNumberOfTricks + 1);
        eventTricks.push([]);
    }

    const deleteTrickFromEventTags = (currIndex: number) => {
        if (currIndex == 0) {
            setEventNumberOfTricks(eventNumberOfTricks - 1);
            setEventTricks([])
        } else {
            setEventTricks(eventTricks.splice(currIndex, 1));
        }
    }


    if (showEventTagForm) {
        return (
            <IonPage>
                {brandEvent ?
                    <IDXModalHeader leftButtonText={'Cancel'} leftButtonAction={() => onDismiss(null, 'cancel')}
                        titleText={`Edit event tags for ${eventName}`}
                        rightButtonText={'Submit'}
                        rightButtonAction={() => createBrandEvent()} /> :
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>{`Create event tags for ${eventName} by ${brand.name}`}</IonTitle>
                        </IonToolbar>
                    </IonHeader>}
                <IonContent>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <div className="flex flex-nowrap text-4xl items-center justify-center mx-10">
                                {brandEvent ? `Edit event tags for ${eventName}` : `Create event tags for ${eventName} by ${brand.name}`}
                            </div>
                        </IonToolbar>
                    </IonHeader>
                    <div className="flex justify-center items-center w-full mt-8">
                        <form className="w-10/12 lg:w-8/12">
                            <div
                                className="text-2xl font-bold tracking-wide text-center">{brandEvent ? "Edit Your Event Tags" : "Create Your Event Tags"}</div>
                            <div className="text-lg font-bold tracking-wide text-center">Feel free to ignore if you want
                                this to be a general event that isn't focused on a certain style, trick, feature, or tag
                                and if you want to accept any video
                            </div>
                            <IonAccordionGroup>
                                <IonAccordion value="first">
                                    <IonItem slot="header" color="light">
                                        <IonLabel>Tag Based Event</IonLabel>
                                        <IonButton fill="outline" id="event-tag-info-trigger" color="favorite-secondary"
                                            className="ml-2" size="small">
                                            <IDXButtonIcon size="text-2xl" icon={informationCircleOutline} />
                                        </IonButton>
                                        <IonPopover trigger="event-tag-info-trigger" triggerAction="click" side="right"
                                            alignment="center">
                                            <IonContent class="ion-padding">Tag things like features, style, or flavor
                                                tricks (down-flat-down, butter, hall of meat)</IonContent>
                                        </IonPopover>
                                    </IonItem>
                                    <div className="mb-52" slot="content">
                                        <TagSelectOnUpload sport={formEventSports} feature={formEventFeatures}
                                            parentAddTag={addTagEventTag}
                                            parentRemoveTag={removeTagEventTag}
                                            currentTags={eventTrickTags} />
                                    </div>
                                </IonAccordion>
                                <IonAccordion value="second">
                                    <IonItem slot="header" color="light">
                                        <IonLabel>Trick Based Event</IonLabel>
                                        <IonButton fill="outline" id="trick-tag-info-trigger" color="favorite-secondary"
                                            className="ml-2" size="small">
                                            <IDXButtonIcon size="text-2xl" icon={informationCircleOutline} />
                                        </IonButton>
                                        <IonPopover trigger="trick-tag-info-trigger" triggerAction="click" side="right"
                                            alignment="center">
                                            <IonContent class="ion-padding">Create a trick - select the tags in the
                                                order of what you want to call the trick</IonContent>
                                        </IonPopover>
                                    </IonItem>
                                    <div className="mb-52" slot="content">
                                        {
                                            eventTricks.length > 0 ? eventTricks.map((tags, index) => {
                                                return (
                                                    <div key={index} className="flex flex-row gap-x-1 items-end">
                                                        <div className="flex-auto w-full h-full">
                                                            <TagSelectOnUpload sport={formEventSports}
                                                                feature={formEventFeatures}
                                                                parentAddTag={(item: any) => addTagEventTrick(item, index)}
                                                                parentRemoveTag={(item: any) => removeTagEventTrick(item, index)}
                                                                tagsCombinedToOne={true}
                                                                currentTags={tags}
                                                                tagsCombinedToOneTitle={`Trick ${index + 1}`} />
                                                        </div>
                                                        <div>
                                                            <IonButton onClick={() => deleteTrickFromEventTags(index)}
                                                                fill="outline" color="danger" size="small">
                                                                <IDXButtonIcon size="text-2xl" icon={trashOutline} />
                                                            </IonButton>
                                                        </div>
                                                        <div>
                                                            <IonButton onClick={() => addTrickToEventTags()}
                                                                fill="outline" color="success" size="small">
                                                                <IDXButtonIcon size="text-2xl"
                                                                    icon={addCircleOutline} />
                                                            </IonButton>
                                                        </div>
                                                    </div>
                                                )
                                            }) :
                                                <div className="flex flex-row gap-x-1 justify-center align-middle my-2">
                                                    <IonButton onClick={() => addTrickToEventTags()} fill="outline"
                                                        color="success">
                                                        Add Trick
                                                        <IonIcon slot="end" icon={addCircleOutline}></IonIcon>
                                                    </IonButton>
                                                </div>
                                        }
                                    </div>
                                </IonAccordion>
                            </IonAccordionGroup>
                            <div className="flex flex-row justify-center items-center w-full mt-8">
                                {brandEvent ?
                                    <IonButton color="danger" onClick={() => onDismiss(brand.id, 'cancel')}>
                                        Cancel
                                    </IonButton> : null
                                }
                                {uploadEventError && <p className="text-red-500 mt-2">Event upload error!</p>}
                                <IonButton color="favorite-secondary" onClick={() => setShowEventTagForm(false)}>
                                    Back
                                </IonButton>
                                <IonButton color="favorite" onClick={() => createBrandEvent()}>
                                    {brandEvent ? "Edit Event" : "Create Event"}
                                </IonButton>
                            </div>
                        </form>
                    </div>
                </IonContent>
            </IonPage>
        );
    }

    return (
        <IonPage>
            {brandEvent ?
                <IDXModalHeader leftButtonText={'Cancel'} leftButtonAction={() => onDismiss(null, 'cancel')}
                    titleText={`Edit event for ${eventName}`}
                    rightButtonText={'Submit'}
                    rightButtonAction={() => createBrandEvent()} />
                :
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{`Create event for ${brand.name}`}</IonTitle>
                    </IonToolbar>
                </IonHeader>}
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <div className="flex flex-nowrap text-4xl items-center justify-center mx-10">
                            {brandEvent ? `Edit event for ${eventName}` : `Create event for ${brand.name}`}
                        </div>
                    </IonToolbar>
                </IonHeader>
                <div className="flex justify-center items-center w-full mt-8">
                    <form className="w-10/12 lg:w-8/12">
                        <h2 className="text-2xl font-bold tracking-wide">Event Details</h2>
                        <div className="mb-6">
                            <div className="flex flex-row items-center">
                                <label className="block mb-2 text-lg font-medium">
                                    Event Name
                                </label>
                            </div>
                            <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder={'STL Showdown Entry, The Only Trick Ever, Cs Get Infinite Degrees, etc...'}
                                required
                                value={eventName}
                                onChange={(e) => {
                                    setEventName(e.target.value);
                                    // setOptions(e.target.value.split(" "));
                                }}
                            />
                        </div>
                        <div className="mb-2 text-lg font-medium">
                            Event Photo
                        </div>
                        <PhotoUpload onProfilePictureSubmit={onEventImageUpload}
                            currEventPhotoUrl={eventCoverPhoto}></PhotoUpload>
                        <div className="mb-6 mt-2">
                            <label className="block mb-2 text-lg font-medium">Location</label>
                            <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="mountain, city, area, worldwide, etc..."
                                value={eventLocation}
                                onChange={(e) => setEventLocation(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-5">
                            <label className="block mb-2 text-lg font-medium">Start Date</label>
                            <IonDatetimeButton className="justify-start" datetime="datetime-start" />
                            <IonModal keepContentsMounted={true}>
                                <IonDatetime id="datetime-start" presentation="date" color="dark" value={eventStartDate}
                                    onIonChange={(e) => setEventStartDate(e.target.value.toString())} />
                            </IonModal>
                        </div>
                        <div className="mb-5">
                            <label className="block mb-2 text-lg font-medium">End Date</label>
                            <IonDatetimeButton className="justify-start" datetime="datetime-end" />
                            <IonModal keepContentsMounted={true}>
                                <IonDatetime id="datetime-end" presentation="date" color="dark" value={eventEndDate}
                                    onIonChange={(e) => setEventEndDate(e.target.value.toString())} />
                            </IonModal>
                        </div>
                        <div className="mb-6">
                            <EditProfileSelect parentCallback={setFormEventSports} options={sportOptions}
                                values={eventSports} value="" label="Sports"
                                multiple={true}></EditProfileSelect>
                        </div>
                        <div className="mb-6">
                            <EditProfileSelect parentCallback={setFormEventFeatures} options={featureOptions}
                                values={eventFeatures} value="" label="Features"
                                multiple={true}></EditProfileSelect>
                        </div>
                        <div className="mb-6">
                            <div className="flex flex-row items-center">
                                <label className="block mb-2 text-lg font-medium">
                                    Event Description
                                </label>
                            </div>

                            <textarea
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 resize-y"
                                placeholder={'The description of the event - what is it, who is it for, prizes, how it will be judged, etc...'}
                                required
                                value={eventDescription}
                                onChange={(e) => {
                                    setEventDescription(e.target.value);
                                }}
                            />
                        </div>
                        <div className="mb-6">
                            <div className="flex flex-row items-center">
                                <label className="block mb-2 text-lg font-medium">
                                    Event Tag
                                </label>
                            </div>

                            <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder='This will be used to search for/aggregate videos from your event - optional. Eg: #NewButters'
                                required
                                value={eventTag}
                                onChange={(e) => {
                                    onSetEventTag(e.target.value);
                                    // setOptions(e.target.value.split(" "));
                                }}
                            />
                        </div>
                        <div>
                            {fieldsEventError && <p className="text-red-500 mt-2">Please fill out all fields!</p>}
                            {brandEvent ?
                                <IonButton
                                    color="danger" onClick={() => onDismiss(brand.id, 'cancel')}>
                                    Cancel
                                </IonButton> :
                                <IonButton
                                    className="mr-4" color="favorite-secondary" onClick={() => {
                                        revertToTheMean(false, false);
                                    }}>Back
                                </IonButton>
                            }
                            <IonButton className="" color="favorite" onClick={() => {
                                if (formEventSports.length > 0 && eventLocation && eventStartDate && eventEndDate && eventName
                                    && eventDescription && (eventImage || eventCoverPhoto)) {
                                    setShowEventTagForm(true);
                                    setEventSports(formEventSports);
                                    if (formEventFeatures.length === 0) {
                                        setEventFeatures(featureOptions.map((feature) => feature.name));
                                    } else {
                                        setEventFeatures(formEventFeatures);
                                    }
                                } else {
                                    setFieldsEventError(true);
                                }
                            }}>Next</IonButton>
                        </div>
                    </form>
                </div>
            </IonContent>
        </IonPage>)
};

export default EventUpsert;
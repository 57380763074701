import {IonButton, IonIcon, IonItem, IonLabel, useIonToast} from "@ionic/react";
import {clipboardOutline, shareOutline} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import {Share} from "@capacitor/share";
import {Clipboard} from "@capacitor/clipboard";
import {UserTrickSearch} from "../../../models/user-trick-search";
import {UserTrick} from "../../../models/user-trick";
import IDXButtonIcon from "../../ComponentsUI/IDXButtonIcon/IDXButtonIcon";

interface ContainerProps {
    list?: boolean;
    isCapacitor: boolean;
    trickId: string;
    trick: UserTrickSearch | UserTrick;
    username?: string;
}

const TrickShare: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [trickUrl, setTrickUrl] = useState<string>('');
    const [titleUrl, setTitleUrl] = useState<string>('');
    const [present] = useIonToast();

    useEffect(() => {
        if (props.trick?.user_id) {
            setTrickUrl(`${process.env.REACT_APP_IDX_URL}/clip/${props.trick.user_id}/${props.trickId}`);
        }
    }, [props.trick, props.trickId]);

    useEffect(() => {
        if (props.trick?.trick_name) {
            if (props.username) {
                setTitleUrl("Ecliptic // " + props.username + " // " + props.trick.trick_name + " // View Clip");
            } else {
                setTitleUrl("Ecliptic // " + props.trick.trick_name + " // View Clip");
            }
        }
    }, [props.trick, props.username]);

    const presentToast = async (message: string, duration: number, icon: string) => {
        await present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    const writeTrickUrlToClipboard = async () => {
        if (props.isCapacitor) {
            const shareOptions = {
                title: titleUrl,
                url: trickUrl,
                dialogTitle: titleUrl
            }
            await Share.share(shareOptions)
        } else {
            await Clipboard.write({
                string: trickUrl
            });
            await presentToast('Copied To Clipboard!', 1500, clipboardOutline);
        }
    };

    return (
        <>
            {
                props.list ? (
                    <IonItem button={true} detail={false} onClick={() => writeTrickUrlToClipboard()}>
                        <IonIcon aria-hidden="true" icon={shareOutline} slot="start"></IonIcon>
                        <IonLabel>Send</IonLabel>
                    </IonItem>
                ) : (
                    <>
                        <IonButton onClick={() => writeTrickUrlToClipboard()} fill="clear" size="small" color="dark">
                            <IDXButtonIcon size="text-2xl" icon={shareOutline}></IDXButtonIcon>
                        </IonButton>
                    </>
                )
            }
        </>
    );
};

export default TrickShare;
import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonCol, IonGrid, IonInput, IonRow } from '@ionic/react';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Keyboard } from '@capacitor/keyboard';

interface OTPInputProps {
    length: number;
    onComplete: (otp: string) => void;
    inputClass?: string;
    buttonClass?: string;
    signUp: boolean;
    clearError?: () => void;
    isNative: boolean;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, onComplete, inputClass, buttonClass, signUp, clearError, isNative }) => {
    const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
    const inputRefs = useRef<(HTMLIonInputElement | null)[]>([]);
    
    useEffect(() => {
        const focusFirstInput = async () => {
            try {
                setTimeout(async () => {
                    if (inputRefs.current[0]) {
                        await inputRefs.current[0]?.setFocus();
                        if (isNative) {
                            await Keyboard.show();
                        }
                    }
                }, 300);
            } catch (error) {
                console.error('Error focusing input:', error);
            }
        };

        focusFirstInput();

        return () => {
            if (isNative) {
                Keyboard.hide();
            }
        };
    }, [isNative]);

    const handleChange = async (value: string | number | null | undefined, index: number) => {
        if (value === null || value === undefined) return;

        const digit = typeof value === 'number' ? value.toString() : value;

        // Handle multi-character input (for iOS paste)
        if (digit.length > 1) {
            const digits = digit.replace(/\D/g, '').split('').slice(0, length);
            const newOtp = [...otp];
            digits.forEach((d, i) => {
                if (i < length) {
                    newOtp[i] = d;
                }
            });
            setOtp(newOtp);

            // Focus the next empty input or blur if complete
            const nextEmptyIndex = newOtp.findIndex(d => d === '');
            if (nextEmptyIndex === -1) {
                inputRefs.current[length - 1]?.blur();
            } else {
                inputRefs.current[nextEmptyIndex]?.setFocus();
            }
            return;
        }

        // Handle single character input
        if (isNaN(Number(digit))) return;

        const newOtp = [...otp];
        newOtp[index] = digit;
        setOtp(newOtp);

        if (isNative) {
            await Haptics.impact({ style: ImpactStyle.Light });
        }

        if (digit !== '') {
            if (index < length - 1) {
                inputRefs.current[index + 1]?.setFocus();
            } else {
                inputRefs.current[index]?.blur();
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.key === 'Backspace') {
            if (index > 0 && otp[index] === '') {
                const newOtp = [...otp];
                newOtp[index - 1] = '';
                setOtp(newOtp);
                inputRefs.current[index - 1]?.setFocus();
                clearError && clearError();
            } else {
                clearError && clearError();
            }
        }
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        e.preventDefault();
        clearError && clearError();

        // Get all numbers from the pasted content
        const pasteData = e.clipboardData.getData('text/plain').replace(/\D/g, '');
        const digits = pasteData.split('').slice(0, length);

        const newOtp = Array(length).fill('');
        digits.forEach((digit, i) => {
            if (i < length) {
                newOtp[i] = digit;
            }
        });

        setOtp(newOtp);

        // Focus the next empty input or blur if complete
        const nextEmptyIndex = newOtp.findIndex(d => d === '');
        if (nextEmptyIndex === -1) {
            inputRefs.current[length - 1]?.blur();
        } else {
            inputRefs.current[nextEmptyIndex]?.setFocus();
        }
    };

    const defaultInputClass = "text-center text-2xl bg-transparent rounded-lg h-10 w-10 border-2 border-electric-blue";
    const defaultButtonClass = "mt-6 neon-button";

    return (
        <div className="w-full max-w-md mx-auto">
            <IonGrid>
                <IonRow className="justify-center">
                    {otp.map((data, index) => (
                        <IonCol size="auto" key={index} className="px-1">
                            <IonInput
                                className={`${defaultInputClass} ${inputClass || ''}`}
                                type="text"
                                inputMode="numeric"
                                value={data}
                                onIonInput={(e) => handleChange(e.detail.value, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                                placeholder="0"
                                color="dark"
                                ref={(el) => (inputRefs.current[index] = el)}
                            />
                        </IonCol>
                    ))}
                </IonRow>
            </IonGrid>
            <IonButton
                expand="block"
                className={`${defaultButtonClass} ${buttonClass || ''}`}
                onClick={() => onComplete(otp.join(''))}
                disabled={otp.some((digit) => digit === '')}
            >
                Continue
            </IonButton>
        </div>
    );
};

export default OTPInput;
import React, { useEffect } from "react";
import { IonButton, IonIcon } from "@ionic/react";

interface ChipProps {
    icon: string;
    spanText: string; // Optional icon
    onClick: () => void;
    fill?: "outline" | "clear" | "solid" | "default";
    color?: string
    disabled?: boolean;
}

const IDXProfileButton: React.FC<ChipProps> = ({ icon, spanText, onClick, fill, color, disabled = false }) => {

    useEffect(() => {

    }, [icon, spanText, onClick, fill, color]);

    return (
        <IonButton fill={fill ? fill : "outline"} color={color ? color : "favorite-secondary"} size="small" disabled={disabled}
            onClick={onClick}>
            <span
                className="hidden md:inline-flex md:pt-0.5 md:pr-2 md:pl-1 text-white">{spanText}</span>
            <IonIcon color="dark" icon={icon} />
        </IonButton>
    )
};

export default IDXProfileButton;
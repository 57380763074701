import React, { useRef, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react';
import { IonButton, IonIcon, IonSpinner, IonSelectOption, IonSelect, IonRefresher, IonRefresherContent } from '@ionic/react';
import { UserTrickSearchMetadata } from '../../../models/userTrickSearchMetadata';
import LoginButton from '../../ComponentsLogin/LoginButton/LoginButton';
import HomeLoading from '../HomeLoading/HomeLoading';
import HomeTrickCardContainer from '../HomeTrickCardContainer/HomeTrickCardContainer';
import { AppContext } from '../../../AppStateProvider';
import { arrowUpOutline } from 'ionicons/icons';
import { useAuth } from '../../../AuthProvider';
import { useAppState } from '../../../AppListenerProvider';
import { UserType } from '../../../models/usertype';
import { HotType } from '../../../models/hotType';
import useHotTricks from '../../../hooks/useHotTricks';
import GenericVirtualizedVideoList, { VirtualizedListRef } from '../GenericVirtualizedVideoList/GenericVirtualizedVideoList';
import { ElementSizeService } from '../../../services/database/elementsize.service';

interface Props {
    externalTricks?: UserTrickSearchMetadata[];
    onDataFetched?: (tricks: UserTrickSearchMetadata[]) => void;
    onError?: (error: Error) => void;
    elementSizeService: ElementSizeService | null;
}

export interface ExploreHotVirtualizedListRef {
    scrollToTop: () => void;
    refreshTricks: () => void;
}

const ExploreHotVirtualizedList = forwardRef<ExploreHotVirtualizedListRef, Props>(({
    externalTricks,
    onDataFetched,
    onError,
    elementSizeService
}, ref) => {
    const { state } = React.useContext(AppContext);
    const { isAuthenticated, isLoading } = useAuth();
    const { isNative } = useAppState();

    const {
        tricks,
        isLoadingHotTricks,
        error,
        infiniteScrollDisabled,
        getMoreHotTricks,
        refreshTricks,
        handleHotChange,
        selectedHotValue,
    } = useHotTricks(state.blockedUsers, state.blockingUsers, isAuthenticated, externalTricks);

    const genericListRef = useRef<VirtualizedListRef>(null);

    useImperativeHandle(ref, () => ({
        scrollToTop: () => {
            genericListRef.current?.scrollToTop();
        },
        refreshTricks: () => {
            refreshTricks();
        }
    }));

    useEffect(() => {
        if (onDataFetched) {
            onDataFetched(tricks);
        }
    }, [tricks, onDataFetched]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error, onError]);

    const renderItem = useCallback((trick: UserTrickSearchMetadata, isScrolling: boolean, scrollSpeed: number) => (
        <HomeTrickCardContainer
            trick={trick}
            isAuthed={isAuthenticated}
            isCapacitor={isNative}
            userId={state.user?.auth_id}
            publicId={state.user?.id}
            userType={UserType.USER}
            isScrolling={isScrolling}
            scrollSpeed={scrollSpeed}
        />
    ), [isAuthenticated, isNative, state.user]);

    const renderEmptyList = useCallback(() => {
        if (isLoadingHotTricks || isLoading) {
            return <HomeLoading />;
        } else if (!isAuthenticated) {
            return (
                <div className="my-8 mx-4">
                    <LoginButton title="Watch the Hottest Clips on Ecliptic" upload={false} profile={true} />
                </div>
            );
        } else {
            return (
                <div className="h-screen flex flex-row justify-center">
                    <div className="text-xl m-4 text-center">
                        Looks like we don't have what you're looking for based on your filters set at the toolbar,
                        change them or change the game to see more! Refresh if you've uploaded too or changed your filters
                    </div>
                </div>
            );
        }
    }, [isLoadingHotTricks, isLoading, isAuthenticated]);

    const renderHeader = useCallback(() => {
        if (!isAuthenticated) {
            return null;
        }

        return (
            <div>
                <div className="items-center mx-8 justify-center">
                    <IonSelect
                        label="Hotness Level"
                        interface="popover"
                        placeholder="Select hotness level"
                        value={selectedHotValue}
                        style={{ ionBackgroundColor: "none" }}
                        onIonChange={(e) => handleHotChange(e.detail.value!)}
                    >
                        <IonSelectOption value={HotType.Day}>Daily Heat</IonSelectOption>
                        <IonSelectOption value={HotType.Week}>Weekly Zest</IonSelectOption>
                        <IonSelectOption value={HotType.Month}>Monthly Movers</IonSelectOption>
                        <IonSelectOption value={HotType.Season}>Seasonal Bangers</IonSelectOption>
                        <IonSelectOption value={HotType.Year}>Yearly Fire</IonSelectOption>
                        <IonSelectOption value={HotType.All}>All Time Bombs</IonSelectOption>
                    </IonSelect>
                </div>
            </div>
        );
    }, [isAuthenticated, selectedHotValue, handleHotChange, refreshTricks]);

    const renderFooter = useCallback(() => {
        if (isLoadingHotTricks) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonSpinner color="theme-secondary"></IonSpinner>
                </div>
            );
        } else if (infiniteScrollDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonButton
                        fill="clear"
                        color="theme-secondary"
                        size="small"
                        className="pb-4"
                        onClick={() => genericListRef.current?.scrollToTop()}
                    >
                        <span className="text-zinc-300 text-lg">
                            Back to top
                        </span>
                        <IonIcon slot="end" icon={arrowUpOutline} />
                    </IonButton>
                </div>
            );
        } else {
            return null;
        }
    }, [isLoadingHotTricks, infiniteScrollDisabled]);

    return (
        <GenericVirtualizedVideoList
            elementSizeService={elementSizeService}
            ref={genericListRef}
            items={tricks}
            isLoading={isLoadingHotTricks}
            isAuthenticated={isAuthenticated}
            infiniteScrollDisabled={infiniteScrollDisabled}
            loadMoreItems={getMoreHotTricks}
            refreshItems={refreshTricks}
            renderItem={renderItem}
            renderEmptyList={renderEmptyList}
            renderHeader={renderHeader}
            renderFooter={renderFooter}
            isNative={isNative}
        />
    );
});

export default ExploreHotVirtualizedList;
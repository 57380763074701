import React, { useRef, useCallback, useState, useEffect, useLayoutEffect, forwardRef, useImperativeHandle } from 'react';
import { useVirtualizer, Range } from '@tanstack/react-virtual';
import { IonRefresher, IonRefresherContent, IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { arrowUpOutline } from 'ionicons/icons';
import { debounce } from 'lodash';
import { UserTrickSearchMetadata } from '../../../models/userTrickSearchMetadata';
import { ElementSizeService } from '../../../services/database/elementsize.service';

interface Props<T extends UserTrickSearchMetadata> {
    items: T[];
    isLoading: boolean;
    isAuthenticated: boolean;
    infiniteScrollDisabled: boolean;
    loadMoreItems: () => void;
    refreshItems: () => Promise<void> | any;
    renderItem: (item: T, isScrolling: boolean, scrollSpeed: number) => React.ReactNode;
    renderEmptyList: () => React.ReactNode;
    renderHeader?: () => React.ReactNode;
    renderFooter?: () => React.ReactNode;
    customHeaderSlot?: React.ReactNode;
    customFooterSlot?: React.ReactNode;
    isNative: boolean;
    elementSizeService: ElementSizeService | null;
}

export interface VirtualizedListRef {
    scrollToTop: () => void;
}

const overscan = 3;
const DEFAULT_HEIGHT = 700;

function GenericVirtualizedVideoList<T extends UserTrickSearchMetadata>(
    {
        items,
        isLoading,
        isAuthenticated,
        infiniteScrollDisabled,
        loadMoreItems,
        refreshItems,
        renderItem,
        renderEmptyList,
        renderHeader,
        renderFooter,
        customHeaderSlot,
        customFooterSlot,
        isNative,
        elementSizeService
    }: Props<T>,
    ref: React.Ref<VirtualizedListRef>
) {
    const [scrollSpeed, setScrollSpeed] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const lastScrollTopRef = useRef(0);
    const lastTimestampRef = useRef(0);
    const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const itemSizeCache = useRef<Map<string, number>>(new Map());
    const parentRef = useRef<HTMLDivElement>(null);
    const pendingSaves = useRef<Set<string>>(new Set());
    const [isLoadingSizes, setIsLoadingSizes] = useState(true);
    const startYRef = useRef(0);

    // VIRTUAL HEIGHT MEASUREMENT
    useEffect(() => {
        const loadSizes = async () => {
            if (!elementSizeService) {
                setIsLoadingSizes(false);
                return;
            }

            try {
                // Then load all sizes
                const sizeMap = await elementSizeService.getAllElementSizesAsMap();
                itemSizeCache.current = new Map(Object.entries(sizeMap));
            } catch (error) {
                console.error('Failed to load item sizes:', error);
            } finally {
                setIsLoadingSizes(false);
            }
        };

        loadSizes();
    }, [elementSizeService]);

    // Debounced function to save pending sizes to database
    const savePendingSizes = useCallback(
        debounce(async () => {
            if (!elementSizeService || pendingSaves.current.size === 0) return;

            const sizesToSave = Array.from(pendingSaves.current).map(id => ({
                id,
                height: itemSizeCache.current.get(id) || 0
            })).filter(size => size.height > 0);

            try {
                await elementSizeService.bulkSaveElementSizes(sizesToSave);
                pendingSaves.current.clear();
            } catch (error) {
                console.error('Failed to save pending sizes:', error);
            }
        }, 500),
        [elementSizeService]
    );

    const updateItemSize = useCallback((id: string, height: number) => {
        itemSizeCache.current.set(id, height);
        pendingSaves.current.add(id);
        savePendingSizes();
    }, [savePendingSizes]);

    const estimateSize = useCallback((index: number) => {
        const item = items[index];
        return itemSizeCache.current.get(item.id) || DEFAULT_HEIGHT; // Default to 600px
    }, [items]);

    const rowVirtualizer = useVirtualizer({
        count: items.length,
        getScrollElement: () => parentRef.current,
        estimateSize,
        overscan,
        getItemKey: useCallback((index) => items[index].id, [items]),
    });

    useLayoutEffect(() => {
        if (isLoadingSizes) return;

        let hasChanges = false;
        const measureItems = () => {
            rowVirtualizer.getVirtualItems().forEach((virtualItem) => {
                const item = items[virtualItem.index];
                const element = itemRefs.current[item.id];
                if (element) {
                    const height = element.getBoundingClientRect().height;
                    const cachedHeight = itemSizeCache.current.get(item.id);
                    if (height !== cachedHeight) {
                        hasChanges = true;
                        updateItemSize(item.id, height);
                    }
                }
            });

            if (hasChanges) {
                rowVirtualizer.measure();
            }
        };

        const resizeObserver = new ResizeObserver(measureItems);

        measureItems();

        rowVirtualizer.getVirtualItems().forEach((virtualItem) => {
            const item = items[virtualItem.index];
            const element = itemRefs.current[item.id];
            if (element) {
                resizeObserver.observe(element);
            }
        });

        return () => {
            resizeObserver.disconnect();
            // Save any pending changes on unmount
            savePendingSizes.flush();
        };
    }, [items, rowVirtualizer, isLoadingSizes, updateItemSize, savePendingSizes]);

    // SCROLL HANDLING
    useEffect(() => {
        const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

        if (!lastItem) {
            return;
        }

        if (
            lastItem.index >= items.length - 2 &&
            !infiniteScrollDisabled &&
            !isLoading
        ) {
            loadMoreItems();
        }
    }, [
        infiniteScrollDisabled,
        loadMoreItems,
        items.length,
        isLoading,
        rowVirtualizer.getVirtualItems(),
    ]);

    const debouncedScrollHandler = useCallback(
        debounce((scrollTop: number) => {
            const now = Date.now();
            const dt = now - lastTimestampRef.current;
            if (dt > 0) {
                const speed = Math.abs(scrollTop - lastScrollTopRef.current) / dt;
                // console.log("Speed:", speed);
                setScrollSpeed(speed);
                setIsScrolling(speed > 0);
            }
            lastScrollTopRef.current = scrollTop;
            lastTimestampRef.current = now;
        }, 16),
        []
    );

    const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop } = event.currentTarget;
        debouncedScrollHandler(scrollTop);
    }, [debouncedScrollHandler]);

    const scrollToTop = useCallback(() => {
        if (parentRef.current) {
            parentRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, []);

    useImperativeHandle(ref, () => ({
        scrollToTop
    }));

    const DefaultHeaderContainer: React.FC = () => {
        const handleRefresh = async (event: CustomEvent) => {
            console.log("REFRESHING");
            await refreshItems();
            event.detail.complete();
        };

        return (
            <>
                {renderHeader && renderHeader()}
            </>
        );
    };

    const DefaultFooterContainer: React.FC = () => {
        if (isLoading) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonSpinner color="theme-secondary"></IonSpinner>
                </div>
            );
        } else if (infiniteScrollDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonButton
                        fill="clear"
                        color="theme-secondary"
                        size="small"
                        className="pb-4"
                        onClick={scrollToTop}
                    >
                        <span className="text-zinc-300 text-lg">
                            Back to top
                        </span>
                        <IonIcon slot="end" icon={arrowUpOutline} />
                    </IonButton>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div
            ref={parentRef}
            style={{ height: '100%', overflow: 'auto' }}
            onScroll={handleScroll}
        >
            {customHeaderSlot || <DefaultHeaderContainer />}
            {items.length === 0 ? (
                renderEmptyList()
            ) : (
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const item = items[virtualRow.index];
                        return (
                            <div
                                key={virtualRow.key}
                                ref={el => itemRefs.current[item.id] = el}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    transform: `translateY(${virtualRow.start}px)`,
                                }}
                            >
                                {renderItem(item, isScrolling, scrollSpeed)}
                            </div>
                        );
                    })}
                </div>
            )}
            {customFooterSlot || (renderFooter ? renderFooter() : <DefaultFooterContainer />)}
        </div>
    );
}

export default forwardRef(GenericVirtualizedVideoList);
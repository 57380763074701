// SignUpContext.tsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import PreferencesService from "../../../services/preferences.service";
import { SignUpData } from "../../../models/signUpData";

// Define the structure of your sign-up data

type OnboardedState = 'init' | 'onboarding' | 'completed';

// Define the context shape
interface SignUpContextType {
    signUpData: SignUpData;
    updateSignUpData: (updates: Partial<SignUpData>) => Promise<void>;
    step: string;
    setStep: (step: string) => Promise<void>;
    resetSignUp: () => Promise<void>;
    isLoadingOnboarding: boolean;
}

const SignUpContext = createContext<SignUpContextType | undefined>(undefined);

// Only used in the context of a sign up provider

export const SignUpProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [signUpData, setSignUpData] = useState<SignUpData>({
        userId: null,
        birthday: null,
        referral: null,
        name: null,
        username: null,
        firstName: null,
        lastName: null,
        pushNotificationToken: null,
        pushNotificationEnabled: false,
        locationEnabled: false,
        sports: [],
        sportIndex: 0,
        displaySkill: true,
        location: null,
        terrain: null,
        skills: null,
        latitude: null,
        longitude: null,
        phoneNumber: null,
        countryData: null,
        level: null,
    });
    const [step, setStepState] = useState<string>(undefined);
    const [isLoadingOnboarding, setIsLoadingOnboarding] = useState(true);

    // Load data from Preferences when component mounts
    useEffect(() => {
        const loadSavedData = async () => {
            try {
                const savedData = await PreferencesService.loadSignUpData();
                if (savedData) {
                    setSignUpData(savedData);
                }

                const savedStep = await PreferencesService.getOnboardingStatus();

                if (savedStep) {
                    setStepState(savedStep);
                }
            } catch (error) {
                console.error('Error loading saved sign-up data:', error);
            } finally {
                setIsLoadingOnboarding(false);
            }
        };

        loadSavedData();
    }, []);

    const updateSignUpData = async (updates: Partial<SignUpData>) => {
        const newData = { ...signUpData, ...updates };
        setSignUpData(newData);
        await PreferencesService.saveSignUpData(newData);
    };

    const setStep = async (newStep: string) => {
        setStepState(newStep);
        await PreferencesService.setOnboardingStatus(newStep);
    };

    const resetSignUp = async () => {
        const initialData: SignUpData = {
            userId: null,
            birthday: null,
            referral: null,
            name: null,
            username: null,
            firstName: null,
            lastName: null,
            pushNotificationToken: null,
            pushNotificationEnabled: false,
            locationEnabled: false,
            sports: [],
            sportIndex: 0,
            displaySkill: true,
            location: null,
            terrain: null,
            skills: null,
            latitude: null,
            longitude: null,
            phoneNumber: null,
            countryData: null,
            level: null,
        };
        setSignUpData(initialData);
        await PreferencesService.clearSignUpData();
    };

    return (
        <SignUpContext.Provider value={{ signUpData, updateSignUpData, step, setStep, resetSignUp, isLoadingOnboarding }}>
            {children}
        </SignUpContext.Provider>
    );
};

// Custom hook to use the SignUp context
export const useSignUp = () => {
    const context = useContext(SignUpContext);
    if (context === undefined) {
        throw new Error('useSignUp must be used within a SignUpProvider');
    }
    return context;
};
import React from 'react';
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import AuthenticationButtons from "../AuthenticationButtons/AuthenticationButtons";
import { useAppState } from "../../../AppListenerProvider";
import { useAuth } from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import WelcomeHelp from '../WelcomeHelp/WelcomeHelp';

interface WelcomeSignUpAuthProps {
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack: () => void;
    handleAuthedStep: () => void;
    handleUserPasswordAuthedStep: () => void;
    handlePhoneAuthStep?: () => void;
}

const WelcomeSignUpAuth: React.FC<WelcomeSignUpAuthProps> = ({
    showSkipButton,
    showBackButton,
    title,
    onBack,
    handleAuthedStep,
    handleUserPasswordAuthedStep,
    handlePhoneAuthStep
}) => {
    const { isActive, isNative, platform } = useAppState();
    const { user, isLoading, isAuthenticated, firebaseApp, auth } = useAuth();
    const authService = AuthService({ user, isLoading, isAuthenticated, firebaseApp, auth }, {
        isActive,
        isNative,
        platform
    });

    return (
        <OnboardingPageWrapper title={title} showBackButton={showBackButton} showSkipButton={showSkipButton}
            onBack={onBack}>
            <AuthenticationButtons
                login={true}
                authService={authService}
                platform={platform}
                handleAuthedStep={handleAuthedStep}
                handlePhoneAuthStep={handlePhoneAuthStep}
                handleUserPasswordAuthedStep={handleUserPasswordAuthedStep}
            />
            <WelcomeHelp />
        </OnboardingPageWrapper>
    );
};

export default WelcomeSignUpAuth;
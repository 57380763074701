import React from 'react';

const BoardUserBiskCardSkeleton: React.FC = () => {
    return (
        <div className="flex items-center justify-between px-4 py-3">
            <div className="flex items-center space-x-3">
                {/* Avatar skeleton */}
                <div className="w-12 h-12 rounded-full bg-gray-600 animate-pulse" />

                {/* Username skeleton */}
                <div className="h-5 w-24 bg-gray-600 rounded animate-pulse" />
            </div>

            {/* Amount/flip button skeleton */}
            <div className="flex-none w-10 h-10 rounded-full bg-gray-600 animate-pulse" />
        </div>
    );
};

export default BoardUserBiskCardSkeleton;
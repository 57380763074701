import { IonButton, IonHeader, IonIcon, IonToolbar, useIonToast } from "@ionic/react";
import { chevronBackOutline, cogOutline, notificationsOutline, searchOutline, storefrontOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "../../../AuthProvider";
import useAuthService from "../../../hooks/useAuthService";
import IDXButtonIcon from "../IDXButtonIcon/IDXButtonIcon";
import IDXLogo from "../../../assets/icons/system/ECLIPTIC_NEW_BLACK_PNG_NO_PADDING_BETA.svg";
import { AppContext } from "../../../AppStateProvider";

interface ToolbarSearchBarProfileProps {
    username?: string;
}

const ToolbarSearchBarProfile: React.FC<ToolbarSearchBarProfileProps> = ({ username }) => {
    const { state } = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();
    const { isAuthenticated } = useAuth();
    const { login } = useAuthService()
    const [present] = useIonToast();
    const [showBack, setShowBack] = useState(true);

    useEffect(() => {
        console.log("Username: ", username);
        console.log("State: ", state?.user?.username);
        if (username !== state?.user?.username) {
            console.log("Show back button");
            setShowBack(true);
        } else {
            console.log("Don't show back button");
            setShowBack(false);
        }
    }, [username, state?.user?.username]);


    const handleBack = () => {
        if (location.key) {
            console.log("Back button clicked");
            history.go(-1);
        } else {
            history.push("/home")
        }
    }

    const openSettings = () => {
        history.push("/settings");
    }
    const openSearch = () => {
        history.push("/search");
    }

    const presentToast = (message: string, duration: number, icon: string) => {
        present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    // TODO: animate placeholder for search bar when clicked on but nothing typed in

    const handleSmokeShack = () => {
        history.push("/shop");
    }

    return (
        <>
            <IonHeader className="bg-grey">
                <IonToolbar>
                    <div className="flex flex-row justify-between items-center mx-2">
                        <div className="flex order-first gap-x-4">
                            {
                                showBack &&
                                <div>
                                    <IonButton fill="clear" size="small" color="dark" onClick={() => handleBack()}>
                                        <IDXButtonIcon size="text-2xl" icon={chevronBackOutline} />
                                    </IonButton>
                                </div>
                            }
                            <div>
                                <IonButton fill="clear" size="small" color="dark" onClick={openSearch}>
                                    <IDXButtonIcon size="text-2xl" icon={searchOutline} />
                                </IonButton>
                            </div>
                            {
                                !showBack &&
                                <div>
                                    <IonButton fill="clear" size="small" color="dark" onClick={() => handleSmokeShack()}>
                                        <IDXButtonIcon size="text-2xl" icon={storefrontOutline} />
                                    </IonButton>
                                </div>
                            }
                        </div>
                        <div className="flex items-center justify-center pl-4"> {/* Adjust h-12 as needed */}
                            <IonIcon
                                icon={IDXLogo}
                                className="w-20 h-12" // This will make the icon fill the height of its container
                                onClick={() => history.push('/home')}
                            />
                        </div>
                        <div className="flex order-last gap-x-4">
                            <div className="opacity-0">
                                <IonButton fill="clear" size="small" color="dark"
                                    onClick={() => presentToast("Keeping track of alerts is almost here!", 3500, notificationsOutline)}>
                                    <IDXButtonIcon size="text-2xl" icon={notificationsOutline} />
                                </IonButton>
                            </div>
                            {
                                !showBack ?
                                    <div>
                                        <IonButton fill="clear" size="small" color="dark"
                                            onClick={() => isAuthenticated ? openSettings() : login()}>
                                            <IDXButtonIcon size="text-2xl" icon={cogOutline} />
                                        </IonButton>
                                    </div> :
                                    <div>
                                        <IonButton fill="clear" size="small" color="dark" onClick={() => handleSmokeShack()}>
                                            <IDXButtonIcon size="text-2xl" icon={storefrontOutline} />
                                        </IonButton>
                                    </div>
                            }
                        </div>
                    </div>
                </IonToolbar>
            </IonHeader>
        </>
    );
};

export default ToolbarSearchBarProfile;

import { useState, useCallback, useEffect } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Board } from '../models/board';
import BiskService from '../services/bisk.service';

export const useBoardResults = () => {
    const [boardSelected, setBoardSelected] = useState<string>('');
    const [boardPeriodSelected, setBoardPeriodSelected] = useState<string>('');
    const [boardSortSelected, setBoardSortSelected] = useState<string>('');
    const [boardFullSelected, setBoardFullSelected] = useState<Board | null>(null);
    const numberOfBoardTransactions = 10;

    const { data: boards = [] } = useQuery({
        queryKey: ['boards'],
        queryFn: BiskService.getBoards,
    });

    useEffect(() => {
        if (boards.length > 0 && !boardSelected) {
            setBoardSelected(boards[0].name);
            setBoardPeriodSelected(boards[0].periods[0]);
            setBoardSortSelected(boards[0].sort[0]);
            setBoardFullSelected(boards[0]);
        }
    }, [boards, boardSelected]);

    const fetchBoardResults = async ({ pageParam = 0 }) => {
        return BiskService.getBoardResult(boardSelected, boardPeriodSelected, boardSortSelected, numberOfBoardTransactions, pageParam);
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        error,
        refetch
    } = useInfiniteQuery({
        queryKey: ['boardResults', boardSelected, boardPeriodSelected, boardSortSelected],
        queryFn: fetchBoardResults,
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.length === numberOfBoardTransactions ? allPages.length : undefined;
        },
        enabled: !!boardSelected && !!boardPeriodSelected && !!boardSortSelected,
    });

    const boardResults = data?.pages.flat() || [];

    const handleBoardChange = useCallback((newBoard: string) => {
        const selectedBoard = boards.find((board: Board) => board.name === newBoard);
        if (selectedBoard) {
            setBoardSelected(newBoard);
            setBoardPeriodSelected(selectedBoard.periods[0]);
            setBoardSortSelected(selectedBoard.sort[0]);
            setBoardFullSelected(selectedBoard);
        }
    }, [boards]);

    const handleBoardPeriodChange = useCallback((newPeriod: string) => {
        setBoardPeriodSelected(newPeriod);
    }, []);

    const handleBoardSortChange = useCallback((newSort: string) => {
        setBoardSortSelected(newSort);
    }, []);

    const getMoreBoardResults = useCallback(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    return {
        boards,
        boardSelected,
        boardPeriodSelected,
        boardSortSelected,
        boardFullSelected,
        boardResults,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        error,
        handleBoardChange,
        handleBoardPeriodChange,
        handleBoardSortChange,
        getMoreBoardResults,
        refetch
    };
};

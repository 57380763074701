import {
    GoogleAuthProvider,
    OAuthProvider,
    PhoneAuthProvider,
    EmailAuthProvider,
    RecaptchaVerifier,
    signInWithCredential,
    signInWithCustomToken,
    signInWithPhoneNumber,
    signOut
} from "firebase/auth";
import { FirebaseAuthentication, SignInWithOAuthOptions } from "@capacitor-firebase/authentication";
import UserService from "./user.service";
import { AUTH0 } from "./utils";
import { AuthContextProps } from "../models/authContextProps";
import { AppStateListenerProps } from "../models/appStateListenerProps";
import PreferencesService from "./preferences.service";
import { is } from "date-fns/locale";

const AuthService = (authContext: AuthContextProps, appStateContext: AppStateListenerProps) => {
    const { auth, isAuthenticated, user } = authContext;
    const { isNative } = appStateContext;

    const fetchSignInMethodsForEmail = async (email: string) => {
        const result = await FirebaseAuthentication.fetchSignInMethodsForEmail({
            email: email,
        });
        return result.signInMethods;
    };

    const loginWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
        try {
            const res = await FirebaseAuthentication.signInWithEmailAndPassword({ email: email, password: password });
            const credential = EmailAuthProvider.credential(email, password);
            await signInWithCredential(auth, credential);
            return {
                isAuthenticated: true,
                user: res.user
            }
        } catch (error) {
            console.error('Error logging in with email and password:', error);
            throw error;
        }
    };

    const signUpWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
        try {
            const res = await FirebaseAuthentication.createUserWithEmailAndPassword({ email: email, password: password });
            const credential = EmailAuthProvider.credential(email, password);
            await signInWithCredential(auth, credential);
            return {
                isAuthenticated: true,
                user: res.user
            }
        } catch (error) {
            console.error(error);
            // @ts-ignore
            if (error.code === 'auth/email-already-in-use') {
                const signInMethodsForEmail = await fetchSignInMethodsForEmail(email);
                if (signInMethodsForEmail.includes('google.com')) {
                    return await signInWithGoogle();
                } else if (signInMethodsForEmail.includes('apple.com')) {
                    return await signInWithApple();
                } else {
                    return await loginWithEmailAndPassword(email, password);
                }
            } else { // @ts-ignore
                if (error.code === 'auth/invalid-email') {
                    console.error('That email address is invalid!');
                } else {
                    console.error('Error signing up with email and password');
                }
                throw error;
            }
        }
    };

    const linkWithEmailPassword = async (email: string, password: string): Promise<any> => {
        if (!user || !isAuthenticated) {
            return signUpWithEmailAndPassword(email, password);
        }

        try {
            const result = await FirebaseAuthentication.linkWithEmailAndPassword({
                email,
                password,
            });
            return {
                isAuthenticated: true,
                user: result.user
            }
        } catch (error) {
            console.error('Error linking with email/password:', error);
            // @ts-ignore
            if (error.code === 'auth/email-already-in-use') {
                throw new Error('This email is already linked to another user');
            }
            throw error;
        }
    };

    const signInWithPhoneNumberHandler = async (phoneNumber: string, recaptchaVerifier?: RecaptchaVerifier): Promise<any> => {
        if (!isNative) {
            // Web implementation remains the same
            try {
                // Initialize reCAPTCHA verifier if not provided
                if (!recaptchaVerifier) {
                    const verifier = new RecaptchaVerifier(auth, 'sign-in-button', {
                        size: 'invisible',
                        callback: () => {
                            // reCAPTCHA solved, allow signInWithPhoneNumber.
                            console.log('reCAPTCHA verified');
                        },
                        'expired-callback': () => {
                            // Response expired. Ask user to solve reCAPTCHA again.
                            console.log('reCAPTCHA expired');
                        }
                    });

                    // Render the reCAPTCHA widget
                    await verifier.render();
                    recaptchaVerifier = verifier;
                }

                const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
                return { confirmationResult };
            } catch (error) {
                console.error("Error sending SMS:", error);

                // Clear the reCAPTCHA if there's an error
                if (recaptchaVerifier) {
                    try {
                        await recaptchaVerifier.clear();
                    } catch (clearError) {
                        console.error("Error clearing reCAPTCHA:", clearError);
                    }
                }
            }
        } else {
            // Native (iOS/Android) implementation
            return new Promise(async (resolve, reject) => {
                let timeoutId: any;
                let listeners: any[] = [];

                const cleanup = () => {
                    if (timeoutId) clearTimeout(timeoutId);
                    listeners.forEach(listener => listener.remove());
                };

                try {
                    // Set up listeners first
                    const codeListener = await FirebaseAuthentication.addListener(
                        'phoneCodeSent',
                        async (event) => {
                            console.log('phoneCodeSent event received:', event);
                            cleanup();
                            resolve({ verificationId: event.verificationId, verificationStarted: true });
                        }
                    );
                    listeners.push(codeListener);

                    const completedListener = await FirebaseAuthentication.addListener(
                        'phoneVerificationCompleted',
                        async (event) => {
                            console.log('phoneVerificationCompleted event received:', event);
                            cleanup();
                            resolve({ user: event.user, verificationCompleted: true });
                        }
                    );
                    listeners.push(completedListener);

                    const failedListener = await FirebaseAuthentication.addListener(
                        'phoneVerificationFailed',
                        async (event) => {
                            console.log('phoneVerificationFailed event received:', event);
                            cleanup();
                            reject(new Error(event.message));
                        }
                    );
                    listeners.push(failedListener);

                    // Set a timeout to ensure we don't hang
                    timeoutId = setTimeout(() => {
                        cleanup();
                        reject(new Error('Phone verification timed out. Please try again, skip this step, or sign in with a different method.'));
                    }, 30000); // 30 second timeout

                    console.log('Calling signInWithPhoneNumber with:', phoneNumber);
                    const result = await FirebaseAuthentication.signInWithPhoneNumber({
                        skipNativeAuth: false,
                        phoneNumber,
                    });
                    console.log('signInWithPhoneNumber immediate result:', result);

                    // Don't resolve here - wait for the listeners

                } catch (error) {
                    console.error('Error in native phone verification:', error);
                    cleanup();
                    reject(error);
                }
            });
        }
    };

    const confirmPhoneVerificationCode = async (verificationCode: string, verificationId?: string, confirmationResult?: any): Promise<any> => {
        if (!isNative) {
            // Web implementation
            try {
                const result = await confirmationResult.confirm(verificationCode);
                const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
                await signInWithCredential(auth, cred);
                return result.user;
            } catch (error) {
                console.error('Error confirming verification code:', error);
                throw error;
            }
        } else {
            // Native implementation
            try {
                const result = await FirebaseAuthentication.confirmVerificationCode({
                    verificationId,
                    verificationCode,
                });

                return result.user;
            } catch (error) {
                console.error('Error confirming verification code:', error);
                throw error;
            }
        }
    };

    const linkPhoneNumberWithCurrentUser = async (phoneNumber: string): Promise<any> => {
        if (!user) {
            throw new Error('No user is currently signed in');
        }

        return new Promise(async (resolve, reject) => {
            try {
                // Attach phone code sent listener
                const phoneCodeSentListener = await FirebaseAuthentication.addListener(
                    'phoneCodeSent',
                    async event => {
                        resolve({ verificationId: event.verificationId, verificationStarted: true });
                    }
                );

                // Attach verification failed listener
                const phoneVerificationFailedListener = await FirebaseAuthentication.addListener(
                    'phoneVerificationFailed',
                    async event => {
                        reject(new Error(event.message));
                    }
                );

                // Start the phone number verification process
                await FirebaseAuthentication.linkWithPhoneNumber({
                    phoneNumber,
                });

                // Return cleanup function
                return () => {
                    phoneCodeSentListener.remove();
                    phoneVerificationFailedListener.remove();
                };
            } catch (error) {
                console.error("Error in linking phone number:", error);
                reject(error);
            }
        });
    };

    const signInWithGoogle = async (): Promise<any> => {
        try {
            const options: SignInWithOAuthOptions = !isNative ? { mode: 'popup' } : { skipNativeAuth: false };
            const result = await FirebaseAuthentication.signInWithGoogle(options);
            const credential = GoogleAuthProvider.credential(result.credential?.idToken);
            await signInWithCredential(auth, credential);
            return {
                isAuthenticated: true,
                user: result.user
            };
        } catch (error) {
            console.error('Error signing in with Google:', error);
            // @ts-ignore
            if (error.code === 'auth/cancelled-popup-request' || error.code === 'auth/popup-closed-by-user') {
                console.log("THROWING ERROR");
                return {
                    isAuthenticated: false,
                    user: null
                };
            } else { // @ts-ignore
                if (error.code === 'auth/popup-blocked') {
                    console.error('Popup blocked by browser');
                    console.log("THROWING ERROR");
                    throw new Error('Please enable popups in your browser to login with Google.');
                } else {
                    console.log("THROWING ERROR");
                    throw error;
                }
            }
        }
    };

    const linkWithGoogle = async (): Promise<any> => {
        if (!user || !isAuthenticated) {
            return signInWithGoogle();
        }

        try {
            const options: SignInWithOAuthOptions = !isNative ? { mode: 'popup' } : { skipNativeAuth: false };
            const result = await FirebaseAuthentication.linkWithGoogle(options);
            return {
                isAuthenticated: true,
                user: result.user
            }
        } catch (error) {
            console.error('Error linking with Google:', error);
            // Handle specific error cases
            // @ts-ignore
            if (error.code === 'auth/credential-already-in-use') {
                throw new Error('This Google account is already linked to another user');
            }
            throw error;
        }
    };

    // GOOD
    const signInWithApple = async (): Promise<any> => {
        try {
            const options: SignInWithOAuthOptions = !isNative ? {
                mode: 'popup',
                scopes: ['email', 'name']
            } : { scopes: ['email', 'name'] };
            const result = await FirebaseAuthentication.signInWithApple(
                {
                    skipNativeAuth: !isNative,
                    ...options,
                }
            );

            if (!isNative) {
                // Web implementation
                const provider = new OAuthProvider('apple.com');
                const credential = provider.credential({
                    idToken: result.credential?.idToken,
                    rawNonce: result.credential?.nonce,
                });
                await signInWithCredential(auth, credential);
            }

            return {
                isAuthenticated: true,
                user: result.user
            };
        } catch (error) {
            console.error('Error signing in with Apple:', error);
            console.error("THROWING ERROR");
            // @ts-ignore
            if (error.code === 'auth/cancelled-popup-request' || error.code === 'auth/popup-closed-by-user') {
                console.error("THROWING ERROR");
                return {
                    isAuthenticated: false,
                    user: null
                };
            } else { // @ts-ignore
                if (error.code === 'auth/popup-blocked') {
                    console.error('Popup blocked by browser');
                    console.error("THROWING ERROR");
                    throw new Error('Please enable popups in your browser to login with Apple.');
                } else {
                    console.error("Error signing in with Apple: ", error);
                    console.error("THROWING ERROR");
                    throw error;
                }
            }
        }
    };

    // GOOD
    const linkWithApple = async (): Promise<any> => {
        if (!user || !isAuthenticated) {
            return signInWithApple();
        }

        try {
            const options: SignInWithOAuthOptions = !isNative ? {
                mode: 'popup',
                scopes: ['email', 'name']
            } : { scopes: ['email', 'name'] };
            const result = await FirebaseAuthentication.linkWithApple({
                ...options,
            });
            return {
                isAuthenticated: true,
                user: result.user
            }
        } catch (error) {
            console.error('Error linking with Apple:', error);
            // @ts-ignore
            if (error.code === 'auth/credential-already-in-use') {
                throw new Error('This Apple account is already linked to another user');
            }
            throw error;
        }
    };

    return {
        fetchSignInMethodsForEmail,
        loginWithEmailAndPassword,
        signInWithApple,
        signInWithGoogle,
        linkWithApple,
        linkWithGoogle,
        linkPhoneNumberWithCurrentUser,
        linkWithEmailPassword,
        signInWithPhoneNumberHandler,
        confirmPhoneVerificationCode,
        signUpWithEmailAndPassword,
        fetchCustomTokenAndSignIn: async () => {
            // Check for Auth0 token in local storage
            const auth0Token = await PreferencesService.getAccessToken();

            if (auth0Token && auth) {
                try {
                    const response = await UserService.postAuthToken(auth0Token, AUTH0);
                    const { firebaseToken } = response;

                    if (firebaseToken) {
                        const user = await signInWithCustomToken(auth, firebaseToken);
                        // Remove Auth0 token and store Firebase token
                        await PreferencesService.removeAccessToken();
                        return true;
                    } else {
                        throw new Error('Firebase token not received');
                    }
                } catch (error) {
                    console.error('Error fetching custom token from server:', error);
                    throw error;
                }
            } else {
                return false;
            }
        },

        loginWithUsernameAndPassword: async (username: string, password: string) => {
            // retrieve the users email from the db
            // sign in with the users email and password
            try {
                const user = await UserService.getUserByUsername(username);
                if (!user) {
                    console.error('User not found');
                    throw new Error('User not found');
                }
                const signInMethodsForUsername = await fetchSignInMethodsForEmail(user.email);

                if (signInMethodsForUsername.length === 0) {
                    console.error('User not found');
                    throw new Error('User not found');
                }

                if (signInMethodsForUsername.includes('google.com')) {
                    return await signInWithGoogle();
                } else if (signInMethodsForUsername.includes('apple.com')) {
                    return await signInWithApple();
                } else if (signInMethodsForUsername.includes('password')) {
                    return await loginWithEmailAndPassword(user.email, password);
                }

                throw new Error('No supported sign-in methods found');
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        signOut: async () => {
            try {
                await FirebaseAuthentication.signOut();
                await signOut(auth);
                return true
            } catch (error) {
                console.error('Error signing out:', error);
                throw error;
            }
        },

        deleteUser: async () => {
            try {
                await FirebaseAuthentication.deleteUser();
                return true;
            } catch (error) {
                console.error('Error deleting user:', error);
                return false;
            }
        },

        updateEmail: async (email: string) => {
            if (!user) {
                return false;
            }
            const res = await FirebaseAuthentication.updateEmail({ newEmail: email });
            return true;
        },

        updatePassword: async (newPassword: string) => {
            if (!user) {
                return false;
            }

            const res = await FirebaseAuthentication.updatePassword({ newPassword });
            return true;
        },

        confirmPasswordReset: async (verificationCode: string, newPassword: string) => {
            const res = await FirebaseAuthentication.confirmPasswordReset({
                oobCode: verificationCode,
                newPassword: newPassword,
            });

            return true;
        },

        sendPasswordResetEmail: async (email: string) => {
            await FirebaseAuthentication.sendPasswordResetEmail({
                email: email,
                actionCodeSettings: {
                    url: 'https://ecliptic.day/auth/login/reset-password',
                    handleCodeInApp: true,
                }
            });
            return true;
        },
    };
};

export default AuthService;

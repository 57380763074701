import { AnimationBuilder, createAnimation } from '@ionic/react';

const customBackAnimation: AnimationBuilder = (_, opts) => {
    const { direction, enteringEl, leavingEl } = opts;

    if (direction === 'back' && !document.body.classList.contains('disable-swipe-back')) {
        const animationDuration = 75;

        const rootAnimation = createAnimation()
            .duration(animationDuration)
            .easing('cubic-bezier(0.47,0,0.745,0.715)');

        const enteringAnimation = createAnimation()
            .addElement(enteringEl)
            .fromTo('transform', 'translateX(-100%)', 'translateX(0)')
            .fromTo('opacity', 0.8, 1)
            .beforeStyles({
                'transform-origin': 'left center',
                'z-index': '100'
            });

        const leavingAnimation = createAnimation()
            .addElement(leavingEl)
            .fromTo('transform', 'translateX(0)', 'translateX(100%)')
            .fromTo('opacity', 1, 0.8)
            .beforeStyles({
                'transform-origin': 'right center',
                'z-index': '99',
                'pointer-events': 'none'
            })
            .afterStyles({
                'pointer-events': 'auto'
            });

        rootAnimation.addAnimation([enteringAnimation, leavingAnimation]);

        return rootAnimation;
    }

    return undefined; // Use default for other directions
};

export default customBackAnimation;
import { IonButton, IonImg } from "@ionic/react";
import React from "react";
import { UserTrick } from "../../../models/user-trick";
import { format, parseISO } from "date-fns";
import IDXChip from "../../ComponentsUI/IDXChip/IDXChip";
import { BrandEvent } from "../../../models/brandEvent";
import { useHistory } from "react-router";
import { handleTagClickUtil } from "../../../services/utils";

interface EventCrewCardProps {
    event: BrandEvent;
}

const EventCrewCard: React.FC<EventCrewCardProps> = ({ event }) => {

    return (
        <div
            className="cursor-pointer p-0 m-0 border-2 border-gray-900 bg-black rounded-md font-bold">
            <IonImg
                className="object-cover min-w-full min-h-full h-full aspect-video"
                src={event?.cover_pic} alt={event?.name} />
            <div className="bg-black flex flex-col p-4">
                <div className="text-xl text-white">{event.name}</div>
                <div
                    className="flex flex-wrap text-xl my-2 gap-x-2 gap-y-2">
                    <IDXChip
                        bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                        borderColor="border-2 border-primary-secondary"
                        text={event.event_tag} />
                    {event.sports?.map((tag, index) => (<IDXChip key={index}
                        bgColor="hover:bg-highlight-alt/95 click:bg-highlight-alt/95"
                        borderColor="border-2 border-highlight-alt"
                        text={tag}
                        onClickChip={(tag) => console.log("Clicked TAG: " + tag)} />))}
                    {event.features?.map((tag, index) => (
                        <IDXChip key={index}
                            bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                            borderColor="border-2 border-highlight"
                            onClickChip={(tag) => console.log("Clicked TAG: " + tag)}
                            text={tag} />))}
                    {event.event_trick_tags?.map((tag, index) => (
                        <IDXChip text={tag} key={index}
                            bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                            borderColor="border-2 border-highlight"
                            onClickChip={(tag) => console.log("Clicked TAG: " + tag)} />))}
                    {event.event_tricks?.map((trick, index) => (
                        <IDXChip key={index} text={trick.join(" ")}
                            bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                            borderColor="border-2 border-highlight"
                            onClickChip={(tag) => console.log("Clicked TAG: " + tag)} />))}
                    {new Date(new Date().toDateString()).toISOString() >= event.start_date && new Date(new Date().toDateString()).toISOString() <= event.end_date ?
                        <IDXChip
                            bgColor="bg-highlight-secondary/50 hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95"
                            borderColor="border-2 border-highlight-secondary"
                            text={"Live"} /> : <IDXChip
                            bgColor="bg-highlight-danger/50 hover:bg-highlight-danger/95 click:bg-highlight-danger/95"
                            borderColor="border-2 border-highlight-danger"
                            text={"Closed"} />}
                </div>
                <div>
                    <div className="text-sm text-gray-400">
                        {event.description ? event.description : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventCrewCard;
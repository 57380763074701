import React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { callOutline, logoApple, logoGoogle, personCircleOutline } from "ionicons/icons";
import '../Welcome.css'
import { motion } from "framer-motion";
import AuthService from "../../../services/auth.service";
import { useHistory } from "react-router";
import { useSignUp } from "../SignUpProvider/SignUpProvider";
import UserService from "../../../services/user.service";
import { processUserData } from "../../../services/utils";

interface WelcomeTheseNutsProps {
    login: boolean;
    authService: ReturnType<typeof AuthService>;
    platform: string;
    handleAuthedStep: () => void;
    handleUserPasswordAuthedStep?: () => void;
    handleEmailAuthStep?: () => void;
    handlePhoneAuthStep?: () => void;
}

type ButtonConfig = {
    icon: string;
    text: string;
    onClick: (platform: string) => void;
    iconClass: string;
    iconColor?: string;
    className: string;
    providerName: string;
};

const AuthenticationButtons: React.FC<WelcomeTheseNutsProps> = ({
    login,
    authService,
    platform,
    handleAuthedStep,
    handleUserPasswordAuthedStep,
    handleEmailAuthStep,
    handlePhoneAuthStep
}) => {
    const history = useHistory();
    const { signUpData } = useSignUp();
    const [displayNukeButton, setDisplayNukeButton] = React.useState(false);

    const handleSignUpAndLink = async (user: any) => {
        // post to user service with email and user_id
        const user_id = await UserService.createUserOnboardingEmail(user.uid, user.email, signUpData.name, signUpData.username);
        // Update User Information
        /*
        birthday - YYYY-MM-DD
        name - string
        username - string
        sports ? sports - string[] : []
        location ? location.location_id - string
        location ? location.name - string
        level ? level: number
        terrain ? terrain: string[]
        */
        /*
        TODO: Update Referral Code on SIGNUP
        */
        const processed = processUserData(signUpData);

        await UserService.upsertUserProfileInformationOnboarding(
            user_id,
            processed.name,
            processed.username,
            processed.birthday,
            processed.sports,
            processed.level,
            processed.uniqueTerrain,
            processed.location_id,
            processed.location_name
        );
        return;
    }

    const signInWithAppleHandler = async () => {
        try {
            console.log("SIGNING IN WITH APPLE");
            if (login) {
                const res = await authService.signInWithApple();
                if (res?.isAuthenticated) {
                    handleAuthedStep();
                }
            } else {
                const res = await authService.linkWithApple();
                if (res?.isAuthenticated) {
                    await handleSignUpAndLink(res.user);
                    handleAuthedStep();
                }
            }
            return;
        } catch (error) {
            console.error("Error signing in with Apple: ", error);
            return;
        }
    }

    const signInWithGoogleHandler = async () => {
        try {
            console.log("SIGNING IN WITH GOOGLE");
            if (login) {
                const res = await authService.signInWithGoogle();
                if (res?.isAuthenticated) {
                    handleAuthedStep();
                }
            } else {
                const res = await authService.linkWithGoogle();
                if (res?.isAuthenticated) {
                    await handleSignUpAndLink(res.user);
                    handleAuthedStep();
                }
            }
            return;
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            return;
        }
    }

    const handleSignIn = async (provider: string) => {
        try {
            if (provider === 'apple') {
                await signInWithAppleHandler();
            } else if (provider === 'google') {
                await signInWithGoogleHandler();
            } else if (provider === 'phone') {
                handlePhoneAuthStep();
            } else {
                if (login) {
                    handleUserPasswordAuthedStep();
                } else {
                    handleEmailAuthStep();
                }
            }
        } catch (error) {
            // Handle error
            console.error('Sign-in error:', error);
        } finally {
        }

        return;
    };

    const buttonConfigs: Record<string, ButtonConfig> = {
        apple: {
            icon: logoApple,
            text: login ? "Continue with Apple" : "Sign Up with Apple",
            onClick: (platform: string) => handleSignIn(platform),
            iconClass: "mr-2 text-black",
            iconColor: "dark",
            className: "",
            providerName: 'apple'
        },
        google: {
            icon: logoGoogle,
            text: login ? "Continue with Google" : "Sign Up with Google",
            onClick: (platform: string) => handleSignIn(platform),
            iconClass: "mr-2 text-red-500",
            className: "",
            providerName: 'google'
        },
        email: {
            icon: personCircleOutline,
            text: login ? 'Use email / username' : 'Use email',
            onClick: (platform: string) => handleSignIn(platform),
            iconClass: "mr-2 text-highlight",
            className: "",
            providerName: 'password'
        },
        phone: {
            icon: callOutline,
            text: login ? 'Use phone number' : 'Sign up with phone number',
            onClick: (platform: string) => handleSignIn(platform),
            iconClass: "mr-2 text-highlight",
            className: "",
            providerName: 'phone'
        }
    };

    const loginPlatformOrder: Record<string, string[]> = {
        ios: ['apple', 'google', 'email', 'phone'],
        android: ['google', 'apple', 'email', 'phone'],
        web: ['email', 'google', 'apple', 'phone']
    };

    const signUpPlatformOrder: Record<string, string[]> = {
        ios: ['apple', 'google', 'email'],
        android: ['google', 'apple', 'email'],
        web: ['email', 'google', 'apple']
    };

    const currentPlatformOrder = login ? (loginPlatformOrder[platform as keyof typeof loginPlatformOrder] || loginPlatformOrder.web) : (signUpPlatformOrder[platform as keyof typeof signUpPlatformOrder] || signUpPlatformOrder.web);

    const renderButtonContent = (config: any) => (
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <IonButton
                className={(login ? 'neon-button' : 'neon-button-alt') + ` ${config.className} w-64 h-12`}
                onClick={() => {
                    config.onClick(config.providerName)
                }}
            >
                <IonIcon icon={config.icon} className={config.iconClass} color={config.iconColor} />
                <span className="text-lg font-bold text-white normal-case">{config.text}</span>
            </IonButton>
        </motion.div>
    );

    const renderButton = (key: string) => {
        const config = buttonConfigs[key as keyof typeof buttonConfigs];
        return renderButtonContent(config);
    };

    return (
        <div className="flex flex-col justify-start items-center space-y-6 w-full">
            {currentPlatformOrder.map((key: string) => (
                <React.Fragment key={key}>
                    {renderButton(key)}
                </React.Fragment>
            ))}
        </div>
    );
};

export default AuthenticationButtons;
import { IonImg } from "@ionic/react";
import { UserTrickSearch } from "../../../models/user-trick-search";

interface SavedTrickCardProps {
    trick: UserTrickSearch;
}

const SavedTrickCard: React.FC<SavedTrickCardProps> = ({ trick }) => (
    <div className="cursor-pointer p-0 m-0 border border-gray-900 bg-black rounded-md font-bold">
        <IonImg
            className="object-cover min-w-full min-h-full h-full aspect-video"
            src={trick?.thumbnail}
            alt={trick?.trick_name}
        />
        <div className="bg-black p-4">
            <div className="text-base text-white">{trick.trick_name}</div>
            <div className="text-sm">{trick.username ? trick.username : trick.name}</div>
            {trick.location && trick.location !== "" && (
                <div className="text-sm">{trick.location}</div>
            )}
        </div>
    </div>
);

export default SavedTrickCard;
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { useDetermineOnboarding } from '../../../hooks/useDetermineOnboarding';

// Create a function component wrapper to use the hook
const ErrorBoundaryWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { resetDetermination } = useDetermineOnboarding();

    return (
        <ErrorBoundaryClass onReset={resetDetermination}>
            {children}
        </ErrorBoundaryClass>
    );
};

// Props interface for the class component
interface Props {
    children: ReactNode;
    onReset: () => void;
}

interface State {
    hasError: boolean;
}

// Rename the class component and make it private to this file
class ErrorBoundaryClass extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(error: Error): State {
        return { hasError: false };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Caught error:', error);
        console.error('Component stack:', errorInfo.componentStack);

        // Call the reset function from the hook
        this.props.onReset();
    }

    public render() {
        return this.props.children;
    }
}

// Export the wrapper component as the default
export default ErrorBoundaryWrapper;
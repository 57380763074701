import { IonButton, IonImg, useIonModal, useIonToast } from "@ionic/react";
import React, { useState } from "react";
import { Post } from "../../../models/post";
import IDXProfileButton from "../../ComponentsUI/IDXProfileButton/IDXProfileButton";
import { ellipsisHorizontalOutline, pencilOutline, trashOutline } from "ionicons/icons";
import PostUpsert from "../../ComponentsCrewProfile/PostUpsert/PostUpsert";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Brand } from "../../../models/brand";
import BrandsService from "../../../services/brand.service";

interface EventCrewCardProps {
    post: Post;
    editBrand: boolean;
    brand: Brand;
    goToLink: (link: string) => void;
}

const PostPage: React.FC<EventCrewCardProps> = ({ post, editBrand, goToLink, brand }) => {
    const queryClient = useQueryClient();

    const [presentAlert] = useIonToast();

    const [currentPhoto, setCurrentPhoto] = useState<string>(undefined);
    const [viewPhotoFullScreen, setViewPhotoFullScreen] = useState(false);
    const [currPostEditing, setCurrentPostEditing] = useState<Post>(undefined);

    // Post Modal
    // Post mutation for refreshing posts after edit
    const refreshPostsMutation = useMutation({
        mutationFn: (brandId: string) => BrandsService.getBrandPosts(brandId),
        onSuccess: (updatedPosts) => {
            queryClient.setQueryData(['brandPosts', brand?.id], updatedPosts);
        }
    });

    const deletePostMutation = useMutation({
        mutationFn: (post: Post) =>
            BrandsService.deletePostForBrand(post.id, brand?.id),
        onMutate: async (deletedPost) => {
            // Cancel any outgoing refetches 
            await queryClient.cancelQueries({ queryKey: ['brandPosts', brand?.id] });

            // Snapshot the previous value
            const previousPosts = queryClient.getQueryData(['brandPosts', brand?.id]);

            // Optimistically update to the new value
            queryClient.setQueryData(['brandPosts', brand?.id], (old: Post[]) =>
                old?.filter(post => post.id !== deletedPost.id)
            );

            // Return context with the snapshotted value
            return { previousPosts };
        },
        onError: (err, deletedPost, context) => {
            // If the mutation fails, use the context we saved to roll back
            queryClient.setQueryData(['brandPosts', brand?.id], context.previousPosts);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['brandPosts', brand?.id] });
        }
    });


    const postDeleteWarningDetails = async (post: Post) => {
        await presentAlert({
            header: 'Do you want to delete this post?',
            message: 'If you delete the post, the wisdom you shared will be lost forever.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: async () => {
                        await deletePostMutation.mutateAsync(post);
                    },
                },
            ],
        })
    }

    // posts data
    // Modal setup
    const [presentEditPost, dismissPost] = useIonModal(PostUpsert, {
        onDismiss: async (data: string | null | undefined | number, role?: string) => {
            dismissPost(data, role);
            if (role === 'success') {
                await refreshPostsMutation.mutateAsync(brand.id);
            }
        },
        brand: brand,
        post: currPostEditing,
    });

    // Function to handle opening the edit modal
    const handleEditPost = (post: Post) => {
        setCurrentPostEditing(post);
        presentEditPost({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                setCurrentPostEditing(undefined);
            }
        });
    };

    // brand information
    const handleClickShopPhoto = (photo: string) => {
        setCurrentPhoto(photo);
        setViewPhotoFullScreen(true);
    }

    const handleCloseShopPhoto = () => {
        setViewPhotoFullScreen(false);
        setCurrentPhoto(undefined);
    }

    const goToPostDetail = (post: Post) => {
        goToLink(post.link);
    }

    return (
        <div key={post.id}>
            {
                viewPhotoFullScreen &&
                <>
                    <div
                        className="fixed inset-0 z-40 flex items-center justify-center p-4"
                        onClick={() => handleCloseShopPhoto()}
                    >
                        <div className="absolute inset-0 bg-black opacity-75"></div>
                        {/* Separate div for background with opacity */}
                        <div className="flex flex-col items-center justify-center">
                            <img
                                src={currentPhoto}
                                alt="Full Screen"
                                className="z-10 rounded-lg w-full md:w-5/6 lg:w-1/2 xl:w-1/2 object-contain"
                                onClick={(e) => e.stopPropagation()} // Prevent the modal from closing when the image is clicked
                            />
                        </div>
                    </div>
                </>
            }
            <div
                className="cursor-pointer p-0 m-0 border-2 border-gray-900 bg-black rounded-md font-bold">
                <IonImg
                    className="object-cover min-w-full min-h-full h-full aspect-video"
                    src={post?.image} alt={post?.title}
                    onClick={() => handleClickShopPhoto(post?.image)} />
                <div className="bg-black p-4 flex flex-col font-bold">
                    <div className="text-xl text-white">{post.title}</div>
                    <div className="text-gray-400">
                        <div className="text-sm">
                            {post.description ? post.description : ""}
                        </div>
                        <div className="flex flex-row-reverse gap-x-2">
                            {post.link ?
                                <IonButton fill="solid" color="favorite"
                                    size="small"
                                    onClick={() => goToPostDetail(post)}>
                                    {"Explore"}
                                </IonButton> : null}
                            {editBrand ?
                                <>
                                    <IDXProfileButton
                                        color="danger"
                                        fill="outline"
                                        spanText=""
                                        onClick={() => postDeleteWarningDetails(post)}
                                        icon={trashOutline} />
                                    <IDXProfileButton
                                        color="favorite-secondary"
                                        fill="outline"
                                        spanText=""
                                        disabled={refreshPostsMutation.isPending}
                                        onClick={() => handleEditPost(post)}
                                        icon={refreshPostsMutation.isPending ? ellipsisHorizontalOutline : pencilOutline}
                                    /></> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostPage;
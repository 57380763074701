import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import LoginButton from "../components/ComponentsLogin/LoginButton/LoginButton";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";

interface ContainerProps {
    title: string;
    username?: string;
    upload?: boolean;
    profile?: boolean;
    brand?: boolean;
}

const LoginButtonPage: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();

    useEffect(() => {
        console.log("Loading Login In App Page");
    }, [history]);

    return (
        <IonPage>
            <ToolbarSearchBar></ToolbarSearchBar>
            <IonContent>
                <div className="flex flex-col gap-2 m-4 space-y-4">
                    {
                        <LoginButton title={props.title} username={props.username} upload={props.upload}
                            profile={props.profile}></LoginButton>
                    }
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LoginButtonPage;
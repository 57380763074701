import React, { useEffect, useRef } from 'react';
import { IonButton, IonIcon, IonImg } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { createGesture, Gesture } from '@ionic/core';

interface FullScreenPictureProps {
    src: string;
    onClose: () => void;
}

const FullScreenPicture: React.FC<FullScreenPictureProps> = ({ src, onClose }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const GESTURE_SENSITIVITY = 25;

    useEffect(() => {
        let gesture: Gesture | undefined;

        if (containerRef.current) {
            console.log("containerRef.current: ", containerRef.current);
            gesture = createGesture({
                el: containerRef.current,
                threshold: 15,
                gestureName: 'swipe-to-dismiss',
                gesturePriority: 40,
                direction: 'y',
                onMove: (detail) => {
                    const deltaY = detail.deltaY;
                    console.log("deltaY: ", deltaY);
                    if (Math.abs(deltaY) > GESTURE_SENSITIVITY) {
                        onClose();
                    }
                },
            });
            gesture.enable();
        }

        return () => {
            if (gesture) {
                gesture.destroy();
            }
        };
    }, [onClose]);

    return (
        <div
            ref={containerRef}
            className="fixed inset-0 z-40 flex items-center justify-center p-4"
            onClick={onClose}
        >
            <div className="absolute inset-0 bg-black opacity-75"></div>
            <div className="flex flex-col items-center justify-center">
                <IonButton
                    shape="round"
                    fill="clear"
                    color="light"
                    className="absolute top-12 right-4 z-50"
                    onClick={onClose}
                >
                    <IonIcon icon={closeOutline}></IonIcon>
                </IonButton>
                <IonImg
                    src={src}
                    alt="Full Screen Picture"
                    className="z-10 rounded-lg w-full md:w-5/6 lg:w-1/2 xl:w-1/2 object-contain"
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
        </div>
    );
};

export default FullScreenPicture;

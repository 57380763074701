import React, { useEffect, useState } from "react";
import { useIonModal, useIonToast } from "@ionic/react";
import { OverlayEventDetail } from "@ionic/core/components";
import biskSvg from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import { useHistory } from "react-router";
import BiskService from "../../../services/bisk.service";
import { alertCircleOutline } from "ionicons/icons";
import BiskProfileModal from "../BiskProfileModal/BiskProfileModal";
import { UserBisk } from "../../../models/userBisk";
import { AppContext } from "../../../AppStateProvider";
import { BiskTransaction } from "../../../models/biskTransaction";
import { BiskTransactionType } from "../../../models/biskTransactionType";
import useAuthService from "../../../hooks/useAuthService";
import IDXProfileButton from "../../ComponentsUI/IDXProfileButton/IDXProfileButton";

interface ContainerProps {
    senderId: string,
    recipientId: string,
    recipientUsername: string,
    isAuthed: boolean,
    trickId?: string,
    trickName?: string,
    component?: string,
    email?: string
}

const BiskProfile: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();
    const { state, dispatch } = React.useContext(AppContext);
    const { login } = useAuthService();

    const [bisk, setBisk] = useState<number>(state.bisk);
    const [profileBisk, setProfileBisk] = useState<number>(0);
    const [present] = useIonToast();
    const [isYourProfile, setIsYourProfile] = useState<boolean>(false);

    const getBisk = async (profileId: string) => {
        try {
            const bisk: UserBisk = await BiskService.getBiskForUser(profileId);
            return bisk.bisk;
        } catch (e) {
            console.log("Error getting Bisk: ", e);
            setBisk(state.bisk);
            return state.bisk;
        }
    }

    useEffect(() => {
        setBisk(state.bisk);
    }, [state.bisk]);

    useEffect(() => {
        const asyncGetBiskForSender = async () => {
            const biskForSender = await getBisk(props.senderId);
            setBisk(biskForSender);
        }

        const asyncGetBiskForProfile = async () => {
            const biskForRecipient = await getBisk(props.recipientId);
            setProfileBisk(biskForRecipient);
        }

        if (props.senderId && props.isAuthed) {
            asyncGetBiskForSender();
        }

        if (props.recipientId) {
            asyncGetBiskForProfile();
        }

    }, [props.senderId, props.recipientId]);

    useEffect(() => {
        if (props.senderId && props.isAuthed) {
            setIsYourProfile(props.senderId === props.recipientId);
        }
    }, [props.senderId, props.recipientId, props.isAuthed]);

    const onBuy = () => {
        history.push("/shop")
    }

    const onSend = async (biskSend: number, note: string) => {
        try {
            const result: BiskTransaction = await BiskService.sendBiskToUser(props.senderId, props.recipientId, biskSend, note);
            console.log("Result: ", result);
            await present({
                message: `Successfully sent ${biskSend} Bisk to ${props.recipientUsername}!`,
                duration: 5000, // Duration in milliseconds
                icon: biskSvg,
                position: "top",
                color: "success",
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]
            });
            const senderBisk = await getBisk(props.senderId);
            setBisk(senderBisk);
            dispatch({ type: "setBisk", payload: senderBisk })

            const profileBisk = await getBisk(props.recipientId);
            setProfileBisk(profileBisk);
        } catch (e) {
            console.log("Error sending Bisk: ", e);
            await present({
                message: `Failed to send Bisk to ${props.recipientUsername}! Error: ${e}`,
                duration: 5000, // Duration in milliseconds
                icon: alertCircleOutline,
                position: "top",
                color: "danger",
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]
            });
        }
    }

    const onWithdraw = async (biskWithdraw: number, platform: string, platformId: string) => {
        try {
            const transaction: BiskTransaction = await BiskService.withdrawBiskFromUser(props.senderId, biskWithdraw, platform, platformId);
            await present({
                message: "Successfully withdrew $" + biskWithdraw / 100 + " to " + platform + "! Your request will be processed within 24-48 hours!",
                duration: 7500,
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ],
                color: "success",
                icon: biskSvg
            });
            const bisk = await getBisk(props.senderId);
            dispatch({ type: "setBisk", payload: bisk })
            setProfileBisk(bisk);
            await BiskService.sendBiskWithdrawalAlert(state.user?.name, state.user?.username, state.user?.email, biskWithdraw, platform, platformId, transaction.transaction_id);
        } catch (e) {
            console.log("Error withdrawing Bisk: ", e);
            await present({
                message: `Failed to withdraw Bisk! Reach out to devs :/ Error: ${e}`,
                duration: 5000,
                color: "danger",
                icon: alertCircleOutline,
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]
            })
        }
    }

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            icon: biskSvg,
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const onClickUsername = (username: string) => {
        history.push('/profile/' + username);
    }

    const onClickObject = (targetId: string, objectId: string, transactionType: string) => {
        if (transactionType === BiskTransactionType.REDEEM || transactionType === BiskTransactionType.REDEEM_FOR_OBJECT) {
            history.push('/shop');
        }

        if (transactionType === BiskTransactionType.SEND_TO_OBJECT) {
            history.push('/clip/' + targetId + "/" + objectId);
        }
    }

    const [presentBiskModal, dismissBiskModal] = useIonModal(BiskProfileModal, {
        onDismiss: (data: string, role: string) => dismissBiskModal(data, role),
        onBuy: onBuy,
        bisk: bisk,
        profileBisk: profileBisk,
        onWithdraw: onWithdraw,
        trickName: props.trickName,
        recipientUsername: props.recipientUsername,
        onSend: onSend,
        isYourProfile: isYourProfile,
        email: props.email,
        recipientId: props.recipientId,
        onClickUsernameHandler: onClickUsername,
        onClickObjectHandler: onClickObject,
    });

    const openBiskModal = async () => {
        // Refresh only profileBisk before opening modal
        if (props.recipientId) {
            const recipientBisk = await getBisk(props.recipientId);
            setProfileBisk(recipientBisk);
        }

        presentBiskModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onBiskClick = async () => {
        if (!props.isAuthed) {
            presentToastLoginHandler("You must be logged in to send/view Bisk!");
        } else {
            // Open Modal
            await openBiskModal();
        }
    };

    return (
        <IDXProfileButton onClick={onBiskClick} icon={biskSvg} spanText={"Bisk"} />
    );
};

export default BiskProfile;
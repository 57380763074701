import { useEffect } from 'react';

const useResizeObserverErrorHandler = () => {
    useEffect(() => {
        const handleError = (e: ErrorEvent) => {
            if (
                e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
                e.message === 'ResizeObserver loop limit exceeded'
            ) {
                e.stopImmediatePropagation();
                e.preventDefault();
            }
        };
        window.addEventListener('error', handleError);
        return () => {
            window.removeEventListener('error', handleError);
        };
    }, []);
};

export default useResizeObserverErrorHandler;
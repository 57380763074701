import {IonContent, IonIcon, IonItem, IonLabel, IonPage} from "@ionic/react";
import {hammer, logoInstagram, logoYoutube, mailOutline, planetOutline, snow} from "ionicons/icons";
import React, {useEffect} from "react";
import {Brand} from "../../../models/brand";
import {handleInstagramLink, handleTikTokLink, handleTwitterLink, handleYoutubeLink} from "../../../services/utils";
import SocialProfileLink from "../../ComponentsProfile/SocialProfileLink/SocialProfileLink";
import xLogo from "../../../assets/icons/logos/x-logo.svg";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    userProfileSocial: Brand,
}

const BrandProfileSocial: React.FC<ContainerProps> = ({onDismiss, userProfileSocial}) => {
    const [noProfileInformation, setNoProfileInformation] = React.useState(false);

    useEffect(() => {
        if (!(userProfileSocial?.profile?.trick_interests && userProfileSocial?.profile?.trick_interests?.length > 0)
            && !(userProfileSocial?.profile?.discord && userProfileSocial?.profile?.discord !== "")
            && !(userProfileSocial?.profile?.instagram && userProfileSocial?.profile?.instagram !== "")
            && !(userProfileSocial?.profile?.twitter && userProfileSocial?.profile?.twitter !== "")
            && !(userProfileSocial?.profile?.youtube && userProfileSocial?.profile?.youtube !== "")
            && !(userProfileSocial?.profile?.website && userProfileSocial?.profile?.website !== "")
            && !(userProfileSocial?.profile?.tiktok && userProfileSocial?.profile?.tiktok !== "")
            && !(userProfileSocial?.profile?.contact && userProfileSocial?.profile?.contact !== "")
            && !(userProfileSocial?.profile?.brand_description && userProfileSocial?.profile?.brand_description !== "")
            && !(userProfileSocial?.profile?.setup && userProfileSocial?.profile?.setup !== "")) {
            setNoProfileInformation(true);
        }
    }, [userProfileSocial]);

    return (
        <IonPage>
            <IDXModalHeader leftButtonAction={() => onDismiss(null, 'cancel')}
                            titleText={`Information`}
                            rightButtonText={'Close'} rightButtonAction={() => onDismiss(null, 'cancel')}/>
            <IonContent className="ion-padding flex">
                {noProfileInformation ?
                    <div className="text-center text-base">Looks like there is no crew information filled out
                        yet!</div> : null}
                {userProfileSocial?.profile?.brand_description && userProfileSocial?.profile?.brand_description !== "" ?
                    <div className="flex-col mt-4 text-center">
                        <div>{userProfileSocial.name}</div>
                        <div>{userProfileSocial.profile.brand_description}</div>
                    </div> : <div></div>}
                {userProfileSocial?.profile?.trick_interests && userProfileSocial?.profile?.trick_interests?.length > 0 ?
                    <div className="flex-row mt-4">
                        <IonItem className="flex-row mt-4 items-center">
                            <IonIcon icon={snow} size="large" className="mr-4"></IonIcon>
                            {userProfileSocial.profile.trick_interests.map((trick: string, index) => (
                                <div className="mr-2" key={index}>{trick}</div>))}
                        </IonItem>
                    </div> : <div></div>}
                {
                    userProfileSocial?.profile?.setup && userProfileSocial?.profile?.setup !== "" ?
                        <IonItem className="flex-row mt-4 items-center">
                            <IonIcon icon={hammer} size="large" className="mr-4"></IonIcon>
                            <IonLabel>
                                <h2>Setup/Accomplishments</h2>
                                <p className="whitespace-pre-wrap break-all">
                                    {userProfileSocial?.profile?.setup.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br/>
                                        </React.Fragment>
                                    ))}</p>
                            </IonLabel>
                        </IonItem> : <div></div>
                }
                {userProfileSocial?.profile?.contact && userProfileSocial?.profile?.contact !== "" ?
                    <SocialProfileLink icon={mailOutline} link={`mailto:${userProfileSocial.profile.contact}`}
                                       value={userProfileSocial.profile.contact}></SocialProfileLink> : <div></div>}
                {userProfileSocial?.profile?.discord && userProfileSocial?.profile?.discord !== "" ?
                    <SocialProfileLink svg_path="/assets/icon/icons8-discord.svg"
                                       value={userProfileSocial.profile.discord}></SocialProfileLink> : <div></div>}
                {userProfileSocial?.profile?.instagram && userProfileSocial?.profile?.instagram !== "" ?
                    <SocialProfileLink icon={logoInstagram}
                                       link={handleInstagramLink(userProfileSocial.profile.instagram)}
                                       value={userProfileSocial.profile.instagram}></SocialProfileLink> : <div></div>}
                {userProfileSocial?.profile?.tiktok && userProfileSocial?.profile?.tiktok !== "" ?
                    <SocialProfileLink svg_path="/assets/icon/icons8-tiktok.svg"
                                       link={handleTikTokLink(userProfileSocial.profile.tiktok)}
                                       value={userProfileSocial.profile.tiktok}></SocialProfileLink> : <div></div>}
                {userProfileSocial?.profile?.twitter && userProfileSocial?.profile?.twitter !== "" ?
                    <SocialProfileLink icon={xLogo} value={userProfileSocial.profile.twitter}
                                       link={handleTwitterLink(userProfileSocial.profile.twitter)}></SocialProfileLink> :
                    <div></div>}
                {userProfileSocial?.profile?.youtube && userProfileSocial?.profile?.youtube !== "" ?
                    <SocialProfileLink icon={logoYoutube} value={userProfileSocial.profile.youtube}
                                       link={handleYoutubeLink(userProfileSocial.profile.youtube)}></SocialProfileLink> :
                    <div></div>}
                {userProfileSocial?.profile?.website && userProfileSocial?.profile?.website !== "" ?
                    <SocialProfileLink icon={planetOutline}
                                       value={userProfileSocial.profile?.website_name && userProfileSocial.profile?.website_name !== "" ? userProfileSocial.profile.website_name : userProfileSocial.profile.website}
                                       link={userProfileSocial.profile.website}></SocialProfileLink> : <div></div>}
            </IonContent>
        </IonPage>
    );
};

export default BrandProfileSocial;
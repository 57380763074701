import { closeCircle } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import IDXChip from "../../ComponentsUI/IDXChip/IDXChip";

interface ContainerProps {
    searchValue: string;
    filters: string[];
    tagCallback: (tag: string) => void;
    sport: string
    sportCallback: (sport: string, resetFilters: boolean) => void;
    searchCallback: (search: string) => void;
}

const SearchBarFilter: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [placeholder, setPlaceholder] = useState('');

    const handleDropTag = (tag: string) => {
        props.tagCallback!(tag);
    }

    useEffect(() => {

    }, [props.filters, props.sport, props.searchValue]);

    // TODO: animate placeholder for search bar when clicked on but nothing typed in

    const toTitleCase = (str: string) => {
        return str.toLowerCase().split(' ').map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
        }).join(' ');
    }

    const formatTag = (tag: string) => {
        tag = tag.trim();
        tag = tag.replaceAll('_', ' ');
        return tag.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
    }

    return (
        ((props.sport !== '' && props.sport !== undefined && props.sport !== null) || (props.searchValue !== '' && props.searchValue !== undefined && props.searchValue !== null) || (props?.filters.length > 0)) &&
        <div className="flex flex-row flex-nowrap font-bold text-xl overflow-x-auto mx-4 gap-x-2 my-2">
            {props.sport !== '' && props.sport !== undefined && props.sport !== null ?
                <IDXChip bgColor='hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95'
                    borderColor='border-2 border-highlight-secondary' text={props.sport}
                    onClickChip={(tag) => handleDropTag(tag)} icon={closeCircle}></IDXChip> : <div></div>}
            {props.searchValue !== '' && props.searchValue !== undefined && props.searchValue !== null ?
                <IDXChip bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                    borderColor='border-2 border-primary-secondary' text={props.searchValue}
                    onClickChip={(tag) => handleDropTag(props.searchValue)} icon={closeCircle}></IDXChip> :
                <div></div>}
            {props.filters.map((tag, index) => (
                <IDXChip key={index} bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                    borderColor='border-2 border-primary-secondary' onClickChip={(tag) => handleDropTag(tag)}
                    text={formatTag(tag)} icon={closeCircle}></IDXChip>))}
        </div>
    );
};

export default SearchBarFilter;

import React, { useEffect, useState } from "react";
import AutoVideoPlayer from "../AutoVideoPlayer/AutoVideoPlayer";
import TricksService from "../../../services/tricks.service";
import { Browser } from "@capacitor/browser";
import { IonImg } from "@ionic/react";

interface ContainerProps {
    thumbnail: string;
    src: string,
    id: string
    isCapacitor: boolean;
    isHorizontal: boolean;
    isCached?: boolean;
    isExternal: boolean;
    isYoutube: boolean;
    isVimeo: boolean;
    isAirtime: boolean;
    aspectRatio: number;
    isScrolling: boolean;
    scrollSpeed: number;
}

const HomePageVideoPlayer: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [videoOptions, setVideoOptions] = useState(undefined);
    const [isYoutubeAndRestricted, setIsYoutubeAndRestricted] = useState<boolean>(false);

    const MATCH_URL_YOUTUBE = /(?:youtu\.be\/|youtube(?:-nocookie|education)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=|shorts\/|live\/))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//

    const getID = (url: string) => {
        return url.match(MATCH_URL_YOUTUBE)[1]
    }

    const checkRegionRestriction = (videoData: any) => {
        // Assuming videoData is the parsed JSON response from the API
        const items = videoData.items;
        if (!items || items.length === 0) {
            console.log("No video information available.");
            return false;
        }

        // Using optional chaining to safely access nested properties
        const blockedRegions = items[0].contentDetails?.regionRestriction?.blocked;
        if (blockedRegions && blockedRegions.length > 0) {
            console.log("Region restriction present, blocked in:", blockedRegions);
            return true;
        } else {
            console.log("No region restrictions found.");
            return false;
        }
    }

    const processVideoOptions = async () => {
        if (props.isYoutube) {
            if (props.isCapacitor) {
                const videoId = getID(props.src);
                const contentDetailsData = await TricksService.getIsExternalVideoRestricted(videoId);
                setIsYoutubeAndRestricted(checkRegionRestriction(contentDetailsData));
            }
        }

        setVideoOptions({
            controls: true,
            loop: true,
            playbackRates: [0.25, 0.5, 1, 1.5],
            poster: props.thumbnail,
            sources: props.src,
        });
    }

    useEffect(() => {
        if (props.src) {
            processVideoOptions();
        }
    }, [props.src, props.thumbnail, props.isCapacitor, props.isHorizontal, props.isYoutube]);


    const getThresholdForVideo = () => {
        if (document.documentElement.clientWidth < 400) {
            return .9;
        }

        if (document.documentElement.clientWidth < 640) {
            return .925; // good
        }

        if (document.documentElement.clientWidth < 768) {
            return .97; // good
        }

        if (document.documentElement.clientWidth < 1024) {
            return .95; // good
        }

        if (document.documentElement.clientWidth < 1280) {
            return .925;
        }

        if (document.documentElement.clientWidth < 1536) {
            return .825;
        }

        if (document.documentElement.clientWidth >= 1536) {
            return .85;
        }

        return .85;
    }

    return (
        <AutoVideoPlayer
            src={props.src}
            thumbnail={props.thumbnail}
            isCapacitor={props.isCapacitor}
            threshold={getThresholdForVideo()}
            isHorizontal={props.isHorizontal}
            isYoutube={props.isYoutube}
            isExternal={props.isExternal}
            isVideoRestricted={isYoutubeAndRestricted}
            isScrolling={props.isScrolling}
            scrollSpeed={props.scrollSpeed}
            aspectRatio={props.aspectRatio} />
    );
};

export default HomePageVideoPlayer;
import React from "react";

const WelcomeHelp: React.FC = () => {
    return (
        <div className="text-sm text-white text-center px-2 py-4">
            Text us at 1-855-629-2475 or DM us on IG @ ecliptic.day if you have trouble signing up or logging in.
        </div>
    );
}

export default WelcomeHelp;

import { IonAvatar, IonSkeletonText, IonIcon } from '@ionic/react';
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import React from 'react';

const BoardLoading: React.FC = () => {
    return (
        <div>
            {
                [...Array(10)].map((_, index) => (
                    <div className="flex items-center justify-between p-4 border-b border-gray-700" key={index}>
                        <div className="flex items-center space-x-3">
                            <IonAvatar>
                                <IonSkeletonText animated />
                            </IonAvatar>
                            <div className="space-y-2">
                                <IonSkeletonText animated style={{ width: '100px' }} />
                                <IonSkeletonText animated style={{ width: '150px' }} />
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-16 h-16 bg-gray-700 rounded-full">
                            <IonIcon icon={biskLogo} className="text-gray-500" style={{ fontSize: '24px' }} />
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default BoardLoading;
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import React from "react";
import {UserType} from "../../../models/usertype";
import CommentDisplay from "../CommentDisplay/CommentDisplay";
import {chevronBackOutline} from "ionicons/icons";


interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    trickId: string
    isAuthed: boolean,
    userType: UserType,
    publicId: string,
    onClickResult: (username: string) => void,
    setTrickCommentsCount?: (count: number) => void,
}

const CommentModal: React.FC<ContainerProps> = ({
                                                    onDismiss,
                                                    trickId,
                                                    isAuthed,
                                                    publicId,
                                                    onClickResult,
                                                    setTrickCommentsCount
                                                }) => {

    const [displayCommentLikes, setDisplayCommentLikes] = React.useState<boolean>(false);

    const onClickPerson = (username: string) => {
        onClickResult(username);
        onDismiss();
    }

    const handleDisplayCommentLikes = () => {
        setDisplayCommentLikes(true);
    }

    //return the new array of user profiles
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="flex justify-between flex-row">
                    {
                        !displayCommentLikes ?
                            <IonButtons slot="start" className="mr-14">
                                <IonButton strong={true} color="theme-alternative"
                                           onClick={() => onDismiss(null, 'cancel')}>
                                </IonButton>
                            </IonButtons>
                            :
                            <IonButtons slot="start">
                                <IonButton color="theme-alternative" onClick={() => setDisplayCommentLikes(false)}>
                                    <IonIcon slot="start" icon={chevronBackOutline}></IonIcon>
                                </IonButton>
                            </IonButtons>
                    }

                    <IonTitle
                        className="items-center text-center">{displayCommentLikes ? "Likes" : "Comments"}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} color="theme-alternative" onClick={() => onDismiss(null, 'cancel')}>
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="pt-2">
                    <CommentDisplay trickId={trickId}
                                    publicId={publicId}
                                    isAuthed={isAuthed}
                                    onClickPerson={onClickPerson}
                                    displayCommentLikesForModal={true}
                                    handleClickViewCommentLikes={handleDisplayCommentLikes}
                                    viewCommentLikes={displayCommentLikes}
                                    onSetTrickCommentCount={setTrickCommentsCount}/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CommentModal;
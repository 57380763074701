import {
    IonAvatar,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonSpinner
} from "@ionic/react";
import React, { useEffect } from "react";
import { Brand } from "../../../models/brand";
import { User } from "../../../models/user";
import { UserType } from "../../../models/usertype";
import UserService from "../../../services/user.service";
import { AppContext } from "../../../AppStateProvider";
import UsernameDisplay from "../Username/UsernameDisplay";
import { UserRole } from "../../../models/userRole";
import { IonInfiniteScrollCustomEvent } from "@ionic/core/dist/types/components";
import IDXModalHeader from "../IDXModalHeader/IDXModalHeader";
import { useHistory } from "react-router";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    userProfileId: string,
    followers: boolean,
}

//change ProfileSocial to obtain all the profiles from the user_id that is stored in the following array, which is stored in the userProfileFollowing.user_interactions.following
//call UserServices.getUserProfile() to get the user profile in ProfileFollowing useEffect
const ViewProfileFollowInfoButton: React.FC<ContainerProps> = ({
    onDismiss,
    userProfileId,
    followers,
}) => {
    const history = useHistory();

    //create a const where I can store the user profiles that are stored in the following array
    const [followProfiles, setFollowProfiles] = React.useState<User[]>([]);
    const [followProfilesDisabled, setFollowProfilesDisabled] = React.useState<boolean>(false);
    const [followCrews, setFollowCrews] = React.useState<Brand[]>([]);
    const [followCrewsDisabled, setFollowCrewsDisabled] = React.useState<boolean>(false);

    const { state } = React.useContext(AppContext);
    const [segmentValue, setSegmentValue] = React.useState("users");
    const [loading, setLoading] = React.useState(false);

    const [pageUser, setPageUser] = React.useState(1);
    const [pageCrew, setPageCrew] = React.useState(1);

    const pageSize = 15;

    const generateMoreFollowers = async (ev: IonInfiniteScrollCustomEvent<any>) => {
        console.log("Generating more followers");
        const userProfilesMore = await getFollowingFollowersData(userProfileId, UserRole.USER, followers, pageUser);
        if (userProfilesMore.length < pageSize) {
            console.log("Disabling follow profiles");
            setFollowProfilesDisabled(true);
        }

        setPageUser(pageUser + 1);

        // remove all objects that are none
        userProfilesMore.forEach((profile: any) => {
            if (profile === null) {
                userProfilesMore.splice(userProfilesMore.indexOf(profile), 1);
            }
        });

        setFollowProfiles([...followProfiles, ...userProfilesMore]);
        await ev.target.complete();
    };

    const generateMoreCrews = async (ev: IonInfiniteScrollCustomEvent<any>) => {
        console.log("Generating more followers");
        const crewProfilesMore = await getFollowingFollowersData(userProfileId, UserRole.GROUP, followers, pageCrew);

        if (crewProfilesMore.length < pageSize) {
            console.log("Disabling follow profiles");
            setFollowCrewsDisabled(true);
        }

        setPageCrew(pageCrew + 1);


        // remove all objects that are none
        crewProfilesMore.forEach((profile: any) => {
            if (profile === null) {
                crewProfilesMore.splice(crewProfilesMore.indexOf(profile), 1);
            }
        });

        setFollowCrews([...followCrews, ...crewProfilesMore]);
        await ev.target.complete();
    };

    const getFollowingFollowersData = async (user_id: string, role: string, following: boolean, page: number) => {
        console.log("Getting following followers data");
        let profiles = []
        profiles = await UserService.getUsersFollowFull(user_id, role, page, pageSize, following)
        return profiles;
    }

    useEffect(() => {
        const getFollowingFollowersHandler = async () => {
            setPageUser(1);
            setPageCrew(1);

            setLoading(true);

            const userProfiles = await getFollowingFollowersData(userProfileId, UserRole.USER, followers, pageUser);
            if (userProfiles.length < pageSize) {
                setFollowProfilesDisabled(true);
            }

            setPageUser(pageUser + 1);
            setFollowProfiles([...followProfiles, ...userProfiles]);

            const crewProfiles = await getFollowingFollowersData(userProfileId, UserRole.GROUP, followers, pageCrew);
            if (crewProfiles.length < pageSize) {
                setFollowCrewsDisabled(true);
            }

            setPageCrew(pageCrew + 1);

            setFollowCrews([...followCrews, ...crewProfiles]);
            setLoading(false);
        }

        if (userProfileId && userProfileId !== "") {
            getFollowingFollowersHandler();
        }
    }, [userProfileId, followers]);

    const handleSegmentChange = (segment: CustomEvent) => {
        console.log("Segment Changed: " + segment.detail.value);
        setSegmentValue(segment.detail.value);
    }

    const onClickPersonUserInteractions = async (usernameCrew: string, userType: string) => {
        if (userType === UserType.USER) {
            console.log("User Type is User: " + usernameCrew)
            history.push(`/profile/${usernameCrew}`);
            return;
        } else if (userType === UserType.BRAND) {
            history.push(`/crews/${usernameCrew}`);
            return;
        } else {
            history.push(`/profile/${usernameCrew}`);
            return;
        }
    }

    const onClickPersonFollow = (username: string, userType: string) => {
        onDismiss();
        // Use setTimeout to ensure the modal is dismissed before navigation

        setTimeout(() => {
            onClickPersonUserInteractions(username, userType);
        }, 100);
    }

    //return the new array of user profiles
    return (
        <IonPage>
            <IDXModalHeader leftButtonAction={() => onDismiss(null, 'cancel')}
                titleText={followers ? "Followers" : "Following"}
                rightButtonText={'Close'}
                rightButtonAction={() => onDismiss(null, 'cancel')} />
            <IonContent>
                <div>
                    <IonSegment>
                        <IonSegment color="dark" mode="md" value={segmentValue}
                            onIonChange={(ev) => handleSegmentChange(ev)}>
                            <IonSegmentButton value="users">
                                <IonLabel>Homies</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="crews">
                                <IonLabel>Crews</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonSegment>
                </div>
                {segmentValue === 'users' &&
                    <div>
                        {
                            loading ?
                                <IonItem className="items-center space-x-4">
                                    <div>Loading</div>
                                    <IonSpinner name="dots" color="favorite"></IonSpinner>
                                </IonItem> :
                                followProfiles && followProfiles.length > 0 ?
                                    followProfiles.map((profile, index) => (
                                        profile && (
                                            <IonItem className="cursor-pointer" key={index}
                                                onClick={() => onClickPersonFollow(profile.username, UserType.USER)}>
                                                <IonLabel>
                                                    <UsernameDisplay username={profile.username} className={"text-xl"}
                                                        userId={profile.id} loggedInId={state.user?.id}
                                                        onUsernameClickHandler={() => onClickPersonFollow(profile.username, UserType.USER)}></UsernameDisplay>
                                                    <div>{profile.name ? profile.name : ""}</div>
                                                </IonLabel>
                                                {/* add profile IonAvatar */}
                                                <IonAvatar slot="start" className="ml-2">
                                                    <img className="shrink w-8 h-8 rounded-full"
                                                        src={profile?.profile ? profile?.profile?.profile_pic ? profile.profile.profile_pic : '/assets/photos/defaultProPic.png' : '/assets/photos/defaultProPic.png'}></img>
                                                </IonAvatar>
                                            </IonItem>
                                        )
                                    )) : <div></div>
                        }
                        <IonInfiniteScroll
                            onIonInfinite={generateMoreFollowers}
                            threshold="30%"
                            disabled={followProfilesDisabled}>
                            <IonInfiniteScrollContent loadingSpinner="bubbles"
                                loadingText="Getting more of your homies"></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </div>
                }
                {
                    segmentValue === 'crews' &&
                    <div>
                        {
                            loading ?
                                <IonItem className="items-center space-x-4">
                                    <div>Loading</div>
                                    <IonSpinner name="dots" color="favorite"></IonSpinner>
                                </IonItem> :
                                followCrews && followCrews.length > 0 ?
                                    followCrews.map((profile, index) => (
                                        profile && (
                                            <IonItem className="cursor-pointer" key={index}
                                                onClick={() => onClickPersonFollow(profile.username, UserType.BRAND)}>
                                                <IonLabel>
                                                    <div className="text-xl">{profile.username}</div>
                                                    <div>{profile.name ? profile.name : ""}</div>
                                                </IonLabel>
                                                {/* add profile IonAvatar */}
                                                <IonAvatar slot="start" className="ml-2">
                                                    <img className="shrink w-8 h-8 rounded-full"
                                                        src={profile?.profile ? profile?.profile?.profile_pic ? profile.profile.profile_pic : '/assets/photos/defaultProPic.png' : '/assets/photos/defaultProPic.png'}></img>
                                                </IonAvatar>
                                            </IonItem>)
                                    )) : <div></div>
                        }
                        <IonInfiniteScroll
                            onIonInfinite={generateMoreCrews}
                            threshold="30%"
                            disabled={followCrewsDisabled}>
                            <IonInfiniteScrollContent loadingSpinner="bubbles"
                                loadingText="Getting more of your crews"></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </div>
                }
            </IonContent>
        </IonPage>
    );
};

export default ViewProfileFollowInfoButton;
import { OverlayEventDetail } from "@ionic/core/components";
import {
    IonAvatar,
    IonButton,
    IonCard,
    IonCardSubtitle,
    IonImg,
    IonSpinner,
    useIonAlert,
    useIonModal,
    useIonToast
} from "@ionic/react";
import { alertCircleOutline, chatbubbleEllipsesOutline } from "ionicons/icons";
import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LocationTrick } from "../../../models/locationTrick";
import { UserTrickSearch } from "../../../models/user-trick-search";
import LocationService from "../../../services/location.service";
import { handleTagClickUtil, splitString } from "../../../services/utils";
import CommentModal from "../../ComponentsComments/CommentModal/CommentModal";
import HomePageVideoPlayer from "../../ComponentsVideo/HomePageVideo/HomePageVideo";
import VideoTagDisplay from "../VideoTagDisplay/VideoTagDisplay";
import TrickLike from "../TrickLike/TrickLike";
import TrickSaveComponent from "../TrickSaveComponent/TrickSaveComponent";
import TrickCardExtraPopover from "../TrickCardExtraPopover/TrickCardExtraPopover";
import BiskHome from "../../ComponentsBisk/BiskHome/BiskHome";
import UsernameDisplay from "../Username/UsernameDisplay";
import TrickShare from "../../ComponentsShare/TrickShare/TrickShare";
import { UserTrickSearchMetadata } from "../../../models/userTrickSearchMetadata";
import ReportReason from "../../ComponentsProfile/ReportReason/ReportReason";
import useAuthService from "../../../hooks/useAuthService";
import TrickCommentsCount from "../../ComponentsComments/TrickCommentsCount/TrickCommentsCount";
import TricksService from "../../../services/tricks.service";
import { TrickMetadataStats } from "../../../models/trickMetadataStats";
import IDXButtonIcon from "../IDXButtonIcon/IDXButtonIcon";

interface ContainerProps {
    trick: UserTrickSearchMetadata;
    isAuthed: boolean;
    userId: string,
    isCapacitor: boolean;
    userType: string;
    publicId: string;
    scrollSpeed?: number;
    isScrolling?: boolean;
}

const HomeTrickCard: React.FC<ContainerProps> = memo((props: ContainerProps) => {
    const history = useHistory();
    const { login } = useAuthService();

    const [presentAlert] = useIonAlert();
    const [profilePicSrc, setProfilePicSrc] = React.useState<string>('/assets/photos/defaultProPic.png');
    const [isHorizontal, setIsHorizontal] = useState<boolean>(undefined);
    const [isExternal, setIsExternal] = useState<boolean>(false);
    const [isYoutube, setIsYoutube] = useState<boolean>(false);
    const [isVimeo, setIsVimeo] = useState<boolean>(false);
    const [isAirtime, setIsAirtime] = useState<boolean>(false);
    const [aspectRatio, setAspectRatio] = useState<number>(16 / 9);
    const [present] = useIonToast();

    const [reportOpen, setReportOpen] = useState(false);

    // Working on implementation - tbd if actually need to do
    const [isCached, setIsCached] = useState<boolean>(false);

    const [trickMetadataStats, setTrickMetadataStats] = useState<undefined | TrickMetadataStats>(undefined);

    const onClickPersonComments = (username: string) => {
        history.push("/profile/" + username);
    }

    const getTrickMetadataStats = async (trickId: string) => {
        const response: TrickMetadataStats = await TricksService.getTrickInteractionsCount(trickId);
        if (response) {
            setTrickMetadataStats(response);
        }
    }

    const [presentComments, dismissComments] = useIonModal(CommentModal, {
        onDismiss: (data: string, role: string) => dismissComments(data, role),
        trickId: props.trick.id,
        isAuthed: props.isAuthed,
        userType: props.userType,
        publicId: props.publicId,
        onClickResult: onClickPersonComments
    })

    const getVideoProperties = (trick: UserTrickSearchMetadata) => {
        if (trick === undefined) {
            return;
        }

        if (trick.object_id === undefined || trick.object_id === "" || trick.object_id === null) {
            setIsHorizontal(false);
            return;
        } else {
            setIsHorizontal(!trick.is_vertical);
            setIsExternal(trick.is_external);
            setIsYoutube(trick.is_youtube);
            setIsVimeo(trick.is_vimeo);
            setIsAirtime(trick.is_airtime);
            setAspectRatio(trick.aspect_ratio);
        }
    }

    useEffect(() => {
        getVideoProperties(props.trick)
        getProfilePic(props.trick);
        setIsCached(false);
    }, [props.trick]);

    useEffect(() => {
        if (props.trick.id !== "" && props.trick.id !== undefined && props.trick.id !== null) {
            getTrickMetadataStats(props.trick.id);
        }
    }, [props.trick.id]);

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            icon: alertCircleOutline,
            position: 'top',
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const handleTagClick = (tag: string) => {
        const newTag = handleTagClickUtil(tag);
        history.push('/search/' + newTag);
    }

    const onClickCard = async (user_id: string, trick_id: string) => {
        if (!props.isAuthed) {
            await presentToastLoginHandler("Login to view clip details");
            return;
        }

        history.push('/clip/' + user_id + '/' + trick_id);
    }

    const onClickHeader = async (username: string) => {
        if (!props.isAuthed) {
            await presentToastLoginHandler("You must be logged in to view profiles!");
            return;
        } else {
            history.push('/profile/' + username);
        }
    }

    const getProfilePic = (trick: UserTrickSearch) => {
        const userProfilePic = trick.profile_pic;
        if (userProfilePic === "" || userProfilePic === undefined || userProfilePic === null) {
            setProfilePicSrc('/assets/photos/defaultProPic.png');
        } else {
            setProfilePicSrc(userProfilePic);
        }
    }

    const addTaggedUserToComments = (comment: string) => {
        const parsedComment = splitString(comment, /(\@[A-Za-z0-9_.-]+)/);
        return (
            <>
                {parsedComment.map((phrase, index) => {
                    if (phrase.at(0) === '@') {
                        return (
                            <span
                                className="text-indigo-300 cursor-pointer"
                                onClick={async () => {
                                    onClickPersonComments(phrase.substring(1));
                                }}
                                key={index}
                            >
                                {phrase}
                            </span>
                        );
                    } else {
                        return <span key={index}>{phrase}</span>;
                    }
                })}
            </>
        );
    }

    const handleTrickLocationClick = async (location: string) => {
        // search for location
        const res: LocationTrick = await LocationService.getTrickLocation(props.trick.id)
        // check if res is not null and res is not {}
        if (res && Object.keys(res).length !== 0 && res.name !== undefined) {
            const lng = res.google_long.toString()
            const lat = res.google_lat.toString()
            history.push(`/map?lat=${lat}&long=${lng}`)
        } else {
            const res = await LocationService.getLocationFromTextPlacesAPI(location)
            if (res) {
                const searched_location = res.result.geometry.location;
                const lng = parseFloat(searched_location.lng)
                const lat = parseFloat(searched_location.lat)
                history.push(`/map?lat=${lat}&long=${lng}`)
            } else {
                const lat = 40.74490596382429;
                const lng = -111.79303497769095;
                history.push(`/map?lat=${lat}&long=${lng}`)
            }
        }
    }

    const openTrickCommentsModal = async () => {
        if (props.isAuthed) {
            presentComments({
                onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                },
            });
        } else {
            await presentToastLoginHandler("You must be logged in to view comments!");
        }
    }

    const handleIsChildOpen = (isOpen: boolean) => {
        setReportOpen(isOpen);
    }

    const handleReportClick = () => {
        console.log("Report Clicked");
        handleIsChildOpen(true);
    }

    return (
        <div key={props.trick.id}
            className="py-2 xs:h-full xs:w-full sm:w-10/12 sm:h-10/12 md:w-9/12 md:h-9/12 l:w-8/12 l:h-8/12 xl:w-7/12 xl:h-7/12">
            <IonCard className="bg-transparent border-0" style={{ margin: 0, padding: 0 }}>
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-start cursor-pointer">
                        <IonAvatar slot="start" className="mx-4"
                            onClick={() => onClickHeader(props.trick?.username)}>
                            <IonImg className="shrink w-12 h-12 rounded-full" src={profilePicSrc}></IonImg>
                        </IonAvatar>
                        <div className="flex flex-col gap-y-0.5">
                            <UsernameDisplay username={props.trick?.username}
                                className={"text-xl font-bold text-zinc-50"}
                                onUsernameClickHandler={onClickHeader} userId={props.trick?.user_id}
                                loggedInId={props.publicId}></UsernameDisplay>
                            {(props.trick?.location !== undefined && props.trick?.location !== "") ?
                                <IonCardSubtitle className="text-xs text-zinc-300"
                                    onClick={() => handleTrickLocationClick(props.trick.location)}>{props.trick.location}</IonCardSubtitle> : null}
                        </div>
                    </div>
                    <ReportReason isOpenParent={reportOpen}
                        text={"User ID: " + props.publicId + " wishes to block " + props.trick.username + " with trick id " + props.trick.id}
                        usernameToReport={props.trick.username} reportingUsername={props.publicId}
                        objectIdToReport={props.trick.id} reportingId={props.publicId}
                        reportedId={props.trick.user_id}
                        handleIsOpen={handleIsChildOpen} />
                    <TrickCardExtraPopover isCapacitor={props.isCapacitor} trick={props.trick} isAuthed={props.isAuthed}
                        userId={props.userId} trickId={props.trick.id} publicId={props.publicId}
                        userType={props.userType} displayTrickShare={false}
                        displayTrickInstagramShare={true} displayTrickDownload={true}
                        userOnDisplayUsername={props.trick?.username}
                        onReportTrick={handleReportClick}></TrickCardExtraPopover>
                </div>

                {
                    isHorizontal === undefined ?
                        <div className="flex justify-center items-center" style={{ height: '360px', width: '100%' }}>
                            <IonSpinner name="crescent" color="theme-secondary"
                                style={{ transform: 'scale(2.5)' }} /> {/* You can choose other spinner names as well */}
                        </div> :
                        <HomePageVideoPlayer
                            thumbnail={props.trick.thumbnail}
                            src={props.trick.video}
                            id={props.trick.id}
                            isCapacitor={props.isCapacitor}
                            isHorizontal={isHorizontal}
                            isCached={false}
                            isExternal={isExternal}
                            isYoutube={isYoutube}
                            isVimeo={isVimeo}
                            isAirtime={isAirtime}
                            aspectRatio={aspectRatio}
                            isScrolling={props.isScrolling}
                            scrollSpeed={props.scrollSpeed}
                        />
                }
                <div className="flex flex-col pl-4 pr-4 my-2 gap-y-1">
                    <div className="text-xl font-bold text-zinc-50"
                        onClick={() => onClickCard(props.trick.user_id, props.trick.id)}>{props.trick.trick_name}</div>
                    {props.trick?.description ?
                        <div className="text-sm text-zinc-300">
                            <div>{addTaggedUserToComments(props?.trick?.description)}</div>
                        </div> : null
                    }
                </div>

                <div className="flex flex-row justify-between mb-2 mx-2">
                    <div className="flex flex-row items-center gap-x-2">
                        <TrickLike trickId={props.trick.id} isAuthed={props.isAuthed} publicId={props.publicId}
                            userType={props.userType} count={trickMetadataStats?.votes} />
                        <IonButton fill="clear" size="small" color="dark" onClick={() => openTrickCommentsModal()}>
                            <IDXButtonIcon size="text-2xl" icon={chatbubbleEllipsesOutline} />
                        </IonButton>
                        <TrickCommentsCount trickId={props.trick.id} isAuthed={props.isAuthed} userType={props.userType}
                            publicId={props.publicId} onClickResult={onClickPersonComments}
                            count={trickMetadataStats?.total_comment_count} />
                        <BiskHome senderId={props.publicId} isAuthed={props.isAuthed} recipientId={props.trick?.user_id}
                            trickId={props.trick?.trick_id} trickName={props.trick?.trick_name}
                            recipientUsername={props.trick?.username} />
                    </div>
                    <div className={"flex flex-row space-x-0.5 items-center"}>
                        <TrickShare isCapacitor={props.isCapacitor} trickId={props.trick.id} trick={props.trick}
                            list={false} username={props.trick.username}></TrickShare>
                        <TrickSaveComponent isAuthed={props.isAuthed} publicId={props.publicId} trickId={props.trick.id}
                            userType={props.userType} />
                    </div>
                </div>
                <div className="">
                    <VideoTagDisplay tags={props.trick?.tags} isAuthed={props.isAuthed} handleTagClick={handleTagClick}
                        trick={props.trick}></VideoTagDisplay>
                </div>
            </IonCard>
        </div>
    )
});

export default HomeTrickCard;
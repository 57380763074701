import React from 'react';
import { IonContent, IonImg, IonPage } from '@ionic/react';

const LoadingSplash: React.FC = () => {
    const gradientStyle = {
        background: 'radial-gradient(circle at center, #1a1a1a 0%, #0D0D0D 50%, #000000 100%)',
    };


    return (
        <IonPage>
            <IonContent>
                <div className="flex flex-col items-center justify-center h-full" style={gradientStyle}>
                    <div
                        className="mb-10 w-64 sm:w-72 md:w-80 lg:w-96 pl-4 sm:pl-4 md:pl-8 lg:pl-12 animate-pulse"> {/* Adjusted widths */}
                        <IonImg
                            src="/assets/logo/ECLIPTIC_NEW_WHITE_PNG_NO_PADDING.png"
                            className="w-full h-auto max-w-[300px]"
                        />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LoadingSplash;
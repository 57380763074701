import {IonImg} from "@ionic/react";
import React from "react";
import {useHistory} from "react-router";
import {Brand} from "../../../models/brand";

interface ContainerProps {
    crew: Brand;
}

const CrewCard: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();

    const onClickCard = (username: string) => {
        history.push('/crews/' + username);
    }

    return (
        <div className="cursor-pointer p-0 m-0 border-2 border-gray-900 bg-black rounded-md font-bold"
             key={props.crew.id}
             onClick={() => onClickCard(props.crew.username)}>
            <IonImg
                src={props.crew.profile.cover_pic ? props.crew.profile.cover_pic : "https://ionicframework.com/docs/img/demos/card-media.png"}
                alt={props.crew.profile.cover_pic ? `${props.crew.name}'s cover pic` : "Silhouette of mountains"}
            />
            <div className="bg-black p-4 flex flex-col">
                <div className="text-xl text-white"
                     onClick={() => onClickCard(props.crew.username)}>{props.crew.name}</div>
                <div className="text-base text-gray-400">
                    {
                        props.crew.profile.brand_description ?
                            props.crew.profile.brand_description
                            :
                            "This is a crew. View their profile to see what's going on"
                    }
                </div>
            </div>
        </div>
    )
};

export default CrewCard;
import React, {memo, useEffect} from 'react';
import HomeTrickCard from "../HomeTrickCard/HomeTrickCard";

interface TrickCardItemProps {
    trick: any;  // Replace 'any' with the actual type of your item
    isAuthed: boolean;
    isCapacitor: boolean;
    userType: string;
    userId: string;
    publicId: string;
    scrollSpeed: number;
    isScrolling: boolean;
}

const HomeTrickCardContainer: React.FC<TrickCardItemProps> = memo((props: TrickCardItemProps) => {
    const {
        trick,
        isAuthed,
        isCapacitor,
        userId,
        publicId,
        userType,
    } = props;

    return (
        <div className="flex flex-col justify-center items-center content-center">
            <HomeTrickCard
                trick={trick}
                isAuthed={isAuthed}
                isCapacitor={isCapacitor}
                userId={userId}
                publicId={publicId}
                userType={userType}
                isScrolling={props.isScrolling}
                scrollSpeed={props.scrollSpeed}
            />
        </div>
    );
});

export default HomeTrickCardContainer;

import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSpinner } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Brand } from "../../../models/brand";
import { Post } from "../../../models/post";
import BrandsService from "../../../services/brand.service";
import PhotoUpload from "../../ComponentsUI/PhotoUpload/PhotoUpload";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";

interface ContainerProps {
    brand: Brand;
    onDismiss?: (data?: string | null | undefined | number, role?: string) => void;
    post?: Post;
    revertToTheMean?: (creatingEvent: boolean, creatingPost: boolean) => void;
}

const PostUpsert: React.FC<ContainerProps> = ({ brand, post, onDismiss, revertToTheMean }) => {
    const history = useHistory();
    const queryClient = useQueryClient();

    const [postTitle, setPostTitle] = useState<string>(undefined);
    const [postDescription, setPostDescription] = useState<string>(undefined);
    const [postLink, setPostLink] = useState<string>(undefined);
    const [postImage, setPostImage] = useState<any>(undefined);
    const [postImageURL, setPostImageURL] = useState<string>(undefined);

    const [uploadPostError, setUploadPostError] = useState(false);
    const [idXUploadPostError, setIdxUploadPostError] = useState(false);
    const [postId, setPostId] = useState<string>(undefined);

    const createPostMutation = useMutation({
        mutationFn: async () => {
            let data = undefined;
            if (postImage) {
                data = new FormData();
                data.append('file', postImage);
                // @ts-ignore
                data.append('filename', postImage.value);
            }

            return BrandsService.upsertPostForBrand(
                postId,
                brand.id,
                postTitle,
                postDescription,
                postLink,
                data,
                postImageURL
            );
        },
        onSuccess: (res) => {
            if (res == null) {
                setIdxUploadPostError(true);
            } else {
                if (post) {
                    onDismiss(brand.id, 'success');
                } else {
                    history.replace('/crews/' + brand.username);
                }
            }
        },
        onError: () => {
            setIdxUploadPostError(true);
        }
    });

    useEffect(() => {
        setPostTitle(undefined);
        setPostDescription(undefined);
        setPostLink(undefined);
        setPostImage(undefined);
        setPostImageURL(undefined);
        setUploadPostError(false);

        if (post) {
            setPostTitle(post.title);
            setPostDescription(post.description);
            setPostLink(post.link);
            setPostImageURL(post.image);
            setPostId(post.id);
        }
    }, [post]);

    const onPostImageUpload = (selectedFile: any) => {
        setPostImage(selectedFile);
    }

    const handleCreatePost = () => {
        if (postTitle && (postImage || postImageURL) && postDescription) {
            createPostMutation.mutate();
        } else {
            setUploadPostError(true);
        }
    };

    return (
        <IonPage>
            {post ? (
                <IDXModalHeader
                    leftButtonText={'Cancel'}
                    leftButtonAction={() => onDismiss(null, 'cancel')}
                    titleText={`Edit post ${brand.name}`}
                    rightButtonText={'Submit'}
                    rightButtonAction={handleCreatePost}
                />
            ) : (
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{`Create a post for ${brand?.name}`}</IonTitle>
                    </IonToolbar>
                </IonHeader>
            )}
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <div className="flex flex-nowrap text-2xl items-center justify-center mx-10">
                            {post ? `Edit ${post?.title} post` : `Create a post for ${brand?.name}`}
                        </div>
                    </IonToolbar>
                </IonHeader>
                <div className="flex flex-col justify-center items-center w-full mt-8">
                    <div className="text-xl">
                        Post Details
                    </div>
                </div>
                <div className="text-lg mx-8 mb-2">
                    Post Photo
                </div>
                <PhotoUpload
                    onProfilePictureSubmit={onPostImageUpload}
                    currEventPhotoUrl={postImageURL}
                />
                <div className="flex flex-col mx-8 my-2">
                    <div className="mb-6">
                        <div className="flex flex-row items-center">
                            <label className="block mb-2 text-lg font-medium">
                                Post Title
                            </label>
                        </div>
                        <input
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder={'Karl Malone'}
                            required
                            value={postTitle}
                            onChange={(e) => setPostTitle(e.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <div className="flex flex-row items-center">
                            <label className="block mb-2 text-lg font-medium">
                                Post Description
                            </label>
                        </div>
                        <textarea
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 resize-y"
                            placeholder={'The description about your post will be displayed below the image and the title - like choosing to create an event or post previously'}
                            required
                            value={postDescription}
                            onChange={(e) => setPostDescription(e.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <div className="flex flex-row items-center">
                            <label className="block mb-2 text-lg font-medium">
                                Post Link
                            </label>
                        </div>
                        <input
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder={'A link people on Ecliptic can click to learn more about your post (most likely a link to your website)'}
                            required
                            value={postLink}
                            onChange={(e) => setPostLink(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex justify-center items-center mt-4">
                    {uploadPostError &&
                        <p className="text-red-500 mt-2">Please fill out all fields!</p>
                    }
                    {idXUploadPostError &&
                        <p className="text-red-500 mt-2">There was an error uploading your post. Please try again.</p>
                    }
                    {post ? (
                        <IonButton color="danger" onClick={() => onDismiss(brand.id, 'cancel')}>
                            Cancel
                        </IonButton>
                    ) : (
                        <IonButton
                            className="mr-4"
                            color="favorite-secondary"
                            onClick={() => revertToTheMean(false, false)}
                        >
                            Back
                        </IonButton>
                    )}
                    <IonButton
                        className=""
                        color="favorite"
                        disabled={createPostMutation.isPending}
                        onClick={handleCreatePost}
                    >
                        {createPostMutation.isPending ? (
                            <IonSpinner name="dots" />
                        ) : post ? (
                            "Edit Post"
                        ) : (
                            "Create Post"
                        )}
                    </IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default PostUpsert;
import React, { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Capacitor } from "@capacitor/core";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonPage,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import WorldWeb from '../components/ComponentsMap/WorldWeb/WorldWeb';
import { LocationTrick } from "../models/locationTrick";
import { UserTrickSearch } from "../models/user-trick-search";
import LocationService from "../services/location.service";
import UserService from "../services/user.service";
import TricksService from "../services/tricks.service";
import { UserType } from "../models/usertype";
import { User } from "../models/user";
import LoginButton from "../components/ComponentsLogin/LoginButton/LoginButton";
import { AppContext } from "../AppStateProvider";
import BiskService from "../services/bisk.service";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import { useAuth } from "../AuthProvider";
import PreferencesService from "../services/preferences.service";
import IonSpinnerMainContent from "../components/ComponentsUI/IonSpinnerMainContent/IonSpinnerMainContent";
import { useAppState } from "../AppListenerProvider";

const MapPage: React.FC = () => {
    const { state } = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();
    const { isAuthenticated, isLoading: authLoading } = useAuth();
    const { isNative } = useAppState();
    const queryClient = useQueryClient();

    const [baseLat] = useState<number>(40.6461);
    const [baseLong] = useState<number>(-111.498);
    const [baseZoom] = useState<number>(4);
    const [blurredMap, setBlurredMap] = useState<boolean | undefined>(undefined);
    const [flyTo, setFlyTo] = useState<boolean>(false);
    const [flyToCoords, setFlyToCoords] = useState<number[] | undefined>(undefined);

    const query = new URLSearchParams(location.search);

    const { data: isSubscribed, isLoading: subscriptionLoading } = useQuery({
        queryKey: ['isSubscribed'],
        queryFn: async () => {
            const auth_id = await PreferencesService.getUserUid();
            if (auth_id) {
                try {
                    const user: User = await UserService.getUserByAuthId(auth_id);
                    return await BiskService.getIsSubscribedById(user.id);
                } catch (e) {
                    return false;
                }
            }
            return false;
        },
        enabled: isAuthenticated,
    });

    const { data: authInfo } = useQuery({
        queryKey: ['authInfo', isSubscribed],
        queryFn: async () => {
            const auth_id = await PreferencesService.getUserUid();
            if (auth_id) {
                const userType = await UserService.getUserType(auth_id);
                if (userType === UserType.USER) {
                    const response: User = await UserService.getUserByAuthId(auth_id);
                    if (response) {
                        const userTricks = await TricksService.getUserTricks(response.id);
                        const isUserGod = (await UserService.getIsUserGod(auth_id)).result;
                        const numberOfInteractions = await UserService.getUserInteractionsCount(response.id);
                        return { isUserGod, userTricksCount: userTricks.length, numberOfInteractions };
                    }
                }
                return { userType };
            }
            return null;
        },
        enabled: isAuthenticated && !authLoading,
    });

    const { data: geoJson, isLoading: isGeoJsonLoading, refetch: refetchGeoJson } = useQuery({
        queryKey: ['mapTricks', isSubscribed],
        queryFn: async () => {
            const res: LocationTrick[] = await LocationService.getAllLocationTricks();
            const resGeoJsonHolder: any = {
                'type': 'FeatureCollection',
                'features': []
            };

            if (res) {
                const trick_ids = res.map((trick) => trick.trick_id);
                const user_tricks: UserTrickSearch[] = await UserService.getTricksFromIds(trick_ids);

                resGeoJsonHolder.features = res.map(trickLocation => {
                    const trick = user_tricks.find((t) => t.trick_id === trickLocation.trick_id);
                    if (trick) {
                        let long = isSubscribed ? (trickLocation.long || trickLocation.google_long) : trickLocation.google_long;
                        let lat = isSubscribed ? (trickLocation.lat || trickLocation.google_lat) : trickLocation.google_lat;

                        const mapThumbUrl = trick.thumbnail.replace("d3btq01zzysv3d.cloudfront.net", "d2xrbrtg3kyq6g.cloudfront.net");

                        return {
                            'type': 'Feature',
                            'properties': {
                                'iconSize': [10, 10],
                                'creation_date': trick.date,
                                'image': mapThumbUrl,
                                'trick': trick,
                                'id': trick.id,
                            },
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [long, lat]
                            }
                        };
                    }
                    return null;
                }).filter(Boolean);
            }

            return resGeoJsonHolder;
        },
        enabled: isAuthenticated && !authLoading && isSubscribed !== undefined,
    });

    useEffect(() => {
        if (authInfo) {
            if (authInfo.userType === UserType.BRAND || authInfo.isUserGod || authInfo.userTricksCount > 0 || isSubscribed || authInfo.numberOfInteractions > 0) {
                setBlurredMap(false);
            } else {
                setBlurredMap(true);
            }
        } else if (isAuthenticated === false) {
            setBlurredMap(true);
        }
    }, [authInfo, isAuthenticated, isSubscribed]);

    useEffect(() => {
        if (query.has("lat") && query.has("long")) {
            setFlyTo(true);
            const lat = query.get("lat")
            const long = query.get("long")
            if (lat && long) {
                setFlyToCoords([parseFloat(long), parseFloat(lat)]);
            }
            query.delete('lat')
            query.delete('long')
            history.replace({
                search: query.toString()
            });
        } else {
            setFlyTo(false);
            setFlyToCoords(undefined);
        }
    }, [location.search]);

    useEffect(() => {
        if (isSubscribed !== undefined && isSubscribed !== state.isSubscribed) {
            refetchGeoJson();
        }
    }, [isSubscribed, state.isSubscribed, refetchGeoJson]);

    const onUploadTrickClick = () => {
        history.push('/upload')
    }

    const onTrickButtonClick = (trick_id: string, user_id: string) => {
        history.push('/clip/' + user_id + "/" + trick_id);
    }

    const reloadAuthInfo = () => {
        queryClient.invalidateQueries({ queryKey: ['authInfo'] });
    }

    const isMapLoading = authLoading || subscriptionLoading || isGeoJsonLoading;

    useEffect(() => {
        if (!isMapLoading) {
            window.prerenderReady = true;
        }
    }, [isMapLoading]);

    if (isMapLoading) {
        <IonPage>
            <div className="flex h-screen justify-center">
                <IonSpinnerMainContent className={"py-20"}
                    size="medium" />
            </div>
        </IonPage>
    }

    return (
        <IonPage>
            <CustomHelmet title={"Ecliptic // Map"}
                description={"Explore Ecliptic, Spots, and Skiers and Snowboarders Sending It Around The WorldWeb"}
                image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/map`} />
            <IonHeader>
            </IonHeader>
            <IonContent fullscreen className="ion-padding-0">
                <WorldWeb lat={baseLat} long={baseLong} zoom={baseZoom} geojson={geoJson}
                    onClick={onTrickButtonClick} blurred={blurredMap} flyTo={flyTo}
                    flyToCoords={flyToCoords}
                    isNative={isNative} isSubscribed={isSubscribed} />
                {isAuthenticated && blurredMap &&
                    <div className="z-100 fixed top-1/4 left-1/2 transform -translate-x-1/2" style={{ zIndex: 100 }}>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle className="text-center">
                                    "To map, you must interact with the Ecliptic Community" - Christopher Railbus.
                                </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <div className="flex flex-col">
                                    <IonButton expand="block" size="large" color="favorite" onClick={onUploadTrickClick}>
                                        Upload Trick
                                    </IonButton>
                                    <IonButton expand="block" size="large" color="secondary" onClick={reloadAuthInfo}>
                                        Reload
                                    </IonButton>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </div>
                }
                {!isAuthenticated && blurredMap &&
                    <div className="z-100 fixed top-1/4 left-1/2 transform -translate-x-1/2" style={{ zIndex: 100 }}>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle className="text-center">
                                    "You underestimate my power!" - Anikin Skyrailer
                                </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="flex flex-col">
                                <LoginButton upload={false} profile={false} />
                                <IonButton expand="block" size="large" color="secondary" onClick={reloadAuthInfo}>
                                    Reload
                                </IonButton>
                            </IonCardContent>
                        </IonCard>
                    </div>
                }
            </IonContent>
        </IonPage>
    );
};

export default MapPage;
import React, { useRef, useCallback, useEffect, useMemo } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { IonContent, IonRefresher, IonRefresherContent, IonSelect, IonSelectOption, IonSpinner } from '@ionic/react';
import BiskActivityCard from '../BiskActivityCard/BiskActivityCard';
import { BiskTransactionType } from '../../../models/biskTransactionType';
import { useBiskTransactions } from '../../../hooks/useBiskTransactions';
import { useAuth } from '../../../AuthProvider';
import ShopRedirect from '../../ComponentsPurchases/ShopRedirect/ShopRedirect';
import BoardLoading from '../../ComponentsUI/BoardLoading/BoardLoading';

interface BiskTransactionListProps {
    blurred: boolean;
    onShopRedirect: () => void;
}

const BiskTransactionList: React.FC<BiskTransactionListProps> = React.memo(({
    blurred,
    onShopRedirect
}) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const { isAuthenticated } = useAuth();
    const {
        biskTransactions,
        hasNextPage,
        biskTransactionsLoading,
        biskFilter,
        getMoreBiskTransactions,
        resetBiskTransactions,
        handleBiskFilterChange,
        isFetchingNextPage,
        status,
        error
    } = useBiskTransactions();

    // Memoize virtualizer options
    const virtualizerOptions = useMemo(() => ({
        count: hasNextPage ? biskTransactions.length + 1 : biskTransactions.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 100,
        overscan: 10, // Increased for smoother scrolling
        initialRect: { width: 0, height: 0 },
    }), [hasNextPage, biskTransactions.length]);

    const rowVirtualizer = useVirtualizer(virtualizerOptions);

    // Memoize loadMore callback
    const loadMore = useCallback(() => {
        if (!isFetchingNextPage && hasNextPage) {
            getMoreBiskTransactions();
        }
    }, [isFetchingNextPage, hasNextPage, getMoreBiskTransactions]);

    // Implement debounced scroll handling
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const handleScroll = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                const virtualItems = rowVirtualizer.getVirtualItems();
                const lastItem = virtualItems[virtualItems.length - 1];
                if (lastItem && lastItem.index >= biskTransactions.length - 1) {
                    loadMore();
                }
            }, 100);
        };

        const scrollElement = parentRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll, { passive: true });
            return () => {
                if (timeoutId) clearTimeout(timeoutId);
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [biskTransactions.length, loadMore, rowVirtualizer]);

    const handleRefresh = useCallback(async (event: CustomEvent) => {
        await resetBiskTransactions();
        event.detail.complete();
    }, [resetBiskTransactions]);

    // Memoize renderItem function
    const renderItem = useCallback((index: number) => {
        const isLoaderRow = index > biskTransactions.length - 1;

        if (index === 1 && blurred) {
            return (
                <ShopRedirect
                    title="Head to the Smoke Shack and Sign Up for the Tow Rope Pass to View Leaderboards!"
                    shopRedirect={onShopRedirect}
                />
            );
        }

        const adjustedIndex = blurred && !isAuthenticated ? index - 1 : index;

        if (isLoaderRow) {
            return hasNextPage ? (
                <div className="p-4 text-center">
                    <IonSpinner name="dots" />
                    <p>Loading more...</p>
                </div>
            ) : (
                <div className="p-4 text-center">Nothing more to load</div>
            );
        }

        return (
            <BiskActivityCard
                transaction={biskTransactions[adjustedIndex]}
                blurred={blurred && adjustedIndex !== 0}
                isAuthed={isAuthenticated}
            />
        );
    }, [biskTransactions, blurred, isAuthenticated, hasNextPage, onShopRedirect]);

    // Memoize BiskHeaderContainer
    const BiskHeaderContainer = useMemo(() => (
        <div>
            <IonRefresher onIonRefresh={handleRefresh} slot="fixed">
                <IonRefresherContent refreshingText={"Refreshing the bisk"}></IonRefresherContent>
            </IonRefresher>
            <div className="items-center my-4 mx-8 justify-center">
                <IonSelect
                    label="The Biskness"
                    interface="popover"
                    placeholder="Select transaction type"
                    style={{ ionBackgroundColor: "none" }}
                    value={biskFilter}
                    onIonChange={(e) => handleBiskFilterChange(e.detail.value)}
                >
                    <IonSelectOption value="">All</IonSelectOption>
                    <IonSelectOption value={BiskTransactionType.SEND}>Sent</IonSelectOption>
                    <IonSelectOption value={BiskTransactionType.AWARD}>Awarded</IonSelectOption>
                    <IonSelectOption value={BiskTransactionType.BUY}>Bought</IonSelectOption>
                    <IonSelectOption value={BiskTransactionType.WITHDRAW}>Withdrew</IonSelectOption>
                    <IonSelectOption value={BiskTransactionType.REDEEM}>Redeemed</IonSelectOption>
                </IonSelect>
            </div>
        </div>
    ), [biskFilter, handleBiskFilterChange, handleRefresh]);

    if (status === 'pending' && biskTransactions.length === 0) return <BoardLoading />;
    if (status === 'error') return <div>Error: {(error as Error).message}</div>;

    return (
        <IonContent scrollY={false}>
            <div
                ref={parentRef}
                style={{
                    height: '100%',
                    overflow: 'auto',
                    willChange: 'transform',
                    WebkitOverflowScrolling: 'touch'
                }}
            >
                {BiskHeaderContainer}
                {biskTransactions.length === 0 ? (
                    <div className="text-xl m-4 text-center">Looks like there is no biskness present :(</div>
                ) : (
                    <div
                        style={{
                            height: `${rowVirtualizer.getTotalSize()}px`,
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                            <div
                                key={virtualRow.index}
                                data-index={virtualRow.index}
                                ref={rowVirtualizer.measureElement}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    transform: `translateY(${virtualRow.start}px)`,
                                    willChange: 'transform',
                                }}
                            >
                                {renderItem(virtualRow.index)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {biskTransactionsLoading && !isFetchingNextPage ? (
                <div className="text-center p-4">Background Updating...</div>
            ) : null}
        </IonContent>
    );
});

export default BiskTransactionList;